import React from 'react';

function SplatterTen(props) {
    return(
        <svg style={props.stylez} version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="100.7000000pt" height="128.000000pt" viewBox="0 0 1007.000000 1280.000000"
 preserveAspectRatio="xMidYMid meet">
<metadata>
Created by potrace 1.15, written by Peter Selinger 2001-2017
</metadata>
<g transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)"
 stroke="none">
<path d="M5760 12785 c-15 -18 -11 -56 20 -200 19 -87 21 -122 17 -270 -4
-130 -10 -189 -26 -250 -49 -183 -139 -284 -187 -210 -18 27 -51 33 -76 13 -9
-7 -37 -46 -63 -87 -89 -141 -208 -225 -352 -251 -101 -18 -139 -5 -204 70
-54 63 -77 72 -122 49 -70 -36 -100 -95 -137 -269 -27 -126 -44 -163 -117
-242 -73 -80 -98 -141 -129 -315 -30 -174 -54 -256 -102 -347 -46 -87 -93
-136 -131 -136 -16 0 -34 6 -40 13 -25 31 -43 110 -52 242 -6 77 -16 151 -23
165 -7 16 -28 30 -61 42 -84 29 -133 104 -150 228 -16 109 41 323 147 558 50
110 59 139 55 169 -7 38 2 61 39 106 32 38 31 72 -2 93 -23 16 -28 16 -59 1
-48 -23 -69 -72 -94 -211 -38 -210 -109 -352 -217 -434 -41 -32 -62 -57 -77
-93 -17 -41 -25 -49 -47 -49 -29 0 -98 -35 -117 -59 -55 -69 -148 -327 -174
-485 l-13 -78 -68 -45 c-47 -31 -70 -53 -75 -71 -3 -15 1 -67 9 -116 21 -122
18 -383 -6 -491 -24 -113 -89 -241 -143 -282 -79 -59 -210 -65 -284 -12 -36
26 -84 105 -81 136 1 14 -4 23 -12 23 -8 0 -24 16 -36 35 -42 68 -115 107
-136 74 -10 -17 -7 -68 7 -101 10 -23 9 -42 -5 -102 -30 -124 -10 -149 204
-257 135 -69 223 -141 236 -193 8 -35 -5 -60 -35 -64 -16 -3 -21 -10 -21 -33
0 -22 -7 -32 -25 -41 -18 -8 -22 -14 -15 -23 7 -9 7 -21 -1 -39 -9 -20 -10
-38 -1 -74 12 -54 5 -67 -61 -109 -58 -37 -37 -39 27 -3 31 17 60 29 65 26 17
-11 20 -67 6 -104 -28 -73 -66 -273 -72 -382 -4 -69 -10 -110 -17 -110 -26 0
-230 -91 -305 -136 -181 -107 -287 -259 -241 -345 9 -16 7 -19 -14 -19 -58 0
-115 -86 -102 -154 12 -64 67 -83 162 -57 96 26 122 98 62 168 -18 21 -25 32
-16 25 39 -31 74 12 47 57 -15 23 14 121 46 160 12 14 25 36 29 48 10 30 103
114 168 150 50 28 159 73 177 73 5 0 11 -24 15 -54 5 -45 40 -194 72 -302 4
-17 0 -23 -23 -31 -64 -23 -121 -93 -137 -170 -11 -47 22 -114 70 -144 34 -21
51 -24 126 -23 54 0 92 5 102 13 12 10 17 8 31 -18 13 -27 14 -32 1 -37 -32
-12 -96 -81 -90 -97 3 -9 -1 -29 -9 -45 -12 -24 -12 -32 -1 -47 12 -16 11 -21
-3 -37 -13 -14 -13 -18 -2 -18 15 0 45 -39 41 -54 -2 -5 3 -13 10 -18 10 -7 9
-11 -4 -19 -26 -15 -22 -29 7 -29 14 0 25 -5 25 -10 0 -7 6 -7 19 -1 21 12 91
3 91 -12 0 -5 7 -4 15 3 8 7 24 10 35 7 11 -3 36 0 56 6 32 10 38 9 51 -8 13
-19 14 -18 8 8 -4 15 -5 27 -2 27 3 0 31 -32 61 -71 55 -69 69 -111 44 -123
-9 -4 -9 -8 -2 -11 7 -4 6 -24 -4 -66 -19 -77 -8 -116 53 -188 25 -29 45 -50
45 -47 0 4 11 -1 25 -10 17 -11 25 -25 25 -45 0 -22 6 -30 34 -40 32 -11 34
-16 40 -68 15 -134 18 -171 12 -171 -22 0 -225 118 -331 194 -92 65 -117 85
-198 164 -77 74 -100 111 -95 150 2 23 11 30 53 43 27 10 51 22 53 29 18 54
-128 151 -360 238 -116 44 -146 60 -196 106 -53 49 -76 101 -75 172 1 52 5 65
27 87 45 45 28 95 -36 111 -20 4 -34 0 -57 -19 -38 -33 -47 -60 -27 -90 13
-20 13 -25 1 -35 -39 -32 -12 -92 95 -219 l79 -93 -28 -24 c-54 -46 -40 -130
26 -155 20 -7 22 -12 13 -28 -17 -33 -11 -103 14 -157 18 -39 21 -55 13 -64
-9 -9 -8 -11 5 -8 10 2 40 -25 82 -74 72 -84 220 -220 321 -294 61 -45 63 -48
42 -58 -26 -13 -37 -74 -15 -87 26 -17 57 -9 81 22 l24 30 67 -33 c50 -24 65
-36 60 -48 -4 -8 -9 -34 -12 -57 l-4 -43 -57 6 c-31 3 -119 15 -196 25 -77 10
-143 19 -148 19 -5 0 -6 7 -3 15 8 23 -24 32 -39 11 -15 -20 -45 -11 -45 13 0
26 -42 61 -72 61 -15 0 -40 -11 -57 -26 -28 -23 -33 -24 -73 -13 -24 6 -37 14
-30 16 20 7 14 41 -10 57 -28 20 -33 20 -53 0 -9 -9 -13 -24 -10 -35 4 -13 2
-17 -7 -14 -7 2 -62 18 -123 35 -477 134 -917 333 -1315 594 -586 385 -880
856 -931 1491 -22 282 20 612 126 980 111 385 287 783 615 1385 208 382 227
432 187 494 -14 21 -25 26 -56 26 -60 0 -76 -25 -77 -124 0 -118 -27 -190
-236 -635 -160 -341 -228 -494 -303 -686 -362 -925 -377 -1662 -43 -2247 222
-389 587 -703 1138 -979 142 -71 493 -219 519 -219 14 0 14 -13 0 -27 -16 -16
-3 -60 22 -74 39 -20 66 -3 94 58 1 2 21 -4 46 -11 24 -8 76 -24 115 -35 61
-18 70 -24 70 -43 -1 -28 33 -37 50 -13 12 15 21 15 133 -11 103 -24 121 -31
125 -50 11 -42 105 -56 135 -20 8 10 30 9 108 -5 54 -10 163 -25 243 -34 217
-25 223 -28 150 -65 -26 -13 -47 -34 -57 -55 -9 -19 -21 -35 -25 -35 -5 0 -4
9 2 19 14 27 13 41 -4 41 -12 0 -38 -48 -40 -75 -1 -5 10 -10 24 -10 19 0 26
-6 28 -24 2 -12 19 -37 38 -54 20 -17 31 -34 26 -39 -10 -10 -69 8 -93 28 -8
8 -22 14 -30 14 -7 0 -37 10 -66 22 -48 20 -51 23 -46 50 4 20 0 35 -12 48
-23 25 -46 25 -77 1 -24 -20 -25 -20 -56 -1 -18 11 -32 24 -32 29 0 5 -10 13
-21 16 -12 4 -41 21 -64 37 -24 17 -55 31 -68 31 -14 0 -28 6 -31 13 -16 42
-136 -12 -197 -90 l-44 -56 -14 28 c-8 15 -15 35 -16 44 -1 18 -75 84 -112
101 -13 6 -23 13 -23 17 0 3 -33 5 -72 5 -88 -2 -155 -32 -211 -94 -48 -53
-67 -92 -74 -151 -5 -40 -1 -59 25 -113 20 -43 40 -71 60 -83 17 -10 29 -21
27 -25 -3 -4 5 -7 18 -8 12 0 49 -3 82 -7 54 -5 68 -3 127 24 45 20 65 25 61
16 -3 -9 2 -14 13 -15 11 -1 6 -4 -11 -8 -42 -9 -47 -50 -9 -80 15 -11 32 -21
39 -21 20 0 48 38 41 56 -5 12 2 24 20 39 20 16 25 26 20 43 -8 25 -53 31 -74
10 -18 -18 -14 1 7 30 10 15 31 51 45 80 26 50 28 53 45 38 10 -9 33 -16 53
-16 46 0 73 29 65 68 -5 26 -1 33 39 61 24 18 44 36 44 42 0 5 5 9 10 9 6 0
10 -12 10 -27 0 -16 9 -43 20 -61 25 -41 25 -42 -5 -42 -34 0 -82 -25 -95 -49
-14 -27 -12 -92 3 -112 36 -47 126 -49 172 -3 13 13 30 21 38 18 24 -9 30 14
7 26 -11 6 -20 17 -20 25 0 17 11 19 25 6 21 -19 124 -42 218 -48 53 -3 97 -9
97 -12 0 -3 -16 -17 -35 -31 -54 -39 -67 -75 -59 -170 7 -82 6 -86 -33 -81 -7
1 -15 -10 -19 -24 -5 -21 -12 -25 -42 -25 -48 0 -113 -33 -137 -70 -11 -16
-26 -30 -33 -30 -22 0 -27 -32 -6 -47 14 -10 16 -17 8 -30 -8 -13 -2 -24 30
-56 l41 -40 82 6 c45 2 85 2 88 -1 3 -3 -9 -21 -27 -39 -33 -34 -32 -56 2 -35
24 15 70 16 70 2 0 -5 -9 -10 -20 -10 -11 0 -20 -5 -20 -11 0 -6 9 -8 21 -4
14 5 28 0 42 -12 12 -11 36 -25 54 -32 61 -24 58 -38 -6 -33 -72 5 -133 -19
-188 -76 l-37 -39 -29 21 c-21 15 -43 21 -84 21 -40 0 -58 5 -67 18 -7 9 -18
17 -25 17 -14 0 -14 -23 1 -38 6 -6 10 -32 10 -58 -2 -40 2 -50 33 -81 34 -34
38 -35 89 -30 53 5 53 5 65 -29 7 -18 28 -46 47 -62 l35 -29 -93 -14 c-70 -11
-219 -14 -598 -14 -530 0 -544 -1 -632 -46 -53 -27 -184 -146 -260 -237 -65
-76 -162 -218 -164 -237 0 -5 -8 -34 -17 -64 -33 -107 -14 -211 38 -211 36 0
59 39 45 76 -6 16 -16 51 -21 78 -8 41 -6 52 7 68 12 13 13 18 4 18 -19 0 -5
65 29 135 37 73 96 143 216 254 106 99 139 116 194 101 69 -18 135 -90 304
-331 59 -84 95 -114 145 -123 22 -3 47 -12 55 -20 12 -12 23 -11 83 8 38 13
84 30 101 39 22 12 34 13 38 6 5 -7 15 -4 29 9 11 10 58 34 104 52 63 26 85
31 94 22 28 -28 99 6 85 41 -3 9 24 28 83 59 l87 45 12 -50 c10 -43 9 -53 -5
-68 -26 -29 -14 -54 26 -55 25 0 46 -11 75 -36 22 -20 52 -46 68 -59 l28 -24
-55 -42 c-287 -220 -212 -656 163 -955 131 -105 244 -148 392 -148 134 0 251
50 338 146 54 59 84 125 107 238 8 38 17 72 19 76 2 4 18 5 34 2 20 -3 33 1
36 9 4 10 20 13 59 11 30 -2 89 3 131 12 90 19 227 21 276 5 49 -17 79 -48 79
-81 0 -36 39 -63 80 -54 17 3 30 3 30 0 0 -16 -89 -132 -138 -180 -73 -71
-146 -116 -247 -149 -64 -22 -87 -25 -120 -18 -34 7 -40 12 -43 36 -2 20 -12
31 -30 39 -15 5 -35 14 -44 19 -25 13 -24 13 -45 -28 -14 -27 -17 -46 -12 -65
7 -26 5 -28 -29 -33 -21 -3 -65 -12 -100 -19 -51 -11 -62 -11 -60 0 2 7 0 13
-4 13 -24 2 -29 -1 -26 -15 2 -13 -19 -22 -92 -43 -292 -83 -624 -242 -658
-316 -19 -42 -15 -73 14 -91 22 -15 29 -15 54 -3 16 8 49 41 74 73 125 161
326 266 631 329 66 14 144 28 173 32 l52 7 0 -31 c0 -42 33 -75 67 -66 45 11
100 53 113 87 19 45 50 66 50 33 0 -8 9 -15 21 -15 16 0 19 4 14 21 -4 13 -3
19 2 16 6 -4 19 0 29 8 25 19 34 19 34 -1 0 -23 24 -11 28 13 4 25 45 46 60
31 15 -15 54 9 48 30 -3 12 11 29 44 55 57 44 136 145 185 238 33 64 37 67 92
84 32 10 61 20 65 22 15 8 38 -71 38 -127 0 -53 3 -61 31 -84 36 -31 53 -32
116 -8 36 14 66 18 119 14 56 -3 81 0 115 15 70 31 96 35 128 19 86 -45 121
-109 141 -257 13 -103 55 -195 108 -239 100 -83 442 -210 564 -210 129 0 285
29 382 71 49 21 91 37 93 35 2 -2 -4 -17 -13 -33 -23 -39 -12 -78 26 -98 40
-21 61 -19 101 8 19 12 62 40 96 60 35 21 71 53 83 72 12 19 27 35 33 35 6 0
35 -25 64 -57 l52 -56 93 6 c123 8 133 12 162 61 13 22 45 58 70 79 56 46 71
74 86 156 6 35 20 86 31 113 33 84 27 114 -33 170 -29 26 -70 54 -91 60 -27 8
-47 24 -68 55 l-30 43 27 10 c15 6 49 27 76 46 26 20 53 34 60 32 7 -2 63 -23
125 -47 100 -40 111 -46 102 -63 -12 -22 -2 -23 51 -7 34 11 41 9 71 -14 49
-36 105 -57 156 -57 37 0 50 5 77 32 18 18 35 38 37 43 2 6 38 20 79 33 41 13
83 25 92 29 30 9 130 -101 157 -174 48 -128 100 -183 175 -183 46 0 80 14 124
50 20 17 46 30 58 30 30 0 97 -51 145 -112 197 -250 309 -578 296 -868 -6
-141 -28 -216 -97 -329 -58 -95 -65 -142 -27 -186 52 -61 147 -35 195 53 22
39 23 52 23 209 -2 447 -119 904 -356 1388 -41 83 -108 208 -149 279 -42 71
-75 130 -74 132 19 17 123 69 168 84 72 24 100 25 170 4 43 -13 66 -29 124
-88 39 -40 87 -91 106 -115 28 -34 42 -43 68 -43 40 -1 72 26 72 62 0 63 -315
435 -528 624 l-101 90 50 53 c86 91 108 174 73 276 -19 55 -83 117 -141 136
-58 19 -211 25 -289 12 -38 -7 -97 -26 -130 -43 l-62 -31 -193 140 c-107 77
-337 240 -511 361 -454 316 -607 426 -608 436 0 18 91 114 124 130 50 23 87
20 220 -19 133 -39 242 -48 252 -20 10 24 -13 42 -69 55 -239 55 -475 119
-794 215 -61 18 -111 62 -119 104 -4 17 -10 31 -15 31 -12 0 -51 79 -44 90 3
5 1 11 -5 15 -13 8 -35 60 -65 155 -40 128 -39 120 -11 120 41 0 93 57 101
113 6 38 4 52 -10 67 -16 17 -16 19 4 30 29 15 26 41 -6 71 -16 14 -33 34 -39
44 -10 16 -15 18 -30 9 -21 -12 -32 -54 -15 -54 6 0 11 -12 11 -26 0 -20 -4
-24 -19 -19 -10 3 -30 1 -45 -4 -14 -6 -26 -7 -26 -3 0 4 -5 36 -10 71 -8 44
-8 67 0 76 8 9 7 14 -1 17 -8 3 -15 42 -20 114 l-7 109 34 38 c47 51 47 94 -1
141 -20 20 -35 45 -35 58 0 42 12 68 31 68 18 0 35 17 26 26 -3 3 -15 0 -28
-6 l-22 -12 7 102 c3 57 11 109 17 116 6 7 8 26 4 43 -8 36 25 272 66 476 91
450 212 808 375 1108 31 56 62 107 69 113 22 17 48 95 49 149 1 43 -2 51 -25
64 -57 32 -169 -33 -222 -128 -29 -51 -103 -248 -114 -301 -3 -14 -20 -101
-39 -195 -30 -153 -144 -836 -144 -865 0 -9 -7 -8 -23 3 -27 18 -99 31 -125
23 -11 -4 -27 -22 -37 -41 -11 -18 -29 -36 -42 -40 -53 -13 -55 -93 -4 -115
38 -15 86 7 95 45 6 22 10 25 29 18 12 -4 37 -8 54 -8 38 0 41 33 -18 -270
-55 -281 -92 -422 -120 -451 -17 -19 -23 -37 -25 -82 -3 -103 -108 -325 -195
-415 -27 -28 -51 -58 -54 -69 -4 -10 -20 -25 -37 -34 -27 -14 -31 -14 -49 2
-11 10 -19 30 -19 48 0 45 -25 76 -60 76 -19 0 -44 13 -72 38 -43 37 -52 59
-33 75 6 5 -5 6 -22 3 -27 -4 -36 -1 -48 18 -9 13 -13 31 -9 40 4 11 1 16 -10
16 -21 0 -56 35 -56 56 0 10 -6 14 -15 10 -23 -8 -18 21 6 42 33 27 62 102 63
157 0 33 -9 69 -26 106 -21 47 -39 66 -100 112 -41 30 -84 57 -94 59 -15 2
-20 14 -24 63 -5 54 -3 63 20 88 20 23 22 30 11 37 -32 19 -55 -16 -64 -98 -3
-24 -8 -46 -11 -49 -3 -3 -38 -13 -78 -23 -67 -16 -74 -16 -85 -1 -7 9 -13 26
-13 39 0 13 -8 25 -17 28 -10 3 -28 11 -40 16 -12 6 -29 8 -37 5 -22 -9 -20
-37 2 -38 47 -3 70 -16 87 -49 14 -27 13 -29 -21 -59 -40 -36 -44 -36 -86 -15
-22 12 -27 19 -19 27 17 17 13 37 -6 37 -38 -1 -74 -50 -37 -50 22 0 84 -39
84 -52 0 -18 -61 -138 -70 -138 -11 0 -209 278 -374 525 -43 65 -126 223 -144
275 -10 28 -9 31 6 28 10 -2 16 -11 14 -21 -2 -9 2 -17 8 -17 5 0 10 9 10 20
0 14 7 20 21 20 18 0 21 -5 17 -32 -5 -31 15 -82 47 -118 7 -8 12 -28 9 -45
l-4 -30 12 33 c9 27 16 32 44 32 19 0 37 0 42 0 4 0 10 -30 15 -66 7 -62 11
-70 49 -106 23 -21 49 -38 58 -38 9 0 21 -5 28 -12 9 -9 21 -9 50 0 49 15 74
15 50 1 -10 -5 -18 -16 -18 -24 0 -10 17 -13 72 -11 64 3 74 6 95 30 29 34 29
70 2 97 -18 18 -20 25 -10 40 6 10 11 27 11 38 0 11 6 22 13 24 9 4 9 8 -2 15
-7 5 -11 14 -7 20 3 6 2 13 -4 17 -5 3 -10 15 -10 26 0 11 -14 33 -32 49 -31
29 -32 30 -13 44 11 8 17 18 12 22 -4 4 -13 2 -19 -4 -7 -7 -20 -12 -31 -12
-14 0 -17 5 -12 20 4 13 2 20 -6 20 -7 0 -21 7 -31 16 -16 16 -18 15 -24 0 -4
-10 1 -25 11 -36 23 -25 13 -37 -20 -22 -24 11 -65 4 -108 -19 -14 -8 -19 -4
-27 17 -6 15 -8 29 -5 32 5 5 -21 23 -62 41 -22 10 -32 31 -15 31 5 0 15 19
22 43 8 23 17 47 22 53 4 6 3 15 -3 21 -11 11 4 63 19 63 4 0 23 12 41 27 55
46 77 128 46 169 -8 10 -15 27 -15 36 0 11 -5 15 -15 12 -8 -4 -22 0 -31 7
-22 18 -105 19 -123 2 -7 -8 -22 -11 -32 -8 -14 5 -19 1 -19 -14 0 -11 -11
-28 -24 -37 -14 -8 -23 -21 -20 -28 8 -20 -35 -66 -62 -66 -14 0 -35 -7 -48
-15 -13 -9 -30 -14 -37 -13 -7 2 -13 -3 -14 -11 0 -8 -6 -16 -12 -18 -10 -4
-13 25 -13 121 0 143 22 272 68 406 32 95 111 254 112 228 0 -10 5 -18 11 -18
7 0 8 7 4 18 -13 30 -10 36 74 166 109 167 125 205 126 287 0 92 -16 109 -99
107 -76 -2 -117 18 -141 68 -18 39 -17 95 5 149 10 26 13 28 21 13 10 -17 39
-25 39 -11 0 4 -5 21 -12 39 -11 31 -9 36 85 177 112 167 159 252 182 334 29
100 28 99 92 116 82 23 117 21 169 -9 37 -22 52 -25 89 -20 24 4 58 17 77 29
28 19 33 29 33 61 0 50 -32 75 -106 82 -51 6 -58 3 -119 -37 -67 -43 -131 -75
-183 -90 l-30 -8 6 55 c11 106 71 209 307 524 357 476 466 676 534 981 39 176
49 299 43 518 -5 202 -14 253 -47 271 -27 14 -56 12 -70 -5z m-2196 -1792
c-40 -184 -71 -249 -161 -338 -37 -36 -70 -65 -74 -65 -13 0 59 198 99 275 37
70 108 155 130 155 8 0 10 -9 6 -27z m-567 -2333 c51 0 73 -11 73 -35 0 -53
55 -57 105 -8 l28 28 -8 -170 c-4 -93 -9 -173 -11 -177 -8 -14 -102 -38 -204
-53 -58 -8 -121 -20 -140 -25 -19 -6 -38 -10 -42 -10 -5 0 -8 53 -8 119 0 134
24 240 73 324 l28 47 39 -20 c22 -11 52 -20 67 -20z m348 -202 l26 -31 -28
-23 c-15 -13 -47 -37 -69 -53 l-42 -31 -6 33 c-6 37 6 100 24 122 22 26 66 18
95 -17z m-134 -260 c4 -6 10 -31 14 -56 7 -48 -5 -66 -36 -57 -9 3 -22 -1 -29
-10 -7 -8 -22 -15 -34 -15 -12 0 -27 -9 -34 -21 -8 -12 -19 -18 -28 -15 -8 3
-14 0 -14 -8 0 -7 -7 -19 -16 -27 -8 -7 -25 -31 -36 -53 -12 -23 -29 -47 -39
-54 -23 -16 -25 -42 -4 -42 16 0 21 -45 6 -54 -22 -14 -59 14 -75 55 -15 37
-15 45 -3 54 7 5 19 20 26 32 10 19 8 26 -10 44 -14 14 -28 19 -44 15 -22 -6
-24 -2 -41 89 -10 52 -15 97 -12 100 7 7 103 24 195 35 92 10 205 4 214 -12z
m144 -29 c13 -13 -42 -12 -66 1 -10 6 -19 15 -19 20 0 10 70 -7 85 -21z m78
-2269 c6 -25 -2 -61 -14 -55 -4 3 -12 -3 -18 -12 -9 -17 -12 -16 -40 11 -38
37 -39 54 -3 62 15 3 32 7 37 9 23 8 33 4 38 -15z m-393 -770 c20 -38 -45
-152 -76 -132 -21 14 -23 51 -4 75 10 13 22 37 26 53 4 16 9 33 11 37 6 10 31
-10 43 -33z m184 -476 c4 -9 4 -26 1 -38 -4 -13 1 -10 12 9 l17 30 10 -27 c5
-16 13 -28 18 -28 4 0 8 -4 8 -9 0 -8 -111 -51 -131 -51 -3 0 4 21 17 47 13
25 24 55 24 65 0 22 16 24 24 2z m303 -141 c27 -87 24 -224 -6 -285 -12 -26
-30 -53 -38 -60 -14 -11 -14 -9 0 19 34 66 6 154 -62 198 -45 28 -49 36 -26
55 8 7 15 28 15 46 0 46 18 43 25 -3 10 -61 15 -62 10 -3 -5 52 -4 56 22 72
15 10 31 18 35 18 4 0 15 -26 25 -57z m-232 -82 c-3 -6 -11 -11 -17 -11 -6 0
-6 6 2 15 14 17 26 13 15 -4z m388 -259 c9 -10 22 -33 28 -50 6 -18 20 -37 30
-43 27 -14 26 -69 -1 -76 -11 -3 -20 -12 -20 -19 0 -8 -9 -14 -20 -14 -13 0
-20 7 -20 19 0 26 -26 44 -54 37 -17 -4 -26 -1 -30 10 -9 24 -7 62 4 69 6 3
10 13 10 22 0 17 37 63 50 63 4 0 14 -8 23 -18z m2614 -243 c12 -31 25 -74 29
-96 6 -39 5 -39 -36 -56 -35 -13 -55 -14 -123 -7 -45 6 -101 11 -125 12 l-43
3 32 43 c33 44 54 50 54 15 0 -32 8 -35 48 -18 44 18 46 32 10 60 -15 13 -23
27 -19 34 11 17 118 71 136 68 8 -1 25 -27 37 -58z m871 -848 c3 -8 -1 -12 -9
-9 -7 2 -15 10 -17 17 -3 8 1 12 9 9 7 -2 15 -10 17 -17z m-116 -48 l41 -28
-31 -9 c-39 -11 -73 13 -65 45 7 24 5 24 55 -8z"/>
<path d="M3075 8520 c3 -5 10 -10 16 -10 5 0 9 5 9 10 0 6 -7 10 -16 10 -8 0
-12 -4 -9 -10z"/>
<path d="M2855 8430 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M2995 5070 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M3510 4279 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M3885 12624 c-12 -13 -13 -19 -4 -25 17 -10 54 5 54 22 0 21 -30 22
-50 3z"/>
<path d="M4228 12453 c-39 -46 -21 -86 28 -61 38 18 51 42 35 62 -18 21 -44
20 -63 -1z"/>
<path d="M2890 12375 c-17 -21 -4 -35 35 -35 19 0 37 5 40 10 10 16 -14 40
-40 40 -12 0 -28 -7 -35 -15z"/>
<path d="M6629 12345 c-56 -38 -66 -86 -25 -124 33 -31 83 -22 131 23 85 81
-7 169 -106 101z"/>
<path d="M6832 12318 c-19 -19 -14 -30 11 -26 12 2 22 9 22 17 0 19 -18 24
-33 9z"/>
<path d="M2705 12270 c-3 -5 1 -10 9 -10 9 0 16 5 16 10 0 6 -4 10 -9 10 -6 0
-13 -4 -16 -10z"/>
<path d="M2825 12246 c-59 -28 -92 -61 -105 -105 -12 -43 0 -73 43 -105 76
-58 161 -54 218 12 66 74 76 146 30 193 -25 25 -37 29 -83 29 -35 0 -70 -8
-103 -24z"/>
<path d="M3970 12256 c0 -8 4 -17 9 -20 11 -7 33 18 24 27 -12 12 -33 7 -33
-7z"/>
<path d="M4436 12250 c-17 -10 -36 -30 -43 -44 -25 -48 26 -106 92 -106 33 0
65 44 65 89 0 75 -52 102 -114 61z"/>
<path d="M6752 12204 c-29 -14 -69 -45 -89 -68 l-37 -43 -16 31 c-23 46 -62
47 -104 2 -43 -45 -52 -80 -30 -113 14 -22 22 -25 54 -20 l38 5 -40 -60 c-22
-33 -54 -89 -71 -125 -73 -158 -31 -235 103 -188 139 49 366 266 428 408 30
69 5 145 -58 177 -54 28 -115 26 -178 -6z"/>
<path d="M3990 12119 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0
-10 -5 -10 -11z"/>
<path d="M4082 12098 c-19 -19 -14 -30 11 -26 12 2 22 9 22 17 0 19 -18 24
-33 9z"/>
<path d="M4987 12103 c-12 -12 -7 -50 8 -63 36 -30 102 22 69 54 -15 16 -65
22 -77 9z"/>
<path d="M2218 11966 c-103 -37 -195 -117 -244 -214 -57 -113 -30 -234 66
-290 39 -24 56 -27 130 -27 71 0 94 4 140 26 88 42 162 110 200 183 71 142 27
274 -109 322 -46 17 -136 17 -183 0z"/>
<path d="M4313 11933 c-7 -2 -13 -14 -13 -25 0 -14 6 -19 23 -16 14 2 22 10
22 22 0 21 -12 28 -32 19z"/>
<path d="M6235 11710 c4 -6 11 -8 16 -5 14 9 11 15 -7 15 -8 0 -12 -5 -9 -10z"/>
<path d="M6246 11554 c-12 -11 -14 -20 -7 -27 14 -14 64 8 58 26 -6 19 -33 20
-51 1z"/>
<path d="M4351 11447 c-13 -16 -5 -27 21 -27 11 0 18 7 18 20 0 23 -22 27 -39
7z"/>
<path d="M1815 11180 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3
0 -8 -4 -11 -10z"/>
<path d="M6650 11107 c-272 -83 -601 -263 -870 -477 -125 -99 -295 -268 -368
-365 -79 -105 -135 -223 -140 -296 -5 -63 6 -89 38 -89 35 0 44 26 26 75 -23
59 -15 89 44 178 86 128 239 261 385 334 114 57 291 108 355 101 34 -3 35 -4
37 -48 1 -25 2 -51 2 -57 2 -23 57 -14 122 20 35 18 136 77 224 131 182 112
272 156 383 187 93 26 141 49 190 93 47 42 69 53 131 67 28 6 54 15 58 21 12
21 -51 58 -127 74 -62 14 -89 15 -159 7 -96 -12 -132 -5 -171 32 -37 35 -74
38 -160 12z"/>
<path d="M3157 11080 c-8 -32 24 -85 56 -88 23 -3 27 0 27 20 0 14 -14 38 -33
56 -39 37 -43 39 -50 12z"/>
<path d="M3031 11056 c-16 -19 -6 -36 20 -36 15 0 20 6 17 22 -4 27 -21 33
-37 14z"/>
<path d="M1530 11035 c-128 -58 -160 -196 -66 -284 40 -38 112 -40 181 -6 164
80 144 290 -28 302 -33 2 -65 -3 -87 -12z"/>
<path d="M2832 10824 c-43 -30 -16 -94 40 -94 49 0 76 61 42 94 -20 20 -53 20
-82 0z"/>
<path d="M2999 10818 c0 -2 -3 -23 -5 -49 -6 -57 23 -102 65 -97 21 2 27 9 29
33 2 23 -6 41 -33 73 -31 35 -52 51 -56 40z"/>
<path d="M1338 10479 c-64 -33 -94 -99 -76 -168 11 -44 47 -73 106 -86 17 -3
32 -12 32 -18 -1 -20 -54 -75 -83 -86 -35 -14 -35 -31 2 -31 19 0 30 -6 33
-18 4 -12 22 -23 53 -32 42 -12 51 -11 81 5 42 23 52 34 64 75 8 28 6 35 -18
59 -15 14 -37 31 -47 37 -19 11 -19 13 6 40 76 83 79 180 7 224 -42 26 -111
25 -160 -1z"/>
<path d="M2886 10414 c-24 -24 -17 -74 10 -74 30 0 64 24 64 45 0 42 -44 60
-74 29z"/>
<path d="M3010 10400 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5
0 -10 -4 -10 -10z"/>
<path d="M5654 10345 c-6 -35 0 -55 12 -42 10 10 13 77 4 77 -5 0 -12 -16 -16
-35z"/>
<path d="M2872 10309 c2 -6 8 -10 13 -10 5 0 11 4 13 10 2 6 -4 11 -13 11 -9
0 -15 -5 -13 -11z"/>
<path d="M2512 10299 c-52 -10 -95 -39 -133 -92 -38 -52 -41 -127 -6 -178 25
-38 97 -78 142 -79 108 -1 216 96 215 192 -1 43 -29 100 -57 114 -13 6 -31 18
-40 26 -24 20 -69 27 -121 17z"/>
<path d="M1607 10214 c-44 -44 -45 -64 -3 -99 l37 -31 -21 -19 c-13 -12 -20
-28 -18 -44 2 -21 9 -27 35 -29 27 -3 35 1 45 23 9 21 9 31 -1 47 -11 18 -9
25 23 57 22 23 36 47 36 62 0 60 -86 81 -133 33z"/>
<path d="M5204 10194 c4 -15 10 -39 13 -55 7 -34 33 -61 33 -34 0 26 -33 115
-43 115 -5 0 -6 -12 -3 -26z"/>
<path d="M6396 10174 c-4 -9 -4 -19 -2 -21 8 -8 26 8 26 23 0 19 -16 18 -24
-2z"/>
<path d="M1078 10168 c-7 -4 -23 -8 -35 -8 -26 0 -58 -43 -49 -65 3 -9 19 -23
36 -33 33 -19 37 -33 15 -52 -26 -21 -37 -83 -23 -126 24 -72 124 -108 196
-70 121 63 126 211 8 255 -27 11 -28 12 -12 36 19 29 21 64 4 66 -49 6 -130 4
-140 -3z m72 -79 c0 -5 -7 -9 -15 -9 -15 0 -20 12 -9 23 8 8 24 -1 24 -14z"/>
<path d="M5890 10145 c0 -8 2 -15 4 -15 2 0 6 7 10 15 3 8 1 15 -4 15 -6 0
-10 -7 -10 -15z"/>
<path d="M1892 10061 c2 -7 7 -12 11 -12 12 1 9 15 -3 20 -7 2 -11 -2 -8 -8z"/>
<path d="M5586 10021 c-4 -7 -5 -15 -2 -18 9 -9 19 4 14 18 -4 11 -6 11 -12 0z"/>
<path d="M1967 10013 c-12 -11 -8 -40 7 -52 26 -22 63 10 50 44 -6 15 -45 21
-57 8z"/>
<path d="M2124 9991 c-44 -19 -65 -60 -62 -120 3 -47 7 -57 28 -70 22 -12 33
-12 82 3 76 22 111 53 110 96 -2 66 -93 119 -158 91z"/>
<path d="M1768 9876 c-20 -44 8 -74 57 -62 29 7 44 49 25 71 -20 25 -69 19
-82 -9z"/>
<path d="M1444 9865 c-4 -9 -2 -21 4 -27 16 -16 47 -5 47 17 0 26 -42 34 -51
10z"/>
<path d="M5222 9848 c3 -35 28 -52 28 -20 0 28 -10 52 -22 52 -5 0 -8 -14 -6
-32z"/>
<path d="M5444 9735 c-14 -14 -22 -30 -19 -35 19 -30 105 11 92 43 -9 25 -44
21 -73 -8z"/>
<path d="M5133 9650 c-83 -50 -61 -123 32 -106 46 9 75 38 75 76 0 50 -51 65
-107 30z"/>
<path d="M2095 9631 c-6 -11 9 -23 19 -14 9 9 7 23 -3 23 -6 0 -12 -4 -16 -9z"/>
<path d="M2090 9585 c0 -8 7 -15 15 -15 8 0 15 7 15 15 0 8 -7 15 -15 15 -8 0
-15 -7 -15 -15z"/>
<path d="M4800 9574 c0 -8 5 -12 10 -9 6 3 10 10 10 16 0 5 -4 9 -10 9 -5 0
-10 -7 -10 -16z"/>
<path d="M2205 9530 c-3 -5 1 -10 9 -10 9 0 16 5 16 10 0 6 -4 10 -9 10 -6 0
-13 -4 -16 -10z"/>
<path d="M6627 9472 c-36 -36 -47 -62 -28 -62 11 0 81 80 81 92 0 16 -17 7
-53 -30z"/>
<path d="M5070 9480 c0 -23 24 -59 47 -69 14 -7 27 -10 29 -9 2 2 -7 23 -18
46 -14 26 -29 42 -40 42 -10 0 -18 -4 -18 -10z"/>
<path d="M1310 9459 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M2317 9444 c-14 -14 -7 -35 11 -32 9 2 17 10 17 17 0 16 -18 25 -28
15z"/>
<path d="M1740 9355 c0 -10 7 -15 18 -13 21 4 19 22 -2 26 -10 2 -16 -3 -16
-13z"/>
<path d="M2392 9328 c-25 -7 -37 -18 -48 -44 -12 -28 -19 -34 -32 -28 -84 36
-169 25 -232 -32 -131 -118 -66 -302 102 -291 80 5 142 45 178 115 24 48 26
58 18 104 -11 58 -6 78 20 78 25 0 62 41 62 68 0 29 -27 41 -68 30z"/>
<path d="M4944 9259 c-40 -11 -71 -68 -54 -99 12 -23 50 -35 85 -26 32 8 64
69 49 93 -19 30 -47 41 -80 32z"/>
<path d="M7390 9229 c-14 -4 -37 -11 -52 -14 -17 -3 -33 -17 -42 -34 -13 -25
-13 -31 1 -50 13 -19 21 -22 52 -17 40 7 82 14 94 15 16 2 47 42 47 62 0 41
-44 58 -100 38z"/>
<path d="M6280 9156 c-29 -9 -36 -16 -38 -43 -2 -24 2 -34 18 -43 55 -29 118
42 73 82 -15 13 -24 14 -53 4z"/>
<path d="M1048 9146 c-56 -20 -85 -42 -116 -88 -24 -37 -27 -52 -30 -140 -3
-67 2 -123 13 -176 36 -163 152 -351 273 -442 74 -55 103 -67 194 -78 126 -16
194 7 230 79 23 44 23 99 -2 201 -11 46 -20 103 -20 128 0 69 -15 108 -75 199
-59 89 -64 101 -40 101 8 0 15 -4 15 -10 0 -13 37 -13 45 1 9 13 -22 79 -37
79 -7 0 -22 -10 -35 -22 l-23 -21 -82 85 c-69 70 -92 87 -137 101 -63 20 -122
21 -173 3z"/>
<path d="M2424 9119 c-10 -17 13 -36 27 -22 12 12 4 33 -11 33 -5 0 -12 -5
-16 -11z"/>
<path d="M4934 9058 c-4 -6 -18 -8 -33 -5 -18 4 -36 -2 -59 -20 -28 -21 -35
-23 -49 -11 -13 10 -15 10 -9 0 8 -14 -18 -61 -41 -75 -7 -4 -13 -19 -13 -32
0 -13 -5 -27 -10 -30 -11 -7 -6 -127 5 -145 13 -21 -7 -46 -61 -80 -72 -46
-110 -77 -103 -84 3 -4 23 8 45 25 94 76 193 82 178 10 -5 -25 -4 -25 24 9 27
31 37 35 79 36 26 0 59 5 73 9 14 5 30 9 37 9 25 2 101 77 127 126 26 49 26
53 14 107 -7 30 -19 58 -25 60 -7 3 -13 13 -13 24 0 10 -9 23 -21 30 -15 8
-18 14 -10 22 18 18 13 29 -9 17 -14 -7 -37 -8 -70 -2 -32 6 -53 6 -56 0z"/>
<path d="M5199 8915 c0 -6 -2 -30 -5 -55 -2 -24 -2 -51 2 -60 11 -31 32 18 27
63 -5 38 -22 76 -24 52z"/>
<path d="M6367 8865 c-30 -29 8 -80 39 -54 20 16 17 47 -5 59 -15 7 -23 6 -34
-5z"/>
<path d="M5840 8791 c-39 -12 -93 -41 -117 -63 -12 -11 -26 -17 -32 -13 -6 3
-25 -7 -42 -24 -25 -25 -28 -34 -19 -50 13 -26 13 -31 -3 -31 -8 0 -19 -11
-25 -25 -11 -24 -10 -25 8 -15 18 10 19 8 13 -22 -8 -44 -56 -99 -74 -87 -52
33 -130 -18 -121 -80 7 -49 42 -65 97 -44 17 6 50 14 75 18 24 3 49 10 56 16
8 7 23 2 49 -16 21 -14 51 -25 68 -25 41 0 97 60 120 130 35 104 47 126 77
137 54 20 86 104 60 154 -23 42 -117 62 -190 40z"/>
<path d="M7984 8743 c-17 -17 -17 -18 5 -21 27 -4 47 11 37 27 -10 16 -22 14
-42 -6z"/>
<path d="M4565 8709 c-10 -15 1 -23 20 -15 9 3 13 10 10 16 -8 13 -22 13 -30
-1z"/>
<path d="M6155 8690 c-36 -14 -93 -87 -109 -139 -22 -76 -5 -121 46 -121 40 0
74 37 83 91 4 24 14 56 21 71 31 60 7 116 -41 98z"/>
<path d="M1902 8621 c4 -13 18 -16 19 -4 0 4 -5 9 -11 12 -7 2 -11 -2 -8 -8z"/>
<path d="M6955 8599 c-10 -15 3 -25 16 -12 7 7 7 13 1 17 -6 3 -14 1 -17 -5z"/>
<path d="M7790 8589 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M2312 8542 l-23 -18 24 -13 c35 -19 62 -11 62 19 0 32 -31 37 -63 12z"/>
<path d="M4955 8530 c-8 -35 3 -60 16 -39 12 19 11 69 0 69 -5 0 -12 -13 -16
-30z"/>
<path d="M7035 8528 c-30 -36 -28 -71 7 -101 24 -20 71 -15 99 10 15 12 17 11
22 -8 3 -11 10 -18 16 -14 14 8 14 42 1 50 -5 3 -10 19 -10 34 0 37 -28 61
-72 61 -28 0 -42 -7 -63 -32z"/>
<path d="M7612 8530 c-23 -10 -39 -36 -28 -47 10 -11 76 26 76 42 0 17 -14 18
-48 5z"/>
<path d="M6231 8504 c-35 -29 -39 -44 -13 -44 27 0 71 53 53 64 -5 4 -23 -6
-40 -20z"/>
<path d="M6410 8473 c-49 -18 -65 -67 -32 -96 28 -25 67 -21 91 9 26 33 26 49
1 74 -20 20 -33 23 -60 13z"/>
<path d="M7223 8410 c-42 -17 -56 -75 -25 -103 25 -23 54 -21 86 4 59 46 9
127 -61 99z"/>
<path d="M5000 8376 c-6 -6 -10 -27 -10 -48 0 -74 71 -104 124 -52 51 52 20
109 -61 109 -24 0 -48 -4 -53 -9z"/>
<path d="M8324 8370 c-46 -20 -59 -35 -67 -75 -11 -58 54 -117 110 -99 41 13
61 27 77 56 46 78 -34 157 -120 118z"/>
<path d="M4500 8350 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M7466 8344 c-23 -22 -20 -37 4 -24 11 6 20 17 20 25 0 19 -5 19 -24
-1z"/>
<path d="M6153 8273 c-7 -3 -13 -13 -13 -24 0 -23 -8 -24 -32 -2 -10 9 -18 11
-18 5 0 -6 5 -14 10 -17 17 -10 0 -26 -20 -20 -22 7 -35 -13 -43 -68 -5 -32
-1 -43 25 -73 29 -33 35 -35 71 -30 54 9 93 39 107 82 14 41 5 108 -12 98 -8
-5 -9 -1 -4 11 5 15 4 16 -9 5 -19 -15 -49 -5 -41 15 6 16 -3 24 -21 18z"/>
<path d="M7167 8260 c-44 -8 -94 -51 -82 -70 19 -30 145 23 145 61 0 20 -4 21
-63 9z"/>
<path d="M6893 8253 c-28 -5 -73 -58 -73 -84 0 -11 12 -31 26 -46 21 -21 32
-24 61 -20 65 11 101 62 76 110 -11 22 -56 53 -66 46 -1 -1 -12 -4 -24 -6z"/>
<path d="M1582 8238 c-34 -44 -15 -91 37 -91 37 0 51 15 51 55 0 41 -63 66
-88 36z"/>
<path d="M4952 8189 c2 -6 8 -10 13 -10 5 0 11 4 13 10 2 6 -4 11 -13 11 -9 0
-15 -5 -13 -11z"/>
<path d="M5366 8169 c-27 -32 -29 -69 -6 -93 15 -15 88 12 107 40 15 24 15 27
-4 52 -29 40 -64 40 -97 1z"/>
<path d="M5900 8180 c-11 -11 -20 -24 -20 -30 0 -15 27 -12 44 6 18 17 21 44
6 44 -5 0 -19 -9 -30 -20z"/>
<path d="M5795 8150 c-3 -5 1 -10 9 -10 9 0 16 5 16 10 0 6 -4 10 -9 10 -6 0
-13 -4 -16 -10z"/>
<path d="M1894 8129 c-8 -14 11 -33 25 -25 6 4 11 14 11 22 0 16 -26 19 -36 3z"/>
<path d="M5554 8126 c-10 -8 -16 -17 -13 -20 3 -3 14 1 25 8 10 8 16 17 13 20
-3 3 -14 -1 -25 -8z"/>
<path d="M5040 8080 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M1848 8064 c-60 -32 -74 -114 -34 -200 27 -58 57 -84 99 -84 56 0
123 54 151 122 22 51 20 76 -6 115 -40 57 -147 82 -210 47z"/>
<path d="M6659 8061 c-21 -17 -22 -20 -7 -26 28 -11 53 -1 56 23 4 28 -17 29
-49 3z"/>
<path d="M4880 8059 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M1120 8040 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M5126 8031 c-4 -6 -1 -21 6 -33 l13 -23 3 24 c4 26 -12 49 -22 32z"/>
<path d="M6917 8013 c-12 -11 -8 -23 8 -23 8 0 15 7 15 15 0 16 -12 20 -23 8z"/>
<path d="M1255 7975 c-5 -2 -22 -6 -37 -9 -60 -13 -128 -102 -128 -166 0 -45
35 -107 71 -126 40 -20 140 -20 181 0 44 22 45 21 46 -27 2 -66 16 -94 63
-118 38 -19 46 -20 87 -8 25 7 59 27 78 46 30 30 34 40 34 88 0 29 5 57 10 60
16 10 40 74 40 105 0 61 -96 123 -154 100 -17 -6 -25 -4 -34 11 -16 25 -39 24
-53 -3 -9 -17 -9 -24 4 -32 13 -10 12 -15 -7 -39 l-22 -27 4 33 c3 29 -1 37
-34 62 -53 40 -115 61 -149 50z"/>
<path d="M5890 7956 c-6 -8 -10 -25 -8 -38 2 -18 10 -24 35 -26 27 -3 35 1 44
22 9 20 9 29 -1 41 -16 19 -54 19 -70 1z"/>
<path d="M6895 7960 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M5605 7920 c-24 -25 -26 -31 -14 -46 9 -12 24 -15 51 -11 44 6 68 22
68 46 0 48 -63 55 -105 11z"/>
<path d="M6026 7914 c-28 -29 -32 -53 -15 -85 28 -53 117 -37 138 23 15 41 14
45 -9 68 -29 29 -81 26 -114 -6z"/>
<path d="M7690 7900 c0 -5 9 -10 20 -10 11 0 20 5 20 10 0 6 -9 10 -20 10 -11
0 -20 -4 -20 -10z"/>
<path d="M6850 7885 c-9 -11 -22 -14 -39 -10 -20 4 -35 -2 -61 -24 l-35 -29
43 5 c64 8 52 -27 -14 -41 -35 -8 -80 -49 -70 -65 5 -7 15 -7 32 -1 34 13 33
14 44 -15 l10 -25 147 0 148 0 3 39 c3 51 -12 60 -44 26 -29 -31 -39 -27 -47
19 -12 75 -49 100 -111 76 -35 -13 -34 7 2 37 15 12 22 22 16 23 -6 0 -17 -7
-24 -15z"/>
<path d="M7600 7857 c-22 -11 -44 -15 -60 -11 -77 20 -130 -1 -156 -61 -12
-31 -12 -39 0 -70 l15 -35 68 0 c38 0 74 5 80 11 8 8 13 8 17 0 8 -12 116 -15
154 -5 12 4 22 14 22 23 0 9 6 24 13 33 8 9 11 32 9 53 -8 69 -88 100 -162 62z"/>
<path d="M5505 7831 c-8 -25 1 -41 21 -41 18 0 18 34 0 49 -11 10 -16 8 -21
-8z"/>
<path d="M5461 7814 c-24 -31 -27 -46 -7 -38 16 6 42 52 34 60 -3 3 -15 -7
-27 -22z"/>
<path d="M5820 7736 c0 -8 4 -17 9 -20 11 -7 33 18 24 27 -12 12 -33 7 -33 -7z"/>
<path d="M5970 7734 c0 -8 5 -12 10 -9 6 4 8 11 5 16 -9 14 -15 11 -15 -7z"/>
<path d="M5740 7719 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M5395 7659 c-4 -6 -5 -13 -2 -16 8 -7 47 7 47 18 0 13 -37 11 -45 -2z"/>
<path d="M6039 7631 c-34 -34 -37 -63 -9 -91 44 -44 142 -4 136 55 -7 66 -77
86 -127 36z"/>
<path d="M1345 7620 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M5302 7610 c-7 -11 -8 -20 -3 -20 9 0 24 30 18 36 -1 2 -8 -6 -15
-16z"/>
<path d="M6755 7570 c-8 -25 1 -41 20 -33 19 7 20 53 1 53 -8 0 -17 -9 -21
-20z"/>
<path d="M6400 7555 c-10 -12 -10 -19 -2 -27 16 -16 42 -2 42 22 0 24 -22 26
-40 5z"/>
<path d="M5555 7550 c-3 -6 1 -13 10 -16 19 -8 30 0 20 15 -8 14 -22 14 -30 1z"/>
<path d="M1127 7527 c-22 -16 -20 -65 4 -80 9 -5 28 -7 42 -3 30 7 46 49 30
75 -13 21 -53 25 -76 8z"/>
<path d="M5630 7530 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M1740 7504 c-65 -22 -92 -41 -117 -83 -16 -25 -30 -59 -33 -76 -5
-33 -12 -34 -97 -21 -35 5 -50 1 -90 -22 -55 -32 -83 -78 -83 -135 0 -59 23
-93 75 -110 56 -19 91 -11 150 37 l42 35 11 -24 c6 -13 14 -40 18 -62 8 -46
53 -113 98 -148 37 -28 136 -65 175 -65 89 0 286 130 311 204 8 27 8 49 -5
101 -19 85 -20 82 11 89 34 9 25 32 -12 29 -27 -2 -31 2 -46 45 -35 96 -107
174 -187 201 -33 11 -191 14 -221 5z"/>
<path d="M6018 7499 c-22 -13 -23 -25 -2 -33 19 -7 44 10 44 30 0 16 -18 18
-42 3z"/>
<path d="M6365 7480 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M5510 7461 c-15 -29 -13 -31 24 -31 18 0 39 -7 46 -15 7 -8 16 -15
21 -15 5 0 9 7 9 15 0 8 -9 15 -20 15 -11 0 -27 11 -36 25 -20 30 -30 31 -44
6z"/>
<path d="M5915 7464 c-63 -22 -104 -83 -47 -69 37 9 102 54 102 71 0 17 -4 17
-55 -2z"/>
<path d="M1449 7394 c-10 -12 -9 -18 3 -31 19 -18 38 -10 38 17 0 24 -25 33
-41 14z"/>
<path d="M2520 7400 c0 -12 45 -24 59 -15 22 13 10 25 -24 25 -19 0 -35 -4
-35 -10z"/>
<path d="M5677 7394 c-4 -4 -7 -16 -7 -26 0 -14 6 -19 23 -16 14 2 22 10 22
23 0 20 -25 32 -38 19z"/>
<path d="M5890 7390 c-11 -7 -12 -12 -3 -21 13 -13 33 -4 33 17 0 16 -9 18
-30 4z"/>
<path d="M1524 7375 c-8 -19 3 -35 26 -35 19 0 34 25 24 41 -8 14 -44 10 -50
-6z"/>
<path d="M6072 7368 c-17 -17 -15 -55 4 -62 15 -6 64 17 64 30 0 3 -5 14 -10
25 -12 21 -40 25 -58 7z"/>
<path d="M6800 7370 c0 -5 4 -10 9 -10 6 0 13 5 16 10 3 6 -1 10 -9 10 -9 0
-16 -4 -16 -10z"/>
<path d="M6274 7295 c-6 -15 2 -35 14 -35 4 0 12 11 19 25 10 22 9 25 -8 25
-11 0 -22 -7 -25 -15z"/>
<path d="M7837 7299 c-9 -6 -21 -8 -26 -5 -5 3 -14 -1 -21 -9 -7 -8 -19 -15
-26 -15 -8 0 -24 -10 -35 -21 -18 -18 -25 -20 -40 -10 -30 18 -36 12 -9 -9 22
-17 23 -19 7 -26 -11 -4 -25 0 -37 11 -11 10 -23 15 -26 12 -4 -3 9 -15 27
-26 25 -16 29 -22 17 -26 -9 -4 -14 -14 -11 -23 2 -9 -5 -27 -16 -39 -12 -13
-17 -25 -11 -28 18 -11 11 -37 -12 -43 -20 -5 -20 -6 -3 -12 15 -5 19 -17 19
-59 1 -57 14 -73 56 -65 14 3 39 -2 55 -10 17 -9 40 -16 52 -16 12 0 33 -4 47
-9 18 -8 28 -6 37 5 9 11 22 14 41 10 20 -4 30 -1 34 9 3 8 21 19 40 25 24 8
34 18 34 32 0 11 8 22 20 25 14 4 25 22 37 62 10 31 17 63 16 71 -10 75 -10
85 6 97 13 11 11 12 -16 7 -26 -5 -33 -3 -33 9 0 8 -7 17 -15 21 -8 3 -15 13
-15 22 0 10 -3 14 -7 11 -3 -4 -17 0 -29 8 -28 18 -132 27 -157 14z"/>
<path d="M5954 7215 c-15 -11 -17 -17 -8 -32 15 -25 48 -23 52 3 4 31 -20 47
-44 29z"/>
<path d="M6077 7183 c-12 -11 -8 -23 8 -23 8 0 15 7 15 15 0 16 -12 20 -23 8z"/>
<path d="M6686 7164 c-19 -18 -21 -65 -4 -82 17 -17 74 -15 92 4 19 18 21 65
4 82 -17 17 -74 15 -92 -4z"/>
<path d="M8596 7151 c-11 -12 -16 -24 -12 -28 9 -9 39 22 34 37 -2 7 -10 4
-22 -9z"/>
<path d="M6155 7139 c-10 -15 3 -25 16 -12 7 7 7 13 1 17 -6 3 -14 1 -17 -5z"/>
<path d="M2630 7125 c-20 -24 -6 -28 15 -5 10 11 13 20 8 20 -6 0 -16 -7 -23
-15z"/>
<path d="M7037 7134 c-8 -8 1 -24 14 -24 5 0 9 7 9 15 0 15 -12 20 -23 9z"/>
<path d="M6010 7060 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M2800 7030 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"/>
<path d="M6632 7026 c-31 -17 -62 -61 -62 -88 0 -28 14 -48 50 -72 34 -22 65
-20 101 7 52 37 66 103 30 140 -20 21 -90 29 -119 13z"/>
<path d="M5757 6944 c-10 -10 -9 -56 2 -72 5 -8 17 -12 27 -10 29 5 27 82 -1
86 -12 2 -24 0 -28 -4z"/>
<path d="M853 6903 c-20 -7 -15 -23 7 -23 11 0 20 4 20 9 0 13 -12 19 -27 14z"/>
<path d="M3203 6855 c-3 -9 -3 -18 0 -22 11 -10 57 7 57 22 0 20 -49 20 -57 0z"/>
<path d="M5950 6830 c-16 -31 -5 -50 28 -50 60 0 68 61 9 68 -18 2 -29 -3 -37
-18z"/>
<path d="M5755 6814 c-9 -9 -15 -28 -13 -43 2 -21 9 -27 34 -29 52 -5 82 39
48 72 -20 21 -48 20 -69 0z"/>
<path d="M6080 6795 c-7 -9 -11 -18 -8 -20 3 -3 11 1 18 10 7 9 11 18 8 20 -3
3 -11 -1 -18 -10z"/>
<path d="M1831 6751 c-29 -29 -28 -69 3 -77 43 -10 70 -1 80 29 20 55 -41 90
-83 48z"/>
<path d="M7260 6740 c-8 -5 -28 -10 -45 -10 -42 0 -50 -7 -79 -74 -37 -84 -41
-124 -16 -164 55 -88 177 -55 213 59 27 83 23 129 -16 167 -37 36 -36 35 -57
22z"/>
<path d="M3071 6716 c-6 -7 -8 -21 -5 -30 6 -14 16 -16 62 -11 59 6 72 11 72
26 0 13 -47 11 -56 -3 -5 -7 -10 -4 -15 10 -9 25 -41 29 -58 8z"/>
<path d="M1387 6678 c18 -18 36 -29 39 -25 10 10 -36 57 -55 57 -11 0 -6 -10
16 -32z"/>
<path d="M6110 6690 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M6826 6691 c-8 -12 25 -19 48 -11 18 6 18 7 2 13 -24 10 -44 9 -50
-2z"/>
<path d="M6045 6681 c-3 -5 -1 -12 4 -15 12 -8 41 3 41 15 0 12 -37 12 -45 0z"/>
<path d="M7435 6679 c-10 -15 3 -25 16 -12 7 7 7 13 1 17 -6 3 -14 1 -17 -5z"/>
<path d="M1930 6640 c-14 -10 -23 -21 -20 -23 3 -3 16 3 29 14 31 25 23 33 -9
9z"/>
<path d="M9118 6630 c-32 -32 -33 -50 -1 -30 25 16 57 60 43 60 -6 0 -25 -14
-42 -30z"/>
<path d="M6804 6619 c-10 -18 13 -32 40 -25 37 9 34 36 -3 36 -17 0 -33 -5
-37 -11z"/>
<path d="M7515 6621 c-3 -5 -2 -12 3 -15 12 -7 52 4 52 15 0 12 -47 12 -55 0z"/>
<path d="M8017 6592 c-28 -30 -36 -55 -14 -46 14 5 51 63 45 70 -3 2 -17 -9
-31 -24z"/>
<path d="M2400 6585 c-37 -20 -56 -59 -44 -93 23 -67 157 -72 200 -7 24 36 6
70 -49 95 -50 23 -73 24 -107 5z"/>
<path d="M6810 6553 c-45 -18 -54 -83 -15 -101 38 -18 59 -15 83 11 27 29 28
51 2 77 -20 20 -41 24 -70 13z"/>
<path d="M7697 6484 c-21 -28 -18 -38 7 -28 9 3 16 17 16 30 0 29 -1 29 -23
-2z"/>
<path d="M2606 6424 c-11 -11 -14 -20 -8 -26 16 -16 47 -5 47 16 0 26 -19 31
-39 10z"/>
<path d="M1925 6400 c3 -5 14 -10 23 -10 15 0 15 2 2 10 -20 13 -33 13 -25 0z"/>
<path d="M7400 6393 c-24 -9 -51 -36 -67 -67 -12 -23 -12 -33 -2 -57 23 -56
81 -65 130 -21 26 24 32 36 32 72 0 61 -40 92 -93 73z"/>
<path d="M7025 6360 c3 -5 19 -10 36 -10 16 0 29 5 29 10 0 6 -16 10 -36 10
-21 0 -33 -4 -29 -10z"/>
<path d="M7818 6363 c-37 -10 -11 -33 37 -33 29 0 43 19 24 31 -15 9 -32 10
-61 2z"/>
<path d="M7955 6350 c-3 -5 1 -10 9 -10 8 0 18 5 21 10 3 6 -1 10 -9 10 -8 0
-18 -4 -21 -10z"/>
<path d="M2515 6329 c-10 -15 3 -25 16 -12 7 7 7 13 1 17 -6 3 -14 1 -17 -5z"/>
<path d="M6936 6293 c-4 -9 -1 -20 6 -24 16 -10 43 15 34 30 -11 16 -33 13
-40 -6z"/>
<path d="M2360 6257 c0 -19 10 -27 26 -20 21 8 17 33 -6 33 -11 0 -20 -6 -20
-13z"/>
<path d="M7090 6225 c-10 -12 -10 -18 -1 -29 17 -21 57 -27 121 -18 69 9 73
29 10 48 -60 18 -115 18 -130 -1z"/>
<path d="M2190 6210 c0 -5 7 -10 16 -10 8 0 12 5 9 10 -3 6 -10 10 -16 10 -5
0 -9 -4 -9 -10z"/>
<path d="M2270 6200 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M2415 6200 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M2540 6177 c-29 -15 -38 -52 -15 -60 20 -8 64 9 71 28 6 14 -11 45
-25 45 -3 0 -17 -6 -31 -13z"/>
<path d="M2630 6150 c0 -13 7 -20 18 -20 11 0 27 9 37 20 18 20 17 20 -18 20
-30 0 -37 -4 -37 -20z"/>
<path d="M7146 6141 c-12 -19 4 -31 44 -31 37 0 60 13 43 23 -20 13 -81 18
-87 8z"/>
<path d="M6837 6134 c-10 -11 3 -24 24 -24 10 0 19 7 19 15 0 15 -31 21 -43 9z"/>
<path d="M2847 6123 c-4 -3 -7 -18 -7 -32 0 -28 14 -19 25 17 6 21 -4 30 -18
15z"/>
<path d="M7090 6115 c0 -8 9 -15 20 -15 11 0 20 7 20 15 0 8 -9 15 -20 15 -11
0 -20 -7 -20 -15z"/>
<path d="M2153 6113 c-20 -7 -15 -23 7 -23 11 0 20 4 20 9 0 13 -12 19 -27 14z"/>
<path d="M2890 6105 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"/>
<path d="M2064 6099 c-3 -6 -1 -16 4 -21 13 -13 45 8 36 23 -7 12 -31 11 -40
-2z"/>
<path d="M6849 6059 c-11 -6 -11 -11 -2 -20 12 -12 43 -6 43 10 0 14 -25 20
-41 10z"/>
<path d="M8536 6029 c-15 -12 -49 -54 -76 -95 -63 -93 -75 -99 -139 -78 -41
13 -56 14 -86 4 -44 -15 -59 -41 -51 -94 4 -28 -2 -68 -19 -131 -35 -127 -58
-305 -51 -391 16 -181 87 -334 216 -467 47 -48 93 -87 103 -87 22 0 67 43 67
64 0 25 -48 73 -104 105 -122 68 -176 142 -184 250 -6 83 11 121 65 148 49 23
71 70 111 235 34 140 64 210 121 278 95 113 149 231 122 264 -19 22 -63 20
-95 -5z"/>
<path d="M2437 6033 c-14 -13 -6 -33 13 -33 13 0 20 7 20 20 0 19 -20 27 -33
13z"/>
<path d="M2315 5950 c-3 -5 1 -10 9 -10 9 0 16 5 16 10 0 6 -4 10 -9 10 -6 0
-13 -4 -16 -10z"/>
<path d="M6904 5935 c-4 -9 -2 -21 4 -27 16 -16 47 -5 47 17 0 26 -42 34 -51
10z"/>
<path d="M7194 5795 c-9 -23 3 -45 25 -45 10 0 21 7 25 15 8 23 -4 45 -25 45
-11 0 -22 -7 -25 -15z"/>
<path d="M1355 5762 c-22 -4 -45 -29 -45 -49 0 -7 12 -18 27 -24 24 -9 31 -7
50 16 13 14 23 30 23 35 0 14 -32 27 -55 22z"/>
<path d="M7262 5714 c-32 -25 -35 -44 -7 -44 17 0 49 44 43 59 -1 6 -18 -1
-36 -15z"/>
<path d="M7595 5710 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M7685 5659 c-31 -19 -55 -46 -55 -61 0 -19 29 -5 63 30 53 54 49 68
-8 31z"/>
<path d="M7784 5599 c-10 -16 10 -31 55 -39 55 -10 61 5 12 30 -46 23 -57 24
-67 9z"/>
<path d="M2756 5575 c-20 -20 -10 -45 19 -45 27 0 40 26 24 46 -15 18 -24 18
-43 -1z"/>
<path d="M2402 5504 c-45 -32 -8 -89 42 -66 32 14 36 56 7 72 -25 13 -22 13
-49 -6z"/>
<path d="M2824 5499 c-8 -14 11 -33 25 -25 16 10 13 36 -3 36 -8 0 -18 -5 -22
-11z"/>
<path d="M1865 5490 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M2066 5475 c-7 -18 16 -30 40 -21 13 5 14 9 4 21 -16 19 -36 19 -44
0z"/>
<path d="M841 5408 c-32 -33 -35 -57 -14 -87 30 -39 75 -40 113 -2 17 16 30
39 30 50 0 56 -89 83 -129 39z"/>
<path d="M2315 5395 c-53 -52 -14 -140 63 -140 50 0 82 27 82 67 0 40 -15 65
-50 83 -41 21 -67 19 -95 -10z"/>
<path d="M8495 5306 c-29 -21 -3 -46 46 -46 46 0 49 11 13 39 -32 25 -34 25
-59 7z"/>
<path d="M1742 5268 c-18 -18 -14 -46 7 -53 38 -12 73 23 50 51 -14 17 -41 18
-57 2z"/>
<path d="M1865 5224 c-14 -14 -14 -19 -2 -31 19 -19 49 -6 45 20 -4 27 -22 32
-43 11z"/>
<path d="M2014 5155 c-14 -21 -15 -31 -6 -50 23 -50 112 -23 112 33 0 45 -79
58 -106 17z"/>
<path d="M2174 5145 c-3 -8 -3 -19 0 -24 10 -16 36 -1 36 20 0 22 -28 26 -36
4z"/>
<path d="M8447 5063 c-12 -11 -8 -23 8 -23 8 0 15 7 15 15 0 16 -12 20 -23 8z"/>
<path d="M2325 5043 c-12 -11 -15 -26 -12 -49 4 -24 0 -37 -13 -49 -11 -10
-24 -33 -30 -51 -19 -56 6 -104 68 -130 l34 -14 -21 -26 c-12 -15 -21 -36 -21
-48 0 -11 -7 -30 -15 -40 -8 -11 -15 -30 -15 -42 0 -32 44 -81 85 -94 20 -6
33 -16 30 -21 -4 -5 1 -9 9 -9 9 0 16 4 16 8 0 5 18 16 40 26 31 14 40 23 42
44 2 48 -2 66 -23 88 -11 12 -19 28 -18 35 5 25 -11 56 -36 69 l-25 14 34 35
c19 20 41 53 50 73 15 37 15 38 60 32 31 -5 51 -2 61 6 21 17 63 -4 67 -33 2
-11 15 -29 30 -40 23 -17 31 -18 57 -8 38 16 55 60 35 90 -9 12 -20 21 -25 21
-6 0 -24 14 -41 30 -29 29 -71 40 -83 21 -3 -6 -20 -4 -41 5 -35 15 -38 14
-76 -11 -22 -14 -46 -25 -55 -23 -8 2 -21 21 -29 43 -7 22 -21 45 -31 52 -24
19 -88 16 -108 -4z"/>
<path d="M2024 4665 c-9 -23 3 -45 24 -45 25 0 46 25 38 45 -7 19 -55 20 -62
0z"/>
<path d="M2563 4604 c-8 -21 13 -42 28 -27 13 13 5 43 -11 43 -6 0 -13 -7 -17
-16z"/>
<path d="M2082 4538 c-17 -17 -15 -55 4 -62 48 -19 84 8 66 49 -13 27 -49 34
-70 13z"/>
<path d="M2570 4540 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M1142 4528 c-16 -16 -15 -33 1 -47 19 -15 50 9 45 35 -4 23 -29 29
-46 12z"/>
<path d="M1580 4030 c0 -5 4 -10 9 -10 6 0 13 5 16 10 3 6 -1 10 -9 10 -9 0
-16 -4 -16 -10z"/>
<path d="M1225 3960 c-3 -5 -2 -10 4 -10 5 0 13 5 16 10 3 6 2 10 -4 10 -5 0
-13 -4 -16 -10z"/>
<path d="M2677 3933 c-12 -11 -8 -23 8 -23 8 0 15 7 15 15 0 16 -12 20 -23 8z"/>
<path d="M2610 3769 c-8 -15 -7 -21 4 -25 19 -7 36 3 36 21 0 22 -29 26 -40 4z"/>
<path d="M9720 3750 c0 -15 5 -20 18 -18 9 2 17 10 17 18 0 8 -8 16 -17 18
-13 2 -18 -3 -18 -18z"/>
<path d="M2661 3676 c-26 -31 14 -71 45 -45 21 17 14 53 -12 57 -11 2 -26 -4
-33 -12z"/>
<path d="M9655 3556 c-11 -8 -16 -19 -12 -30 9 -22 41 -17 45 8 6 27 -11 38
-33 22z"/>
<path d="M9941 3524 c-11 -14 -18 -31 -15 -39 9 -22 123 -117 134 -110 27 16
4 104 -38 148 -32 34 -55 34 -81 1z"/>
<path d="M4350 3228 c-19 -5 -56 -25 -81 -43 -32 -23 -50 -30 -57 -23 -6 6
-15 7 -21 4 -14 -9 -14 -43 0 -48 14 -5 -14 -64 -33 -70 -10 -4 -10 -8 -2 -19
6 -8 15 -36 19 -64 5 -38 15 -57 44 -86 106 -107 314 -53 377 97 18 43 18 128
0 162 -43 83 -143 120 -246 90z"/>
<path d="M9275 3126 c-25 -25 -25 -55 2 -78 31 -26 74 42 52 84 -13 24 -25 23
-54 -6z"/>
<path d="M4064 3079 c-10 -17 13 -36 27 -22 12 12 4 33 -11 33 -5 0 -12 -5
-16 -11z"/>
<path d="M3970 2925 c-7 -8 -18 -15 -26 -15 -22 0 -44 -30 -44 -59 0 -56 54
-65 86 -15 9 13 23 24 30 24 23 0 40 46 24 65 -16 19 -54 19 -70 0z"/>
<path d="M9910 2872 c-65 -32 -91 -132 -49 -186 26 -33 59 -33 97 -1 64 54 82
148 36 184 -32 25 -39 26 -84 3z"/>
<path d="M2210 2860 c0 -5 4 -10 9 -10 6 0 13 5 16 10 3 6 -1 10 -9 10 -9 0
-16 -4 -16 -10z"/>
<path d="M4015 2820 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M4809 2809 c-26 -26 -21 -59 12 -78 24 -13 29 -13 53 2 28 19 36 63
14 85 -18 18 -56 14 -79 -9z"/>
<path d="M3900 2770 c0 -5 7 -10 16 -10 8 0 12 5 9 10 -3 6 -10 10 -16 10 -5
0 -9 -4 -9 -10z"/>
<path d="M4557 2674 c-52 -27 -67 -52 -67 -115 0 -46 38 -93 82 -104 75 -19
168 51 168 126 0 33 -6 47 -34 75 -19 19 -43 34 -54 34 -11 0 -26 2 -34 5 -7
2 -35 -7 -61 -21z"/>
<path d="M4092 2530 c-67 -63 -19 -163 70 -146 40 7 78 54 78 95 0 60 -101 95
-148 51z"/>
<path d="M4339 2531 c-20 -16 -22 -23 -14 -47 8 -22 18 -30 40 -32 47 -6 73
48 39 82 -20 21 -37 20 -65 -3z"/>
<path d="M3930 2525 c-16 -19 -5 -35 25 -35 26 0 39 22 22 39 -15 15 -32 14
-47 -4z"/>
<path d="M7568 2489 c-55 -41 -27 -106 49 -115 51 -6 68 7 68 55 0 73 -59 103
-117 60z"/>
<path d="M7815 2487 c-18 -14 -18 -15 1 -89 4 -17 -6 -36 -40 -76 -25 -30 -46
-61 -46 -69 0 -23 103 -156 144 -188 60 -46 116 -57 256 -50 118 6 128 5 169
-17 81 -42 93 -75 79 -213 -2 -25 1 -31 20 -33 27 -4 51 13 63 45 10 28 -18
144 -60 244 -69 164 -183 310 -295 378 -115 69 -247 100 -291 68z"/>
<path d="M3130 2475 c-7 -8 -10 -22 -6 -30 8 -22 46 -1 46 25 0 24 -22 26 -40
5z"/>
<path d="M3587 2484 c-14 -14 -7 -35 11 -32 9 2 17 10 17 17 0 16 -18 25 -28
15z"/>
<path d="M5410 2395 c-14 -17 -5 -35 19 -35 22 0 45 25 36 40 -9 15 -41 12
-55 -5z"/>
<path d="M2875 2340 c-3 -5 1 -10 9 -10 9 0 16 5 16 10 0 6 -4 10 -9 10 -6 0
-13 -4 -16 -10z"/>
<path d="M6465 2340 c-8 -12 20 -24 35 -15 5 3 10 10 10 16 0 12 -37 12 -45
-1z"/>
<path d="M6413 2291 c-77 -36 -97 -120 -41 -173 29 -27 38 -30 81 -25 69 6
112 49 112 112 0 83 -72 123 -152 86z"/>
<path d="M5784 2214 c-22 -8 -56 -30 -74 -49 -29 -30 -34 -43 -38 -93 -8 -109
56 -187 154 -187 45 0 60 6 124 50 68 47 77 50 134 50 73 0 139 -28 236 -101
96 -73 121 -115 130 -215 6 -72 11 -86 34 -106 29 -24 47 -22 84 10 28 26 28
68 1 93 -12 10 -69 91 -127 178 -59 89 -133 187 -166 220 -134 134 -351 200
-492 150z"/>
<path d="M7565 2185 c-17 -16 -25 -35 -25 -57 1 -101 161 -227 220 -173 40 36
13 121 -66 205 -55 58 -90 65 -129 25z"/>
<path d="M3255 2175 c-14 -13 -25 -31 -25 -40 0 -29 46 -65 85 -65 78 0 102
80 34 115 -40 21 -66 19 -94 -10z"/>
<path d="M5233 2179 c-42 -12 -59 -42 -37 -63 29 -30 102 -16 123 22 20 37
-27 59 -86 41z"/>
<path d="M4610 2095 c-30 -32 -18 -58 27 -63 54 -5 112 65 67 82 -34 13 -71 5
-94 -19z"/>
<path d="M7427 1976 c-4 -9 -2 -18 3 -21 13 -8 41 6 37 19 -6 17 -34 19 -40 2z"/>
<path d="M7235 1960 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M6787 1954 c-14 -14 -7 -35 11 -32 9 2 17 10 17 17 0 16 -18 25 -28
15z"/>
<path d="M3213 1933 c-23 -9 -15 -35 10 -31 12 2 22 9 22 17 0 16 -14 22 -32
14z"/>
<path d="M3884 1906 c-24 -18 -11 -48 18 -44 14 2 24 11 26 24 4 26 -20 37
-44 20z"/>
<path d="M5613 1754 c-3 -8 -1 -20 5 -26 16 -16 42 -2 42 22 0 24 -38 28 -47
4z"/>
<path d="M4310 1593 c-53 -19 -69 -89 -28 -117 36 -25 76 -20 110 14 24 25 29
36 23 58 -10 45 -54 64 -105 45z"/>
<path d="M8468 1579 c-29 -16 -23 -73 8 -98 35 -28 60 -26 80 4 17 26 13 67
-8 92 -12 15 -56 16 -80 2z"/>
<path d="M5136 1553 c-12 -12 -6 -33 9 -33 8 0 15 6 15 14 0 17 -14 28 -24 19z"/>
<path d="M7915 1398 c-27 -21 -55 -73 -55 -103 0 -29 46 -65 83 -65 49 0 97
58 97 117 0 34 -8 46 -37 62 -32 16 -57 14 -88 -11z"/>
<path d="M6678 1379 c-42 -24 -1 -109 52 -109 32 0 50 23 50 64 0 47 -56 72
-102 45z"/>
<path d="M4852 1255 c-50 -26 -160 -139 -163 -168 -5 -45 42 -112 89 -128 41
-13 156 -7 216 13 93 30 145 135 111 224 -21 55 -56 75 -140 81 -51 4 -68 0
-113 -22z"/>
<path d="M9002 1148 c-16 -16 -15 -63 2 -77 10 -8 20 -6 40 10 36 28 33 73 -4
77 -14 2 -31 -3 -38 -10z"/>
<path d="M8567 1103 c-13 -12 -7 -43 7 -43 16 0 46 26 46 41 0 10 -43 12 -53
2z"/>
<path d="M8870 836 c0 -8 4 -17 8 -20 13 -8 35 11 28 23 -10 16 -36 14 -36 -3z"/>
<path d="M8976 741 c-4 -7 -5 -15 -2 -18 9 -9 19 4 14 18 -4 11 -6 11 -12 0z"/>
<path d="M7809 372 c-29 -24 -20 -51 15 -45 31 6 50 28 42 48 -7 20 -29 19
-57 -3z"/>
<path d="M7914 296 c-79 -35 -144 -123 -144 -193 0 -74 56 -110 157 -100 100
10 153 67 153 162 0 105 -81 170 -166 131z"/>
</g>
</svg>
    )
    }
export default SplatterTen;