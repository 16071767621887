export const adjs = [
  'Iron',
  'Strong',
  'Perfect',
  'Hateful',
  'Brave',
  'Sneaky',
  'Smart',
  'Quick',
  'Surreptitious',
  'Gold',
  'Plastic',
  'Fast',
  'Weak',
  'Cruel',
  'Jealous',
  'Nice',
  'Evil',
  'Loving',
  'Clever',
  'Kind',
  'Talented',
  'Goofy',
  'Slippery',
  'Silly',
  'Smelly',
  'Snarky',
  'Snobbish',
  'Slimy',
  'Swift',
  'Slim',
  'Silent',
  'Short',
  'Shifty',
  'Snazzy',
  'Insane',
  'Invisible',
  'Ghostly',
  'Tacky',
  'Fly',
  'Flying',
  'Floating',
  'Angry',
  'Tactical',
  'Terrible',
  'Lazy',
  'Tarnished',
  'The',
  'Honest',
  'Mad',
  'Transient',
  'Friendly',
  'Mean',
  'Squishy',
  'Hardened',
  'Soft',
  'Cunning',
  'Corrosive',
  'Cuddly',
  'Weird',
  'Thee',
  'Elevated',
  'Likeable',
  'Stone',
  'Loyal',
  'Proper',
  'Cloud',
  'Impeccable',
  'Lovely',
  'Electric',
  'Sleeping',
  'Unfounded',
  'Sugar',
  'Salty',
  'Chaos',
  'Masked',
  'Lonely',
  'Fuzzy',
  'Feathered',
  'Emotional',
  'Musical',
  'Glistening',
  'Dank',
  'Night',
  'Lurid',
  'Dark',
  'Mortal',
  'Smooth',
  'Von',
  'Count',
  'Wind',
  'Cursed',
  'Reluctant',
  'Heavenly',
  'Pointy',
  'Rugged',
  'Gritty',
  'Fit',
  'Stout',
  'Twighlight',
  'Gallant',
  'Celtic',
  'Pretty',
  'Sweet',
  'Exquisite',
  'Deplorable',
  'Wicked',
  'Urbane',
  'Stylish',
  'Vaunted',
  'Hidden',
  'Tasmanian',
  'Los',
  'Le',
  'La',
  'Elite',
  'Rude',
  'Mini',
  'Whispering',
  'Chained',
  'Hardy',
  'Resolute',
  'Dogged',
  'Icy',
  'Wet',
  'Glassy',
  'Soggy',
  'Spineless',
  'Sopping',
  'Young',
  'Witty',
  'Droll',
  'Jolly',
  'Priceless',
  'Jocular',
  'Awful',
  'Tragic',
  'Dingy',
  'Nimble',
  'Lively',
  'Rapid',
  'Tight',
  'Brisk',
  'Turbo',
  'Ballistic',
  'Irate',
  'Livid',
  'Inflamed',
  'Indignant',
  'Diplomatic',
  'Prudent',
  'Mute',
  'Taciturn',
  'Hypoxic',
  'Sick',
  'Galactic',
  'Dangerous',
  'Dead',
  'Shocking',
  'Stunning',
  'Thrilling',
  'Marvelous',
  'Incredible',
  'X-',
  'Awesome',
  'Cosmic',
  'Colossal',
  'Grand',
  'Mega',
  'Thrifty',
  'Nasty',
  'Interstellar',
  'Cold',
  'Frosty',
  'Chilly',
  'Stony',
  'Bitter',
  'Sharp',
  'Mammoth',
  'Ultimate',
  'Nefarious',
  'Malevolent',
  'Sinful',
  'Heinous',
  'Criminal',
  'Fire',
  'Fantastic',
  'Feral',
  'Trained',
  'Forgotten',
  'Forged',
  'Freakish',
  'Grim',
  'Giant',
  'Gauche',
  'Awkward',
  'Graceful',
  'Graceless',
  'Insolent',
  'Impolite',
  'Presumptuous',
  'Abrupt',
];

export const nouns = [
  'Snake',
  'Chef',
  'Slicer',
  'Snail',
  'Stabber',
  'Stinker',
  'Stool',
  'Steve',
  'Skunk',
  'Giant',
  'Shell',
  'Hulk',
  'Horror',
  'Sea',
  'Slapper',
  'Knight',
  'Paladin',
  'Assassin',
  'Barbarian',
  'Mage',
  'Dragon',
  'Usurper',
  'Beholder',
  'Betrayer',
  'Dog',
  'Cat',
  'Mouse',
  'Penguin',
  'Eagle',
  'Wolf',
  'Coyote',
  'Gnome',
  'Tiger',
  'Panther',
  'Jackal',
  'Sailor',
  'Captain',
  'Sapper',
  'General',
  'Major',
  'Bard',
  'Toy',
  'Star',
  'Striker',
  'Player',
  'Flyer',
  'Warrior',
  'Wave',
  'Cyclone',
  'Storm',
  'Thunder',
  'Twister',
  'Ninja',
  'Clown',
  'Samurai',
  'Master',
  'Legend',
  'Termite',
  'Ant',
  'Spider',
  'Giver',
  'Taker',
  'Singer',
  'Passer',
  'Rocket',
  'Dealer',
  'Avenger',
  'Ent',
  'Archer',
  'Agent',
  'Yeti',
  'Savior',
  'Reaper',
  'Weather',
  'Jester',
  'Spear',
  'Flex',
  'Tripper',
  'Judge',
  'Cash',
  'Smoke',
  'Operator',
  'Sleeper',
  'Titan',
  'Emperor',
  'Elf',
  'Antagonist',
  'Imp',
  'Pixie',
  'Thug',
  'Brute',
  'Desperado',
  'Werewolf',
  'Champion',
  'Priest',
  'Monk',
  'Nun',
  'Sorcerer',
  'Warlock',
  'Druid',
  'Liar',
  'Arbiter',
  'Arbiter',
  'Referee',
  'Dude',
  'Golem',
  'Cyborg',
  'Robot',
  'Bull',
  'Stallion',
  'Honeybadger',
  'Hippo',
  'Cobra',
  'Lion',
  'Wolverine',
  'Scorpion',
  'Mamba',
  'Anaconda',
  'Hawk',
  'Raptor',
  'Challenger',
  'Demigod',
  'Arrow',
  'Shot',
  'Bullet',
  'Target',
  'Slam',
  'Hammer',
  'Orc',
  'Tide',
  'Minotaur',
  'Creep',
  'Bully',
  'Hoodlum',
  'Ruffian',
  'Watchman',
  'Sentry',
  'Custodian',
  'Conscript',
  'Gallant',
  'Pumpkin',
  'Rose',
  'Shadow',
  'Specter',
  'Phantom',
  'Vision',
  'Wight',
  'Revenant',
  'Shade',
  'Phantasm',
  'Jet',
  'Missile',
  'Bomb',
  'Dancer',
  'Fiddler',
  'Falcon',
  'Kite',
  'Harrier',
  'Vulture',
  'Jaguar',
  'Leopard',
  'Ocelot',
  'Serval',
  'Cheetah',
  'Caracal',
  'Lynx',
  'Wildcat',
  'Magus',
  'Thaumaturge',
  'Surfer',
  'Gorilla',
  'Camper',
  'Hero',
  'Angel',
  'Abyss',
  'Stack',
  'Banshee',
  'Blade',
  'Sword',
  'Blizzard',
  'Scream',
  'Buster',
  'Boom',
  'Blur',
  'Beast',
  'Bird',
  'Bishop',
  'Bug',
  'Widow',
  'Crow',
  'Cannonball',
  'Cloak',
  'Criminal',
  'Crystal',
  'Doom',
  'Lord',
  'Ruler',
  'Goblin',
  'Quake',
  'Fang',
  'Freak',
  'Gladiator',
  'Gargoyle',
  'Gorgon',
  'Harpoon',
];

export function reviewConstants() {
  let repeatAdjs = [];
  for (let j = 0; j < adjs.length; j++) {
    for (let i = j + 1; i < adjs.length; i++) {
      if (adjs[j] === adjs[i]) {
        repeatAdjs.length !== 0
          ? repeatAdjs.push(' ' + adjs[j])
          : repeatAdjs.push(adjs[j]);
      }
    }
  }

  const lastAdjIndex = repeatAdjs.length - 1;
  repeatAdjs[lastAdjIndex] =
    repeatAdjs.length > 1
      ? ' and' + repeatAdjs[lastAdjIndex]
      : repeatAdjs[lastAdjIndex];
  let adjsReport = 'There are ' + adjs.length + ' adjectives. ';
  const adjsSingularOrPlural = repeatAdjs.length === 1 ? ' is' : ' are';

  adjsReport +=
    repeatAdjs.length !== 0
      ? repeatAdjs + adjsSingularOrPlural + ' repeated.'
      : 'No adjectives are repeated.';

  let repeatNouns = [];
  for (let j = 0; j < nouns.length; j++) {
    for (let i = j + 1; i < nouns.length; i++) {
      if (nouns[j] === nouns[i]) {
        repeatNouns.length !== 0
          ? repeatNouns.push(' ' + nouns[j])
          : repeatNouns.push(nouns[j]);
      }
    }
  }

  const lastNounIndex = repeatNouns.length - 1;
  repeatNouns[lastNounIndex] =
    repeatNouns.length > 1
      ? ' and' + repeatNouns[lastNounIndex]
      : repeatNouns[lastNounIndex];
  let nounsReport = 'There are ' + nouns.length + ' nouns. ';
  const nounsSingularOrPlural = repeatNouns.length === 1 ? ' is' : ' are';

  nounsReport +=
    repeatNouns.length !== 0
      ? repeatNouns + nounsSingularOrPlural + ' repeated.'
      : 'No nouns are repeated.';

  const uniqueTags =
    (adjs.length - repeatAdjs.length) * (nouns.length - repeatNouns.length);
  console.log(
    ' ' + adjsReport,
    '\n',
    nounsReport,
    '\n',
    'There are ' + uniqueTags + ' unique tags available.'
  );
}
