import React from 'react';

function SplatterSix(props) {
    return(
<svg style={props.stylez} version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="128.000000pt" height="128.000000pt" viewBox="0 0 1280.000000 1280.000000"
 preserveAspectRatio="xMidYMid meet">
<metadata>
Created by potrace 1.15, written by Peter Selinger 2001-2017
</metadata>
<g transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)"
 stroke="none">
<path d="M3393 10802 c-15 -11 -24 -33 -28 -64 -6 -41 -3 -52 18 -80 14 -17
56 -48 94 -67 43 -23 69 -43 72 -56 2 -11 17 -40 34 -65 76 -113 130 -226 138
-290 19 -145 22 -201 10 -224 -7 -12 -11 -38 -9 -57 3 -30 7 -34 35 -37 27 -3
35 2 57 35 14 21 35 74 46 117 l20 79 -30 51 c-16 28 -38 56 -49 63 -19 12
-72 112 -87 163 -4 14 -24 54 -45 89 -22 34 -39 64 -39 66 0 2 -16 27 -35 56
-19 29 -37 66 -41 83 -13 64 -46 107 -108 143 -27 16 -27 16 -53 -5z"/>
<path d="M4933 10783 c-18 -7 -16 -38 3 -53 12 -11 18 -11 30 2 13 12 14 20 5
37 -12 21 -17 23 -38 14z"/>
<path d="M4127 10408 c-7 -26 8 -48 33 -48 21 0 31 30 18 54 -13 23 -45 20
-51 -6z"/>
<path d="M8350 10249 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0
-10 -5 -10 -11z"/>
<path d="M5675 10211 c-3 -5 -1 -12 5 -16 5 -3 10 1 10 9 0 18 -6 21 -15 7z"/>
<path d="M5848 10114 c-4 -3 -4 -12 -1 -20 7 -19 -27 -30 -39 -13 -6 10 -7 6
-2 -11 3 -14 14 -36 24 -50 10 -14 15 -32 11 -40 -15 -39 -172 -153 -188 -137
-2 3 3 22 12 43 35 82 4 179 -54 172 -22 -3 -29 -12 -46 -63 -19 -57 -23 -62
-75 -87 -66 -32 -189 -72 -285 -93 -38 -8 -104 -23 -145 -32 -41 -9 -92 -16
-112 -16 -26 1 -38 -3 -38 -13 0 -17 -12 -18 111 6 232 46 401 96 501 150 l36
20 22 -30 c29 -41 23 -57 -35 -97 -79 -52 -78 -51 -70 -97 10 -65 7 -88 -21
-127 -40 -58 -43 -132 -10 -205 28 -63 73 -110 115 -122 65 -18 274 1 306 28
8 6 26 13 42 14 19 2 28 9 28 21 0 10 5 19 10 21 6 1 22 5 36 8 40 9 109 113
110 168 1 23 6 48 11 54 10 13 -6 28 -44 43 -35 13 -58 62 -51 107 6 39 32 58
69 51 23 -4 24 -3 9 13 -16 18 -34 17 -86 -5 -13 -5 -20 2 -29 30 -6 20 -9 42
-7 50 3 7 -1 16 -10 19 -9 4 -13 13 -9 21 3 8 9 22 12 30 5 12 -3 15 -37 15
-64 0 -37 16 39 23 12 1 20 7 19 12 -2 6 -18 9 -35 7 -24 -2 -32 1 -32 12 0 9
-8 16 -18 16 -24 0 -45 45 -33 69 10 17 0 47 -11 35z"/>
<path d="M4280 9746 c0 -2 23 -50 51 -107 29 -57 64 -139 79 -183 30 -84 43
-105 55 -87 3 7 -14 53 -38 104 -25 51 -54 113 -66 140 -47 106 -64 137 -72
137 -5 0 -9 -2 -9 -4z"/>
<path d="M9235 9701 c-6 -11 9 -23 19 -14 9 9 7 23 -3 23 -6 0 -12 -4 -16 -9z"/>
<path d="M6160 9670 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"/>
<path d="M6292 9620 c7 -11 17 -20 22 -20 5 0 1 9 -9 20 -10 11 -20 20 -22 20
-2 0 2 -9 9 -20z"/>
<path d="M6840 9535 c0 -8 7 -15 15 -15 8 0 15 7 15 15 0 8 -7 15 -15 15 -8 0
-15 -7 -15 -15z"/>
<path d="M6085 9361 c-20 -4 -26 -9 -17 -14 14 -10 52 0 52 14 0 5 -1 8 -2 8
-2 -1 -16 -4 -33 -8z"/>
<path d="M6160 9344 c0 -13 18 -39 24 -34 2 3 -2 14 -10 24 -8 11 -14 16 -14
10z"/>
<path d="M8235 9320 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M5213 9243 c-24 -37 -22 -158 4 -210 48 -97 81 -15 64 162 -5 62 -8
70 -28 73 -15 2 -27 -6 -40 -25z"/>
<path d="M4837 9204 c-4 -4 -7 -18 -7 -31 0 -20 5 -24 23 -21 16 2 22 10 22
28 0 24 -23 39 -38 24z"/>
<path d="M5982 9138 c7 -19 38 -22 38 -4 0 10 -9 16 -21 16 -12 0 -19 -5 -17
-12z"/>
<path d="M3149 9103 c-23 -6 -49 -57 -48 -95 0 -18 9 -46 20 -63 31 -50 69
-136 69 -159 0 -43 55 -242 77 -278 28 -45 50 -60 130 -89 57 -21 66 -22 119
-9 95 23 144 69 145 137 1 97 -34 181 -94 225 -46 35 -80 45 -187 58 l-95 11
-41 47 c-23 25 -40 51 -39 57 8 27 13 132 7 141 -9 14 -42 23 -63 17z"/>
<path d="M8429 9046 c-43 -12 -92 -35 -76 -36 20 0 127 34 127 42 0 4 -1 8 -2
7 -2 0 -24 -6 -49 -13z"/>
<path d="M8165 9010 c-21 -16 -34 -30 -29 -30 10 1 82 60 72 60 -3 0 -22 -13
-43 -30z"/>
<path d="M9385 8970 c27 -12 35 -12 35 0 0 6 -12 10 -27 9 -25 0 -26 -1 -8 -9z"/>
<path d="M8054 8915 c-30 -31 -54 -61 -54 -67 0 -6 1 -9 3 -7 2 2 29 32 62 67
72 77 63 83 -11 7z"/>
<path d="M9160 8940 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M4624 8905 c4 -11 20 -56 36 -100 15 -44 40 -111 55 -150 15 -38 44
-119 65 -180 86 -246 140 -394 170 -470 49 -123 50 -125 25 -170 -36 -67 -37
-83 -4 -90 40 -9 79 -58 79 -101 0 -19 7 -51 15 -70 18 -44 13 -54 -26 -54
-16 0 -58 -15 -92 -32 l-62 -33 40 -5 c72 -9 53 -65 -30 -84 -16 -4 -46 -10
-65 -15 -19 -4 -66 -21 -105 -38 -38 -16 -98 -40 -133 -53 -34 -12 -60 -25
-58 -27 3 -3 19 0 37 6 17 6 36 8 41 5 5 -4 23 3 38 15 20 14 31 17 36 10 9
-15 -22 -38 -105 -80 -36 -18 -86 -47 -111 -64 -25 -17 -67 -42 -94 -56 -65
-32 -174 -134 -197 -184 -12 -25 -19 -64 -19 -102 l0 -63 30 0 c20 0 40 11 62
33 31 31 157 117 171 117 4 0 36 14 70 30 35 17 70 30 78 30 8 0 25 10 39 21
35 28 129 89 138 89 6 0 38 46 50 73 2 5 -13 -3 -34 -17 -60 -39 -146 -86
-159 -86 -6 0 -29 -15 -51 -32 -63 -53 -100 -77 -130 -84 -16 -4 -60 -25 -99
-46 -95 -51 -135 -48 -135 9 0 61 73 133 205 202 44 23 86 48 95 55 8 7 42 28
75 45 112 61 145 83 145 96 0 15 60 55 81 55 8 0 -5 -12 -29 -26 -24 -14 -41
-28 -39 -30 2 -3 20 4 39 15 34 20 154 69 194 78 66 15 129 -43 91 -85 -21
-23 -22 -33 -2 -26 10 4 15 0 15 -11 0 -10 -5 -15 -13 -12 -12 5 -31 -10 -36
-28 -1 -5 -9 -9 -17 -7 -16 3 -19 -14 -4 -23 15 -9 12 -25 -4 -25 -8 0 -23
-16 -35 -35 -11 -19 -19 -35 -17 -35 8 0 66 32 66 36 0 3 -10 2 -22 -2 -16 -5
-11 2 16 26 33 29 40 31 60 21 12 -7 28 -11 36 -9 7 2 19 -8 26 -21 7 -13 20
-28 28 -33 26 -15 18 -53 -19 -98 -19 -23 -35 -49 -35 -58 0 -10 -19 -44 -42
-77 -23 -33 -56 -98 -74 -144 -36 -90 -57 -121 -84 -121 -31 0 -42 25 -31 67
16 58 -4 65 -28 10 -24 -56 -56 -104 -102 -155 -37 -40 -79 -96 -79 -106 0 -3
31 26 68 64 37 39 74 70 80 70 22 0 24 -27 4 -67 -11 -21 -25 -74 -32 -118
-14 -94 -33 -157 -60 -196 -11 -15 -20 -33 -20 -39 0 -7 -8 -29 -19 -49 -10
-20 -27 -54 -37 -76 -11 -22 -31 -57 -46 -78 -14 -21 -30 -57 -33 -80 -8 -48
-36 -107 -59 -126 -9 -8 -16 -18 -16 -24 0 -6 -17 -31 -38 -56 -97 -119 -126
-173 -136 -256 -7 -58 4 -117 22 -123 23 -7 77 66 140 190 35 70 51 110 38 94
-14 -16 -32 -47 -42 -68 -22 -50 -38 -44 -42 15 -4 60 27 129 111 245 40 56
59 66 56 30 -1 -13 -1 -31 -1 -42 2 -16 4 -16 18 -5 12 10 14 23 9 56 -6 39
-1 53 50 151 31 59 63 134 72 166 22 88 78 204 121 250 21 23 44 57 51 76 24
64 36 85 73 130 36 45 78 128 78 156 0 22 54 128 58 115 2 -6 -5 -26 -15 -44
-13 -21 -17 -40 -13 -52 6 -16 10 -11 20 25 6 25 19 54 28 64 13 14 14 20 5
23 -15 5 -18 33 -4 33 5 0 11 -6 14 -12 2 -8 11 0 20 17 9 17 16 24 17 17 0
-6 5 -11 11 -10 6 2 14 -3 16 -10 3 -7 1 -12 -4 -11 -23 4 -28 -5 -25 -51 4
-53 22 -60 22 -9 0 18 4 28 10 24 6 -4 17 -2 25 5 35 29 25 -81 -11 -121 -10
-11 -12 -19 -6 -19 7 0 12 -10 13 -22 l0 -23 9 23 c12 29 20 28 20 -3 0 -16
-6 -25 -15 -25 -12 0 -13 -3 -3 -19 9 -15 7 -30 -10 -75 -12 -31 -17 -56 -12
-56 6 0 10 -10 10 -22 0 -21 0 -21 15 -2 15 18 15 16 9 -26 -8 -60 -23 -86
-25 -45 -1 17 -3 23 -6 13 -5 -21 -14 -23 -31 -6 -16 16 -16 -10 -1 -49 13
-31 12 -94 -1 -129 -5 -12 -5 -35 0 -51 4 -15 9 -21 9 -13 1 8 8 28 16 43 10
20 12 43 8 74 l-6 47 34 -22 c19 -12 54 -32 79 -43 43 -21 46 -21 91 -6 59 21
57 22 62 -10 3 -20 4 -16 6 14 1 41 39 103 62 103 6 0 10 -24 10 -52 -1 -29 2
-82 7 -118 6 -54 10 -65 27 -68 11 -2 30 -13 42 -24 l22 -21 10 27 c20 52 43
37 150 -103 33 -44 84 -169 83 -206 -1 -10 2 -24 7 -30 14 -18 32 -94 41 -173
10 -86 16 -118 24 -131 3 -4 6 -48 7 -97 1 -49 5 -99 10 -111 4 -12 11 -75 14
-140 4 -65 11 -125 15 -133 4 -8 11 -37 15 -65 20 -151 62 -283 118 -373 21
-34 45 -83 51 -110 29 -106 117 -235 271 -397 142 -150 174 -184 206 -225 47
-58 226 -238 267 -269 33 -23 92 -105 140 -191 13 -25 46 -70 71 -100 26 -30
64 -79 85 -108 69 -95 169 -120 258 -63 26 16 52 40 59 53 6 13 22 36 35 51
26 31 63 140 69 202 4 51 -11 173 -32 250 -20 73 -78 157 -135 198 -24 17 -65
47 -93 68 -48 36 -125 74 -125 62 0 -3 -11 2 -25 11 -13 9 -35 16 -49 16 -14
0 -28 5 -31 10 -4 6 -10 8 -13 6 -11 -6 -119 19 -178 42 -28 10 -91 41 -140
67 -49 26 -104 56 -122 66 -33 17 -199 189 -237 245 -11 16 -50 58 -86 94 -71
69 -124 163 -153 272 -12 45 -29 89 -76 193 -4 11 -11 76 -15 145 -3 69 -13
179 -21 245 -18 135 -19 167 -16 281 l2 82 33 5 c17 2 33 4 34 3 1 -1 3 -19 5
-41 3 -38 6 -41 57 -63 29 -13 55 -21 57 -19 2 2 23 -14 45 -35 23 -22 43 -37
46 -34 3 3 -2 21 -11 40 -9 19 -16 36 -16 39 0 11 86 -7 110 -23 14 -8 34 -21
45 -28 11 -7 33 -20 48 -30 21 -13 26 -23 22 -39 -3 -12 -1 -25 5 -28 6 -3 9
-54 7 -123 -2 -139 10 -172 85 -212 26 -14 50 -28 53 -31 3 -4 -2 -38 -12 -77
-15 -63 -15 -80 -4 -139 20 -101 47 -165 83 -200 49 -46 73 -55 162 -60 129
-8 193 28 382 212 73 71 103 197 69 290 -25 71 -22 77 63 112 42 17 94 36 115
43 20 6 41 20 47 30 14 27 165 143 202 156 18 6 40 23 48 37 13 24 13 28 -2
44 -15 14 -24 15 -65 6 -57 -14 -77 -1 -93 57 -6 21 -16 43 -24 49 -24 20 -25
-22 -2 -80 19 -50 24 -55 64 -63 52 -12 57 -38 10 -57 -17 -7 -45 -26 -61 -42
-41 -40 -87 -67 -117 -67 -17 -1 -31 -11 -45 -33 -20 -32 -96 -80 -145 -92
-22 -5 -34 5 -93 75 -66 79 -69 81 -105 75 -96 -15 -160 -27 -197 -37 -60 -17
-177 -96 -225 -153 -25 -29 -50 -49 -59 -47 -35 8 -81 54 -92 93 -15 50 0 179
27 235 17 34 23 39 52 38 17 -1 32 2 32 8 0 5 -4 6 -10 3 -6 -3 -10 1 -10 9 0
20 5 20 36 0 14 -10 23 -23 20 -30 -3 -8 1 -14 9 -14 8 0 20 -9 28 -20 7 -10
26 -29 40 -41 l28 -21 -18 37 c-17 35 -17 38 -1 49 13 10 14 16 6 24 -7 7 -13
20 -14 29 -8 55 -17 89 -26 101 -6 7 -8 33 -5 62 3 27 2 52 -4 55 -5 4 -9 17
-9 30 0 12 -7 25 -15 29 -8 3 -15 12 -15 21 0 19 2 19 45 -6 29 -17 45 -20 83
-15 25 3 50 10 54 14 20 20 -98 112 -123 97 -5 -4 -21 1 -36 10 -24 16 -25 20
-19 74 4 31 9 94 12 141 2 47 11 108 18 135 8 28 17 87 21 131 l7 81 39 -21
c21 -11 37 -25 36 -31 -2 -5 10 -15 27 -21 17 -7 54 -21 83 -32 36 -14 53 -16
56 -8 3 8 8 8 18 -1 7 -7 18 -13 23 -13 6 0 0 7 -13 16 -13 9 -34 17 -47 17
-13 0 -21 3 -18 8 3 5 -2 6 -10 3 -15 -6 -76 24 -76 37 0 3 -20 14 -44 23 -46
18 -68 37 -73 65 -18 87 -18 89 27 131 25 24 53 41 69 41 23 2 24 2 4 6 -13 2
-23 8 -23 12 0 16 47 49 79 56 31 7 33 6 28 -15 -5 -21 -5 -21 14 -4 18 16 19
16 19 -9 0 -19 8 -31 30 -42 28 -15 33 -14 68 3 20 10 62 27 92 37 30 9 58 21
61 26 3 5 11 -3 18 -18 8 -16 17 -36 22 -45 13 -26 12 -29 -25 -72 -31 -35
-38 -38 -68 -32 -23 4 -29 3 -18 -4 28 -18 58 -10 94 27 38 40 41 49 27 85 -5
13 -12 35 -15 50 -4 18 -15 30 -30 34 -13 3 -34 17 -47 30 -12 13 -28 24 -35
24 -17 0 -56 85 -52 113 3 19 10 22 52 25 27 2 52 -1 55 -5 13 -19 113 -76
155 -88 49 -15 73 -4 60 29 -8 20 5 20 76 1 56 -15 214 -20 282 -9 54 9 81 39
75 80 -7 40 -4 40 44 15 47 -25 165 -37 213 -21 43 14 106 86 106 121 -1 34
-33 100 -63 128 -30 27 -110 41 -122 21 -22 -35 7 -128 58 -187 27 -30 16 -50
-34 -63 -49 -13 -105 -6 -174 22 -49 19 -71 42 -158 156 -16 20 -44 54 -63 74
-19 20 -34 41 -34 46 0 4 -16 25 -35 46 -37 40 -43 61 -22 69 7 3 0 9 -15 15
-14 6 -48 27 -75 46 -26 19 -66 41 -88 49 -22 8 -49 18 -60 23 -11 4 -27 6
-35 5 -18 -2 -77 55 -67 65 3 3 22 1 42 -6 20 -7 39 -9 42 -6 4 3 0 6 -9 6 -8
0 -23 9 -32 19 -17 18 -17 21 -2 43 13 18 26 23 64 24 136 3 142 4 142 20 0
12 -6 15 -22 10 -74 -21 -187 -18 -172 5 4 7 -5 10 -22 8 -56 -4 -79 1 -92 22
-13 20 -12 21 15 16 15 -2 24 -2 18 0 -5 2 -15 15 -22 29 -10 22 -9 26 12 40
34 22 114 104 103 104 -6 0 -7 11 -4 26 4 14 2 32 -3 38 -8 10 -4 16 16 25 26
12 27 13 9 26 -17 13 -15 16 27 46 30 21 48 42 52 61 4 15 11 30 16 33 5 4 9
11 9 17 0 6 -8 4 -18 -5 -14 -13 -21 -14 -30 -5 -17 17 13 48 47 49 14 1 37 5
51 10 19 7 14 8 -22 5 l-47 -3 19 25 c11 13 20 34 20 46 0 14 19 35 58 63 31
23 72 56 90 74 l32 31 0 -32 c0 -25 -12 -45 -50 -87 -47 -51 -61 -85 -41 -97
5 -3 35 20 67 51 56 54 61 56 108 54 28 -1 51 -3 53 -4 1 -2 -2 -18 -7 -37
-22 -75 -89 -109 -180 -89 -30 6 -56 7 -61 2 -10 -10 -6 -10 103 -17 l78 -4
34 40 c20 23 37 56 41 78 4 24 18 49 41 71 19 18 34 39 34 46 0 18 10 16 36
-8 12 -12 28 -19 34 -17 6 2 -5 15 -26 28 -24 16 -41 36 -46 55 -4 17 -22 42
-40 58 l-33 27 40 -16 c61 -25 257 -21 320 7 26 11 49 18 52 16 3 -3 21 4 41
15 52 29 67 29 93 2 19 -20 20 -24 6 -24 -22 0 -22 -26 1 -39 16 -10 16 -12 0
-41 -10 -18 -18 -37 -18 -44 0 -23 -25 -47 -43 -41 -13 5 -15 14 -10 34 6 23
5 25 -10 12 -10 -7 -17 -19 -17 -27 0 -9 -8 -10 -27 -5 -16 4 -44 6 -63 4 -19
-2 -72 -6 -117 -10 -45 -3 -80 -9 -77 -14 2 -4 -10 -15 -29 -24 -21 -10 -45
-36 -66 -70 -18 -30 -37 -55 -40 -55 -4 0 -25 -16 -47 -35 -21 -19 -47 -38
-56 -41 -10 -3 -18 -10 -18 -15 0 -12 6 -11 40 6 17 9 30 11 30 5 0 -5 9 -1
21 11 11 11 26 18 32 16 13 -4 17 1 64 90 36 68 70 90 160 102 77 11 188 7
205 -6 14 -12 54 -7 75 9 7 5 16 7 21 4 5 -3 12 11 16 32 3 20 9 52 12 69 8
42 -3 98 -22 118 -17 17 -75 20 -102 5 -71 -40 -149 -60 -261 -65 -107 -6
-121 -4 -188 19 -82 29 -111 32 -138 12 -16 -12 -17 -17 -7 -37 6 -13 11 -17
11 -9 -3 34 2 40 32 40 l31 0 -31 -31 c-17 -17 -31 -35 -31 -40 0 -17 -91
-108 -134 -133 -22 -13 -49 -33 -60 -45 -15 -16 -22 -18 -39 -10 -27 15 -124
-17 -130 -43 -3 -11 -14 -18 -29 -18 -16 0 -30 -10 -41 -30 -10 -16 -23 -30
-29 -30 -5 0 -22 -8 -36 -19 -15 -10 -39 -21 -54 -25 -16 -3 -28 -11 -28 -17
0 -6 7 -9 15 -5 25 9 18 -38 -9 -63 -18 -17 -32 -22 -49 -17 -16 4 -29 0 -37
-9 -7 -8 -25 -15 -41 -15 -19 0 -32 -7 -40 -22 -13 -24 -38 -47 -52 -48 -4 0
-15 16 -24 35 -26 55 -73 42 -73 -20 0 -31 -25 -65 -47 -65 -6 0 -22 -9 -36
-21 -20 -15 -35 -18 -61 -14 -39 8 -56 -9 -33 -32 14 -14 13 -20 -6 -59 -22
-45 -34 -52 -68 -34 -12 7 -19 7 -19 1 0 -6 14 -14 31 -18 l31 -8 -42 -28
c-53 -34 -63 -34 -56 1 3 15 8 38 10 52 2 14 9 36 14 50 14 35 4 313 -11 322
-40 26 -79 -3 -113 -85 -12 -29 -28 -53 -36 -55 -7 -1 -40 13 -72 33 -32 19
-62 35 -66 35 -4 0 -13 21 -19 48 -7 26 -28 94 -47 152 -19 58 -34 117 -34
131 0 15 -11 51 -24 80 -28 60 -34 54 -11 -10 8 -23 16 -68 19 -99 2 -31 18
-91 34 -132 17 -41 32 -88 34 -105 2 -25 0 -30 -17 -27 -11 1 -54 7 -95 13
-41 6 -106 14 -145 17 -56 6 -79 13 -117 39 -38 27 -54 32 -88 29 -23 -2 -48
-10 -55 -17 -28 -29 -52 -113 -48 -169 7 -105 -32 -200 -82 -200 -27 0 -42
-17 -27 -32 6 -6 12 -6 16 1 4 6 20 11 37 11 24 0 29 -4 29 -23 0 -12 -7 -32
-15 -43 -13 -19 -19 -20 -57 -12 -24 5 -63 11 -88 14 -80 9 -90 48 -29 114 23
24 52 61 65 82 14 21 29 38 34 38 16 0 12 20 -6 27 -8 3 -25 -4 -37 -16 -13
-11 -38 -24 -57 -27 -19 -4 -41 -16 -48 -26 -11 -15 -17 -16 -27 -8 -8 6 -11
18 -8 26 4 10 -3 14 -26 14 -19 0 -34 6 -37 15 -9 23 -24 7 -24 -26 0 -18 -5
-29 -14 -29 -8 0 -16 -7 -20 -15 -3 -10 -16 -14 -38 -13 -30 3 -33 1 -30 -22
2 -14 1 -22 -3 -18 -4 4 -19 -2 -33 -12 -13 -11 -34 -20 -46 -20 -12 0 -28 -6
-35 -12 -10 -11 -16 -8 -32 11 -10 13 -19 29 -19 37 0 7 -6 17 -13 21 -8 4
-28 44 -46 87 -18 44 -37 87 -41 95 -5 9 -22 57 -38 106 -55 166 -132 386
-141 403 -13 24 -91 237 -91 248 0 5 -8 18 -17 29 l-16 20 7 -20z m2866 -581
c11 -12 11 -15 0 -12 -8 2 -14 -3 -15 -10 0 -18 -55 -72 -72 -72 -11 0 -9 7 6
26 12 15 21 34 21 43 0 37 38 53 60 25z m-165 -94 c-3 -5 -11 -10 -16 -10 -6
0 -7 5 -4 10 3 6 11 10 16 10 6 0 7 -4 4 -10z m59 -176 c-31 -25 -44 -29 -44
-15 0 15 51 51 62 45 6 -4 -3 -17 -18 -30z m-60 18 c-6 -4 -13 -15 -17 -24 -3
-10 -11 -18 -17 -18 -17 0 -11 28 8 39 20 12 43 15 26 3z m61 -72 c-3 -5 -10
-10 -16 -10 -5 0 -9 5 -9 10 0 6 7 10 16 10 8 0 12 -4 9 -10z m-2075 -140 c0
-5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z m1784 -50
c-6 -5 -24 21 -24 34 0 6 6 1 14 -10 8 -10 12 -21 10 -24z m-434 -40 c0 -5 -7
-10 -15 -10 -8 0 -15 -7 -15 -16 0 -9 5 -12 11 -8 7 4 10 1 7 -7 -4 -13 -68
-32 -78 -22 -6 6 66 73 80 73 5 0 10 -4 10 -10z m-1486 -819 c-24 -37 -28 -40
-40 -24 -10 15 -10 21 4 37 16 18 53 37 60 31 2 -2 -9 -22 -24 -44z m1775 35
c7 -8 20 -12 29 -9 13 3 22 -6 33 -28 14 -29 14 -36 1 -61 -11 -23 -17 -27
-28 -17 -8 6 -14 26 -15 43 0 26 -2 28 -9 11 -6 -17 -9 -13 -15 20 -4 22 -13
43 -19 47 -8 6 -8 8 0 8 6 0 17 -6 23 -14z m-1749 -67 c0 -11 -4 -18 -10 -14
-5 3 -7 12 -3 20 7 21 13 19 13 -6z m2008 -5 c7 -14 8 -23 3 -20 -4 3 -16 -3
-26 -14 -18 -19 -17 -20 3 -20 24 0 27 -15 13 -53 -14 -36 -70 -45 -94 -16
-10 12 -16 28 -13 36 3 7 1 13 -4 13 -6 0 -10 -7 -10 -15 0 -10 -9 -13 -31
-10 -43 5 -46 11 -22 43 20 27 63 43 63 23 0 -6 21 5 46 24 25 19 49 35 53 35
4 0 13 -12 19 -26z m60 -96 c-20 -5 -18 -6 10 -7 33 -1 43 -20 20 -38 -17 -14
-85 -26 -91 -16 -2 4 3 29 10 56 l14 47 30 -17 c30 -18 30 -18 7 -25z m-2028
12 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z
m1530 -70 c0 -16 -5 -30 -10 -30 -14 0 -24 26 -16 45 10 26 26 16 26 -15z
m110 -15 c17 -9 30 -19 30 -24 0 -5 -17 -27 -37 -51 l-38 -42 -24 26 c-24 25
-30 63 -16 106 7 21 21 18 85 -15z m40 15 c0 -5 -6 -10 -14 -10 -8 0 -18 5
-21 10 -3 6 3 10 14 10 12 0 21 -4 21 -10z m-1610 -49 c0 -28 -4 -52 -9 -55
-10 -7 -25 79 -16 94 14 23 25 7 25 -39z m1200 19 c11 -6 32 -36 47 -68 15
-31 34 -65 42 -75 8 -9 11 -20 8 -23 -11 -12 -74 6 -92 26 -16 18 -17 23 -5
46 12 22 12 29 -1 52 -9 16 -22 26 -32 24 -11 -2 -15 2 -11 13 7 18 18 19 44
5z m110 -75 c10 -12 10 -15 -1 -15 -10 -1 -8 -4 4 -11 16 -9 25 -39 11 -39
-12 0 -43 45 -44 63 0 21 13 22 30 2z m136 -23 c-10 -10 -29 15 -21 28 6 9 10
8 17 -4 6 -10 7 -20 4 -24z m-1400 -43 c3 -32 11 -57 20 -62 8 -4 14 -16 14
-27 0 -18 68 -80 88 -80 14 0 22 -25 22 -68 -1 -56 -67 -111 -125 -103 -11 1
-44 19 -73 38 -44 30 -52 40 -47 57 16 53 27 93 32 119 9 48 23 29 24 -35 l2
-63 6 57 c3 31 12 63 19 70 17 17 15 41 -2 35 -12 -5 -13 2 -9 30 4 26 3 34
-5 29 -17 -11 -15 16 4 42 21 30 24 25 30 -39z m1140 -23 c8 -8 14 -19 14 -24
0 -6 6 -12 12 -14 9 -3 8 -12 -5 -32 -10 -15 -16 -35 -12 -44 6 -15 -10 -32
-32 -32 -6 0 -23 21 -37 46 -29 51 -34 73 -13 55 16 -13 37 4 37 29 0 31 15
37 36 16z m-1212 -49 c-5 -36 -10 -45 -25 -44 -21 1 -19 18 8 70 19 38 24 30
17 -26z m1024 18 c-4 -17 0 -29 11 -35 9 -4 12 -12 8 -16 -4 -4 -18 3 -32 16
-19 18 -22 27 -14 41 14 28 32 23 27 -6z m113 -64 c7 -14 15 -30 18 -35 7 -15
-61 3 -80 20 -24 22 -14 41 21 41 21 0 32 -7 41 -26z m-451 -35 c5 -7 16 -31
24 -53 13 -38 13 -43 -4 -58 -26 -24 -72 -16 -87 15 -20 39 -17 108 5 112 23
5 49 -2 62 -16z m388 -66 c15 -19 26 -40 24 -46 -1 -6 9 -16 22 -23 23 -13 23
-15 21 -140 l-3 -126 -29 -17 c-36 -22 -45 -22 -41 -1 2 10 -2 18 -9 18 -6 0
-35 24 -63 53 -44 45 -51 58 -46 80 4 15 9 79 12 142 4 66 11 118 17 122 16
10 64 -22 95 -62z m-818 10 c13 -8 12 -13 -7 -32 l-23 -22 0 32 c0 34 5 38 30
22z m710 -395 c10 -12 10 -15 -4 -15 -9 0 -16 7 -16 15 0 8 2 15 4 15 2 0 9
-7 16 -15z m64 -44 c9 -10 19 -34 22 -53 6 -32 2 -38 -50 -86 -52 -46 -59 -50
-81 -40 -19 8 -31 8 -50 0 -25 -11 -25 -11 -25 36 0 44 18 113 33 128 3 4 12
-5 18 -20 16 -35 47 -34 55 2 7 28 29 52 49 52 7 0 20 -8 29 -19z m192 -91
c40 -60 45 -72 33 -86 -7 -9 -14 -25 -16 -36 -5 -31 -22 -58 -37 -58 -8 0 -28
22 -45 50 -25 41 -29 54 -21 75 6 15 10 43 10 64 0 30 13 61 27 61 2 0 24 -31
49 -70z m-166 -29 c0 -13 -52 -53 -58 -46 -6 6 38 53 51 54 4 1 7 -3 7 -8z
m120 -156 c0 -39 -89 -41 -109 -1 -13 27 -17 75 -7 103 6 20 11 18 61 -34 30
-30 55 -61 55 -68z m-90 -78 c0 -5 -7 -4 -15 3 -8 7 -15 20 -15 29 1 13 3 13
15 -3 8 -11 15 -24 15 -29z"/>
<path d="M6360 6466 c0 -2 9 -6 20 -9 11 -3 18 -1 14 4 -5 9 -34 13 -34 5z"/>
<path d="M9070 8810 c-8 -4 -63 -30 -122 -57 -59 -26 -110 -53 -112 -60 -6
-15 21 -6 52 18 29 22 158 63 172 54 5 -3 10 -2 10 2 0 5 10 13 22 18 12 6 23
16 26 23 4 14 -25 15 -48 2z"/>
<path d="M8350 8806 c0 -2 7 -9 15 -16 9 -7 15 -8 15 -2 0 5 -7 12 -15 16 -8
3 -15 4 -15 2z"/>
<path d="M7180 8784 c0 -8 5 -12 10 -9 6 4 8 11 5 16 -9 14 -15 11 -15 -7z"/>
<path d="M8461 8728 c-14 -23 -14 -23 8 -4 22 20 25 26 13 26 -4 0 -13 -10
-21 -22z"/>
<path d="M7120 8655 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"/>
<path d="M7625 8629 c-10 -15 3 -25 16 -12 7 7 7 13 1 17 -6 3 -14 1 -17 -5z"/>
<path d="M8582 8519 c-29 -12 -52 -23 -49 -25 2 -2 20 3 40 11 20 8 42 12 50
9 8 -3 17 2 20 10 8 20 4 20 -61 -5z"/>
<path d="M5450 8490 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M8101 8351 c-14 -14 -22 -16 -30 -8 -8 8 -11 7 -11 -3 0 -17 17 -47
22 -40 2 3 13 20 26 38 25 36 22 42 -7 13z"/>
<path d="M8227 8353 c-15 -15 -3 -111 24 -198 17 -52 49 -225 49 -262 0 -20
-5 -64 -11 -97 -10 -58 -9 -65 19 -127 40 -90 82 -118 127 -85 18 13 18 15 -5
62 -36 74 -33 145 8 208 37 58 39 76 12 131 -25 51 -25 54 3 66 21 9 21 9 -4
5 -23 -5 -26 -1 -37 40 -9 32 -19 47 -37 54 -17 6 -25 17 -25 34 0 13 -7 29
-15 36 -11 9 -12 17 -5 30 13 25 13 30 -2 25 -7 -3 -26 15 -41 40 -27 42 -45
54 -60 38z"/>
<path d="M8083 8260 c1 -16 9 -26 22 -28 18 -3 18 -3 4 10 -9 7 -19 20 -23 28
-3 8 -4 4 -3 -10z"/>
<path d="M5930 8220 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M7100 8210 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M7075 8171 c-3 -5 -1 -12 5 -16 5 -3 10 1 10 9 0 18 -6 21 -15 7z"/>
<path d="M5200 8070 c-21 -16 -23 -20 -8 -25 33 -13 73 -1 76 23 2 12 -2 22
-8 22 -7 0 -15 -10 -18 -22 l-6 -23 -5 22 c-6 22 -6 22 -31 3z"/>
<path d="M5098 8069 c-10 -5 -18 -21 -18 -34 0 -15 -5 -23 -12 -20 -7 2 -26
-6 -42 -19 -22 -18 -26 -27 -19 -40 8 -15 18 -9 69 43 33 34 68 61 79 61 17 0
17 2 5 10 -19 12 -40 12 -62 -1z"/>
<path d="M8836 8053 c-14 -14 10 -23 62 -23 39 0 53 3 46 10 -12 12 -99 22
-108 13z"/>
<path d="M9292 8038 c-17 -17 -15 -26 9 -38 24 -14 49 2 49 31 0 22 -39 26
-58 7z"/>
<path d="M9173 7833 c9 -9 19 -14 23 -11 10 10 -6 28 -24 28 -15 0 -15 -1 1
-17z"/>
<path d="M7359 7777 c5 -5 19 -7 32 -5 20 3 19 5 -9 9 -20 3 -29 2 -23 -4z"/>
<path d="M7842 7734 c-12 -8 -22 -19 -22 -25 0 -5 -11 -27 -25 -49 -26 -41
-31 -69 -17 -85 5 -6 17 -38 27 -73 16 -56 23 -66 56 -83 48 -24 81 -24 103 0
13 14 14 24 6 45 -11 30 -13 89 -4 123 7 24 50 43 100 43 38 0 64 9 64 21 0 5
-38 7 -85 4 l-85 -5 -6 28 c-17 68 -62 91 -112 56z"/>
<path d="M7996 7743 c-12 -12 -6 -33 9 -33 8 0 15 6 15 14 0 17 -14 28 -24 19z"/>
<path d="M4590 7519 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M7620 7523 c0 -4 5 -15 10 -23 8 -13 10 -13 10 2 0 9 -4 20 -10 23
-5 3 -10 3 -10 -2z"/>
<path d="M7713 7443 c9 -2 25 -2 35 0 9 3 1 5 -18 5 -19 0 -27 -2 -17 -5z"/>
<path d="M8223 7370 c-18 -4 -61 -6 -95 -4 -45 3 -59 1 -48 -6 20 -13 211 -9
207 5 -5 13 -25 15 -64 5z"/>
<path d="M9560 7340 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M4310 7330 c-9 -6 -10 -10 -3 -10 6 0 15 5 18 10 8 12 4 12 -15 0z"/>
<path d="M4926 7265 c-11 -8 -17 -17 -14 -20 2 -3 13 4 23 15 21 23 18 25 -9
5z"/>
<path d="M8065 7210 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M5029 7123 c-13 -16 -12 -17 4 -4 9 7 17 15 17 17 0 8 -8 3 -21 -13z"/>
<path d="M4853 7025 c-9 -24 1 -27 15 -5 8 13 8 20 2 20 -6 0 -13 -7 -17 -15z"/>
<path d="M9565 6960 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M4700 6884 c0 -8 5 -12 10 -9 6 4 8 11 5 16 -9 14 -15 11 -15 -7z"/>
<path d="M4655 6840 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M4093 6754 c-10 -15 -29 -38 -43 -52 l-25 -25 33 19 c42 24 90 84 68
84 -8 0 -23 -12 -33 -26z"/>
<path d="M8175 6690 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M7225 6640 c10 -11 20 -20 23 -20 3 0 -3 9 -13 20 -10 11 -20 20 -23
20 -3 0 3 -9 13 -20z"/>
<path d="M3925 6610 c-10 -11 -14 -20 -9 -20 5 0 15 9 22 20 7 11 11 20 9 20
-2 0 -12 -9 -22 -20z"/>
<path d="M5075 6580 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M5020 6550 c0 -5 1 -11 2 -12 1 -2 -4 -13 -10 -25 -9 -16 -9 -22 1
-26 9 -3 14 7 16 34 1 21 -1 39 -4 39 -3 0 -5 -4 -5 -10z"/>
<path d="M7185 6550 c3 -5 10 -10 16 -10 5 0 9 5 9 10 0 6 -7 10 -16 10 -8 0
-12 -4 -9 -10z"/>
<path d="M6942 6528 c5 -15 37 -18 41 -3 1 6 -9 12 -22 13 -16 3 -22 -1 -19
-10z"/>
<path d="M7370 6515 c0 -14 3 -25 8 -25 4 0 12 -10 17 -22 8 -18 17 -22 39
-20 24 3 38 -5 74 -45 25 -26 42 -40 38 -30 -3 10 -6 24 -6 30 0 7 -22 27 -49
45 -26 18 -58 46 -70 63 -27 36 -51 38 -51 4z"/>
<path d="M7181 6521 c-10 -7 -11 -13 -2 -30 14 -26 18 -26 30 -2 13 23 -7 45
-28 32z"/>
<path d="M7065 6490 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M3360 6450 c-8 -5 -12 -11 -9 -14 3 -3 14 1 25 9 21 16 8 20 -16 5z"/>
<path d="M3255 6399 c-12 -19 5 -23 22 -6 16 16 16 17 1 17 -9 0 -20 -5 -23
-11z"/>
<path d="M5000 6359 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M7138 6344 c24 -17 58 -12 46 7 -4 8 -9 8 -13 1 -4 -7 -12 -8 -21 -2
-26 16 -36 11 -12 -6z"/>
<path d="M3725 6330 c-16 -11 -43 -27 -60 -36 -16 -9 -46 -30 -66 -45 -20 -16
-41 -29 -46 -29 -5 0 -35 -10 -65 -23 -38 -15 -68 -37 -94 -68 -44 -52 -99
-101 -163 -147 -24 -18 -40 -32 -34 -32 11 0 117 72 149 101 11 11 61 33 110
50 74 25 105 30 184 30 52 0 94 -3 94 -6 -1 -4 -30 -39 -65 -78 -100 -111
-110 -130 -23 -42 98 100 157 173 127 157 -28 -15 -125 -26 -157 -18 -57 14
-30 50 112 145 34 23 62 46 62 51 0 16 -32 10 -65 -10z"/>
<path d="M7336 6291 c-34 -38 29 -104 69 -71 22 18 18 57 -7 74 -29 21 -41 20
-62 -3z"/>
<path d="M5474 6179 c-3 -6 -1 -16 5 -22 8 -8 11 -5 11 11 0 24 -5 28 -16 11z"/>
<path d="M7360 6162 c0 -13 31 -52 41 -52 6 0 26 -12 45 -26 58 -45 48 -20
-15 34 -59 52 -71 59 -71 44z"/>
<path d="M6980 6125 c0 -8 7 -15 15 -15 8 0 15 7 15 15 0 8 -7 15 -15 15 -8 0
-15 -7 -15 -15z"/>
<path d="M5462 6123 c-7 -2 -17 -14 -23 -26 -11 -21 -11 -21 14 2 15 13 31 20
38 16 8 -5 10 -4 5 3 -8 13 -13 14 -34 5z"/>
<path d="M5025 6110 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M5202 6100 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M7580 6113 c0 -6 8 -17 18 -24 16 -13 16 -13 2 6 -8 11 -16 22 -17
24 -2 2 -3 0 -3 -6z"/>
<path d="M4042 5973 c-73 -76 -141 -151 -152 -167 -10 -16 -26 -33 -34 -38 -9
-5 -16 -14 -16 -21 -1 -7 -14 -28 -30 -47 -17 -19 -30 -38 -30 -42 0 -5 -12
-26 -27 -47 -16 -21 -35 -49 -43 -61 -31 -45 -145 -162 -171 -176 -75 -38
-177 5 -199 84 -23 87 53 256 192 427 18 22 -2 5 -45 -37 -42 -43 -77 -82 -77
-87 0 -5 -19 -49 -42 -97 -37 -78 -42 -96 -43 -160 0 -67 2 -75 29 -103 61
-64 156 -81 212 -38 46 35 94 80 94 88 0 5 19 27 43 51 24 24 64 72 89 108 26
36 61 76 77 90 17 14 31 29 31 33 0 5 25 47 54 92 60 91 135 170 149 156 14
-14 -12 -80 -39 -98 -14 -9 -32 -30 -41 -47 -18 -37 -115 -166 -161 -216 -18
-19 -47 -55 -63 -80 l-31 -45 33 35 c65 71 95 107 149 175 30 38 82 100 115
136 46 51 64 80 78 126 10 34 30 77 43 96 29 43 29 47 6 47 -10 0 -76 -61
-150 -137z"/>
<path d="M5197 6063 c-4 -3 -7 -30 -7 -58 0 -29 -6 -65 -14 -81 -15 -30 -28
-175 -14 -150 4 8 10 29 12 47 7 50 21 85 30 71 17 -28 118 40 168 113 14 19
5 13 -26 -17 -26 -27 -51 -48 -56 -48 -8 0 -22 36 -34 93 -7 31 -24 36 -32 10
-16 -52 -22 -56 -19 -15 4 43 3 47 -8 35z"/>
<path d="M7650 6040 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M6845 6020 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M4653 5775 c-3 -9 -3 -18 0 -21 9 -9 27 6 27 22 0 19 -19 18 -27 -1z"/>
<path d="M8107 5756 c10 -13 31 -31 48 -41 75 -45 265 -145 275 -145 6 0 27
-12 45 -26 49 -37 145 -94 159 -94 7 0 32 -6 57 -14 24 -8 71 -20 104 -27 33
-7 96 -29 140 -49 93 -43 168 -65 190 -57 28 11 17 57 -21 92 -36 33 -175 95
-213 95 -11 0 -35 13 -53 28 -38 33 -116 62 -166 62 -21 0 -60 14 -96 34 -34
18 -83 36 -111 40 -54 7 -159 43 -240 82 -28 13 -58 24 -67 24 -9 0 -20 5 -23
10 -3 6 -15 10 -26 10 -19 0 -19 -1 -2 -24z"/>
<path d="M7876 5712 c-3 -6 -1 -14 5 -17 15 -10 25 3 12 16 -7 7 -13 7 -17 1z"/>
<path d="M5373 5590 c0 -30 2 -43 4 -27 2 15 2 39 0 55 -2 15 -4 2 -4 -28z"/>
<path d="M7477 5612 c-20 -22 -23 -92 -5 -110 17 -17 59 -15 87 4 19 14 22 22
16 53 -7 38 -17 53 -37 57 -7 1 -20 5 -29 8 -9 4 -22 -1 -32 -12z"/>
<path d="M6460 5540 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M9022 5539 c2 -6 8 -10 13 -10 5 0 11 4 13 10 2 6 -4 11 -13 11 -9 0
-15 -5 -13 -11z"/>
<path d="M7130 5510 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M5155 5453 c-7 -46 -7 -153 0 -363 2 -52 4 -99 4 -104 1 -6 4 -6 10
0 6 5 7 43 2 91 -5 67 -4 86 8 98 12 11 12 19 3 40 -14 30 -16 163 -3 197 9
25 3 88 -9 88 -4 0 -11 -21 -15 -47z"/>
<path d="M3722 5454 c-7 -8 -8 -14 -3 -14 10 0 25 19 20 25 -2 1 -10 -3 -17
-11z"/>
<path d="M4870 5450 c0 -5 4 -10 9 -10 6 0 13 5 16 10 3 6 -1 10 -9 10 -9 0
-16 -4 -16 -10z"/>
<path d="M5640 5375 c0 -8 4 -15 10 -15 5 0 12 -16 16 -35 3 -20 12 -35 20
-35 10 0 12 5 5 18 -5 9 -15 32 -21 50 -13 33 -30 43 -30 17z"/>
<path d="M3500 5250 c0 -5 7 -7 15 -4 8 4 15 8 15 10 0 2 -7 4 -15 4 -8 0 -15
-4 -15 -10z"/>
<path d="M5367 5094 c-12 -12 -8 -192 7 -286 13 -88 26 -118 54 -118 15 0 15
6 -7 125 -6 28 -7 71 -4 96 9 75 -27 206 -50 183z"/>
<path d="M5700 5036 c0 -9 5 -16 10 -16 6 0 10 4 10 9 0 6 -4 13 -10 16 -5 3
-10 -1 -10 -9z"/>
<path d="M5700 5000 c0 -35 13 -89 20 -85 11 7 3 76 -9 88 -8 8 -11 7 -11 -3z"/>
<path d="M4560 4904 c0 -8 5 -12 10 -9 6 3 10 10 10 16 0 5 -4 9 -10 9 -5 0
-10 -7 -10 -16z"/>
<path d="M7628 4832 c-35 -3 -38 -5 -37 -35 1 -60 23 -101 60 -115 86 -32 143
56 82 126 -20 23 -51 30 -105 24z"/>
<path d="M8540 4576 c0 -9 5 -16 10 -16 6 0 10 4 10 9 0 6 -4 13 -10 16 -5 3
-10 -1 -10 -9z"/>
<path d="M5625 4390 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M3771 4186 c-8 -9 -11 -19 -7 -23 9 -9 29 13 24 27 -2 8 -8 7 -17 -4z"/>
<path d="M6250 4041 c0 -11 5 -23 10 -26 6 -3 10 3 10 14 0 11 -4 23 -10 26
-5 3 -10 -3 -10 -14z"/>
<path d="M6170 4000 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M3410 3945 c-15 -18 2 -45 30 -45 23 0 34 28 19 46 -15 18 -33 18
-49 -1z"/>
<path d="M3776 3934 c-4 -9 -4 -19 -1 -22 2 -3 7 3 11 12 4 9 4 19 1 22 -2 3
-7 -3 -11 -12z"/>
<path d="M5630 3899 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M4845 3800 c-3 -5 1 -10 9 -10 9 0 16 5 16 10 0 6 -4 10 -9 10 -6 0
-13 -4 -16 -10z"/>
<path d="M5540 3480 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M6190 3290 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M6210 3175 c-10 -12 -10 -19 -2 -27 16 -16 42 -2 42 22 0 24 -22 26
-40 5z"/>
<path d="M6352 2908 c-19 -19 -14 -36 13 -54 32 -21 59 -4 49 30 -11 35 -40
46 -62 24z"/>
</g>
</svg>
    )
    }
export default SplatterSix;