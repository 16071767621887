import React from 'react';

function SplatterSeven(props) {
    return(
<svg style={props.stylez} version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="128.000000pt" height="128.000000pt" viewBox="0 0 1280.000000 1280.000000"
 preserveAspectRatio="xMidYMid meet">
<metadata>
Created by potrace 1.15, written by Peter Selinger 2001-2017
</metadata>
<g transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)"
 stroke="none">
<path d="M7732 10713 c-67 -51 -88 -80 -94 -128 -6 -49 23 -117 62 -145 59
-42 162 -38 211 10 27 25 28 29 14 43 -11 11 -12 18 -4 23 8 5 6 10 -5 16 -13
7 -15 13 -6 23 9 11 4 30 -24 87 -45 93 -46 95 -77 103 -20 5 -37 -2 -77 -32z"/>
<path d="M6902 10520 c-10 -11 -28 -39 -41 -62 l-23 -43 51 53 c29 29 49 57
46 62 -8 14 -12 13 -33 -10z"/>
<path d="M7200 10520 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5
0 -10 -4 -10 -10z"/>
<path d="M7457 10440 c19 -115 -4 -408 -53 -685 -26 -147 -36 -176 -80 -242
-24 -34 -55 -85 -69 -112 -14 -28 -28 -51 -32 -51 -17 0 14 183 38 225 10 19
10 77 -1 70 -5 -3 -10 -13 -10 -20 0 -13 -20 -66 -41 -107 -5 -10 -9 -26 -9
-36 0 -17 -39 -59 -51 -55 -4 1 -21 -2 -38 -7 l-31 -9 6 137 c5 98 12 149 25
180 10 23 22 42 26 42 5 0 9 19 9 43 1 37 0 39 -8 17 -6 -14 -16 -36 -24 -50
-8 -14 -31 -58 -51 -97 -44 -86 -62 -93 -62 -25 0 58 27 192 43 211 8 11 8 13
-3 10 -8 -3 -19 -19 -25 -37 -6 -17 -15 -31 -21 -30 -13 3 -8 24 16 72 11 21
18 40 16 42 -2 3 -21 -27 -41 -65 -20 -39 -41 -73 -46 -76 -15 -9 -23 65 -12
103 6 19 7 42 3 51 -5 9 -5 45 0 81 4 36 6 73 3 82 -5 15 9 56 35 108 4 8 20
21 35 29 20 9 25 16 17 24 -19 19 -55 -11 -85 -73 -16 -33 -31 -60 -35 -60 -4
0 -14 9 -24 20 -9 11 -23 20 -30 20 -9 0 -12 9 -9 30 3 20 0 30 -8 30 -10 0
-10 3 0 15 10 12 10 19 2 27 -7 7 -12 23 -12 36 0 32 -16 19 -24 -20 -3 -17
-12 -39 -19 -47 -8 -9 -22 -60 -31 -113 -13 -70 -22 -98 -32 -98 -8 0 -17 -6
-20 -14 -5 -12 -9 -11 -25 5 -10 10 -26 19 -34 19 -8 0 -15 2 -15 5 0 14 44
91 118 208 45 70 80 127 78 127 -3 0 -53 -72 -112 -161 -59 -89 -113 -163
-120 -166 -7 -2 -24 -22 -38 -44 -15 -21 -31 -39 -37 -39 -14 0 -1 42 17 56
16 12 19 34 5 34 -5 0 -16 -15 -24 -33 -9 -18 -27 -49 -41 -70 -14 -21 -26
-40 -26 -42 0 -2 -33 -60 -72 -129 -40 -68 -87 -151 -105 -183 l-31 -58 -12
30 c-14 37 -92 169 -102 173 -5 2 -8 10 -8 18 0 8 -7 14 -15 14 -8 0 -15 5
-15 10 0 6 -8 10 -19 10 -10 0 -29 8 -42 18 -40 31 -60 34 -80 13 -25 -27 -34
-151 -19 -271 6 -52 13 -114 15 -137 2 -24 6 -47 10 -53 19 -31 60 -232 47
-227 -4 1 -28 -21 -54 -48 l-46 -49 -17 36 c-13 26 -28 39 -56 48 -58 20 -86
4 -94 -54 -4 -25 -4 -54 0 -63 4 -10 13 -43 21 -73 17 -70 15 -74 -141 -269
-111 -140 -122 -151 -153 -151 -60 0 -126 -50 -147 -111 -13 -40 -28 -38 -36
3 -5 24 -7 27 -8 10 -1 -21 -2 -22 -17 -8 -8 9 -23 16 -32 16 -8 0 -27 10 -40
23 l-24 22 15 -30 c8 -16 21 -42 28 -58 7 -15 28 -36 46 -47 22 -13 33 -26 31
-37 -2 -10 -32 -40 -67 -69 -36 -28 -72 -67 -80 -85 -9 -18 -28 -40 -43 -48
-26 -15 -27 -14 -86 47 -48 50 -67 63 -100 68 -23 4 -47 13 -53 20 -19 23 -40
17 -34 -9 5 -19 -2 -28 -35 -50 -23 -15 -46 -27 -51 -27 -5 0 -16 -11 -24 -24
-17 -26 -11 -50 19 -76 28 -23 18 -37 -31 -44 -129 -17 -163 -23 -169 -29 -15
-15 6 -16 85 -7 81 11 83 10 105 -13 l22 -24 -33 -21 c-18 -12 -32 -28 -30
-35 4 -20 -161 -33 -227 -17 -52 12 -74 11 -211 -9 -67 -10 -191 -8 -217 3
-22 10 -71 -37 -66 -62 5 -24 81 -29 115 -7 20 13 37 15 91 9 37 -4 69 -7 72
-6 3 0 30 10 61 21 35 13 84 20 135 21 43 1 116 6 162 12 46 5 86 8 89 5 3 -3
-5 -31 -16 -62 -24 -63 -21 -75 17 -75 20 0 23 -3 14 -12 -10 -10 -17 -10 -32
0 -12 7 -20 8 -20 2 0 -5 -19 -10 -42 -11 -24 -1 -54 -4 -68 -8 -14 -4 -42 -2
-63 3 -31 8 -40 8 -47 -4 -16 -25 -1 -53 56 -108 41 -39 59 -65 70 -102 14
-46 17 -49 35 -39 22 12 35 36 24 47 -3 4 -11 -1 -16 -11 -15 -26 -29 -20 -29
13 0 27 3 30 34 30 18 0 52 6 74 14 90 32 188 32 161 0 -9 -12 -9 -13 2 -9 10
4 15 -2 16 -15 1 -14 -6 -22 -25 -26 -15 -4 -33 -11 -39 -16 -7 -6 -20 -8 -28
-4 -8 3 -15 1 -15 -4 0 -6 7 -10 16 -10 14 0 14 -3 4 -15 -7 -9 -11 -18 -8
-20 3 -3 11 6 18 20 13 25 66 47 84 37 10 -6 -121 -132 -137 -132 -16 0 -67
-41 -78 -63 -8 -16 -1 -12 25 12 19 19 38 32 41 30 2 -3 -18 -39 -44 -81 -27
-42 -55 -92 -62 -112 -11 -30 -18 -36 -44 -37 l-30 -1 26 -9 26 -10 -22 -76
c-17 -57 -28 -79 -44 -84 -11 -5 -21 -15 -21 -24 0 -8 -8 -15 -18 -15 -73 0
-281 -33 -336 -54 -52 -20 -72 -23 -98 -15 -44 12 -77 11 -113 -6 -29 -13 -29
-14 -7 -20 12 -4 22 -11 22 -15 0 -11 -132 -40 -182 -40 -25 0 -71 -9 -104
-20 -32 -11 -84 -22 -114 -25 -66 -5 -79 -7 -107 -23 -12 -7 -52 -13 -88 -13
-36 -1 -68 -5 -71 -10 -3 -5 41 -6 102 -2 79 4 105 3 100 -5 -4 -6 24 0 61 14
57 22 78 25 133 20 47 -4 71 -2 85 8 11 7 43 19 72 26 44 11 54 11 69 -1 11
-9 15 -9 10 -1 -10 16 2 15 19 -2 12 -12 20 -12 56 0 50 18 119 52 119 60 0
10 -113 -5 -124 -16 -6 -6 -33 -10 -61 -9 l-50 1 55 15 c30 9 114 33 186 54
72 22 148 41 170 44 143 17 239 24 245 18 4 -5 12 -32 18 -62 11 -54 11 -55
-20 -87 -18 -19 -37 -30 -48 -27 -20 5 -50 -25 -35 -35 15 -11 35 -75 26 -84
-5 -5 -26 -10 -48 -12 -22 -1 -49 -8 -61 -14 -12 -7 -32 -13 -45 -13 -13 -1 2
-6 32 -11 45 -7 60 -6 83 8 19 11 41 14 63 11 19 -3 34 -2 34 3 0 5 17 12 38
15 20 4 50 9 66 12 19 3 26 2 22 -6 -10 -16 0 -14 32 6 l27 17 -20 -22 c-27
-33 -156 -93 -171 -81 -7 6 -15 4 -23 -5 -7 -8 -19 -12 -27 -9 -15 5 -70 -25
-61 -34 3 -2 16 3 30 12 31 20 49 1 21 -21 -18 -14 -18 -14 4 -4 36 18 28 -16
-9 -35 -17 -9 -29 -19 -26 -22 3 -2 14 0 25 6 11 6 30 7 48 2 l29 -8 -28 -12
c-37 -15 -42 -14 -27 4 7 8 8 15 3 15 -5 0 -34 -26 -65 -57 -84 -87 -96 -123
-42 -123 17 0 39 16 71 50 37 41 52 50 79 50 24 0 32 4 28 14 -3 8 5 15 23 19
15 4 38 9 51 13 21 6 23 4 20 -22 -2 -21 -9 -30 -25 -32 -13 -2 -23 1 -23 7 0
6 -6 11 -14 11 -16 0 -30 -45 -20 -62 4 -7 3 -8 -4 -4 -6 4 -18 -4 -27 -17 -9
-14 -42 -39 -75 -56 -32 -17 -85 -52 -118 -76 -33 -25 -64 -45 -69 -45 -5 0
-28 -16 -50 -35 -23 -19 -45 -35 -49 -35 -9 0 -114 -71 -114 -77 0 -2 6 -1 13
3 6 5 -1 -7 -16 -25 -15 -18 -34 -45 -42 -60 -12 -25 -12 -27 8 -29 12 0 38
11 57 27 19 15 45 33 57 40 13 6 29 24 37 39 14 26 118 111 194 158 19 11 32
24 30 27 -3 7 90 77 103 77 13 0 3 -34 -12 -38 -8 -3 -29 -11 -48 -18 -54 -23
-30 -26 27 -4 57 22 87 26 77 10 -12 -20 9 -9 51 26 24 19 49 32 55 28 6 -3
28 11 50 32 21 21 39 33 39 27 0 -7 -5 -15 -10 -18 -6 -4 -8 -10 -5 -16 3 -5
20 7 37 26 16 19 37 35 47 34 12 0 11 -3 -6 -16 -13 -8 -23 -24 -23 -35 0 -14
-5 -18 -15 -14 -8 3 -15 1 -15 -4 0 -6 -10 -10 -22 -10 -18 0 -20 -2 -9 -9 8
-5 21 -6 30 -3 8 3 3 -7 -12 -23 -15 -17 -27 -36 -27 -43 0 -9 -6 -12 -15 -8
-9 3 -15 0 -15 -9 0 -18 -16 -20 -24 -2 -3 7 -5 6 -3 -3 2 -15 -68 -92 -77
-83 -6 7 -81 -33 -96 -52 -8 -10 3 -6 24 9 50 33 62 33 75 -4 13 -39 23 -38
49 8 23 41 126 116 115 84 -3 -9 5 -3 18 15 57 75 164 203 171 203 5 0 8 -5 8
-10 0 -14 -37 -87 -61 -122 -21 -29 -24 -43 -9 -33 6 3 10 1 10 -5 0 -6 -8 -9
-18 -6 -12 4 -34 -10 -72 -49 -30 -30 -59 -55 -65 -55 -6 0 -22 -14 -35 -32
l-23 -32 44 35 c24 18 65 53 91 76 29 27 53 41 62 37 11 -4 20 5 32 33 17 40
99 121 111 109 4 -4 -10 -40 -30 -82 -20 -41 -37 -80 -37 -88 0 -8 -19 -42
-43 -77 -59 -88 -97 -155 -97 -172 0 -19 20 -26 47 -16 20 7 93 93 93 110 0 5
18 20 40 34 23 15 52 46 70 77 16 29 30 45 30 36 0 -10 4 -18 9 -18 5 0 7 9 4
20 -5 20 9 56 47 115 10 17 19 38 19 48 1 10 6 28 13 40 11 21 11 21 19 2 6
-14 7 -6 5 23 -2 23 1 45 5 48 5 3 9 -4 9 -15 0 -21 15 -29 22 -12 2 5 14 11
28 13 14 1 30 10 37 18 9 10 12 11 13 3 0 -15 -42 -59 -52 -56 -5 2 -8 -4 -8
-12 0 -9 6 -12 15 -9 8 4 15 1 15 -6 0 -6 -9 -14 -20 -17 -21 -6 -28 -23 -9
-23 5 0 7 -4 4 -10 -3 -5 -1 -10 4 -10 6 0 11 8 11 17 0 15 2 14 19 -3 10 -10
16 -23 13 -29 -3 -5 3 -3 13 5 20 16 20 18 -2 -37 -4 -11 -3 -14 5 -9 8 5 12
-3 12 -23 0 -38 12 -40 34 -6 19 28 11 34 -11 8 -13 -16 -14 -13 -8 27 5 41
14 54 31 50 11 -3 44 54 44 75 0 11 9 26 20 33 11 7 20 21 20 32 0 28 15 25
29 -6 13 -28 11 -33 -37 -86 -18 -20 -23 -33 -18 -52 5 -19 1 -31 -15 -47 -11
-11 -18 -23 -14 -27 3 -3 17 7 30 23 13 17 24 25 25 19 0 -6 -7 -17 -15 -24
-8 -7 -15 -25 -15 -41 0 -16 -12 -47 -26 -69 -14 -21 -24 -44 -22 -51 4 -12
48 53 48 71 0 5 14 18 30 30 28 20 30 20 30 3 -1 -10 -7 -29 -15 -43 -18 -32
-18 -40 -1 -40 7 0 19 15 27 33 7 17 18 35 23 39 7 5 7 8 -1 8 -19 0 -16 15
12 48 13 16 22 31 19 34 -2 3 3 11 12 19 15 12 16 11 9 -11 -7 -23 -7 -24 10
-10 17 14 18 13 11 -5 -5 -11 -9 -34 -10 -51 -1 -22 -12 -42 -34 -62 -18 -16
-32 -34 -32 -39 0 -4 9 3 19 16 13 18 20 20 23 10 3 -8 14 7 26 35 25 56 26
60 10 50 -8 -5 -9 -2 -5 10 4 10 7 20 7 22 0 2 5 4 11 4 24 0 36 -29 43 -99 7
-80 -3 -100 -50 -101 -30 0 -42 -19 -70 -102 -12 -40 -23 -67 -24 -60 0 19
-17 6 -34 -28 -8 -17 -10 -28 -5 -25 14 9 11 -5 -16 -85 -27 -80 -30 -100 -15
-100 6 0 10 8 10 19 0 24 49 172 63 189 19 24 24 -7 10 -61 -23 -90 -9 -107
16 -19 7 25 17 42 24 39 14 -4 29 -149 20 -192 -8 -34 -6 -194 3 -270 13 -117
66 -401 76 -410 7 -7 9 -6 5 1 -4 6 -11 44 -17 85 -6 41 -13 85 -16 99 -27
156 -34 232 -22 248 7 8 7 12 -1 12 -6 0 -11 11 -11 25 0 14 4 25 9 25 5 0 7
-9 4 -20 -3 -11 -1 -20 5 -20 13 0 23 69 12 89 -5 10 -5 69 0 142 12 178 20
212 47 202 12 -5 14 -1 9 15 -4 13 1 37 13 61 10 23 15 39 10 35 -5 -3 -9 2
-9 11 0 11 5 15 15 11 11 -4 15 2 15 23 0 15 5 42 11 60 16 46 3 50 -16 6 -20
-49 -27 -41 -13 15 14 58 24 70 59 70 26 0 29 -4 30 -32 0 -32 1 -32 11 -10 9
19 17 22 37 17 14 -4 28 -8 30 -11 4 -4 -5 -41 -39 -154 -29 -97 -70 -321 -70
-383 0 -30 -6 -70 -14 -88 -16 -40 -30 -130 -18 -111 13 20 82 334 117 537 19
108 62 203 66 147 1 -9 3 -28 6 -42 12 -81 14 -142 3 -154 -7 -10 -8 -16 0
-21 16 -10 12 -44 -14 -99 -36 -77 -36 -116 0 -158 25 -28 37 -34 78 -37 27
-1 58 -6 69 -10 12 -5 35 -5 52 -1 41 9 73 -4 85 -35 14 -37 12 -92 -2 -98 -9
-3 -7 -9 6 -19 17 -14 18 -20 8 -59 -6 -24 -16 -75 -22 -114 -6 -38 -18 -90
-26 -115 -20 -64 -33 -140 -24 -145 4 -3 10 10 12 28 3 17 20 86 37 152 39
142 39 144 40 173 1 12 5 22 10 22 15 0 71 -60 71 -76 0 -8 7 -14 15 -14 8 0
24 -19 36 -43 25 -49 48 -77 63 -77 6 0 3 5 -7 11 -9 6 -33 43 -53 82 -19 40
-48 86 -63 102 -44 47 -48 60 -38 126 11 69 8 67 123 55 56 -6 114 -32 114
-51 0 -21 65 -117 73 -109 4 4 -7 22 -23 41 -37 42 -41 70 -7 52 70 -37 116
-100 117 -160 0 -50 -26 -60 -56 -22 -12 15 -25 25 -29 21 -7 -7 17 -93 36
-130 5 -10 9 -23 9 -29 0 -6 11 -37 25 -68 37 -82 85 -106 85 -42 0 40 23 57
51 38 30 -22 95 -111 118 -163 11 -24 23 -44 26 -44 3 0 2 8 -3 18 -5 9 -17
35 -27 57 -10 22 -28 53 -38 69 -11 16 -35 73 -54 128 -35 105 -33 138 6 138
11 0 22 7 25 16 9 24 -11 52 -50 72 -28 13 -41 29 -57 72 l-22 55 -48 3 c-27
2 -54 -2 -62 -8 -20 -16 -63 5 -85 42 -10 18 -33 39 -49 46 -17 7 -33 22 -36
32 -3 10 -17 35 -31 56 -14 20 -30 63 -36 96 -6 37 -20 71 -38 93 -38 48 -56
93 -42 107 8 8 15 6 27 -6 9 -9 20 -16 23 -16 4 0 12 -10 17 -22 14 -30 39
-38 30 -8 -11 35 24 21 45 -17 25 -48 40 -63 61 -63 11 0 19 -4 19 -10 0 -5
-5 -10 -10 -10 -6 0 4 -18 21 -40 34 -42 82 -72 102 -64 16 6 37 -11 83 -66
34 -42 134 -152 251 -279 l53 -56 -25 -21 c-25 -23 -25 -64 1 -64 7 0 17 -6
21 -14 4 -7 30 -23 56 -34 30 -13 46 -26 42 -33 -10 -16 45 -69 72 -69 16 0
21 4 16 16 -3 9 -9 11 -12 6 -7 -12 -51 3 -51 18 0 5 7 10 15 10 8 0 15 5 15
10 0 6 -6 10 -14 10 -8 0 -18 4 -21 10 -14 23 36 7 85 -26 28 -19 58 -33 65
-30 8 3 23 23 34 44 18 36 24 40 70 46 48 6 71 24 71 55 0 18 -61 81 -79 81
-10 0 -38 16 -64 36 -41 32 -78 39 -61 12 14 -23 -14 -1 -48 37 -49 56 -88
127 -88 161 0 79 -29 119 -90 126 -20 2 -29 13 -45 58 -27 73 -38 91 -89 149
-51 58 -74 114 -56 136 18 21 63 18 94 -6 15 -12 30 -18 34 -15 3 4 0 11 -8
16 -8 5 -11 12 -7 16 4 4 13 2 19 -4 7 -7 16 -12 22 -12 5 0 2 6 -8 13 -13 10
-15 18 -8 37 9 24 9 24 36 -17 16 -23 25 -46 22 -51 -3 -6 3 -8 14 -5 11 3 20
7 20 9 0 2 -25 31 -55 64 -57 62 -56 60 -59 163 -1 15 5 22 17 22 9 0 17 -4
17 -8 -1 -4 8 -19 20 -34 11 -14 18 -28 15 -31 -3 -3 -12 3 -20 14 -14 18 -14
17 -4 -8 7 -16 16 -28 21 -28 17 0 18 27 4 62 -13 31 -13 38 -1 48 24 20 49
-49 54 -147 1 -6 12 -24 25 -40 13 -15 27 -39 31 -53 4 -14 19 -39 32 -56 14
-19 28 -56 33 -89 10 -62 22 -83 56 -95 23 -9 34 -45 34 -114 0 -22 4 -47 9
-55 13 -20 1 105 -14 150 -7 22 -8 45 -3 59 8 22 9 22 49 -14 40 -37 55 -45
159 -86 82 -33 90 -34 38 -6 -26 14 -49 30 -53 36 -3 5 -16 10 -27 10 -22 0
-80 31 -115 63 -12 11 -30 42 -39 69 -11 34 -28 58 -56 81 -67 53 -87 78 -94
114 -4 20 -18 55 -31 79 -14 24 -21 44 -15 44 12 0 36 -29 56 -68 9 -18 27
-37 39 -41 25 -10 137 -127 137 -144 0 -7 19 -32 42 -57 26 -29 31 -32 14 -10
-15 19 -33 49 -40 65 -8 17 -28 47 -45 69 -17 21 -31 42 -31 46 0 12 -108 120
-120 120 -6 0 -10 6 -8 13 3 7 28 14 58 17 48 4 57 9 76 38 19 28 21 36 11 64
-9 24 -25 37 -72 59 -33 15 -71 38 -84 52 -28 31 -40 107 -16 107 19 0 19 10
-1 38 -14 21 -72 58 -85 55 -3 -1 -4 9 -1 22 3 19 11 25 31 25 18 0 38 12 63
40 43 46 53 45 97 -10 21 -27 31 -50 31 -74 0 -27 11 -48 50 -95 28 -34 62
-68 76 -78 17 -11 21 -18 13 -23 -8 -5 -4 -10 10 -15 32 -10 138 -101 146
-125 4 -11 23 -29 43 -40 70 -38 280 -185 372 -261 14 -11 71 -57 128 -101 56
-44 102 -84 102 -89 0 -30 -91 5 -157 60 -74 61 -178 120 -190 107 -7 -7 81
-79 113 -92 18 -8 48 -27 66 -42 18 -15 59 -43 91 -62 33 -19 104 -77 160
-127 56 -51 104 -93 108 -93 12 0 130 -72 177 -107 23 -18 59 -35 80 -39 20
-4 75 -22 121 -41 57 -22 108 -35 155 -39 85 -6 160 6 164 26 2 13 -18 15
-125 16 -119 1 -132 3 -181 28 -30 15 -68 43 -85 63 -18 20 -46 42 -64 49 -36
16 -54 42 -62 94 -5 30 -13 39 -61 62 -30 15 -77 30 -104 33 -72 9 -105 26
-166 89 -30 32 -74 71 -98 87 -23 17 -50 38 -60 48 -42 44 -225 182 -270 205
-45 23 -108 72 -160 124 -24 24 -51 46 -62 50 -23 9 -276 246 -298 280 -9 13
-15 39 -14 57 2 18 -3 39 -11 47 -19 19 -47 61 -47 70 0 13 65 9 78 -4 7 -7
12 -8 12 -3 0 4 9 0 21 -11 15 -14 22 -15 28 -6 4 7 15 10 23 7 18 -7 28 20
17 45 -3 8 -11 30 -17 48 -10 32 -10 32 22 32 20 0 49 -12 77 -31 24 -17 33
-21 19 -9 l-25 23 55 -6 c101 -11 116 -14 160 -34 25 -11 54 -30 65 -42 16
-19 17 -24 5 -31 -12 -8 -10 -10 8 -10 12 0 22 4 22 8 0 14 -84 89 -115 103
-16 8 -31 23 -33 34 -8 40 -18 43 -127 37 l-105 -5 0 39 c1 42 22 80 39 69 18
-11 12 7 -6 20 -17 13 -17 14 0 33 21 23 7 39 -20 23 -29 -16 -53 5 -53 45 0
35 0 35 38 28 20 -4 52 -13 71 -20 43 -18 51 -18 26 0 -11 7 -46 19 -77 26
-55 12 -57 14 -52 39 4 14 38 86 76 159 38 74 68 136 65 138 -5 5 -127 -213
-127 -227 0 -5 -9 -9 -20 -9 -49 0 -35 61 59 250 32 63 61 128 65 143 3 16 11
25 17 21 6 -3 9 0 8 7 -1 8 3 28 11 44 7 17 21 49 31 72 10 23 22 61 25 85 7
47 30 98 43 98 4 0 8 6 8 13 3 94 16 145 51 196 34 50 37 62 41 133 2 48 -2
90 -9 108 -11 27 -10 33 12 62 13 18 45 89 72 158 26 68 63 146 82 172 18 26
34 54 34 60 0 7 -7 2 -16 -10 -34 -49 -44 -16 -18 61 15 43 34 63 34 35 0 -6
3 -9 6 -6 6 7 21 114 29 211 3 32 11 65 19 72 8 8 12 24 9 35 -4 13 2 29 16
44 12 12 18 26 14 29 -3 4 -1 7 7 7 9 0 10 3 1 14 -9 10 -8 15 4 20 9 3 15 18
15 38 0 18 12 67 25 108 27 81 29 90 17 90 -4 0 -13 -23 -19 -51 -12 -50 -28
-79 -42 -79 -8 0 -34 94 -35 125 -1 11 3 23 8 26 4 4 3 9 -3 13 -6 4 -13 -5
-17 -21 -3 -15 -12 -48 -19 -73 -8 -25 -17 -62 -21 -83 -3 -20 -10 -40 -15
-43 -5 -3 -7 -9 -5 -13 14 -22 -162 -458 -221 -545 -15 -22 -36 -57 -47 -76
-10 -19 -30 -53 -45 -75 -39 -61 -49 -77 -71 -117 -11 -21 -22 -38 -24 -38 -8
0 -3 56 6 67 5 7 8 16 5 20 -10 18 16 158 52 278 12 39 23 84 25 100 9 69 28
161 36 170 4 6 13 57 20 115 15 124 18 145 20 145 1 0 3 19 4 41 2 23 6 46 10
52 3 6 3 13 -2 16 -11 6 -14 66 -4 66 8 0 12 103 14 306 1 77 2 82 29 100 34
23 77 101 72 131 -4 18 -13 23 -51 28 -77 10 -89 24 -91 107 -1 31 2 36 16 31
13 -5 15 -2 10 11 -4 10 -7 24 -7 32 0 8 -4 14 -9 14 -8 0 -19 -38 -27 -94 -2
-16 -9 -35 -15 -42 -6 -8 -8 -21 -5 -30 4 -9 3 -32 0 -51 -13 -68 -28 -16 -30
98 -1 61 -6 114 -11 118 -6 3 -11 32 -12 64 -1 36 -9 72 -21 95 -11 20 -20 46
-20 57 0 11 -5 27 -10 35 -7 10 -8 1 -3 -30z m-527 -743 c0 -10 -33 -68 -36
-64 -10 9 10 67 22 67 8 0 14 -2 14 -3z m5 -118 c-4 -12 -16 -19 -31 -19 l-24
0 22 41 c20 40 22 41 30 19 5 -13 6 -31 3 -41z m-12 -64 c-2 -31 -42 -216 -49
-226 -12 -20 -22 3 -14 32 5 16 6 37 3 45 -3 8 0 51 7 95 11 73 15 80 34 77
14 -2 20 -10 19 -23z m177 -135 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8
10 11 10 2 0 4 -4 4 -10z m-1138 -292 c-7 -7 -12 -8 -12 -2 0 14 12 26 19 19
2 -3 -1 -11 -7 -17z m-32 -47 c0 -5 -7 -14 -15 -21 -16 -14 -18 -10 -9 14 6
17 24 22 24 7z m1499 -95 c-8 -49 -15 -66 -24 -62 -8 3 -16 0 -19 -7 -10 -20
2 26 19 73 9 25 18 51 21 59 12 35 14 -3 3 -63z m-39 -151 c0 -8 -4 -15 -10
-15 -5 0 -10 7 -10 15 0 8 5 15 10 15 6 0 10 -7 10 -15z m-1640 -374 c-6 -12
-16 -19 -21 -15 -6 4 -4 13 7 25 21 24 29 18 14 -10z m-54 -67 c-3 -9 -6 -24
-6 -35 0 -10 -4 -19 -10 -19 -11 0 -14 53 -3 63 12 13 25 7 19 -9z m-753 -176
c15 -11 27 -22 27 -26 0 -4 14 -22 31 -41 l30 -35 -36 -14 c-19 -8 -35 -21
-35 -29 0 -13 -12 -15 -65 -11 -35 2 -67 7 -70 10 -3 3 3 23 14 44 13 24 21
57 21 87 0 47 0 47 28 41 15 -4 39 -16 55 -26z m-132 -13 c0 -13 -41 -19 -41
-7 0 11 20 22 33 18 5 -1 8 -6 8 -11z m-33 -123 c3 -9 -2 -13 -14 -10 -9 1
-19 9 -22 16 -3 9 2 13 14 10 9 -1 19 -9 22 -16z m2582 -249 c-7 -21 -16 -42
-21 -47 -12 -13 2 66 14 82 17 21 20 6 7 -35z m-2640 -93 c0 -5 -5 -10 -11
-10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z m210 -154 c0 -2 -7 -9
-15 -16 -12 -10 -15 -10 -15 4 0 9 7 16 15 16 8 0 15 -2 15 -4z m-50 -16 c0
-5 -4 -10 -9 -10 -6 0 -13 5 -16 10 -3 6 1 10 9 10 9 0 16 -4 16 -10z m-1 -41
c-9 -17 -13 -19 -20 -8 -14 22 -11 29 11 29 18 0 19 -3 9 -21z m284 -165 c8
-8 -33 -84 -46 -84 -6 0 -20 -9 -32 -20 -25 -23 -55 -18 -55 10 0 17 49 62 95
88 23 13 29 14 38 6z m88 -5 c24 -12 16 -29 -14 -30 -17 -1 -18 -2 -4 -6 9 -2
17 -8 17 -12 0 -10 -99 -106 -104 -100 -6 6 67 158 77 159 5 0 17 -5 28 -11z
m39 -69 c-12 -41 -19 -50 -39 -50 -9 0 -31 -13 -49 -28 -28 -25 -30 -30 -19
-52 7 -14 23 -26 35 -28 39 -6 23 -17 -25 -18 -27 0 -47 -5 -45 -10 1 -5 8 -9
15 -10 30 -3 30 -6 -2 -19 -37 -16 -101 -20 -101 -6 0 5 7 14 15 21 11 9 15 9
15 1 0 -7 5 -9 11 -6 6 4 8 14 5 22 -3 8 -2 12 3 9 4 -3 17 13 27 36 17 37
138 168 155 168 4 0 4 -13 -1 -30z m-534 -14 c7 -8 19 -16 26 -19 8 -3 -6 -22
-37 -50 -46 -43 -73 -55 -87 -41 -8 7 21 64 32 64 5 0 7 6 4 14 -3 8 5 21 18
30 27 19 26 19 44 2z m237 4 c14 0 51 -63 61 -102 10 -43 -25 -60 -129 -62
-53 0 -84 3 -81 9 2 6 8 9 13 8 6 -1 10 10 9 25 -2 41 -27 33 -50 -15 -17 -36
-24 -41 -50 -40 -47 3 -45 31 3 77 45 43 178 113 199 105 8 -3 19 -5 25 -5z
m-15 -197 c-21 -9 -38 -22 -38 -29 0 -8 -6 -14 -14 -14 -7 0 -27 -7 -42 -15
-16 -8 -36 -15 -44 -15 -8 0 -22 -5 -30 -11 -11 -8 -8 -9 15 -4 l30 7 -23 -25
c-13 -14 -39 -28 -58 -31 -19 -4 -34 -11 -34 -16 0 -4 9 -6 20 -3 11 3 20 0
20 -6 0 -6 -3 -11 -7 -11 -5 0 -38 -9 -75 -19 -54 -15 -68 -16 -68 -5 0 7 4
16 10 19 5 3 23 35 40 71 18 36 39 68 48 70 9 3 35 14 57 24 58 28 62 28 150
28 l80 0 -37 -15z m-216 -9 c-24 -17 -39 -14 -27 5 3 6 16 11 28 11 l21 0 -22
-16z m456 -6 c-3 -7 -18 -14 -34 -16 -28 -2 -28 -2 -10 13 24 18 49 20 44 3z
m-42 -57 c-10 -11 -24 -17 -32 -14 -8 3 -21 0 -29 -7 -10 -8 -15 -9 -15 -2 0
7 -10 5 -27 -4 -35 -18 -63 -18 -63 0 0 25 25 35 89 35 34 0 65 2 69 5 15 16
23 3 8 -13z m-126 -55 c0 -3 -13 -20 -30 -38 -27 -29 -30 -30 -30 -11 0 37 11
53 36 53 13 0 24 -2 24 -4z m-316 -96 c-11 -11 -34 -24 -52 -29 -26 -8 -32 -7
-32 6 0 14 -3 14 -20 -2 -22 -20 -34 -16 -24 8 6 16 64 34 119 36 l29 1 -20
-20z m-46 -67 c-13 -16 -48 -17 -48 -2 0 6 -7 9 -15 6 -8 -4 -17 -2 -20 2 -2
5 18 8 45 8 39 -1 47 -3 38 -14z m142 9 c0 -5 -18 -16 -41 -25 -34 -13 -39
-14 -29 -2 7 8 19 15 26 15 8 0 14 5 14 10 0 6 7 10 15 10 8 0 15 -4 15 -8z
m14 -31 c-11 -10 -80 -24 -70 -14 9 9 70 30 75 26 3 -2 0 -7 -5 -12z m-221
-91 c29 -10 54 -3 89 27 15 13 16 13 11 -6 -3 -12 -11 -21 -19 -21 -7 0 -16
-11 -20 -25 -4 -18 -18 -28 -52 -39 -88 -27 -129 -28 -156 -5 -15 13 -22 28
-19 40 7 25 52 61 66 52 6 -3 7 -1 3 5 -3 6 2 15 11 21 14 8 22 5 38 -14 11
-14 33 -29 48 -35z m227 40 c0 -5 -9 -14 -19 -20 -25 -13 -38 5 -14 19 23 13
33 14 33 1z m-70 -30 c-8 -5 -17 -7 -19 -4 -3 3 1 9 9 14 8 5 17 7 19 4 3 -3
-1 -9 -9 -14z m135 -60 c-3 -5 -12 -10 -18 -10 -7 0 -6 4 3 10 19 12 23 12 15
0z m2140 -54 c-20 -30 -45 -41 -45 -19 0 13 -3 13 -15 3 -9 -7 -18 -10 -22 -6
-3 3 -1 6 7 6 9 0 10 3 1 14 -14 17 -5 21 52 25 l38 2 -16 -25z m-82 -91 c-4
-11 -11 -6 -24 20 -26 47 -23 56 6 22 14 -15 22 -34 18 -42z m-2193 36 c15
-29 14 -30 -36 -51 -57 -25 -82 -27 -64 -5 7 8 17 12 23 8 6 -3 7 -1 3 5 -8
13 32 62 50 62 8 0 18 -9 24 -19z m2144 -14 c-3 -9 -10 -17 -15 -17 -11 0 -12
34 -2 43 11 12 24 -8 17 -26z m-2314 3 c-8 -5 -10 -10 -5 -10 14 -1 -97 -69
-112 -70 -21 0 -15 17 14 39 15 12 34 19 44 16 12 -4 22 1 29 15 6 11 18 20
28 20 15 0 15 -2 2 -10z m71 -4 c16 10 10 -9 -17 -47 -31 -45 -81 -70 -95 -48
-7 11 -9 10 -9 -4 0 -13 -6 -17 -22 -15 -35 5 -32 25 6 38 19 6 54 28 78 50
24 21 45 34 46 29 2 -5 8 -7 13 -3z m214 -56 c-16 -16 -34 -30 -39 -30 -6 0
-4 6 5 13 9 7 14 18 11 26 -3 10 0 12 11 8 9 -3 19 -2 22 3 4 6 9 10 12 10 4
0 -6 -13 -22 -30z m-91 1 c3 -5 -10 -11 -29 -13 -19 -2 -35 -1 -35 3 0 16 55
25 64 10z m-163 -55 c-14 -17 -49 -27 -56 -16 -3 5 7 13 22 18 39 15 47 14 34
-2z m106 8 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m83 -9 c0
-9 -8 -18 -17 -21 -10 -3 -26 -10 -35 -15 -33 -17 -32 -9 2 21 40 33 50 36 50
15z m2240 5 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4
10 -10z m-1713 -32 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z m44
17 c11 7 0 -23 -18 -50 -13 -19 -13 -19 -13 0 0 30 13 71 18 57 2 -7 8 -10 13
-7z m-851 -21 c-56 -19 -93 -41 -115 -70 -17 -22 -34 -34 -51 -34 -33 0 -30
10 16 55 25 24 50 39 71 42 18 2 35 5 37 8 3 3 17 5 31 4 14 0 19 -3 11 -5z
m150 -1 c0 -5 -12 -16 -25 -26 -23 -17 -26 -17 -32 -2 -3 10 0 21 8 26 17 11
49 12 49 2z m310 -28 c-7 -9 -15 -13 -18 -10 -3 2 1 11 8 20 7 9 15 13 18 10
3 -2 -1 -11 -8 -20z m320 9 c0 -8 -4 -12 -10 -9 -5 3 -10 10 -10 16 0 5 5 9
10 9 6 0 10 -7 10 -16z m-584 -23 c-19 -21 -76 -45 -76 -33 0 13 51 42 89 51
2 1 -4 -8 -13 -18z m140 -46 c-11 -8 -22 -12 -25 -9 -6 6 19 24 34 24 6 -1 1
-7 -9 -15z m474 -21 c0 -8 -5 -12 -10 -9 -6 4 -8 11 -5 16 9 14 15 11 15 -7z
m-988 -16 c-12 -12 -35 -1 -27 12 3 5 13 6 21 3 10 -4 12 -9 6 -15z m923 -6
c-8 -8 -25 10 -19 20 4 6 9 5 15 -3 4 -7 6 -15 4 -17z m191 2 c-4 -14 -3 -23
2 -20 4 2 14 1 21 -3 9 -6 7 -10 -8 -14 -11 -3 -22 1 -25 8 -5 14 3 55 11 55
3 0 2 -12 -1 -26z m-866 6 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11
10 2 0 4 -4 4 -10z m339 -28 c-15 -24 -59 -55 -59 -42 0 6 7 13 15 16 8 4 12
10 9 15 -2 5 0 18 6 29 9 17 14 19 25 10 10 -9 11 -16 4 -28z m2110 14 c12
-14 5 -46 -10 -46 -12 0 -39 42 -33 52 8 12 30 10 43 -6z m-1775 -21 c3 -8 1
-15 -4 -15 -6 0 -10 7 -10 15 0 8 2 15 4 15 2 0 6 -7 10 -15z m-245 -106 c-5
-10 -6 -26 -3 -36 4 -13 3 -14 -4 -5 -7 11 -10 11 -15 -2 -3 -8 -1 -16 5 -18
7 -3 4 -12 -8 -24 -10 -10 -24 -31 -31 -46 -14 -31 -53 -57 -53 -35 0 7 11 24
25 37 14 13 25 27 25 32 0 5 -11 1 -25 -8 -34 -22 -31 -10 9 39 18 23 41 57
51 76 12 25 19 30 25 20 5 -7 4 -21 -1 -30z m1529 -60 c23 -35 30 -55 24 -61
-7 -7 -16 1 -26 26 -8 20 -20 36 -27 36 -8 0 -7 -6 3 -17 21 -25 28 -61 13
-67 -13 -4 -42 23 -83 78 -27 35 -15 56 32 56 26 0 36 -8 64 -51z m52 -13 c0
-8 3 -23 7 -33 6 -16 5 -16 -10 -4 -18 15 -23 51 -7 51 6 0 10 -6 10 -14z
m-150 -16 c0 -4 12 -20 26 -35 18 -19 22 -31 15 -38 -7 -7 -18 -2 -36 17 -14
15 -25 31 -25 36 0 4 -3 16 -6 24 -5 12 -2 15 10 10 9 -3 16 -10 16 -14z
m-223 -289 c35 -68 47 -125 18 -86 -11 14 -26 19 -55 18 -22 -1 -40 2 -40 7 0
5 -11 20 -24 34 -25 24 -25 24 -4 29 18 5 19 8 8 21 -18 22 5 48 38 44 20 -2
33 -17 59 -67z m-167 -36 c10 -26 4 -55 -11 -55 -5 0 -9 18 -9 40 0 46 6 51
20 15z m-42 -29 c3 -32 1 -37 -15 -34 -19 3 -29 38 -19 65 10 26 31 8 34 -31z"/>
<path d="M5025 7159 c-4 -6 -5 -12 -2 -15 2 -3 7 2 10 11 7 17 1 20 -8 4z"/>
<path d="M7980 10474 c0 -5 7 -19 15 -30 8 -10 15 -14 15 -8 0 5 -7 19 -15 30
-8 10 -15 14 -15 8z"/>
<path d="M8390 10374 c0 -8 5 -12 10 -9 6 4 8 11 5 16 -9 14 -15 11 -15 -7z"/>
<path d="M5250 10330 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M7984 10305 c-1 -14 2 -25 6 -25 11 0 21 30 14 41 -10 16 -19 9 -20
-16z"/>
<path d="M6507 10103 c-4 -3 -7 -11 -7 -17 0 -6 5 -5 12 2 6 6 9 14 7 17 -3 3
-9 2 -12 -2z"/>
<path d="M7190 9981 c0 -17 -2 -23 -17 -60 -8 -18 -12 -35 -10 -37 2 -3 13 18
25 46 12 28 17 52 11 55 -5 4 -9 1 -9 -4z"/>
<path d="M2590 9609 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M4170 9560 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M7933 9535 c0 -22 2 -30 4 -17 2 12 2 30 0 40 -3 9 -5 -1 -4 -23z"/>
<path d="M4550 9340 c0 -5 4 -10 9 -10 6 0 13 5 16 10 3 6 -1 10 -9 10 -9 0
-16 -4 -16 -10z"/>
<path d="M4985 9123 l-18 -28 25 23 c28 25 88 30 128 9 46 -24 60 -27 60 -13
0 20 -54 36 -120 36 -52 0 -59 -3 -75 -27z"/>
<path d="M2343 8964 c-1 -23 4 -34 13 -34 18 0 19 56 1 63 -9 3 -13 -6 -14
-29z"/>
<path d="M2347 8914 c-4 -4 -7 -16 -7 -26 0 -18 1 -18 16 -3 11 10 13 20 7 26
-5 5 -12 6 -16 3z"/>
<path d="M4977 8850 c6 -46 13 -66 13 -37 0 10 5 17 12 17 7 0 6 6 -4 18 -9 9
-18 24 -21 32 -3 8 -3 -5 0 -30z"/>
<path d="M3995 8800 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M4981 8754 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M4990 8724 c0 -6 7 -19 16 -30 14 -18 14 -18 3 6 -14 31 -19 36 -19
24z"/>
<path d="M5040 8630 c6 -11 13 -20 16 -20 2 0 0 9 -6 20 -6 11 -13 20 -16 20
-2 0 0 -9 6 -20z"/>
<path d="M5300 8630 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M4268 8593 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M5120 8435 c0 -12 32 -67 36 -62 7 6 -13 54 -25 62 -6 3 -11 3 -11 0z"/>
<path d="M5066 8323 c-12 -12 -6 -33 9 -33 8 0 15 6 15 14 0 17 -14 28 -24 19z"/>
<path d="M5210 8320 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M5010 8280 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M8420 7640 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M3118 7433 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M8560 7420 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M4391 7408 c-1 -9 6 -24 15 -32 21 -22 31 -20 12 2 -9 9 -18 24 -21
32 -4 12 -6 12 -6 -2z"/>
<path d="M8935 7390 c-27 -10 -48 -36 -40 -50 9 -14 68 -13 83 2 28 28 -4 63
-43 48z"/>
<path d="M4410 7316 c0 -9 7 -16 16 -16 17 0 14 22 -4 28 -7 2 -12 -3 -12 -12z"/>
<path d="M3855 7280 c-8 -13 5 -13 25 0 13 8 13 10 -2 10 -9 0 -20 -4 -23 -10z"/>
<path d="M2347 7271 c-41 -6 -55 -24 -22 -28 11 -2 31 6 45 17 14 11 23 19 20
18 -3 0 -22 -4 -43 -7z"/>
<path d="M3900 7260 c-23 -7 -23 -8 -3 -9 12 -1 25 4 28 9 3 6 5 10 3 9 -2 -1
-14 -5 -28 -9z"/>
<path d="M8162 7202 c-27 -4 -72 -61 -72 -92 0 -31 35 -84 64 -98 45 -20 98 7
122 65 20 46 2 88 -44 110 -46 21 -43 20 -70 15z"/>
<path d="M3935 7155 c-16 -7 -23 -14 -14 -14 8 -1 24 6 35 14 24 18 18 18 -21
0z"/>
<path d="M3805 6900 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M7556 6895 c-3 -8 -1 -15 3 -15 5 0 11 7 15 15 3 8 1 15 -3 15 -5 0
-11 -7 -15 -15z"/>
<path d="M4245 6780 c-3 -5 -2 -10 4 -10 5 0 13 5 16 10 3 6 2 10 -4 10 -5 0
-13 -4 -16 -10z"/>
<path d="M4423 6766 c9 -22 -74 -58 -139 -61 -67 -3 -89 -19 -51 -36 19 -9 30
-9 39 -1 8 6 35 18 62 27 37 13 51 14 56 5 5 -9 9 -9 14 -1 4 6 1 11 -6 11 -7
1 2 7 21 15 23 11 31 20 27 31 -3 9 -6 18 -6 20 0 2 -5 4 -11 4 -6 0 -9 -6 -6
-14z"/>
<path d="M4140 6720 c0 -12 8 -12 35 0 18 8 17 9 -7 9 -16 1 -28 -3 -28 -9z"/>
<path d="M8260 6655 c0 -9 9 -34 20 -57 10 -22 26 -63 34 -92 12 -40 25 -60
56 -85 23 -18 44 -30 48 -27 11 11 -18 100 -42 126 -12 14 -40 51 -61 83 -44
65 -55 76 -55 52z"/>
<path d="M4005 6630 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M3625 6580 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M7540 6586 c0 -2 7 -7 16 -10 8 -3 12 -2 9 4 -6 10 -25 14 -25 6z"/>
<path d="M7590 6566 c0 -2 7 -7 16 -10 8 -3 12 -2 9 4 -6 10 -25 14 -25 6z"/>
<path d="M2340 6530 c-9 -6 -10 -10 -3 -10 6 0 15 5 18 10 8 12 4 12 -15 0z"/>
<path d="M2305 6520 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M3350 6495 c-19 -7 -73 -27 -119 -44 -46 -17 -90 -40 -98 -51 -8 -11
-31 -29 -51 -41 -20 -11 -28 -17 -17 -13 11 3 40 8 65 10 34 4 51 12 72 34 15
17 32 30 38 30 19 0 160 71 160 80 0 12 -7 12 -50 -5z"/>
<path d="M3801 6424 c-88 -46 -92 -61 -6 -21 45 21 53 22 82 11 28 -12 34 -11
56 6 14 11 32 20 41 20 31 0 15 17 -21 24 -56 9 -60 7 -152 -40z"/>
<path d="M1966 6399 c-67 -25 -82 -43 -22 -25 27 8 56 22 65 31 18 18 19 18
-43 -6z"/>
<path d="M3496 6405 c-11 -8 -30 -15 -43 -15 -24 0 -43 -26 -43 -59 0 -16 6
-21 28 -21 23 0 34 10 64 55 35 54 33 69 -6 40z"/>
<path d="M4049 6314 c-21 -24 -22 -26 -3 -16 25 13 50 42 37 42 -5 0 -20 -12
-34 -26z"/>
<path d="M4160 6300 c-13 -8 -12 -10 3 -10 9 0 17 5 17 10 0 12 -1 12 -20 0z"/>
<path d="M7160 6292 c0 -5 7 -15 15 -22 8 -7 15 -8 15 -2 0 5 -7 15 -15 22 -8
7 -15 8 -15 2z"/>
<path d="M4280 6275 c-7 -8 -18 -14 -24 -14 -6 1 -19 -1 -30 -6 -17 -7 -17 -9
5 -21 40 -22 53 -15 57 30 0 5 6 13 14 17 8 5 9 9 2 9 -6 0 -17 -7 -24 -15z"/>
<path d="M7086 6275 c4 -8 10 -15 15 -15 4 0 6 7 3 15 -4 8 -10 15 -15 15 -4
0 -6 -7 -3 -15z"/>
<path d="M2265 6273 c-11 -3 -28 -14 -38 -24 -18 -19 -18 -20 10 -13 15 3 37
15 48 25 20 19 17 21 -20 12z"/>
<path d="M3996 6265 c-11 -8 -17 -17 -14 -20 2 -3 13 4 23 15 21 23 18 25 -9
5z"/>
<path d="M7225 6265 c11 -9 29 -23 40 -32 35 -30 117 -85 121 -81 2 2 -19 21
-46 41 -53 40 -65 62 -27 53 13 -3 9 0 -8 8 -16 7 -46 16 -65 19 -33 6 -34 6
-15 -8z"/>
<path d="M3472 6227 c-36 -37 -117 -70 -176 -72 -35 -1 -66 -23 -66 -47 0 -14
48 -20 70 -8 11 6 76 14 144 17 129 6 151 0 125 -35 -7 -9 2 -3 20 13 l32 30
-142 5 -142 5 66 29 c49 21 72 38 86 62 11 19 19 34 18 34 -2 0 -17 -15 -35
-33z"/>
<path d="M4060 6235 c0 -9 -7 -18 -15 -21 -23 -9 -18 -22 8 -17 15 3 22 12 23
29 1 13 -2 24 -7 24 -5 0 -9 -7 -9 -15z"/>
<path d="M4140 6230 c0 -5 9 -9 20 -7 31 5 36 17 7 17 -15 0 -27 -4 -27 -10z"/>
<path d="M4100 6220 c0 -5 7 -10 16 -10 8 0 12 5 9 10 -3 6 -10 10 -16 10 -5
0 -9 -4 -9 -10z"/>
<path d="M3563 6210 c-13 -5 -23 -14 -23 -20 0 -16 15 -12 35 10 20 22 20 23
-12 10z"/>
<path d="M7250 6210 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M3885 6172 c-16 -8 -36 -23 -42 -33 -7 -9 -29 -27 -49 -39 -19 -12
-33 -24 -30 -27 7 -7 107 60 135 91 28 29 27 30 -14 8z"/>
<path d="M5150 6159 c0 -11 4 -18 10 -14 5 3 7 12 3 20 -7 21 -13 19 -13 -6z"/>
<path d="M4679 6093 l-24 -28 28 24 c25 23 32 31 24 31 -2 0 -14 -12 -28 -27z"/>
<path d="M5135 6100 c-3 -11 -10 -20 -15 -20 -4 0 -10 -9 -13 -20 -8 -31 11
-24 28 10 15 30 35 33 35 5 0 -8 4 -16 9 -19 11 -7 2 34 -12 52 -14 17 -25 15
-32 -8z"/>
<path d="M4645 6099 c-4 -5 -18 -15 -33 -20 -17 -7 -22 -13 -15 -20 7 -7 20
-1 41 20 17 17 27 31 22 31 -5 0 -12 -5 -15 -11z"/>
<path d="M8470 6095 c0 -9 5 -15 11 -13 6 2 11 8 11 13 0 5 -5 11 -11 13 -6 2
-11 -4 -11 -13z"/>
<path d="M2750 6079 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M2500 6039 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M3640 6014 c-30 -34 -35 -59 -8 -36 15 12 52 72 45 72 -3 0 -19 -16
-37 -36z"/>
<path d="M3711 6017 c-7 -17 -26 -34 -55 -48 -31 -14 -44 -26 -43 -39 1 -17 3
-17 17 -6 8 7 22 11 30 9 12 -4 12 -3 2 5 -10 7 -2 16 32 38 50 31 50 32 36
54 -7 11 -11 9 -19 -13z"/>
<path d="M3170 6019 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M5190 6028 c0 -4 5 -18 11 -32 7 -14 8 -33 4 -43 -8 -20 -17 -113
-11 -113 6 0 36 96 36 114 0 9 -9 30 -20 48 -11 18 -20 30 -20 26z"/>
<path d="M4165 6010 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M2580 5989 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M4078 5957 c-31 -23 -90 -63 -132 -89 -42 -26 -76 -52 -76 -59 0 -6
15 0 34 15 19 14 37 26 40 26 7 0 206 140 206 146 0 10 -19 0 -72 -39z"/>
<path d="M4414 5978 l-19 -23 23 19 c21 18 27 26 19 26 -2 0 -12 -10 -23 -22z"/>
<path d="M4797 5941 c-21 -22 -33 -41 -28 -43 6 -1 -2 -30 -19 -63 -16 -33
-32 -76 -35 -95 -5 -29 -3 -27 13 15 30 77 69 149 92 170 12 11 26 28 31 37
17 32 -14 20 -54 -21z"/>
<path d="M5117 5964 c-4 -11 -32 -46 -63 -77 -30 -32 -52 -57 -48 -57 3 0 1
-6 -6 -14 -6 -8 -17 -13 -24 -10 -7 3 -21 -6 -32 -18 l-19 -23 23 19 c12 11
25 16 30 12 4 -4 0 -13 -9 -19 -8 -7 -24 -25 -34 -42 l-18 -30 24 27 c13 14
41 47 63 73 22 27 44 44 52 41 7 -3 16 -1 19 4 3 4 -1 10 -7 13 -9 3 1 19 25
45 24 25 37 47 35 58 -4 18 -5 17 -11 -2z"/>
<path d="M7510 5952 c0 -10 109 -76 116 -70 3 3 -88 67 -108 76 -5 2 -8 -1 -8
-6z"/>
<path d="M7001 5928 c-1 -16 4 -28 9 -28 12 0 12 8 0 35 -8 18 -9 17 -9 -7z"/>
<path d="M9020 5930 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M5016 5899 c-14 -17 -26 -35 -26 -41 0 -14 3 -11 34 35 31 45 27 48
-8 6z"/>
<path d="M5120 5900 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M5292 5890 c-23 -21 -29 -40 -13 -40 10 0 45 50 39 56 -3 2 -14 -5
-26 -16z"/>
<path d="M3525 5870 c-11 -5 -36 -9 -54 -9 -26 -1 -32 -4 -24 -12 9 -9 23 -9
58 1 25 7 48 17 51 21 6 11 -4 10 -31 -1z"/>
<path d="M5146 5863 c-6 -14 -5 -15 5 -6 7 7 10 15 7 18 -3 3 -9 -2 -12 -12z"/>
<path d="M5776 5826 c-9 -30 -16 -59 -16 -65 0 -6 -4 -11 -9 -11 -5 0 -7 -18
-4 -40 4 -29 0 -48 -15 -75 -14 -23 -20 -45 -16 -63 8 -38 -13 -112 -31 -112
-8 0 -15 -5 -15 -11 0 -7 9 -9 23 -5 12 3 26 6 31 6 4 0 6 10 3 22 -3 12 2 47
12 77 10 31 21 79 25 108 4 28 15 76 25 106 11 31 17 65 14 76 -3 12 -1 21 6
21 6 0 11 5 11 10 0 6 -6 10 -14 10 -9 0 -21 -21 -30 -54z"/>
<path d="M7035 5835 c7 -31 12 -41 19 -34 2 2 -3 18 -11 34 l-14 30 6 -30z"/>
<path d="M4617 5806 c-6 -16 2 -28 14 -20 12 7 11 34 0 34 -5 0 -11 -6 -14
-14z"/>
<path d="M5290 5803 c0 -6 -3 -20 -6 -30 -7 -17 -6 -17 12 -1 14 14 16 21 6
30 -8 8 -12 9 -12 1z"/>
<path d="M3940 5787 c-25 -11 -42 -28 -36 -35 2 -2 21 8 41 23 40 26 37 32 -5
12z"/>
<path d="M3824 5776 c-10 -8 -16 -17 -13 -20 3 -3 14 3 24 14 21 23 17 26 -11
6z"/>
<path d="M3725 5770 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M4105 5739 c-66 -45 -97 -79 -71 -79 9 0 33 16 55 35 21 19 42 35 47
35 5 0 14 11 21 25 6 14 10 25 10 25 -1 0 -29 -19 -62 -41z"/>
<path d="M5880 5755 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"/>
<path d="M3133 5726 c-40 -17 -80 -39 -90 -48 -10 -9 -28 -19 -40 -22 -13 -4
-23 -11 -23 -16 0 -11 25 -1 130 52 86 43 113 49 85 18 -22 -24 -4 -27 20 -4
17 17 17 45 0 50 -5 2 -42 -12 -82 -30z"/>
<path d="M4862 5730 c-23 -17 -42 -32 -42 -35 0 -7 67 35 80 51 19 22 7 17
-38 -16z"/>
<path d="M7713 5752 c4 -6 22 -21 39 -34 17 -12 28 -17 25 -10 -4 6 -22 21
-39 34 -17 12 -28 17 -25 10z"/>
<path d="M4519 5724 c-23 -25 -23 -26 -2 -15 23 12 38 29 31 37 -3 2 -15 -8
-29 -22z"/>
<path d="M4339 5713 c-13 -16 -12 -17 4 -4 16 13 21 21 13 21 -2 0 -10 -8 -17
-17z"/>
<path d="M5206 5699 c-11 -11 -16 -22 -12 -26 8 -9 40 22 35 35 -2 5 -13 1
-23 -9z"/>
<path d="M4415 5679 c-11 -17 1 -21 15 -4 8 9 8 15 2 15 -6 0 -14 -5 -17 -11z"/>
<path d="M4469 5673 c-13 -16 -12 -17 4 -4 9 7 17 15 17 17 0 8 -8 3 -21 -13z"/>
<path d="M4783 5665 c-14 -10 -19 -22 -16 -34 3 -11 -6 -38 -21 -63 l-27 -43
6 40 5 40 -16 -44 c-14 -36 -14 -50 -5 -82 11 -38 28 -53 28 -26 0 8 10 35 22
60 12 26 23 68 24 94 1 29 8 53 17 60 21 18 9 16 -17 -2z"/>
<path d="M4258 5630 c-20 -29 -35 -40 -55 -41 -18 -1 -21 -3 -10 -6 26 -7 73
18 91 48 32 55 12 54 -26 -1z"/>
<path d="M6378 5665 c-6 -11 10 -61 17 -53 2 2 1 17 -3 33 -5 16 -11 25 -14
20z"/>
<path d="M3887 5640 c-37 -23 -64 -60 -45 -60 15 0 85 67 77 73 -2 3 -17 -4
-32 -13z"/>
<path d="M7863 5640 c27 -22 37 -25 37 -12 0 8 -43 32 -57 32 -3 0 6 -9 20
-20z"/>
<path d="M7605 5640 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M3723 5580 c-44 -26 -42 -34 2 -13 19 9 35 20 35 25 0 11 0 11 -37
-12z"/>
<path d="M4890 5485 c-13 -14 -19 -25 -14 -25 11 0 47 40 41 46 -2 2 -14 -7
-27 -21z"/>
<path d="M5000 5452 c-8 -15 -22 -38 -31 -52 -8 -14 -23 -45 -31 -70 -9 -25
-28 -76 -42 -114 -14 -38 -26 -72 -25 -75 1 -15 104 201 125 263 25 73 27 94
4 48z"/>
<path d="M6530 5467 c0 -3 7 -21 16 -42 8 -21 13 -48 10 -61 -7 -27 7 -39 23
-21 8 10 6 24 -9 55 -11 23 -20 45 -20 51 0 5 -4 13 -10 16 -5 3 -10 4 -10 2z"/>
<path d="M6570 5460 c0 -15 31 -33 40 -24 5 5 -1 14 -16 21 -13 6 -24 8 -24 3z"/>
<path d="M2656 5444 c-9 -9 -14 -19 -11 -22 3 -3 13 2 22 11 9 9 14 19 11 22
-3 3 -13 -2 -22 -11z"/>
<path d="M5430 5440 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"/>
<path d="M7095 5369 c-4 -6 -5 -13 -2 -16 7 -7 27 6 27 18 0 12 -17 12 -25 -2z"/>
<path d="M3273 5350 c-3 -11 -9 -18 -13 -15 -11 7 -101 -55 -172 -120 -37 -33
-71 -55 -84 -55 -29 0 -53 -37 -30 -46 9 -4 44 19 90 58 118 99 190 153 202
150 6 -1 16 9 22 23 8 17 8 25 1 25 -6 0 -13 -9 -16 -20z"/>
<path d="M5822 5358 c-16 -16 -15 -28 3 -28 8 0 15 9 15 20 0 23 -2 24 -18 8z"/>
<path d="M5117 5343 c-4 -3 -7 -11 -7 -17 0 -6 5 -5 12 2 6 6 9 14 7 17 -3 3
-9 2 -12 -2z"/>
<path d="M6700 5346 c0 -2 7 -7 16 -10 8 -3 12 -2 9 4 -6 10 -25 14 -25 6z"/>
<path d="M4990 5325 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"/>
<path d="M5190 5299 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M6386 5284 c-33 -33 -10 -93 36 -94 23 0 47 36 41 61 -5 18 -39 49
-55 49 -3 0 -14 -7 -22 -16z"/>
<path d="M5322 5270 c-6 -11 -12 -28 -12 -37 0 -12 5 -8 15 11 18 34 16 56 -3
26z"/>
<path d="M6700 5276 c0 -8 5 -18 10 -21 6 -3 10 1 10 9 0 8 -4 18 -10 21 -5 3
-10 -1 -10 -9z"/>
<path d="M3920 5198 c-40 -40 -80 -85 -90 -100 -25 -42 -179 -215 -206 -233
-25 -16 -32 -35 -13 -35 10 0 20 11 105 108 36 41 172 139 187 134 4 -1 6 1 5
6 -1 4 3 17 10 28 6 12 8 24 5 28 -4 3 7 21 24 39 32 34 67 97 53 97 -4 0 -40
-33 -80 -72z"/>
<path d="M5440 5245 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"/>
<path d="M7508 5208 c12 -12 25 -18 29 -14 4 4 -1 14 -11 22 -31 22 -43 17
-18 -8z"/>
<path d="M3550 5209 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M3625 5210 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M5078 5185 c-22 -30 -25 -63 -4 -46 13 11 31 60 24 66 -2 2 -11 -7
-20 -20z"/>
<path d="M6057 5183 c-4 -3 -7 -11 -7 -17 0 -6 5 -5 12 2 6 6 9 14 7 17 -3 3
-9 2 -12 -2z"/>
<path d="M2199 5134 c-23 -25 -23 -26 -2 -13 13 7 25 19 27 26 8 19 2 16 -25
-13z"/>
<path d="M3415 5113 c-26 -21 -71 -59 -100 -84 -29 -25 -66 -54 -84 -65 -36
-21 -40 -34 -10 -34 11 0 41 17 65 38 63 52 186 174 181 178 -2 2 -25 -13 -52
-33z"/>
<path d="M6147 5129 c7 -7 15 -10 18 -7 3 3 -2 9 -12 12 -14 6 -15 5 -6 -5z"/>
<path d="M8285 5100 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M5707 5057 c-8 -22 4 -37 29 -37 13 0 24 5 24 10 0 6 -4 10 -9 10 -5
0 -16 7 -24 15 -11 11 -16 11 -20 2z"/>
<path d="M4816 5024 c-9 -20 -15 -39 -12 -41 3 -3 12 12 21 32 20 49 14 56 -9
9z"/>
<path d="M3145 5015 c-21 -17 -25 -24 -14 -25 9 0 24 11 33 25 20 31 18 31
-19 0z"/>
<path d="M4743 4980 c1 -11 5 -20 9 -20 4 0 8 9 8 20 0 11 -4 20 -9 20 -6 0
-9 -9 -8 -20z"/>
<path d="M4786 4953 c-6 -14 -5 -15 5 -6 7 7 10 15 7 18 -3 3 -9 -2 -12 -12z"/>
<path d="M2832 4938 c-8 -11 -6 -17 9 -26 10 -7 19 -18 19 -25 0 -19 -99 -110
-155 -142 -49 -28 -65 -42 -65 -57 0 -19 28 -3 118 69 87 69 152 128 152 140
0 2 -9 1 -20 -2 -16 -5 -23 0 -34 25 -10 25 -15 28 -24 18z"/>
<path d="M7350 4942 c0 -17 38 -57 46 -49 4 4 -4 19 -19 33 -15 14 -27 21 -27
16z"/>
<path d="M4653 4898 c-32 -36 -103 -151 -97 -157 9 -9 55 51 92 119 36 65 37
72 5 38z"/>
<path d="M1950 4893 c-8 -3 -31 -23 -50 -46 l-35 -40 47 34 c25 19 49 40 52
47 3 6 4 11 3 11 -1 -1 -9 -4 -17 -6z"/>
<path d="M3054 4885 c-10 -8 -14 -15 -8 -15 6 0 17 7 24 15 16 19 9 19 -16 0z"/>
<path d="M4748 4893 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M5297 4893 c-10 -9 -9 -23 2 -23 5 0 11 7 15 15 5 15 -5 20 -17 8z"/>
<path d="M5500 4880 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M4740 4853 c-24 -52 -60 -146 -56 -150 1 -2 19 36 39 84 20 48 35 88
33 90 -1 2 -9 -9 -16 -24z"/>
<path d="M1975 4860 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M5500 4830 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M4051 4804 c-12 -27 -11 -28 4 -12 10 9 16 21 13 28 -2 7 -10 -1 -17
-16z"/>
<path d="M6497 4799 c7 -7 15 -10 18 -7 3 3 -2 9 -12 12 -14 6 -15 5 -6 -5z"/>
<path d="M5470 4759 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M4115 4700 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M4050 4685 c-7 -9 -11 -18 -8 -20 3 -3 11 1 18 10 7 9 11 18 8 20 -3
3 -11 -1 -18 -10z"/>
<path d="M4234 4688 c-22 -34 -32 -58 -24 -58 10 0 42 61 36 67 -2 3 -8 -2
-12 -9z"/>
<path d="M3348 4627 c-24 -23 -75 -77 -115 -119 -39 -42 -89 -86 -110 -96 -51
-26 -70 -49 -78 -94 -6 -30 -4 -39 9 -44 13 -5 14 -9 5 -20 -7 -8 -8 -18 -4
-22 4 -4 12 2 19 12 6 10 22 28 35 40 13 12 31 40 41 62 27 62 96 150 183 233
42 41 77 78 77 83 0 18 -22 6 -62 -35z"/>
<path d="M2868 4636 c-10 -7 -18 -19 -18 -27 1 -10 4 -9 14 4 7 9 18 17 24 17
6 0 14 5 17 10 9 14 -17 12 -37 -4z"/>
<path d="M5526 4608 c-3 -13 -5 -36 -5 -53 l1 -30 13 35 c16 41 18 70 6 70 -5
0 -11 -10 -15 -22z"/>
<path d="M6740 4611 c0 -10 7 -24 15 -31 8 -7 15 -23 15 -37 0 -13 9 -41 19
-61 19 -37 25 -72 23 -124 -2 -32 -25 -37 -60 -12 -21 15 -22 14 -21 -13 0
-20 2 -23 7 -11 5 13 11 15 22 8 24 -16 56 -11 75 10 19 21 18 26 -15 110 -12
30 -28 78 -35 107 -7 28 -16 56 -20 62 -10 17 -25 13 -25 -8z"/>
<path d="M6911 4625 c0 -5 6 -21 14 -35 8 -14 14 -20 14 -15 0 6 -6 21 -14 35
-8 14 -14 21 -14 15z"/>
<path d="M6060 4596 c-5 -15 -10 -32 -10 -37 0 -5 -4 -9 -10 -9 -5 0 -10 -8
-10 -18 0 -10 -7 -27 -15 -38 -8 -10 -15 -28 -15 -39 -1 -41 -22 -95 -41 -105
-15 -9 -26 -6 -54 16 -19 14 -35 22 -35 17 0 -12 48 -78 58 -79 27 -3 70 -31
113 -75 28 -27 57 -48 64 -46 10 5 18 44 30 157 1 14 -8 48 -20 75 -14 31 -26
79 -29 124 -3 41 -8 76 -11 79 -2 3 -9 -7 -15 -22z"/>
<path d="M3910 4585 c-7 -9 -11 -18 -8 -20 3 -3 11 1 18 10 7 9 11 18 8 20 -3
3 -11 -1 -18 -10z"/>
<path d="M4197 4558 c-61 -78 -125 -210 -115 -240 3 -7 16 10 30 37 13 28 29
59 35 70 5 11 16 34 24 50 8 17 20 30 26 30 18 0 32 18 42 53 14 48 -3 47 -42
0z"/>
<path d="M3295 4530 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M3584 4495 c-36 -34 -42 -51 -13 -40 13 5 64 75 55 75 -3 0 -21 -16
-42 -35z"/>
<path d="M3115 4501 c-3 -5 -1 -12 5 -16 5 -3 10 1 10 9 0 18 -6 21 -15 7z"/>
<path d="M4628 4463 c-28 -33 -31 -48 -4 -19 15 15 25 30 23 32 -2 2 -11 -4
-19 -13z"/>
<path d="M4866 4463 c-6 -14 -5 -15 5 -6 7 7 10 15 7 18 -3 3 -9 -2 -12 -12z"/>
<path d="M3324 4394 c-33 -37 -97 -105 -144 -151 -97 -96 -81 -96 28 -1 100
89 209 218 183 218 -5 0 -35 -30 -67 -66z"/>
<path d="M6976 4427 c3 -10 9 -15 12 -12 3 3 0 11 -7 18 -10 9 -11 8 -5 -6z"/>
<path d="M2144 4416 c-10 -8 -15 -18 -11 -22 4 -4 15 0 24 9 22 21 12 31 -13
13z"/>
<path d="M7664 4394 c4 -15 20 -41 36 -58 16 -16 34 -44 40 -62 6 -18 17 -35
25 -38 27 -10 24 11 -7 53 -17 23 -40 61 -52 84 -26 50 -52 64 -42 21z"/>
<path d="M6996 4387 c3 -10 9 -15 12 -12 3 3 0 11 -7 18 -10 9 -11 8 -5 -6z"/>
<path d="M2180 4380 c-8 -5 -12 -12 -9 -15 4 -3 12 1 19 10 14 17 11 19 -10 5z"/>
<path d="M4520 4380 c-8 -5 -11 -12 -7 -16 4 -4 13 -2 19 4 15 15 7 24 -12 12z"/>
<path d="M8500 4355 c13 -14 26 -25 28 -25 3 0 -5 11 -18 25 -13 14 -26 25
-28 25 -3 0 5 -11 18 -25z"/>
<path d="M3952 4283 c-17 -19 -32 -41 -32 -49 0 -17 24 -18 37 -1 11 13 35 79
31 83 -2 1 -18 -13 -36 -33z"/>
<path d="M4826 4248 c-37 -82 -53 -152 -37 -163 8 -4 8 -12 1 -26 -6 -10 -8
-19 -5 -19 6 0 43 81 55 123 5 16 5 27 -1 27 -11 0 -6 29 15 88 24 66 9 50
-28 -30z"/>
<path d="M6900 4310 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M2080 4278 c-11 -12 -18 -24 -15 -26 7 -7 55 27 55 38 0 17 -18 11
-40 -12z"/>
<path d="M6760 4245 c0 -8 4 -15 10 -15 5 0 7 7 4 15 -4 8 -8 15 -10 15 -2 0
-4 -7 -4 -15z"/>
<path d="M2045 4220 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M6910 4210 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M3835 4133 c-15 -26 -38 -59 -51 -73 -22 -25 -51 -91 -59 -140 -4
-18 1 -14 15 14 11 22 27 47 35 56 8 9 15 23 15 31 0 8 4 19 9 25 15 14 72
126 67 131 -2 2 -16 -18 -31 -44z"/>
<path d="M8720 4165 c0 -18 19 -40 26 -32 4 4 0 15 -9 24 -9 10 -17 13 -17 8z"/>
<path d="M3058 4133 c-25 -30 -48 -72 -48 -85 1 -7 9 3 20 22 10 19 28 45 40
58 11 12 17 22 12 22 -5 0 -16 -8 -24 -17z"/>
<path d="M4615 4123 c-4 -10 -11 -33 -15 -52 -7 -28 -6 -31 5 -20 7 8 16 31
19 52 7 39 3 49 -9 20z"/>
<path d="M1975 4090 c-3 -5 -2 -10 4 -10 5 0 13 5 16 10 3 6 2 10 -4 10 -5 0
-13 -4 -16 -10z"/>
<path d="M2287 4043 c-4 -3 -7 -11 -7 -17 0 -6 5 -5 12 2 6 6 9 14 7 17 -3 3
-9 2 -12 -2z"/>
<path d="M2337 4034 c-4 -4 -2 -13 4 -20 6 -8 8 -22 5 -31 -5 -15 -3 -14 9 2
9 11 14 26 13 34 -3 16 -21 25 -31 15z"/>
<path d="M3002 4014 c4 -16 -10 -37 -53 -83 -33 -34 -59 -68 -59 -75 0 -17
-134 -144 -165 -156 -28 -11 -58 -62 -49 -85 4 -9 18 -21 32 -26 23 -9 24 -13
20 -61 -4 -41 -11 -56 -32 -73 -14 -11 -26 -25 -26 -30 0 -17 72 60 86 91 10
24 11 39 2 68 -11 35 -9 43 22 103 19 35 61 92 95 126 33 34 77 88 98 119 20
31 43 58 51 60 9 2 8 8 -7 23 -20 20 -20 20 -15 -1z"/>
<path d="M3098 4019 c-10 -5 -18 -16 -18 -23 0 -7 -19 -29 -42 -49 -23 -20
-39 -39 -36 -42 7 -7 118 102 118 115 0 12 -1 12 -22 -1z"/>
<path d="M4098 3923 c-14 -21 -28 -51 -32 -68 l-6 -30 22 27 c20 26 51 99 44
105 -2 2 -14 -14 -28 -34z"/>
<path d="M3575 3940 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M2150 3925 c-10 -12 -10 -15 4 -15 9 0 16 7 16 15 0 8 -2 15 -4 15
-2 0 -9 -7 -16 -15z"/>
<path d="M2624 3912 c-5 -4 -23 -24 -38 -45 -16 -20 -57 -60 -92 -88 -70 -56
-89 -93 -31 -62 17 9 35 16 39 15 4 -1 5 4 2 12 -6 15 92 126 111 126 18 0 35
24 27 37 -4 7 -12 9 -18 5z"/>
<path d="M5241 3894 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M5060 3868 c0 -15 -2 -35 -5 -45 -3 -10 2 -3 12 15 13 25 14 35 5 44
-9 9 -12 6 -12 -14z"/>
<path d="M3689 3853 c-13 -16 -12 -17 4 -4 16 13 21 21 13 21 -2 0 -10 -8 -17
-17z"/>
<path d="M3967 3854 c-4 -4 -7 -14 -7 -23 0 -11 3 -12 11 -4 6 6 10 16 7 23
-2 6 -7 8 -11 4z"/>
<path d="M3645 3830 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M2163 3813 c-13 -2 -23 -11 -23 -19 0 -22 54 -14 58 9 2 9 0 16 -5
16 -4 -1 -18 -4 -30 -6z"/>
<path d="M3835 3811 c-6 -11 9 -23 19 -14 9 9 7 23 -3 23 -6 0 -12 -4 -16 -9z"/>
<path d="M3500 3797 c0 -7 -9 -30 -21 -51 -11 -21 -15 -35 -9 -31 10 6 50 78
50 90 0 3 -4 5 -10 5 -5 0 -10 -6 -10 -13z"/>
<path d="M2684 3760 c-41 -64 -96 -121 -110 -116 -6 2 -11 -1 -10 -7 0 -7 -3
-13 -8 -14 -5 -1 -13 -7 -17 -15 -7 -10 -9 -9 -9 3 0 11 -24 -6 -66 -47 -75
-72 -97 -82 -120 -60 -23 23 -30 20 -9 -4 18 -20 18 -21 -4 -44 -27 -29 -45
-86 -31 -100 13 -13 80 11 140 49 41 26 41 27 24 46 -17 19 -17 22 -2 47 9 15
25 31 37 37 12 5 21 14 22 20 0 5 43 48 95 94 52 47 97 95 100 107 12 46 -3
48 -32 4z"/>
<path d="M3217 3744 c-26 -35 -17 -38 11 -3 13 16 20 29 15 29 -4 0 -16 -12
-26 -26z"/>
<path d="M4367 3733 c-4 -3 -7 -11 -7 -17 0 -6 5 -5 12 2 6 6 9 14 7 17 -3 3
-9 2 -12 -2z"/>
<path d="M3782 3698 c-14 -14 -16 -28 -3 -28 9 0 24 30 18 36 -2 2 -9 -2 -15
-8z"/>
<path d="M7670 3690 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M3657 3663 c-4 -3 -7 -13 -7 -22 1 -13 3 -13 11 2 11 19 8 33 -4 20z"/>
<path d="M2985 3650 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M2910 3609 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M2015 3590 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M2225 3570 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M3540 3534 c0 -8 5 -12 10 -9 6 4 8 11 5 16 -9 14 -15 11 -15 -7z"/>
<path d="M4106 3493 c-6 -14 -5 -15 5 -6 7 7 10 15 7 18 -3 3 -9 -2 -12 -12z"/>
<path d="M4040 3444 c-19 -25 -44 -56 -55 -69 -11 -12 -31 -42 -44 -66 -39
-71 -139 -200 -200 -258 l-56 -53 55 44 c78 63 148 149 265 324 21 32 48 69
58 81 11 12 18 26 16 32 -2 6 -20 -10 -39 -35z"/>
<path d="M8190 3481 c0 -5 5 -13 10 -16 6 -3 10 -2 10 4 0 5 -4 13 -10 16 -5
3 -10 2 -10 -4z"/>
<path d="M2218 3455 c-24 -28 -35 -55 -37 -92 -1 -31 20 -29 51 5 34 37 40 57
23 88 l-13 26 -24 -27z"/>
<path d="M3201 3450 c-13 -11 -30 -20 -37 -20 -8 0 -20 -11 -26 -25 -15 -33 6
-56 53 -58 l34 -2 0 62 c0 35 0 63 0 63 0 -1 -11 -10 -24 -20z"/>
<path d="M4161 3446 c-7 -8 -8 -17 -3 -20 6 -3 13 1 16 10 9 23 2 28 -13 10z"/>
<path d="M6870 3404 c0 -8 5 -12 10 -9 6 3 10 10 10 16 0 5 -4 9 -10 9 -5 0
-10 -7 -10 -16z"/>
<path d="M2720 3315 c-7 -9 -8 -15 -2 -15 5 0 12 7 16 15 3 8 4 15 2 15 -2 0
-9 -7 -16 -15z"/>
<path d="M3772 3128 c-7 -7 -12 -19 -12 -27 0 -11 4 -10 20 4 21 19 26 35 12
35 -4 0 -13 -5 -20 -12z"/>
<path d="M7300 2908 c0 -25 65 -95 107 -117 30 -15 113 -30 113 -21 0 12 -120
119 -150 135 -14 7 -35 16 -47 19 -19 6 -23 4 -23 -16z"/>
<path d="M6915 2899 c-10 -15 3 -25 16 -12 7 7 7 13 1 17 -6 3 -14 1 -17 -5z"/>
<path d="M3910 2869 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M7720 2840 c0 -19 3 -21 12 -12 9 9 9 15 0 24 -9 9 -12 7 -12 -12z"/>
</g>
</svg>
    )
    }
export default SplatterSeven;