import React from 'react';

function SplatterNine(props) {
    return(
        <svg style={props.stylez} width="128.000000pt" height="92.8000000pt" viewBox="0 0 1280.000000 928.000000"
 preserveAspectRatio="xMidYMid meet">
<metadata>
Created by potrace 1.15, written by Peter Selinger 2001-2017
</metadata>
<g transform="translate(0.000000,928.000000) scale(0.100000,-0.100000)"
 stroke="none">
<path d="M7163 9239 c10 -73 72 -119 161 -119 47 0 57 3 68 23 7 12 11 27 10
33 -1 6 2 31 7 57 l8 47 -130 0 -130 0 6 -41z"/>
<path d="M10423 9221 c3 -33 8 -64 12 -70 8 -14 85 -14 119 -1 24 9 26 14 26
70 l0 60 -81 0 -82 0 6 -59z"/>
<path d="M4500 9220 c-25 -25 -25 -41 -1 -81 l19 -32 26 28 c29 31 31 41 16
79 -11 31 -33 33 -60 6z"/>
<path d="M5535 9230 c-3 -5 -1 -20 6 -32 7 -12 22 -54 34 -93 l22 -70 -63 -3
c-55 -2 -64 -6 -64 -22 0 -36 12 -50 42 -50 26 0 29 -3 26 -27 -2 -20 -9 -29
-26 -31 -12 -2 -30 -15 -39 -28 -14 -22 -21 -24 -57 -19 -23 4 -53 2 -68 -4
-24 -9 -28 -16 -28 -51 0 -36 7 -47 56 -97 30 -31 64 -73 74 -92 32 -63 114
-155 155 -176 33 -16 60 -20 149 -20 l108 0 43 47 c76 85 89 122 95 289 4 121
8 148 23 163 13 13 17 34 17 86 0 67 -1 69 -46 119 -26 29 -60 59 -75 67 -16
8 -29 22 -29 30 0 17 -26 16 -100 -3 -19 -5 -48 -6 -63 -2 -16 4 -40 4 -54 -2
-41 -15 -81 -11 -93 11 -11 21 -35 27 -45 10z"/>
<path d="M11042 9038 c-17 -17 -15 -57 2 -64 21 -8 58 15 54 33 -5 25 -42 45
-56 31z"/>
<path d="M7100 9027 c-34 -17 -48 -78 -26 -112 14 -22 24 -25 71 -25 63 0 85
16 85 64 0 47 -33 86 -73 86 -18 0 -43 -6 -57 -13z"/>
<path d="M3600 8974 c-98 -18 -160 -42 -200 -79 -14 -13 -43 -31 -64 -42 -59
-27 -80 -75 -84 -195 -5 -124 13 -285 36 -316 9 -13 26 -38 37 -56 42 -66 70
-97 124 -135 126 -87 297 -93 471 -16 41 19 83 36 93 39 11 4 21 15 24 26 6
24 1 24 60 -1 53 -23 93 -55 93 -76 0 -8 13 -22 30 -31 16 -9 30 -22 30 -28 0
-7 7 -14 15 -18 8 -3 15 -15 15 -26 0 -11 6 -20 13 -20 7 0 33 -20 57 -45 25
-25 69 -59 98 -76 49 -28 53 -32 47 -57 -4 -15 -11 -70 -14 -122 l-6 -95 -37
-3 c-51 -4 -62 -21 -47 -72 21 -69 72 -92 90 -40 15 42 29 28 29 -31 0 -42 3
-49 19 -49 25 0 32 -11 24 -38 l-5 -22 -24 24 c-24 24 -63 36 -113 36 -19 0
-30 -8 -41 -33 -11 -23 -23 -33 -42 -35 l-28 -3 26 -52 c14 -29 38 -64 52 -79
l26 -28 -28 0 c-41 0 -60 -14 -81 -59 -10 -22 -27 -43 -37 -47 -17 -5 -19 -12
-13 -41 6 -35 6 -35 -17 -19 -12 9 -31 16 -42 16 -18 0 -18 1 -3 13 25 18 9
32 -55 46 -32 7 -60 17 -64 22 -3 6 -16 15 -27 20 -76 35 -90 47 -122 110
l-33 64 -87 3 c-71 2 -94 -1 -125 -17 -33 -17 -51 -19 -134 -14 -70 4 -100 2
-111 -7 -13 -11 -19 -10 -37 6 -43 38 -68 70 -94 122 -15 28 -33 52 -41 52 -8
0 -30 7 -48 16 -28 13 -35 25 -54 87 -11 40 -24 90 -29 112 l-8 40 -60 -1
c-39 0 -77 -8 -113 -24 -71 -31 -85 -64 -76 -169 8 -99 -7 -207 -34 -235 -23
-24 -27 -60 -11 -102 9 -23 7 -31 -15 -54 -14 -14 -25 -33 -25 -42 0 -13 -19
-28 -36 -28 -2 0 -2 11 2 25 5 19 2 25 -10 25 -13 0 -16 11 -16 50 0 68 -16
100 -51 100 -58 0 -77 -88 -36 -165 18 -32 29 -41 53 -43 31 -3 31 -4 27 -46
-4 -27 -1 -46 6 -51 13 -7 135 11 161 24 8 5 27 37 42 72 15 35 30 64 34 64 4
0 15 -23 24 -52 9 -29 23 -59 29 -67 17 -21 5 -37 -23 -30 -19 4 -25 1 -30
-21 -8 -30 -6 -33 26 -49 17 -9 25 -24 30 -59 12 -87 62 -163 130 -198 20 -10
48 -33 61 -51 39 -53 71 -155 72 -226 0 -59 -1 -62 -16 -44 -8 11 -25 22 -36
26 -21 7 -53 69 -53 104 0 9 -5 34 -11 55 -10 35 -13 38 -37 32 -15 -4 -30
-12 -34 -18 -4 -7 -11 -1 -17 15 -15 39 -65 61 -141 62 -36 1 -68 6 -72 11
-16 21 -8 77 13 94 16 13 21 29 22 71 2 48 0 55 -18 58 -12 2 -42 -9 -68 -23
-39 -22 -47 -31 -47 -56 0 -21 -12 -43 -41 -74 -33 -36 -48 -45 -75 -45 -19 0
-34 -4 -34 -10 0 -5 -9 -10 -19 -10 -11 0 -23 5 -26 10 -8 13 -45 13 -45 0 0
-6 -22 -26 -48 -45 -27 -19 -54 -46 -60 -59 -7 -17 -17 -24 -27 -20 -20 8 -45
-13 -45 -39 0 -11 -11 -44 -25 -74 -18 -38 -31 -53 -45 -53 -24 0 -26 -22 -4
-55 19 -30 11 -113 -12 -122 -30 -11 -14 -40 31 -58 32 -12 45 -22 43 -34 -1
-9 5 -33 15 -54 17 -35 21 -38 48 -32 28 6 29 6 29 -34 0 -34 3 -41 19 -41 11
0 23 5 26 10 4 7 11 7 18 2 7 -5 47 -15 89 -22 70 -13 75 -15 58 -27 -11 -8
-20 -21 -20 -30 0 -9 -7 -21 -16 -27 -20 -15 -8 -46 19 -46 11 0 31 10 44 22
l23 21 0 -62 c0 -53 -4 -68 -25 -93 -14 -16 -25 -41 -25 -54 0 -14 -16 -46
-35 -71 -37 -48 -41 -62 -26 -91 5 -9 12 -25 15 -34 13 -41 86 -7 86 39 0 26
10 53 19 53 10 0 11 -77 2 -102 -7 -18 -17 -22 -63 -24 -63 -2 -105 -24 -114
-60 -7 -27 -24 -33 -24 -8 0 25 -151 194 -173 194 -6 0 -21 -12 -33 -27 -32
-39 -48 -21 -29 32 8 22 14 52 15 68 0 27 0 27 -70 27 -40 0 -70 -4 -70 -10 0
-6 -26 -10 -59 -10 l-59 0 -23 -46 c-17 -37 -20 -50 -10 -63 6 -9 16 -26 23
-38 6 -13 17 -23 23 -23 6 0 30 -20 53 -44 47 -50 75 -63 106 -48 11 6 39 17
62 25 l41 15 17 -28 c14 -25 21 -28 46 -23 23 4 30 2 30 -10 0 -21 31 -37 72
-37 l32 0 -21 -21 c-21 -21 -23 -21 -70 -6 -47 16 -48 16 -68 -7 -11 -13 -26
-32 -33 -42 -11 -16 -12 -12 -7 27 5 42 3 49 -25 77 -24 24 -30 27 -30 13 0
-22 -31 -51 -53 -51 -9 0 -32 -7 -51 -17 -45 -21 -68 -6 -57 35 10 34 4 52
-18 52 -27 0 -40 20 -34 51 5 28 4 29 -30 29 -26 0 -43 -7 -60 -26 l-24 -25 5
30 c6 41 -31 89 -92 122 -28 14 -46 31 -46 41 0 10 -4 18 -10 18 -5 0 -10 7
-10 16 0 13 3 14 14 5 8 -7 32 -11 53 -9 37 3 38 4 36 42 -3 51 -18 66 -63 66
-46 0 -66 -20 -51 -49 17 -31 12 -38 -9 -12 -16 18 -20 38 -20 97 0 40 5 76
10 79 16 10 13 18 -32 84 -40 60 -41 64 -31 104 10 35 9 43 -3 50 -8 5 -12 12
-9 18 4 5 -5 9 -18 9 -26 0 -34 14 -60 105 -37 129 -59 216 -71 288 -9 56 -20
85 -37 103 -13 14 -29 48 -35 76 -11 46 -10 51 11 72 32 32 22 69 -30 120 -56
56 -109 125 -101 134 4 3 10 1 14 -5 7 -12 87 -43 111 -43 8 0 11 7 8 19 -3
12 4 28 19 42 32 30 33 84 2 110 -25 20 -68 25 -68 8 0 -6 -11 -24 -25 -41
-14 -16 -25 -39 -25 -50 0 -15 -4 -18 -17 -13 -10 4 -47 19 -83 32 -165 60
-174 66 -210 130 -18 32 -49 78 -69 103 -20 25 -45 62 -55 83 -19 36 -143 163
-152 154 -13 -13 14 -73 55 -121 49 -58 156 -259 167 -312 5 -25 2 -35 -15
-46 -29 -21 -42 -60 -31 -100 5 -18 35 -83 67 -144 57 -110 58 -112 39 -132
-10 -12 -30 -22 -45 -24 -36 -4 -41 -46 -8 -82 13 -14 29 -26 36 -26 10 0 31
-38 45 -82 3 -11 15 -18 29 -18 25 0 57 -28 57 -48 0 -7 -13 -12 -28 -12 -16
0 -37 -7 -48 -15 -19 -15 -19 -15 5 -36 14 -11 37 -23 53 -26 35 -7 36 -28 3
-73 -33 -45 -31 -77 9 -122 46 -53 93 -70 185 -67 l76 2 2 -67 2 -68 -42 7
c-23 4 -51 16 -63 26 -11 10 -26 19 -32 19 -15 0 -15 0 -1 -38 12 -36 79 -72
134 -72 104 0 140 -122 58 -197 -39 -36 -39 -59 2 -103 47 -50 -5 -77 -83 -45
-33 14 -38 14 -48 0 -10 -14 -14 -14 -26 -1 -13 13 -18 11 -40 -9 -14 -13 -41
-28 -60 -33 -31 -9 -39 -6 -76 22 -23 17 -48 46 -57 64 -9 18 -24 36 -33 39
-9 3 -24 12 -34 20 -9 8 -18 11 -21 6 -6 -12 8 -186 17 -201 4 -7 14 -12 22
-12 8 0 14 -4 14 -10 0 -5 -9 -10 -20 -10 -11 0 -20 5 -20 10 0 13 -27 13 -35
0 -10 -16 28 -58 58 -64 18 -4 27 -12 27 -25 0 -11 6 -21 13 -24 7 -3 5 -7 -8
-12 -33 -11 -49 -21 -52 -32 -7 -19 30 -23 77 -10 48 14 150 11 150 -4 0 -5
-4 -9 -8 -9 -5 0 -8 -10 -7 -22 1 -14 15 -32 38 -47 20 -13 37 -30 37 -38 0
-17 14 -17 29 1 15 18 92 17 114 -2 14 -12 22 -13 43 -4 13 7 37 12 51 12 23
0 25 -3 19 -32 -9 -45 12 -84 56 -104 21 -9 40 -24 43 -33 3 -9 21 -26 40 -39
33 -21 35 -25 35 -77 0 -39 4 -56 16 -63 11 -6 13 -11 5 -16 -6 -4 -11 -12
-11 -18 0 -7 22 -9 69 -4 48 4 72 2 77 -5 4 -7 27 -10 63 -6 l56 5 0 -45 c0
-51 28 -83 74 -83 20 0 31 -9 48 -39 13 -22 20 -47 17 -55 -8 -20 0 -20 29 -1
31 20 67 9 67 -21 0 -12 5 -26 10 -29 19 -12 10 -35 -16 -45 -22 -9 -24 -13
-13 -26 6 -8 17 -13 24 -10 6 2 17 -5 23 -15 7 -10 19 -19 27 -19 9 0 22 -15
31 -33 13 -26 25 -36 60 -45 24 -7 53 -12 63 -12 27 0 71 56 71 91 0 23 -6 31
-35 43 -37 16 -45 30 -20 40 23 9 18 25 -15 44 -50 28 -34 34 20 7 27 -14 65
-25 85 -25 28 0 43 -8 68 -35 31 -33 33 -34 71 -22 27 8 48 25 70 56 17 24 47
59 66 78 l35 34 10 -32 c6 -17 19 -37 28 -44 9 -7 17 -22 17 -34 0 -12 11 -33
25 -47 25 -24 31 -45 19 -57 -12 -12 -61 22 -74 52 -17 42 -38 47 -71 14 -59
-58 -69 -65 -75 -49 -8 21 -31 9 -38 -19 -4 -14 -18 -27 -36 -33 -16 -6 -30
-17 -30 -25 0 -16 -41 -17 -90 -3 -21 5 -28 1 -45 -24 -11 -17 -30 -33 -43
-36 -28 -8 -37 -43 -26 -102 9 -45 10 -46 59 -59 28 -7 71 -13 97 -13 68 0 95
-13 103 -49 6 -27 12 -32 46 -37 21 -4 39 -10 39 -15 0 -4 8 -16 19 -26 10
-10 22 -32 25 -50 9 -40 48 -53 78 -26 19 17 20 36 3 91 -6 19 -4 22 19 22 39
0 61 15 76 50 7 17 11 32 9 33 -2 1 -17 9 -33 17 -16 8 -31 21 -34 28 -6 18
72 16 88 -3 7 -8 18 -15 25 -15 6 0 17 -11 23 -25 6 -14 18 -25 27 -25 8 0 15
-6 15 -12 -1 -16 -107 -98 -127 -98 -9 0 -3 -14 18 -40 31 -38 32 -41 19 -79
-16 -48 -8 -61 36 -61 19 0 34 -4 34 -9 0 -5 3 -16 6 -25 5 -13 -3 -16 -49
-16 -81 0 -159 20 -155 39 2 9 18 18 38 21 100 17 70 59 -42 60 -16 0 -33 -9
-45 -24 -17 -22 -22 -23 -73 -16 -30 5 -75 9 -99 9 -39 1 -43 -1 -38 -19 5
-19 0 -20 -108 -20 -109 0 -113 -1 -129 -25 -9 -13 -16 -34 -16 -45 0 -11 -7
-23 -15 -26 -20 -8 -72 14 -79 32 -18 47 -101 -28 -90 -83 6 -31 -1 -28 147
-59 26 -6 77 -55 77 -75 0 -6 10 -9 23 -7 20 3 22 9 25 76 l3 72 39 0 c32 0
46 -7 74 -35 25 -24 48 -37 78 -41 31 -5 54 -18 81 -44 32 -33 37 -44 37 -83
0 -69 -5 -76 -47 -66 -22 4 -46 20 -59 37 -33 41 -102 66 -151 53 -21 -5 -49
-9 -63 -8 -20 2 -29 -7 -50 -47 -14 -27 -30 -51 -36 -53 -6 -2 -16 22 -23 54
-7 32 -21 77 -32 101 -20 46 -34 49 -85 17 -19 -12 -24 -10 -58 30 -20 24 -36
50 -36 58 0 8 -11 22 -23 30 -14 9 -29 34 -37 61 -9 33 -24 55 -51 77 -60 48
-49 85 26 91 35 3 40 0 43 -20 2 -15 15 -28 35 -37 30 -13 34 -12 58 11 14 13
28 24 32 24 5 0 17 9 28 19 30 28 23 85 -11 89 -36 5 -55 32 -55 76 0 35 -3
40 -30 48 -16 4 -64 9 -105 11 l-75 2 -3 -37 c-2 -21 -7 -38 -13 -38 -12 0
-12 -47 1 -55 6 -3 10 -15 10 -26 0 -42 -68 -11 -113 52 -28 38 -61 51 -70 27
-2 -7 5 -39 16 -70 12 -32 18 -60 14 -64 -3 -4 -23 -7 -44 -8 -28 -1 -39 4
-47 20 -16 31 -114 114 -135 114 -10 0 -30 -9 -45 -21 -22 -17 -26 -29 -26
-68 0 -68 20 -84 93 -75 54 6 56 6 50 -15 -3 -12 -14 -26 -24 -32 -46 -24 7
-128 70 -137 32 -4 69 -30 76 -53 4 -11 16 -19 28 -19 12 0 32 -3 44 -6 18 -5
25 -1 33 20 6 16 24 33 44 41 18 8 36 21 40 30 5 14 8 14 36 0 30 -16 52 -49
63 -96 4 -14 22 -42 42 -62 19 -20 35 -42 35 -50 0 -8 7 -20 15 -27 19 -16 19
-40 0 -40 -20 0 -38 -37 -33 -71 5 -34 4 -35 -47 -9 -22 11 -50 20 -63 20 -22
0 -24 -3 -18 -32 4 -18 7 -52 6 -75 0 -23 4 -44 9 -47 5 -4 30 4 55 16 25 12
48 19 52 15 12 -12 -6 -55 -36 -87 -38 -40 -39 -60 -2 -60 15 -1 56 -11 92
-24 53 -18 66 -27 68 -47 2 -13 8 -30 14 -36 7 -9 3 -15 -17 -23 -34 -13 -52
-13 -57 0 -2 6 -23 18 -47 26 -43 15 -45 14 -68 -9 l-24 -24 21 -36 c19 -32
25 -35 51 -30 26 5 29 3 29 -20 0 -29 5 -33 71 -62 53 -23 115 -74 134 -112
13 -25 12 -27 -23 -44 -32 -16 -37 -24 -38 -54 0 -29 3 -35 19 -35 11 0 28 7
37 15 13 12 23 13 48 4 39 -13 41 -25 5 -34 -43 -10 -73 -33 -73 -56 0 -24 43
-112 77 -157 l24 -33 -28 -33 c-15 -18 -40 -36 -55 -39 -19 -4 -28 -13 -28
-26 0 -25 21 -41 64 -48 32 -5 36 -3 42 21 3 15 21 39 39 54 18 15 37 37 44
49 14 28 36 40 80 44 l34 4 13 -60 c7 -33 13 -63 13 -67 2 -15 -64 5 -80 24
-11 14 -25 19 -46 16 l-32 -3 21 -47 c17 -40 26 -49 53 -54 19 -3 46 -3 62 0
22 5 35 0 59 -22 26 -24 32 -38 37 -91 8 -73 -8 -194 -31 -233 -15 -25 -14
-29 1 -46 25 -27 21 -32 -22 -32 -24 0 -48 8 -65 21 -27 21 -57 22 -171 6 -58
-8 -57 -29 2 -58 36 -17 73 -25 143 -29 52 -4 109 -13 125 -20 17 -8 66 -16
110 -19 62 -5 80 -9 80 -21 0 -25 -145 -39 -262 -25 -35 4 -63 4 -63 -1 0 -4
12 -45 27 -90 28 -88 50 -107 93 -84 25 13 70 2 180 -44 67 -28 91 -43 112
-73 15 -21 25 -46 22 -55 -8 -26 26 -30 117 -13 46 9 107 20 136 25 28 4 57
11 63 15 6 4 23 -3 36 -16 21 -20 39 -24 100 -27 86 -5 99 2 108 58 5 34 10
40 31 41 40 2 43 3 56 17 20 19 64 14 85 -9 17 -19 18 -21 1 -30 -9 -6 -17
-19 -17 -29 0 -14 -7 -20 -24 -20 -38 0 -50 -23 -43 -88 6 -56 6 -57 -28 -79
-19 -13 -39 -23 -45 -23 -21 0 -9 -50 17 -75 22 -20 36 -25 67 -23 55 4 76 35
79 118 1 36 7 68 12 72 12 9 77 10 100 2 25 -10 45 -58 45 -108 0 -57 19 -76
74 -76 64 0 74 13 79 100 2 41 14 107 27 145 38 118 37 150 -4 230 -43 83 -44
108 -11 137 29 25 32 43 8 57 -16 10 -16 13 10 36 36 32 47 32 47 -4 0 -38 33
-111 64 -140 23 -21 31 -23 80 -17 70 8 105 21 113 43 4 10 17 32 30 49 13 17
23 40 23 51 0 10 12 31 28 46 27 26 28 26 40 6 9 -13 19 -18 32 -14 10 3 21 1
25 -5 3 -5 10 -10 16 -10 12 0 12 117 -1 125 -15 10 -12 37 10 80 23 45 16 55
-41 55 -61 0 -71 -15 -67 -102 l3 -75 -27 9 c-22 8 -28 18 -37 70 -6 33 -15
66 -21 73 -6 7 -13 41 -17 77 -5 62 -5 64 22 76 29 13 44 5 45 -24 0 -16 34
-44 53 -44 11 0 17 8 17 25 0 14 4 25 9 25 5 0 12 12 16 27 5 21 2 32 -15 45
-12 10 -30 18 -40 18 -13 0 -19 11 -24 41 -8 48 -36 107 -62 130 -11 9 -41 20
-69 24 -27 5 -42 10 -32 12 13 4 17 15 17 56 0 80 6 94 40 89 21 -3 34 2 51
22 12 14 29 26 36 26 8 0 25 11 38 24 21 21 23 27 12 50 -15 33 -59 35 -81 4
-8 -12 -13 -25 -10 -29 5 -9 -55 -43 -63 -35 -5 4 -21 128 -22 174 -1 6 -13
12 -27 12 -24 0 -26 -3 -20 -30 5 -26 3 -30 -17 -30 -15 0 -31 -13 -49 -44
-32 -50 -38 -130 -14 -187 9 -22 12 -48 7 -74 -6 -36 -11 -42 -56 -63 -28 -12
-57 -26 -65 -31 -30 -16 -34 -57 -18 -158 8 -54 11 -99 7 -101 -5 -2 -2 -19 6
-39 21 -49 20 -162 0 -170 -9 -3 -24 2 -34 11 -10 9 -23 16 -29 16 -7 0 -12 5
-12 10 0 6 14 10 31 10 26 0 30 3 27 22 -3 25 -31 35 -95 37 -31 1 -43 -3 -43
-13 0 -30 13 -46 36 -46 13 0 24 -3 24 -7 -1 -13 -72 -63 -90 -63 -10 0 -38
-19 -63 -43 -24 -23 -56 -50 -71 -60 -14 -10 -36 -39 -49 -65 -37 -78 -104
-104 -224 -88 -48 7 -53 10 -67 47 -21 53 -60 63 -93 25 -19 -22 -26 -25 -43
-16 -46 25 -101 203 -82 268 27 89 19 112 -40 112 -21 1 -21 2 -6 14 16 12 16
16 -2 75 -22 69 -26 111 -10 111 5 0 19 19 31 42 30 60 47 77 90 88 50 14 64
39 47 86 -9 26 -20 37 -39 41 -23 4 -28 11 -33 53 -5 41 -11 50 -36 60 -17 7
-30 13 -30 14 0 0 7 23 15 50 10 34 27 59 50 79 53 43 46 86 -17 101 -36 8
-58 -15 -58 -65 0 -22 -5 -39 -11 -39 -22 0 -38 -35 -39 -79 0 -31 -9 -58 -29
-90 -16 -25 -34 -68 -40 -96 -13 -53 -56 -135 -71 -135 -15 0 -21 18 -8 23 10
3 11 9 1 26 -7 11 -13 38 -13 58 -1 21 -7 64 -14 96 -8 32 -11 69 -7 84 5 18
2 37 -8 54 -15 27 -14 28 33 79 44 47 48 55 43 87 -4 20 -13 43 -22 53 -8 9
-15 29 -15 43 0 15 -4 27 -9 27 -12 0 -41 -29 -41 -40 0 -5 -15 -17 -32 -26
-18 -9 -41 -26 -50 -38 -16 -19 -18 -19 -18 -3 0 10 -13 30 -30 45 -20 19 -30
38 -32 64 -2 42 10 61 53 78 26 11 31 10 43 -5 28 -39 78 3 60 52 -9 26 -14
28 -55 26 -38 -2 -46 1 -54 20 -6 12 -37 35 -72 52 -129 63 -133 93 -23 145 8
4 29 29 45 56 29 48 31 49 70 44 l40 -5 6 -60 c6 -47 12 -63 33 -80 15 -12 26
-31 26 -45 1 -38 78 -116 139 -139 50 -20 51 -20 97 -1 25 11 53 20 63 20 9 0
27 14 40 31 19 26 25 28 38 17 13 -11 22 -6 57 35 46 53 72 62 62 22 -4 -14
-2 -25 4 -25 5 0 10 -9 10 -20 0 -26 41 -51 94 -57 37 -5 50 -1 80 22 24 18
42 25 56 20 14 -4 20 -2 20 8 0 8 7 20 15 27 18 15 20 61 3 78 -7 7 -21 12
-33 12 -12 0 -28 12 -38 29 -25 42 -22 68 9 75 27 7 28 23 1 40 -12 8 -23 7
-38 -3 -22 -13 -22 -13 -15 53 4 45 3 66 -4 66 -28 0 -72 -34 -96 -75 -15 -26
-33 -45 -43 -45 -19 0 -44 -24 -54 -51 -3 -11 -20 -18 -46 -21 -34 -3 -41 0
-41 14 0 9 -4 20 -10 23 -12 8 -13 55 -1 74 7 11 11 10 19 -4 5 -10 14 -15 21
-11 12 8 15 56 4 56 -5 0 -13 11 -19 25 -9 19 -9 28 2 42 7 10 14 26 14 36 0
23 13 21 48 -8 19 -16 42 -25 65 -25 31 0 36 4 51 40 19 49 19 45 0 65 -11 11
-13 19 -6 28 5 7 13 26 16 42 16 75 -60 88 -92 16 -19 -43 -92 -113 -92 -88 0
33 13 76 21 71 5 -3 9 1 9 8 0 7 5 30 11 51 11 41 56 77 96 77 25 0 53 35 53
65 0 12 11 31 23 44 22 19 25 20 37 6 10 -12 11 -38 6 -102 -5 -73 -4 -87 10
-98 25 -18 10 -61 -31 -88 -41 -26 -44 -39 -10 -57 17 -9 25 -22 25 -41 l0
-27 83 5 c69 4 79 2 66 -9 -33 -27 -42 -57 -25 -83 18 -27 51 -147 61 -217 3
-27 12 -49 21 -52 8 -3 13 -10 9 -16 -7 -11 39 -25 55 -15 5 3 9 19 8 33 -3
42 11 58 29 33 8 -12 24 -21 36 -21 12 0 28 -4 34 -8 19 -12 -2 -74 -42 -122
-34 -41 -37 -60 -10 -60 7 0 15 -12 17 -27 2 -22 9 -29 31 -31 l27 -3 -2 59
c-2 33 1 64 5 68 4 4 54 -8 111 -27 l104 -34 19 -70 c17 -65 20 -70 47 -73 16
-2 41 2 54 10 20 10 26 10 29 1 3 -7 10 -13 17 -13 7 0 30 -11 50 -25 66 -45
115 -24 117 51 l2 39 7 -45 c4 -25 12 -56 17 -70 6 -14 14 -68 18 -120 4 -52
11 -101 15 -110 5 -8 13 -46 19 -85 8 -57 15 -74 37 -91 l26 -21 -27 -7 c-24
-6 -26 -10 -23 -49 3 -42 3 -42 38 -39 19 2 38 8 41 14 4 6 20 7 38 3 31 -7
32 -8 15 -26 -24 -26 -11 -47 32 -51 l37 -3 48 -155 c27 -85 48 -165 49 -177
0 -17 6 -23 21 -23 20 0 21 4 15 50 -6 45 -5 50 13 50 11 0 28 -11 38 -25 49
-71 44 -68 95 -55 28 7 49 19 52 29 13 45 17 82 11 99 -5 17 -11 18 -52 11
-25 -5 -49 -7 -51 -4 -3 3 2 21 11 39 14 31 20 34 69 38 29 2 60 8 69 12 12 5
23 -4 42 -37 15 -25 47 -64 72 -88 26 -24 55 -57 65 -74 10 -16 36 -39 57 -50
21 -11 41 -24 46 -30 5 -6 12 -4 20 7 9 11 21 15 37 11 23 -6 23 -5 16 56 -4
35 -11 73 -16 86 -7 18 -5 24 9 28 11 2 23 22 31 51 11 39 11 54 -1 89 -18 55
-111 151 -163 167 l-39 13 -43 -49 c-24 -27 -48 -64 -55 -83 -10 -28 -13 -31
-21 -17 -10 18 -73 23 -83 6 -3 -6 -13 -10 -22 -10 -14 0 -14 3 1 25 20 31 20
59 0 80 -15 15 -15 18 6 40 17 18 21 30 15 52 -7 31 -32 63 -49 63 -6 0 -17 9
-24 20 -7 11 -19 20 -27 20 -17 0 -19 9 -6 29 8 12 13 11 29 -9 17 -21 23 -23
53 -14 26 7 35 16 39 40 4 16 3 39 -1 50 -4 10 -8 43 -8 72 -1 28 -3 52 -6 52
-20 0 -56 -28 -64 -49 -5 -14 -10 -19 -10 -10 -2 27 -50 111 -76 131 -32 25
-32 61 0 82 22 14 25 24 25 75 0 91 -17 106 -120 106 -70 0 -83 -3 -98 -21 -9
-12 -25 -24 -34 -27 -12 -4 -18 -17 -18 -40 0 -52 -17 -66 -70 -57 -25 4 -36
8 -25 9 18 1 18 2 3 18 -16 15 -16 17 5 28 14 6 22 20 22 35 0 22 -4 25 -35
24 -19 0 -46 -3 -60 -5 -28 -4 -27 -6 -12 89 12 68 34 92 84 92 34 0 39 2 33
20 -9 27 21 80 45 80 11 0 33 14 51 31 17 17 33 28 36 25 3 -3 -4 -24 -16 -48
-12 -25 -17 -44 -11 -46 6 -2 8 -8 4 -13 -3 -5 8 -17 25 -27 17 -9 31 -24 31
-33 0 -15 26 -28 54 -29 5 0 6 19 2 43 -6 34 -5 40 5 30 7 -7 29 -13 49 -13
32 0 40 -5 63 -41 15 -22 27 -45 27 -50 0 -18 16 -9 54 30 31 32 36 42 30 67
-4 16 -14 38 -24 49 -18 20 -33 61 -48 125 -8 33 -11 35 -54 38 -26 2 -71 -3
-101 -11 -40 -11 -58 -12 -66 -4 -15 15 -14 27 4 27 23 0 18 41 -6 47 -25 7
-23 46 3 51 12 2 20 -8 28 -38 l11 -42 47 6 c34 5 55 15 74 36 19 20 36 28 57
28 41 -1 68 25 76 72 7 42 29 79 41 68 4 -4 1 -14 -5 -22 -9 -11 -10 -20 -1
-35 13 -25 27 -26 52 -4 29 26 29 12 2 -47 -14 -30 -24 -65 -22 -78 4 -35 -33
-48 -58 -21 -18 20 -68 26 -79 10 -7 -12 33 -51 52 -51 7 0 25 -18 40 -41 22
-34 31 -40 57 -37 24 2 35 11 48 37 11 22 31 40 58 52 23 10 49 28 59 40 23
29 60 20 83 -21 16 -28 21 -30 73 -30 33 0 55 4 55 11 0 6 9 22 20 36 12 16
20 41 20 64 0 20 3 44 6 52 7 19 43 10 66 -17 17 -20 17 -19 18 40 0 43 -5 65
-15 74 -8 7 -15 16 -15 20 0 5 -12 22 -27 38 -69 72 -91 103 -86 122 4 15 0
20 -15 20 -22 0 -42 26 -42 54 0 8 11 28 25 44 16 19 21 32 14 39 -6 6 -2 16
11 28 31 28 26 52 -12 60 -18 4 -41 14 -50 22 -24 21 -38 8 -38 -38 0 -32 -3
-39 -20 -39 -11 0 -20 -7 -20 -15 0 -18 -20 -19 -54 -3 -17 7 -28 22 -32 45
-4 19 -18 43 -34 56 -23 18 -26 26 -19 45 9 24 35 30 42 10 3 -10 9 -10 28 -2
130 62 150 67 235 60 88 -6 114 -2 114 20 0 11 17 14 73 14 66 0 75 -2 86 -23
9 -17 9 -30 2 -51 -14 -35 -14 -83 -1 -91 15 -9 12 -128 -5 -168 -15 -36 -11
-65 9 -79 6 -4 -10 -8 -36 -8 -26 0 -50 4 -53 10 -7 11 -84 14 -109 4 -9 -3
-16 -11 -16 -18 0 -16 78 -53 129 -60 24 -4 48 -9 53 -12 12 -8 -51 -54 -73
-54 -37 0 -42 -26 -20 -97 11 -38 30 -84 42 -103 20 -32 22 -33 35 -16 13 18
14 17 24 -10 5 -16 14 -36 19 -44 23 -41 -52 -159 -122 -194 -29 -13 -33 -13
-62 10 -40 30 -69 31 -89 2 -20 -28 -21 -92 -1 -108 11 -9 25 -7 68 13 52 24
55 24 70 7 13 -16 13 -21 2 -37 -20 -26 -18 -43 5 -43 17 0 19 -4 14 -25 -9
-35 11 -76 46 -100 17 -11 30 -26 30 -33 0 -8 10 -5 29 9 27 20 34 21 95 10
l65 -11 70 72 c45 46 79 92 93 126 15 35 27 52 40 52 10 0 18 7 18 16 0 25
-19 54 -37 54 -13 0 -13 3 -2 16 18 21 64 44 90 44 39 0 87 -23 98 -46 15 -33
49 -40 74 -16 19 20 20 23 5 61 -14 35 -14 40 -1 45 27 11 46 6 78 -18 31 -24
78 -26 91 -5 3 5 20 9 38 9 38 0 56 15 56 46 0 33 23 50 74 57 64 9 96 -5 96
-41 0 -31 -16 -52 -40 -52 -39 0 -68 -78 -40 -106 19 -19 0 -42 -51 -63 -52
-23 -58 -37 -36 -84 22 -48 108 -44 156 7 24 25 32 83 18 122 -6 17 -1 23 28
37 52 25 79 21 109 -14 14 -17 26 -37 26 -45 0 -19 45 -36 74 -28 28 7 116
109 116 134 0 11 5 22 11 26 21 13 3 132 -26 174 -20 29 -28 59 -40 156 -17
139 -18 167 -6 159 13 -8 61 16 61 30 0 7 -8 22 -17 33 -14 16 -33 21 -85 24
-65 4 -67 3 -70 -21 -4 -31 -55 -67 -114 -82 -48 -12 -63 -26 -94 -87 -13 -24
-39 -54 -59 -68 -31 -21 -47 -24 -123 -24 -74 0 -91 3 -107 19 -32 32 -54 39
-103 34 -54 -6 -59 0 -78 92 -15 71 -39 135 -51 135 -20 0 -6 38 36 104 50 77
55 111 28 186 -32 88 -17 175 39 237 11 12 18 29 15 37 -3 8 1 20 9 26 8 7 14
18 14 25 0 7 23 36 52 63 l52 50 -45 12 c-48 13 -79 35 -79 57 0 22 47 106 69
125 28 23 27 40 -2 36 -13 -1 -38 7 -57 19 -19 12 -36 18 -38 13 -2 -6 -10
-10 -17 -10 -8 0 -23 -12 -35 -27 -19 -24 -21 -36 -16 -89 5 -52 3 -63 -15
-83 -21 -23 -54 -24 -106 -4 -16 6 -15 -7 3 -69 5 -18 -15 -40 -74 -86 -21
-15 -44 -22 -75 -22 -25 0 -49 -4 -52 -10 -3 -5 -26 -10 -51 -10 -35 0 -44 3
-44 18 0 9 -18 67 -39 127 -21 61 -46 157 -55 215 -12 70 -27 125 -47 164 -32
65 -37 110 -14 132 8 9 15 24 15 35 0 30 57 143 86 170 l26 24 -7 -28 c-6 -21
-3 -31 11 -42 26 -20 61 -18 73 2 5 10 12 15 16 11 4 -3 -3 -16 -14 -27 -15
-15 -34 -21 -65 -21 -69 0 -76 -6 -76 -60 0 -58 24 -84 75 -83 19 1 32 -3 29
-8 -3 -5 -1 -9 5 -9 5 0 13 -9 16 -20 3 -11 15 -20 26 -20 15 0 19 7 19 33 0
22 11 44 33 71 l33 38 49 -30 c72 -44 110 -48 190 -21 37 12 69 28 72 36 3 7
15 13 27 13 12 0 27 5 34 12 15 15 16 60 2 74 -6 6 -17 48 -24 95 l-14 84 34
38 c41 44 43 73 9 110 -35 37 -82 37 -124 -2 -17 -16 -31 -36 -31 -45 0 -9 -5
-16 -10 -16 -11 0 -106 38 -169 68 -31 14 -35 14 -45 0 -11 -15 -13 -15 -24 3
-11 17 -24 19 -117 19 -116 0 -119 -2 -95 -66 l11 -27 84 6 c46 3 94 11 106
17 27 15 32 8 18 -23 -15 -32 -79 -60 -122 -52 -18 3 -53 2 -79 -3 -39 -8 -48
-6 -62 9 -20 24 -64 24 -92 1 -18 -15 -20 -26 -16 -74 5 -70 -9 -83 -49 -40
-34 36 -36 46 -9 37 16 -5 20 -2 20 17 0 36 -68 62 -140 54 -52 -6 -57 -5 -81
21 -14 16 -34 47 -44 70 -10 24 -26 45 -36 48 -9 3 -23 13 -30 21 -11 13 -10
18 4 25 26 15 20 29 -11 29 -22 0 -29 6 -38 35 -10 32 -9 35 10 35 12 0 29
-13 41 -31 16 -25 25 -29 48 -25 23 5 31 0 52 -35 14 -22 30 -51 35 -65 9 -22
12 -23 31 -13 12 6 42 9 66 6 l45 -5 -5 37 c-3 20 -9 50 -12 68 -6 24 -2 36
14 52 12 12 28 21 37 21 18 0 16 -32 -7 -88 -21 -51 -5 -66 73 -70 58 -3 69 0
97 23 17 15 31 32 31 38 0 6 12 21 27 33 28 22 27 59 -2 48 -20 -8 -19 -1 5
47 12 22 29 43 38 46 25 9 32 23 32 65 0 65 -41 88 -160 88 -98 0 -133 -12
-128 -43 3 -21 7 -22 70 -19 68 4 108 -3 108 -18 0 -5 -9 -12 -20 -15 -11 -4
-27 -22 -35 -41 -9 -23 -21 -34 -34 -34 -12 0 -21 5 -21 10 0 6 -12 10 -27 10
-16 0 -45 3 -65 6 -31 5 -38 11 -38 30 0 14 8 25 19 29 23 7 35 37 26 64 -5
15 -17 21 -53 23 -38 2 -49 8 -57 26 -12 26 -36 28 -75 6 -26 -16 -28 -16 -48
4 -11 11 -27 24 -37 29 -16 9 -16 13 3 50 22 42 16 63 -17 63 -9 0 -27 9 -41
20 -29 23 -60 26 -60 6 0 -9 -17 -16 -49 -21 -66 -9 -91 7 -91 58 0 33 4 39
30 48 19 7 30 18 30 30 0 19 46 119 54 119 3 0 1 -13 -4 -28 -4 -16 -11 -58
-15 -93 -4 -35 -12 -70 -18 -77 -5 -7 -7 -15 -4 -18 12 -12 55 6 103 44 27 22
59 42 71 46 11 4 27 14 34 23 12 13 10 18 -9 31 -12 8 -22 20 -22 27 0 17 -33
45 -53 45 -9 0 -17 5 -17 10 0 6 7 10 16 10 9 0 14 7 12 18 -2 10 -11 16 -21
14 -10 -2 -17 3 -17 12 0 11 9 13 38 9 29 -4 41 0 56 16 11 12 16 21 12 21 -3
0 1 11 9 24 9 14 30 27 50 30 20 4 40 17 50 32 16 25 16 27 -3 47 -10 12 -25
37 -32 56 -9 22 -25 38 -46 47 -39 17 -107 18 -135 4 -17 -10 -19 -7 -19 24 0
38 -36 75 -74 77 -17 0 -14 4 12 17 31 15 33 19 28 52 -3 19 0 49 6 66 10 28
8 36 -12 65 -13 19 -28 37 -34 41 -6 4 -5 12 4 22 7 9 11 18 9 20 -2 2 -40 0
-83 -4 -64 -6 -82 -12 -95 -29 -18 -23 -31 -19 -31 10 0 10 -7 22 -16 27 -26
14 -9 55 20 48 13 -3 55 -9 94 -12 59 -6 72 -4 72 7 0 23 -57 79 -82 81 -72 4
-108 -10 -108 -43 0 -11 -7 -19 -15 -19 -8 0 -15 -4 -15 -10 0 -5 -11 -10 -25
-10 -14 0 -25 4 -25 9 0 5 -19 9 -43 9 -57 -1 -97 25 -97 63 0 16 8 34 18 41
11 7 24 23 31 36 15 27 27 28 34 2 4 -18 11 -19 54 -14 63 9 73 18 73 70 0 23
5 46 10 49 6 3 10 13 10 21 0 9 10 24 22 34 18 14 23 30 26 88 4 62 7 73 28
87 33 21 32 62 -2 75 -14 6 -39 10 -55 10 -16 0 -29 5 -29 11 0 29 -166 169
-200 169 -22 0 -38 33 -33 65 14 88 -109 116 -152 34 l-13 -24 -15 35 c-9 19
-24 58 -34 85 -22 56 -62 89 -129 106 -32 8 -44 17 -46 33 -3 19 -8 21 -49 18
-55 -4 -88 11 -144 65 -22 21 -55 49 -73 63 -33 24 -34 28 -42 125 -11 119
-68 331 -104 385 -16 25 -44 47 -79 65 -48 23 -69 27 -178 30 -68 2 -135 2
-149 -1z m-363 -1601 c3 -10 14 -27 24 -38 10 -11 29 -41 43 -67 l24 -46 -29
-6 c-21 -4 -29 -11 -29 -27 0 -15 -5 -19 -15 -15 -10 3 -17 -3 -21 -19 -8 -32
-40 -33 -47 -2 -5 18 -11 204 -8 230 2 15 52 7 58 -10z m1516 -82 c49 -1 45
-22 -6 -35 -40 -10 -47 -25 -47 -101 0 -66 9 -97 26 -91 7 3 16 1 19 -3 3 -5
-2 -12 -11 -15 -15 -6 -14 -38 3 -101 4 -14 -5 -10 -31 18 -26 26 -46 37 -66
37 -17 0 -30 5 -30 12 0 6 -9 3 -20 -7 -11 -10 -20 -26 -20 -37 0 -13 -7 -18
-27 -18 -16 0 -43 -9 -62 -21 -35 -21 -35 -23 -15 -103 5 -20 2 -26 -10 -26
-10 0 -16 9 -16 25 0 14 -8 33 -17 43 -10 11 -26 33 -35 51 -9 17 -29 36 -43
41 -38 14 -30 23 15 15 28 -5 46 -2 62 9 12 9 30 16 40 16 11 0 18 7 18 20 0
28 22 71 34 67 6 -2 13 -19 16 -38 6 -38 31 -50 54 -26 11 10 12 17 4 25 -14
14 7 42 32 42 40 0 50 11 50 54 0 54 -14 72 -68 86 -48 12 -60 30 -44 64 10
23 11 23 84 10 40 -7 90 -13 111 -13z m-1028 -187 c22 -20 32 -33 22 -30 -10
3 -27 6 -38 6 -10 0 -25 13 -34 30 -22 42 -1 39 50 -6z m40 -180 c28 -31 36
-34 53 -25 29 15 52 14 31 -2 -27 -21 -32 -56 -14 -102 9 -24 15 -52 13 -62
-4 -25 34 -24 47 2 5 11 17 19 26 17 15 -3 15 -7 -2 -40 -11 -20 -19 -41 -19
-48 0 -6 -13 -28 -29 -48 -16 -21 -31 -42 -33 -48 -5 -15 -105 -23 -122 -9
-11 10 -10 21 10 69 13 31 24 64 24 73 0 8 5 20 11 26 18 18 -6 49 -56 73 -41
20 -59 40 -35 40 6 0 10 6 10 14 0 7 7 19 15 26 8 7 15 25 15 40 0 25 7 40 18
40 2 0 19 -16 37 -36z m824 -55 c-20 -13 -27 -11 -38 8 -7 12 -5 20 7 29 13
10 19 8 31 -8 12 -17 12 -21 0 -29z m207 -182 c-3 -14 3 -28 19 -43 14 -13 25
-30 25 -38 0 -13 -5 -13 -32 3 -29 15 -33 23 -36 64 -3 40 -1 46 13 41 11 -5
15 -14 11 -27z m-80 -14 c-3 -21 -6 -45 -6 -54 0 -9 -11 -21 -24 -27 -17 -8
-26 -21 -28 -44 l-4 -33 -16 35 c-9 19 -19 51 -23 72 -6 34 -4 38 31 62 21 14
47 26 58 26 16 0 18 -5 12 -37z m262 -78 c-34 -19 -63 -74 -47 -89 8 -8 13 -7
16 2 5 14 63 17 63 3 0 -15 -28 -32 -46 -29 -12 2 -26 -10 -42 -39 -27 -49
-23 -63 17 -63 16 0 35 -7 42 -16 12 -15 11 -18 -5 -28 -11 -6 -26 -19 -35
-28 -17 -20 -56 -23 -97 -8 -22 9 -25 13 -14 23 10 9 13 9 17 0 7 -18 30 -16
37 3 11 28 6 54 -9 54 -8 0 -15 4 -15 8 0 5 -19 14 -41 21 -40 12 -40 13 -21
27 16 11 20 21 15 48 -5 32 -3 35 23 38 20 2 30 9 32 24 2 14 19 28 50 41 57
25 103 31 60 8z m-1701 -351 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2
13 -5z m1781 -344 c5 0 12 -7 15 -14 3 -8 22 -18 41 -22 26 -4 36 -11 36 -25
0 -10 4 -20 9 -23 4 -3 11 -18 14 -33 3 -16 8 -35 12 -45 10 -25 -30 -33 -61
-13 -14 9 -41 42 -61 73 -20 31 -42 61 -50 65 -14 9 -17 35 -6 63 6 16 8 15
24 -4 9 -12 22 -22 27 -22z m295 -51 c19 -36 29 -45 55 -47 18 -2 32 -9 32
-17 0 -7 7 -15 15 -19 8 -3 15 -12 15 -21 0 -13 -7 -11 -32 9 -40 30 -129 66
-163 66 -28 0 -28 0 -15 -34 6 -17 6 -26 -1 -26 -5 0 -12 7 -15 15 -4 8 -12
15 -19 15 -7 0 -18 14 -24 30 -9 26 -8 30 9 30 10 0 21 9 25 19 5 18 8 18 33
5 38 -20 52 -17 52 8 0 27 4 23 33 -33z m226 -77 c8 -26 5 -35 -25 -67 l-34
-37 -10 27 c-14 37 -14 39 20 63 17 13 30 30 30 39 0 23 9 11 19 -25z m-1975
-52 c50 0 61 -10 52 -46 -9 -35 -50 -52 -74 -31 -10 10 -26 17 -35 17 -9 0
-19 4 -22 10 -3 5 -19 7 -35 3 -27 -5 -30 -3 -30 21 0 19 5 26 20 26 11 0 20
6 20 14 0 8 8 22 17 32 16 16 18 15 30 -15 12 -28 17 -31 57 -31z m1334 -106
c2 -1 -6 -12 -17 -23 -28 -28 -59 -27 -71 3 -5 13 -15 26 -22 29 -7 3 14 3 47
0 33 -3 62 -7 63 -9z m300 -23 c16 -9 5 -64 -11 -58 -18 6 -31 33 -24 51 7 18
16 20 35 7z m49 -123 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z
m-197 -19 c9 -16 18 -19 40 -14 26 6 28 4 33 -32 3 -21 9 -53 13 -70 5 -29 4
-33 -15 -33 -16 0 -26 -10 -37 -35 -14 -35 -38 -46 -47 -22 -2 6 5 25 17 40
17 24 18 31 8 48 -11 18 -11 23 5 35 16 13 11 14 -48 12 l-67 -3 34 38 c19 20
34 42 34 47 0 17 19 11 30 -11z m190 -43 c8 -22 16 -25 67 -28 50 -3 58 -6 58
-23 0 -27 -52 -32 -95 -10 -27 14 -31 14 -36 0 -3 -8 -15 -15 -25 -15 -22 0
-25 32 -9 74 12 33 28 33 40 2z m-3578 -3 c30 -27 21 -33 -54 -33 l-73 0 40
25 c48 29 62 30 87 8z m1223 -135 c8 -28 15 -57 15 -63 0 -13 49 -61 68 -66 9
-3 3 -17 -17 -45 l-30 -40 25 -59 c13 -33 24 -65 24 -71 0 -7 16 -19 36 -29
39 -18 52 -39 36 -58 -5 -6 -15 -36 -22 -64 -9 -38 -17 -53 -29 -53 -9 0 -21
-4 -26 -9 -6 -5 -29 -12 -52 -16 l-43 -7 0 39 c0 63 -15 73 -41 29 -19 -33
-23 -36 -32 -20 -7 12 -5 27 8 51 16 29 23 33 60 33 36 0 44 4 49 23 13 49 7
68 -25 79 -31 11 -31 12 -25 59 6 45 5 49 -14 49 -25 0 -34 21 -26 55 4 17 11
22 22 18 20 -7 34 13 34 49 0 19 -7 31 -27 40 -16 8 -33 28 -41 50 -14 36 -13
39 9 58 36 30 47 25 64 -32z m2280 32 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0
6 2 10 4 10 3 0 8 -4 11 -10z m-3853 -47 c9 -10 19 -45 22 -78 l7 -60 -24 28
c-18 21 -31 27 -65 27 -23 0 -42 2 -42 4 0 1 5 18 11 37 6 19 12 40 15 47 5
18 59 15 76 -5z m4020 -105 c-2 -27 1 -49 8 -53 8 -5 5 -14 -11 -31 -12 -13
-19 -30 -16 -38 9 -22 56 -31 80 -17 14 9 22 10 30 2 30 -30 -116 -102 -158
-78 -14 7 -20 7 -23 -3 -3 -7 -13 -20 -23 -28 -15 -13 -19 -30 -19 -74 0 -70
15 -82 84 -69 55 10 76 26 76 57 0 31 8 30 58 -6 47 -34 47 -35 27 -70 -8 -14
-14 -52 -15 -86 0 -58 -2 -62 -25 -68 -27 -7 -54 3 -75 28 -7 9 -28 16 -47 16
-26 0 -42 9 -73 40 -21 22 -64 52 -94 66 -31 14 -53 30 -50 35 3 5 19 16 35
25 19 9 32 25 35 43 4 19 16 33 37 42 21 9 33 22 35 38 2 13 12 26 23 28 20 6
22 17 13 76 -7 36 -8 38 -38 31 -17 -3 -38 -13 -48 -21 -15 -13 -16 -10 -20
41 -1 31 -1 56 0 56 2 0 17 -14 33 -31 18 -20 35 -29 45 -25 9 3 33 6 55 6 36
0 39 2 29 19 -11 22 -14 84 -3 95 4 4 14 5 23 4 11 -3 14 -14 12 -50z m-1860
-102 c4 -6 8 -17 8 -25 0 -9 10 -12 35 -9 33 4 35 2 35 -24 0 -21 5 -28 20
-28 11 0 20 -5 20 -11 0 -16 -142 -1 -156 17 -6 8 -19 14 -28 14 -23 0 -20 10
11 42 15 15 21 28 15 28 -7 0 -12 5 -12 11 0 13 42 1 52 -15z m-325 -78 c55
-38 51 -49 -18 -47 -33 1 -62 6 -65 11 -6 9 33 58 45 58 2 0 20 -10 38 -22z
m-681 -3 c9 -23 -15 -48 -33 -33 -25 20 -42 -11 -43 -82 -1 -36 1 -74 5 -86 5
-17 -2 -27 -42 -52 -26 -17 -54 -36 -61 -42 -9 -8 -21 -4 -46 15 l-34 26 -67
-35 -67 -35 -39 17 c-22 9 -39 22 -39 29 0 7 -6 13 -14 13 -8 0 -18 9 -21 20
-4 11 -30 32 -58 47 -29 14 -55 31 -59 37 -13 20 -8 36 11 36 10 0 35 11 55
25 26 17 50 25 81 25 24 0 47 5 50 10 12 19 33 10 44 -20 11 -34 13 -34 134
-24 l75 7 11 43 c10 38 15 44 49 53 94 24 101 25 108 6z m2416 -24 c16 -16 19
-27 14 -56 -6 -30 -10 -35 -27 -30 -15 5 -19 2 -19 -19 0 -14 5 -26 10 -26 6
0 10 -13 10 -30 0 -36 -15 -38 -40 -8 l-18 23 -14 -33 -13 -33 -49 50 -50 50
15 48 15 48 43 -3 c36 -2 44 1 48 18 7 26 47 26 75 1z m-2324 -164 c-2 -7 14
-30 35 -51 22 -22 37 -45 35 -56 -2 -11 5 -25 16 -33 22 -16 33 -95 17 -126
-16 -30 -56 -27 -151 10 -44 17 -84 28 -89 25 -5 -4 -12 -2 -16 4 -3 5 -2 10
3 10 15 0 79 130 87 175 10 52 12 55 41 55 15 0 24 -5 22 -13z m526 -117 c-4
-61 -17 -129 -25 -124 -5 3 -14 24 -20 47 -6 23 -16 49 -23 59 -12 16 -15 16
-50 -8 -23 -16 -39 -36 -43 -55 -8 -41 -28 -36 -30 7 -2 45 36 83 84 84 17 0
34 3 36 8 3 4 20 7 39 7 29 0 33 -3 32 -25z m748 -114 c3 -29 -1 -38 -29 -61
-34 -27 -42 -52 -21 -73 6 -6 9 -14 7 -17 -3 -2 -20 15 -37 40 -17 25 -38 45
-47 45 -16 0 -20 18 -7 36 4 6 21 15 38 18 18 4 40 16 50 26 28 28 41 24 46
-14z m1637 -5 c21 -16 24 -26 22 -71 -2 -46 2 -57 25 -81 l28 -29 -55 0 c-36
0 -67 -7 -93 -21 -21 -11 -42 -17 -47 -12 -4 4 -9 30 -10 57 -3 46 -2 49 31
65 27 13 37 26 49 64 8 26 17 47 20 47 3 0 17 -9 30 -19z m-2438 -33 c6 -13
27 -37 45 -54 36 -33 45 -64 19 -64 -8 0 -24 -9 -35 -20 -25 -25 -30 -25 -30
-1 0 11 -4 23 -10 26 -5 3 -10 15 -10 26 0 10 -6 19 -14 19 -8 0 -25 14 -37
31 -22 31 -22 32 -4 45 29 21 63 17 76 -8z m1708 -141 c6 -8 21 -13 33 -12 36
3 55 -12 68 -52 7 -21 27 -57 45 -80 33 -44 131 -123 152 -123 18 0 82 -34 83
-44 0 -8 -51 -2 -93 10 -16 5 -18 1 -15 -33 3 -34 0 -38 -21 -41 -21 -3 -23
-7 -18 -48 2 -24 1 -41 -4 -38 -5 3 -9 15 -9 28 0 12 -5 27 -12 34 -10 10 -7
18 15 38 15 13 27 31 27 39 0 7 3 21 7 31 5 14 2 16 -23 11 -35 -7 -98 28 -90
49 8 22 -33 17 -79 -9 -40 -23 -40 -24 -39 -80 1 -31 -1 -57 -5 -57 -3 0 -27
34 -53 75 -47 72 -61 126 -38 140 6 3 10 1 10 -4 0 -6 8 -11 19 -11 26 0 39
65 17 89 -28 31 -36 67 -18 85 20 19 26 20 41 3z m-770 -73 c-7 -8 -8 -29 -4
-54 7 -40 -1 -50 -22 -29 -8 8 -16 2 -29 -22 -9 -19 -20 -36 -24 -39 -4 -3 -9
-14 -11 -24 -4 -18 -26 -26 -116 -46 -1 0 3 14 9 30 13 38 5 58 -27 70 -14 5
-25 16 -25 24 0 20 39 46 68 46 12 0 25 7 28 15 6 14 8 14 18 0 8 -11 23 -15
46 -13 29 2 39 10 55 40 15 28 23 34 32 26 10 -8 10 -14 2 -24z m1298 6 c30 0
34 -3 31 -21 -5 -36 -33 -47 -58 -24 -12 11 -26 28 -32 39 -9 16 -7 18 7 13 9
-4 32 -7 52 -7z m956 -61 c16 -7 29 -27 40 -63 24 -74 22 -89 -18 -136 -25
-30 -33 -48 -29 -65 3 -13 -3 -38 -15 -60 -18 -34 -18 -40 -6 -59 22 -30 18
-49 -14 -80 l-29 -28 -4 28 c-2 21 -9 30 -24 32 -27 4 -34 35 -34 153 0 82 2
89 20 89 11 0 29 9 40 20 17 17 20 33 20 100 0 86 4 91 53 69z m-1566 -5 c-3
-3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m-517 -62 c0 -11 -9 -27
-20 -37 -11 -10 -20 -24 -20 -32 0 -7 -12 -18 -26 -23 -35 -14 -44 -12 -38 5
4 11 0 15 -16 15 -20 0 -22 4 -17 36 3 20 8 38 11 41 4 4 54 -8 89 -22 13 -5
17 -2 17 14 0 12 5 21 10 21 6 0 10 -8 10 -18z m8 -207 c-18 -25 -42 -48 -53
-51 -14 -3 -21 -13 -21 -26 1 -15 -16 -33 -56 -61 -48 -33 -58 -45 -58 -69 0
-49 17 -70 50 -63 31 7 42 -4 46 -42 2 -21 1 -21 -12 -8 -9 8 -26 15 -39 15
-19 0 -22 -4 -17 -22 5 -20 3 -21 -39 -14 -24 4 -53 16 -64 27 -20 20 -20 20
2 25 29 8 39 56 24 112 -11 38 -10 43 11 64 15 15 35 22 66 24 36 1 49 7 83
42 22 23 44 42 49 42 5 0 12 11 16 25 4 16 13 25 25 25 16 0 15 -6 -13 -45z
m542 -95 c26 -26 38 -25 42 3 3 22 4 22 18 -4 8 -15 34 -50 58 -79 23 -28 42
-60 42 -70 0 -10 7 -23 15 -30 17 -14 11 -58 -17 -113 -17 -33 -21 -36 -40
-26 -21 12 -38 0 -38 -28 0 -15 -20 -16 -79 -2 -50 11 -80 42 -81 81 0 15 -5
30 -11 34 -7 4 -9 24 -6 54 6 50 -4 70 -34 70 -10 0 -20 -6 -22 -12 -11 -26
-7 40 4 71 7 19 18 31 29 31 10 0 28 9 41 20 31 25 54 25 79 0z m-1006 -40 c5
-28 8 -30 51 -30 l45 0 0 -45 c0 -45 0 -45 -34 -45 -24 0 -39 7 -50 21 -8 12
-25 27 -37 33 -15 7 -26 24 -30 46 -9 42 -5 50 25 50 18 0 25 -7 30 -30z
m1335 -187 c15 -53 14 -63 -9 -63 -15 0 -20 -7 -20 -25 0 -34 -16 -31 -36 6
-17 34 -11 60 23 98 26 28 31 26 42 -16z m-2021 -212 c2 -23 -2 -55 -9 -70
-12 -27 -13 -28 -41 -12 -32 18 -32 36 3 96 27 45 43 40 47 -14z m859 22 c5
-14 -40 -28 -57 -18 -8 5 -8 11 1 21 14 17 51 15 56 -3z m933 -758 c26 -13 30
-21 30 -56 0 -33 -3 -40 -17 -37 -10 2 -21 18 -26 38 -5 19 -13 39 -18 45 -10
10 -12 25 -4 25 4 0 19 -7 35 -15z m138 -135 c14 -21 22 -48 22 -73 0 -37 1
-39 18 -25 32 28 52 30 52 5 0 -18 -9 -25 -44 -36 -53 -15 -66 -10 -66 29 0
17 -10 40 -25 56 -27 29 -32 53 -13 72 17 17 29 11 56 -28z m7 -331 c4 -17 24
-47 46 -67 21 -20 39 -45 39 -55 0 -10 11 -29 25 -43 26 -26 24 -50 -3 -57
-10 -2 -43 9 -74 25 -32 15 -66 28 -78 28 -12 0 -22 9 -26 24 -7 28 1 46 21
46 18 0 25 23 25 81 0 55 15 66 25 18z m-2115 -73 c0 -12 3 -31 6 -43 4 -13 2
-23 -3 -23 -13 0 -46 97 -37 111 9 16 34 -17 34 -45z m180 -172 c0 -10 6 -14
14 -11 8 3 20 -1 26 -9 10 -11 7 -19 -17 -41 -51 -49 -66 -78 -51 -102 7 -12
20 -21 29 -21 24 0 46 -30 52 -74 3 -21 20 -67 37 -103 16 -36 30 -69 30 -74
0 -12 -46 -11 -92 1 -22 6 -48 25 -67 48 -17 20 -38 46 -47 56 -13 15 -15 22
-5 35 6 9 18 31 27 49 13 30 13 38 -1 75 -9 23 -17 62 -18 86 -2 42 0 44 33
56 23 8 34 18 32 29 -2 9 2 16 8 16 5 0 10 -7 10 -16z m2424 -45 c14 -11 26
-23 26 -27 0 -12 -125 -1 -132 11 -23 37 63 50 106 16z"/>
<path d="M4660 3796 c0 -24 -3 -64 -6 -90 l-7 -46 31 0 c23 0 37 9 61 39 17
21 31 44 31 50 0 5 9 15 20 21 25 13 26 29 3 36 -10 4 -32 12 -50 20 -17 8
-43 14 -57 14 -24 0 -26 -3 -26 -44z"/>
<path d="M4443 8783 c-7 -2 -13 -18 -13 -34 0 -22 7 -32 26 -41 22 -11 28 -10
40 7 18 25 18 31 -6 55 -21 21 -26 22 -47 13z"/>
<path d="M7749 8785 c-1 -6 -2 -19 -3 -30 -1 -11 -13 -25 -28 -33 -16 -7 -33
-22 -39 -34 -21 -38 7 -160 46 -202 22 -24 31 -26 103 -26 l79 0 41 49 c35 41
42 57 42 93 0 81 -52 148 -115 148 -13 0 -27 5 -30 10 -3 6 -21 10 -39 10 -17
0 -37 5 -44 12 -7 7 -13 8 -13 3z"/>
<path d="M7308 8709 c-28 -16 -23 -49 6 -49 30 0 57 25 50 45 -7 17 -31 19
-56 4z"/>
<path d="M2665 8640 c-11 -16 -23 -30 -27 -30 -15 0 -8 -59 14 -115 20 -53 21
-62 9 -119 -7 -33 -17 -66 -23 -73 -5 -7 -7 -17 -3 -23 4 -6 8 -21 9 -33 11
-94 36 -121 94 -105 53 14 59 22 65 81 10 93 24 136 48 156 21 17 22 21 10 44
-7 14 -10 30 -6 37 4 6 9 45 11 85 l4 74 -51 25 c-30 16 -68 26 -92 26 -35 0
-45 -5 -62 -30z"/>
<path d="M2348 8580 c3 -32 0 -50 -7 -50 -13 0 -14 -20 -2 -39 7 -11 15 -11
45 -1 21 6 36 15 34 19 -2 3 -14 32 -27 64 -31 71 -47 74 -43 7z"/>
<path d="M4399 8614 c-11 -14 -9 -19 11 -35 65 -52 69 -53 90 -34 27 24 25 40
-6 64 -32 25 -76 27 -95 5z"/>
<path d="M5017 8542 c-18 -19 -24 -82 -8 -82 5 0 6 -20 3 -45 -4 -43 -3 -45
21 -45 44 0 55 49 19 78 -14 11 -14 12 1 12 22 0 31 38 18 73 -12 31 -31 35
-54 9z"/>
<path d="M9191 8491 c-10 -10 -22 -37 -26 -59 -9 -54 17 -81 87 -89 61 -7 78
8 78 71 0 52 -6 60 -54 80 -49 21 -62 20 -85 -3z"/>
<path d="M2230 8474 c0 -10 12 -37 26 -61 19 -34 31 -43 47 -41 48 7 26 48
-55 106 -15 11 -18 11 -18 -4z"/>
<path d="M5516 8329 c-9 -16 -28 -37 -40 -46 l-24 -18 24 -47 c28 -55 70 -88
112 -88 17 0 32 -6 35 -12 3 -9 13 -5 31 11 24 22 26 31 26 102 l0 77 -33 15
c-18 9 -51 20 -74 25 -38 9 -41 8 -57 -19z"/>
<path d="M4824 8265 c-9 -14 -22 -25 -28 -25 -7 0 -17 -19 -23 -42 -10 -37 -9
-46 9 -70 22 -31 74 -39 84 -13 4 8 12 15 20 15 11 0 14 18 14 80 l0 80 -30 0
c-20 0 -35 -8 -46 -25z"/>
<path d="M5095 8278 c-31 -17 -55 -51 -55 -79 0 -29 20 -52 37 -41 20 13 53
77 53 105 0 30 -5 32 -35 15z"/>
<path d="M9123 8283 c-8 -2 -13 -22 -13 -44 0 -51 29 -82 68 -73 38 9 52 31
52 81 l0 43 -47 -1 c-27 0 -54 -3 -60 -6z"/>
<path d="M7327 8274 c-4 -4 -7 -16 -7 -26 0 -14 8 -18 36 -18 31 0 35 3 32 23
-2 15 -11 23 -28 25 -14 2 -29 0 -33 -4z"/>
<path d="M2420 8254 c-14 -7 -21 -14 -16 -14 5 0 1 -9 -8 -19 -15 -17 -16 -24
-5 -60 6 -22 22 -49 33 -61 20 -18 27 -19 78 -8 43 9 58 17 62 32 21 78 -19
147 -82 145 -21 -1 -48 -7 -62 -15z"/>
<path d="M5335 8214 c-23 -55 -20 -61 32 -67 65 -7 73 -2 73 48 0 46 -9 55
-61 55 -24 0 -31 -6 -44 -36z"/>
<path d="M1195 8211 c-59 -21 -85 -34 -85 -44 0 -5 -11 -25 -26 -44 -21 -27
-25 -42 -22 -84 3 -46 7 -55 41 -82 l37 -31 68 13 c70 14 103 35 128 84 12 22
13 39 3 92 -10 60 -14 67 -43 79 -46 19 -79 24 -101 17z"/>
<path d="M1433 8150 c-28 -11 -31 -50 -5 -50 28 0 56 25 49 44 -7 18 -12 19
-44 6z"/>
<path d="M7840 8140 c-98 -9 -143 -28 -160 -70 -7 -16 -20 -32 -30 -35 -10 -3
-39 -28 -64 -55 l-46 -49 0 -90 c0 -67 -7 -119 -29 -203 -38 -146 -38 -145
-82 -199 -97 -117 -113 -209 -48 -272 41 -39 59 -70 59 -97 0 -16 -7 -20 -37
-20 -45 0 -100 -31 -105 -58 -3 -20 -4 -20 -53 -1 -42 16 -54 27 -78 69 -46
84 -108 93 -149 20 -16 -27 -30 -40 -44 -40 -25 0 -34 -18 -34 -63 0 -43 8
-52 60 -75 69 -30 82 -53 84 -138 1 -62 -2 -76 -21 -96 -23 -24 -33 -88 -14
-88 5 0 14 -23 20 -50 17 -80 37 -120 60 -120 17 0 21 9 27 50 6 42 11 52 40
69 58 34 91 110 62 139 -9 9 -3 11 28 10 21 -1 58 2 80 6 40 8 42 7 52 -23 7
-21 18 -31 31 -31 22 0 59 -44 86 -103 10 -21 30 -44 46 -52 37 -20 94 -19
113 1 31 30 15 63 -55 116 -19 14 -57 49 -84 77 -27 28 -56 51 -66 51 -9 0
-22 6 -28 14 -14 17 39 55 118 85 83 31 101 53 101 124 l0 60 44 -5 c26 -3 59
-17 82 -34 22 -15 41 -29 43 -31 11 -7 -24 -62 -47 -74 -32 -17 -42 -28 -42
-51 0 -13 8 -18 28 -18 31 0 72 25 72 44 0 7 16 21 35 31 31 17 42 18 111 8
63 -9 81 -16 98 -37 13 -16 39 -30 71 -37 28 -7 80 -23 117 -37 65 -24 75 -25
167 -6 18 4 59 21 89 38 52 29 58 36 78 94 27 80 58 112 144 151 65 30 74 38
147 138 l77 107 4 86 c3 70 1 88 -12 99 -12 10 -16 31 -16 79 0 76 -25 137
-71 176 -22 19 -29 33 -29 59 0 50 -40 130 -88 176 -40 39 -110 80 -162 96
-14 4 -34 15 -45 25 -32 28 -80 39 -250 55 -166 16 -340 17 -485 5z"/>
<path d="M1546 8091 c-6 -9 -72 -42 -108 -54 -19 -7 -24 -42 -8 -52 6 -3 10
-15 10 -25 0 -28 25 -32 215 -31 156 0 178 2 215 21 52 26 59 45 28 73 -13 12
-31 33 -40 46 -17 24 -18 24 -72 11 -48 -11 -61 -11 -98 3 -47 18 -133 23
-142 8z"/>
<path d="M2539 8039 c-11 -22 -9 -133 3 -145 12 -11 68 8 98 33 37 31 39 70 5
107 -31 34 -89 37 -106 5z"/>
<path d="M5957 8037 c-42 -33 -35 -69 17 -82 33 -8 40 -6 52 10 19 26 17 52
-6 75 -25 25 -29 25 -63 -3z"/>
<path d="M6538 8035 c-17 -38 0 -78 38 -89 l31 -9 -19 -44 c-16 -36 -17 -49
-8 -83 15 -56 59 -126 87 -139 37 -17 133 -14 154 5 10 9 32 23 51 32 49 25
78 75 78 134 0 61 -21 94 -90 138 -48 31 -55 32 -120 28 -58 -4 -73 -9 -89
-29 -25 -31 -33 -22 -21 21 12 43 -1 60 -46 60 -27 0 -37 -5 -46 -25z"/>
<path d="M6392 7988 c-6 -6 -12 -17 -12 -24 0 -6 -7 -17 -15 -24 -27 -23 1
-40 66 -40 l59 0 0 34 c0 18 -6 38 -12 44 -22 17 -73 23 -86 10z"/>
<path d="M2431 7924 c-12 -19 -21 -45 -21 -57 0 -45 -12 -65 -41 -71 -15 -3
-37 -13 -47 -23 -17 -17 -17 -19 2 -53 10 -19 26 -40 34 -47 13 -11 13 -15 -2
-37 -15 -24 -15 -26 4 -36 17 -9 19 -18 14 -73 -3 -41 -1 -70 8 -87 12 -22 11
-27 -10 -45 -21 -18 -25 -33 -30 -96 -3 -51 -11 -81 -23 -98 -11 -13 -19 -34
-19 -47 0 -22 -4 -24 -46 -24 -56 0 -56 -20 0 -33 24 -6 41 -18 48 -32 5 -12
25 -30 44 -38 25 -13 34 -23 34 -42 0 -15 6 -25 14 -25 8 0 21 -3 30 -6 12 -4
16 0 16 16 0 33 16 56 54 78 39 23 76 86 76 129 0 15 11 46 25 68 16 27 24 55
25 86 0 62 -20 79 -94 79 -65 0 -69 5 -58 70 l8 45 48 -3 c62 -3 77 9 65 50
-7 25 -5 34 9 44 23 18 61 17 68 -1 10 -24 43 -18 54 11 18 46 12 84 -13 93
-13 5 -29 16 -36 25 -15 18 -52 21 -68 5 -17 -17 -63 18 -63 47 0 15 -6 24
-15 24 -12 0 -15 13 -15 58 0 88 -41 112 -79 46z"/>
<path d="M2734 7934 c-7 -29 7 -44 44 -44 28 0 37 21 22 49 -16 31 -58 28 -66
-5z"/>
<path d="M5718 7929 c-21 -11 -24 -48 -5 -50 30 -3 53 5 74 24 12 12 23 25 23
30 0 11 -72 8 -92 -4z"/>
<path d="M4302 7878 c-34 -34 1 -82 56 -76 21 2 28 9 30 30 5 42 -57 75 -86
46z"/>
<path d="M3446 7834 c-9 -22 18 -68 61 -103 41 -34 58 -38 66 -18 7 18 -25 89
-52 116 -25 25 -66 28 -75 5z"/>
<path d="M5215 7773 c-28 -7 -43 -29 -38 -58 8 -38 28 -50 70 -39 46 12 65 54
37 85 -17 19 -30 21 -69 12z"/>
<path d="M767 7689 c-45 -29 -62 -59 -34 -59 7 0 22 -18 33 -41 19 -39 21 -40
76 -44 62 -4 98 -18 98 -40 0 -22 21 -18 27 5 4 15 14 20 38 20 27 0 35 5 45
30 16 38 7 60 -25 60 -17 0 -25 5 -25 18 0 10 -15 32 -33 50 -30 29 -39 32
-93 32 -51 0 -68 -5 -107 -31z"/>
<path d="M563 7674 c6 -38 74 -137 106 -154 26 -13 51 -3 51 22 0 37 -60 128
-84 128 -6 0 -17 9 -24 20 -7 11 -22 20 -33 20 -19 0 -21 -4 -16 -36z"/>
<path d="M7290 7640 c0 -5 -6 -10 -14 -10 -10 0 -12 -8 -7 -32 5 -26 12 -34
33 -36 24 -3 28 1 33 32 4 19 4 39 0 45 -8 14 -45 14 -45 1z"/>
<path d="M3436 7475 c-8 -41 -2 -55 24 -55 39 0 50 11 50 51 0 38 -1 39 -34
39 -31 0 -34 -3 -40 -35z"/>
<path d="M4049 7469 c-7 -13 -16 -49 -20 -79 -4 -30 -11 -67 -14 -82 -6 -24
-4 -28 15 -28 13 0 24 8 27 20 4 16 14 20 50 20 73 0 83 11 83 85 0 71 2 69
-82 80 -42 6 -48 5 -59 -16z"/>
<path d="M2650 7448 l-19 -31 25 -29 c13 -16 24 -34 24 -39 0 -19 16 -7 44 32
l28 40 -28 30 c-35 37 -50 37 -74 -3z"/>
<path d="M1180 7429 c-6 -11 -9 -33 -5 -49 9 -42 37 -62 82 -58 35 3 38 6 41
36 4 40 -9 58 -56 78 -45 18 -49 18 -62 -7z"/>
<path d="M5172 7318 c-8 -8 -3 -21 21 -52 52 -67 53 -68 81 -54 36 16 37 65 2
74 -13 3 -26 14 -29 25 -6 22 -55 27 -75 7z"/>
<path d="M5527 7163 c-3 -21 -9 -48 -13 -60 -9 -31 10 -30 42 2 32 32 31 88
-2 93 -18 3 -22 -3 -27 -35z"/>
<path d="M1770 7095 c-67 -17 -87 -26 -95 -43 -5 -12 -13 -22 -17 -22 -5 0 -8
-28 -8 -62 0 -57 4 -67 35 -107 19 -24 35 -51 35 -61 0 -25 70 -101 108 -117
27 -11 57 -13 133 -7 55 3 99 11 99 16 0 5 -4 17 -9 26 -10 18 -4 52 26 164
18 66 13 96 -25 136 -40 43 -71 60 -132 73 -30 6 -57 14 -60 18 -3 3 -43 -3
-90 -14z"/>
<path d="M9053 7013 c-23 -9 -15 -42 11 -49 13 -3 26 -14 29 -25 6 -22 16 -24
42 -5 21 15 15 43 -12 53 -10 4 -24 13 -31 20 -14 14 -19 15 -39 6z"/>
<path d="M6810 6958 c-46 -13 -90 -46 -90 -66 1 -27 46 -76 64 -69 8 3 20 24
26 46 6 22 22 49 35 59 46 37 31 49 -35 30z"/>
<path d="M680 6915 c-14 -8 -42 -14 -62 -15 l-38 0 -2 -52 c-4 -111 11 -158
49 -158 8 0 28 -7 44 -16 25 -13 36 -13 67 -3 64 21 81 39 82 87 0 48 -42 142
-64 142 -7 0 -19 7 -26 15 -15 18 -18 18 -50 0z"/>
<path d="M2657 6838 c-9 -7 -19 -25 -22 -40 -5 -21 -1 -33 19 -53 38 -38 70
-31 74 16 2 23 -3 42 -12 52 -9 9 -16 20 -16 26 0 15 -22 14 -43 -1z"/>
<path d="M6903 6828 c-50 -55 -53 -95 -5 -77 40 15 72 49 72 76 0 31 -39 31
-67 1z"/>
<path d="M6211 6786 c-15 -16 -25 -42 -29 -71 -5 -47 -5 -47 18 -35 43 23 91
79 88 103 -5 33 -48 34 -77 3z"/>
<path d="M883 6753 c-18 -2 -33 -8 -33 -13 0 -5 -7 -16 -16 -24 -9 -10 -13
-24 -9 -36 5 -16 15 -20 49 -20 41 0 43 2 54 39 15 49 15 61 0 59 -7 0 -28 -3
-45 -5z"/>
<path d="M8532 6724 c-25 -17 -31 -84 -9 -101 19 -16 83 -23 96 -10 24 24 12
64 -27 96 -36 28 -40 29 -60 15z"/>
<path d="M6817 6713 c-11 -11 -8 -58 5 -71 29 -29 78 -7 78 35 0 32 -61 59
-83 36z"/>
<path d="M6302 6684 c-31 -22 -32 -104 0 -134 25 -23 56 -26 82 -7 15 11 16
19 7 56 -5 25 -22 56 -38 73 -27 26 -30 27 -51 12z"/>
<path d="M1565 6650 c-32 -12 -47 -36 -43 -67 3 -25 8 -28 42 -31 57 -5 81 4
95 35 12 25 10 30 -14 50 -27 24 -46 27 -80 13z"/>
<path d="M2102 6653 c-35 -3 -42 -7 -38 -21 3 -9 8 -35 11 -57 11 -67 14 -73
56 -89 61 -24 113 -21 128 8 17 31 6 94 -22 129 -24 30 -59 38 -135 30z"/>
<path d="M500 6636 c-18 -23 -8 -61 17 -64 17 -2 26 4 35 23 11 24 10 29 -7
41 -24 18 -30 17 -45 0z"/>
<path d="M5904 6596 c-16 -43 15 -69 51 -42 16 12 18 18 9 33 -14 23 -52 29
-60 9z"/>
<path d="M6776 6538 c-4 -25 -15 -61 -26 -80 -16 -31 -17 -38 -5 -54 13 -18
14 -18 21 1 4 11 12 23 17 26 15 10 67 -11 67 -27 0 -17 36 -27 81 -22 28 2
35 7 37 30 3 22 -2 28 -32 38 -34 11 -36 14 -36 53 0 58 -10 69 -68 75 l-49 4
-7 -44z"/>
<path d="M5783 6511 c-3 -11 -18 -25 -34 -31 -64 -27 -14 -75 54 -53 58 20 87
43 87 68 0 26 -17 35 -67 35 -26 0 -36 -5 -40 -19z"/>
<path d="M5975 6453 c-11 -3 -22 -8 -25 -12 -3 -3 -25 -19 -50 -36 -25 -16
-55 -38 -67 -48 -20 -18 -23 -18 -29 -3 -10 26 -34 19 -46 -15 -10 -28 -14
-30 -41 -25 -38 8 -53 21 -84 69 -24 38 -25 38 -81 35 -50 -3 -57 -6 -60 -25
-3 -17 -10 -23 -29 -23 -29 0 -27 -20 3 -35 11 -5 35 -27 53 -48 41 -46 69
-54 112 -31 18 9 51 17 72 17 34 0 50 -5 107 -33 8 -4 26 -27 38 -51 26 -49
38 -59 67 -59 11 0 28 -9 38 -20 18 -21 76 -26 167 -12 39 6 45 11 53 39 9 28
6 35 -27 67 -51 52 -47 63 22 71 95 10 110 15 142 45 20 19 30 38 30 58 0 42
-21 56 -71 48 -29 -5 -48 -2 -63 8 -17 10 -34 12 -67 6 -33 -6 -48 -5 -60 6
-15 14 -64 17 -104 7z"/>
<path d="M7701 6399 c-19 -8 -21 -12 -11 -24 16 -19 84 -19 100 0 10 12 8 16
-15 25 -31 12 -42 12 -74 -1z"/>
<path d="M7044 6375 c-8 -21 14 -45 41 -45 12 0 30 -9 41 -21 34 -38 68 -50
106 -37 50 17 60 28 54 60 -4 20 -2 28 9 28 9 0 12 6 9 15 -9 22 -252 22 -260
0z"/>
<path d="M6461 6361 c-12 -12 -21 -28 -21 -36 0 -9 -25 -46 -55 -82 -42 -51
-55 -75 -55 -99 0 -18 5 -36 10 -39 6 -3 10 -17 10 -31 0 -28 24 -32 52 -8 14
13 21 14 32 5 12 -10 18 -8 31 13 17 25 22 26 109 26 l93 0 10 47 c7 27 8 56
3 69 -11 28 -71 94 -86 94 -7 0 -14 7 -18 15 -3 8 -16 19 -28 24 -59 22 -67
22 -87 2z"/>
<path d="M6867 6275 c-23 -36 -28 -56 -30 -120 -3 -72 -1 -79 27 -116 17 -21
34 -39 38 -39 4 0 8 -11 8 -25 0 -21 5 -25 29 -25 31 0 62 27 45 38 -6 4 -16
40 -23 79 -8 43 -21 84 -34 100 -14 18 -17 28 -9 31 22 7 58 41 44 42 -10 0
-12 10 -7 40 l7 40 -34 0 c-28 -1 -37 -7 -61 -45z"/>
<path d="M1686 6209 c-22 -10 -46 -19 -53 -19 -21 0 -18 -126 3 -138 19 -10
90 3 111 20 17 14 17 93 -1 110 -8 8 -15 22 -17 30 -4 15 -8 14 -43 -3z"/>
<path d="M913 6189 c-18 -6 -23 -16 -23 -43 0 -45 16 -54 46 -25 27 25 30 38
12 61 -10 12 -19 14 -35 7z"/>
<path d="M7850 6173 c1 -23 79 -109 106 -117 13 -4 26 -1 33 8 27 32 -55 126
-110 126 -21 0 -29 -5 -29 -17z"/>
<path d="M7094 6155 c-5 -20 -16 -28 -49 -37 -39 -11 -44 -15 -48 -47 -6 -43
12 -74 56 -102 70 -43 97 -35 97 28 0 22 6 36 20 43 20 11 30 70 11 70 -4 0
-12 15 -17 33 -7 24 -16 33 -36 35 -22 3 -29 -2 -34 -23z"/>
<path d="M509 6121 l-24 -20 23 -21 c27 -26 142 -42 142 -21 0 24 -21 51 -39
51 -10 0 -24 7 -31 15 -17 20 -43 19 -71 -4z"/>
<path d="M1950 6115 c-13 -15 -10 -25 17 -76 2 -3 -12 -3 -31 -1 -53 6 -76
-12 -76 -58 0 -51 22 -73 64 -65 52 11 66 16 66 26 0 5 20 14 45 20 25 6 45
15 45 20 0 4 -20 11 -45 15 -50 7 -58 25 -20 42 27 12 32 40 15 73 -13 23 -62
26 -80 4z"/>
<path d="M7470 6105 c-8 -9 -49 -40 -92 -68 -54 -35 -81 -59 -85 -76 -8 -33
10 -140 46 -266 16 -55 31 -134 34 -175 6 -79 30 -161 52 -180 23 -19 64 -1
80 35 7 19 25 41 38 50 47 30 66 141 49 276 -6 46 -16 129 -23 184 -8 75 -18
115 -41 160 -38 76 -42 79 -58 60z"/>
<path d="M2197 6083 c-9 -9 -8 -55 1 -70 4 -7 18 -14 31 -15 34 -4 51 45 25
73 -17 19 -45 25 -57 12z"/>
<path d="M188 6037 c-7 -36 1 -47 33 -47 44 0 69 17 69 45 0 23 -4 25 -49 25
-43 0 -49 -3 -53 -23z"/>
<path d="M8090 6020 c0 -24 6 -33 27 -41 37 -14 57 -8 61 18 4 25 -31 53 -65
53 -19 0 -23 -5 -23 -30z"/>
<path d="M6431 5958 c-16 -17 -27 -36 -25 -43 5 -12 72 -45 93 -45 6 0 34 10
61 23 33 15 50 28 50 41 0 14 -5 17 -24 12 -13 -3 -26 -3 -28 2 -4 10 -67 42
-83 42 -8 0 -27 -14 -44 -32z"/>
<path d="M12736 5974 c-27 -26 -20 -44 18 -44 26 0 36 5 41 20 3 11 3 24 0 30
-9 15 -42 12 -59 -6z"/>
<path d="M80 5947 c0 -29 36 -47 93 -47 31 0 57 4 57 8 0 5 -10 18 -23 30 -18
17 -36 22 -75 22 -37 0 -52 -4 -52 -13z"/>
<path d="M6799 5924 c-10 -13 -10 -25 2 -72 12 -51 17 -57 42 -60 l27 -3 0 65
c0 54 -3 67 -19 76 -27 14 -36 13 -52 -6z"/>
<path d="M1080 5860 c0 -30 29 -70 51 -70 18 0 69 33 69 45 0 25 -46 55 -83
55 -34 0 -37 -2 -37 -30z"/>
<path d="M6130 5876 c0 -7 -9 -34 -20 -59 -17 -39 -26 -47 -56 -53 -41 -8 -40
-23 4 -44 17 -8 32 -20 32 -27 0 -6 21 -15 48 -18 26 -4 84 -18 129 -30 122
-35 173 -41 173 -22 0 10 15 21 39 28 39 11 40 14 47 65 8 70 -1 102 -32 116
-23 11 -29 9 -45 -11 -12 -15 -19 -39 -19 -66 0 -35 -4 -45 -21 -50 -13 -4
-25 -1 -32 8 -7 8 -25 18 -40 21 -36 9 -68 60 -60 98 9 43 -29 38 -56 -8 -12
-20 -25 -34 -28 -31 -3 4 0 13 7 22 21 26 -5 75 -41 75 -18 0 -29 -5 -29 -14z"/>
<path d="M2153 5844 c-8 -22 24 -46 57 -42 28 3 40 35 18 49 -23 15 -68 10
-75 -7z"/>
<path d="M12657 5842 c-24 -26 -21 -44 11 -75 42 -40 88 -20 75 33 -13 56 -55
76 -86 42z"/>
<path d="M17 5813 c-14 -4 -17 -13 -15 -36 2 -26 11 -36 46 -56 36 -19 48 -22
68 -12 30 13 30 30 2 75 -23 35 -48 43 -101 29z"/>
<path d="M7070 5785 c-32 -85 15 -225 58 -172 18 22 25 138 10 167 -19 36 -55
38 -68 5z"/>
<path d="M1143 5745 c-37 -16 -53 -42 -53 -84 0 -24 5 -32 24 -37 34 -8 49 2
36 25 -8 15 -5 21 18 30 24 10 62 56 62 75 0 11 -54 5 -87 -9z"/>
<path d="M1982 5738 c-20 -25 -26 -77 -11 -104 15 -29 68 -64 96 -64 32 0 88
80 94 134 4 33 5 32 -101 42 -51 5 -68 3 -78 -8z"/>
<path d="M12570 5700 c-6 -12 -10 -35 -8 -53 2 -27 8 -33 31 -35 34 -4 53 10
62 45 12 51 -61 88 -85 43z"/>
<path d="M6544 5659 c-8 -13 5 -29 32 -39 16 -7 23 -16 21 -31 -1 -15 8 -27
32 -41 32 -18 34 -18 53 -2 23 22 20 53 -7 68 -11 6 -24 16 -30 23 -13 16 -93
34 -101 22z"/>
<path d="M12672 5648 c-18 -18 -15 -68 4 -75 37 -14 70 41 44 73 -15 17 -32
18 -48 2z"/>
<path d="M6784 5509 c9 -57 25 -74 50 -56 27 19 15 58 -25 81 l-32 19 7 -44z"/>
<path d="M23 5491 c-31 -26 -32 -81 -2 -81 30 0 89 30 89 45 0 7 6 15 13 18 9
3 9 7 -2 20 -18 22 -70 21 -98 -2z"/>
<path d="M7151 5354 c-23 -30 -27 -49 -10 -60 14 -8 69 45 69 68 0 27 -35 22
-59 -8z"/>
<path d="M6871 5116 c-7 -8 -11 -27 -9 -43 2 -22 8 -28 28 -28 22 0 25 4 25
40 0 43 -21 58 -44 31z"/>
<path d="M491 5059 c-64 -11 -84 -27 -119 -94 -22 -41 -27 -64 -27 -125 0
-100 19 -132 105 -177 58 -31 71 -34 127 -30 80 6 120 30 161 97 26 44 32 65
32 111 0 59 7 69 75 104 21 11 31 52 17 72 -4 7 -19 14 -32 15 -20 2 -25 -2
-30 -26 -3 -17 -14 -32 -26 -36 -16 -6 -23 -2 -33 20 -25 59 -136 89 -250 69z"/>
<path d="M6451 5041 c-18 -18 -21 -33 -21 -114 0 -112 11 -130 75 -120 44 6
145 58 145 74 0 19 -29 67 -45 72 -9 4 -15 19 -15 36 0 22 -6 31 -22 37 -13 4
-25 11 -28 15 -4 5 -20 11 -37 15 -24 5 -36 1 -52 -15z"/>
<path d="M7556 5034 c-19 -19 -20 -25 -3 -48 23 -32 65 4 52 45 -8 23 -28 24
-49 3z"/>
<path d="M1460 4902 c-19 -9 -43 -32 -54 -50 l-19 -34 29 -24 c25 -21 36 -24
81 -19 78 8 83 11 83 59 0 50 -7 62 -43 75 -36 14 -36 14 -77 -7z"/>
<path d="M6740 4861 c-8 -17 -9 -26 -1 -34 17 -17 14 -77 -4 -77 -20 0 -19
-16 4 -39 27 -27 113 -37 159 -18 21 8 45 18 55 21 22 7 22 45 1 53 -9 3 -25
1 -35 -5 -10 -7 -24 -12 -31 -12 -16 1 -112 88 -126 114 -9 18 -10 18 -22 -3z"/>
<path d="M6025 4771 c-14 -22 -25 -44 -25 -47 0 -4 11 -22 25 -40 14 -18 25
-37 25 -42 0 -5 16 -13 36 -17 28 -5 43 -1 70 17 34 23 46 62 25 84 -5 5 -13
25 -18 44 -9 34 -11 35 -61 38 -50 3 -52 2 -77 -37z"/>
<path d="M1072 4783 c-25 -22 -28 -36 -19 -85 8 -37 9 -38 51 -38 53 0 71 18
71 72 0 62 -57 90 -103 51z"/>
<path d="M7035 4687 c-17 -13 -17 -15 3 -35 16 -16 34 -22 67 -22 41 0 45 2
45 25 0 19 -7 26 -37 34 -49 14 -56 14 -78 -2z"/>
<path d="M6710 4570 c0 -50 1 -52 25 -46 99 25 103 70 7 91 l-32 7 0 -52z"/>
<path d="M6968 4602 c-30 -18 -35 -34 -15 -50 19 -16 76 -24 113 -18 37 7 46
31 15 41 -10 3 -26 14 -36 25 -21 24 -40 24 -77 2z"/>
<path d="M7370 4590 c-11 -11 -17 -26 -14 -34 13 -34 84 -30 84 4 0 51 -34 66
-70 30z"/>
<path d="M1050 4529 c-66 -14 -120 -103 -120 -199 0 -58 43 -174 80 -213 27
-29 35 -32 103 -35 88 -5 110 4 185 77 48 48 65 85 42 93 -5 2 -16 19 -24 40
-14 32 -14 37 -1 42 21 8 19 40 -4 60 -11 10 -21 30 -23 44 -2 17 -10 28 -23
31 -11 2 -30 14 -42 27 -12 13 -28 24 -35 25 -7 0 -33 4 -58 8 -25 4 -61 4
-80 0z"/>
<path d="M1449 4511 c-14 -5 -35 -13 -47 -20 -26 -14 -58 -71 -45 -80 6 -3 15
-21 21 -40 20 -59 38 -71 103 -71 32 0 61 5 64 10 4 6 9 43 12 83 5 72 5 72
-26 92 -17 11 -31 23 -31 28 0 9 -16 8 -51 -2z"/>
<path d="M7068 4414 c34 -31 62 -63 62 -71 0 -10 9 -13 33 -11 31 3 32 5 35
49 l3 46 -48 18 c-26 9 -70 19 -98 22 l-50 4 63 -57z"/>
<path d="M10297 4463 c-12 -12 -7 -50 8 -63 16 -13 30 -7 45 20 15 29 -30 66
-53 43z"/>
<path d="M1946 4425 c-19 -20 -20 -26 -3 -49 8 -11 23 -17 38 -14 20 2 24 8
24 38 0 28 -4 36 -21 38 -12 2 -29 -4 -38 -13z"/>
<path d="M8576 4404 c-10 -9 -16 -33 -16 -60 0 -37 3 -44 19 -44 64 0 98 48
67 95 -19 29 -47 32 -70 9z"/>
<path d="M640 4384 c-14 -37 -12 -52 10 -74 25 -25 62 -26 85 0 26 29 13 67
-31 85 -48 20 -53 19 -64 -11z"/>
<path d="M1662 4397 c-16 -19 -23 -60 -14 -75 5 -8 22 -12 38 -10 25 3 29 8
32 38 3 24 -2 38 -14 47 -22 16 -29 16 -42 0z"/>
<path d="M9640 4375 c-18 -22 1 -39 35 -31 18 5 25 12 23 24 -4 23 -41 27 -58
7z"/>
<path d="M7386 4312 c-3 -5 -24 -12 -46 -15 -69 -9 -73 -17 -67 -132 8 -132
22 -243 51 -385 14 -70 21 -137 18 -171 -4 -55 -3 -56 36 -87 30 -24 50 -32
80 -32 55 0 72 8 72 36 0 35 19 71 45 82 44 20 58 129 30 231 -8 30 -19 127
-25 215 -6 89 -15 175 -21 192 -10 34 -36 49 -96 59 -21 4 -45 9 -55 11 -9 3
-19 1 -22 -4z"/>
<path d="M9467 4173 c-12 -12 -7 -50 8 -63 35 -29 74 20 45 56 -13 15 -42 19
-53 7z"/>
<path d="M7157 4133 c-4 -3 -7 -17 -7 -30 0 -19 5 -23 29 -23 37 0 59 24 41
45 -13 15 -51 20 -63 8z"/>
<path d="M12463 4103 c-8 -3 -13 -25 -13 -61 0 -33 -9 -79 -20 -109 -37 -98
-1 -110 80 -28 54 55 55 57 55 114 0 34 -6 65 -14 74 -13 16 -61 22 -88 10z"/>
<path d="M1503 4028 c-26 -18 -33 -30 -33 -56 0 -51 16 -72 53 -72 46 0 65 18
69 65 3 40 -22 85 -46 85 -6 0 -26 -10 -43 -22z"/>
<path d="M7167 4022 c-16 -18 -16 -20 4 -45 14 -18 30 -27 50 -27 25 0 29 4
29 28 0 36 -10 49 -41 57 -18 4 -30 1 -42 -13z"/>
<path d="M6829 3924 c-6 -8 -17 -14 -23 -14 -27 0 -71 -61 -81 -114 -14 -67
-10 -78 40 -115 31 -24 50 -31 87 -31 45 0 48 2 97 61 46 54 51 66 51 108 0
26 -6 55 -13 65 -7 10 -43 26 -79 36 -58 16 -69 16 -79 4z"/>
<path d="M7137 3903 c-4 -3 -7 -19 -7 -35 0 -28 0 -28 64 -28 36 0 67 4 70 9
16 25 -105 76 -127 54z"/>
<path d="M1333 3830 c-35 -21 -41 -38 -21 -58 29 -29 67 -5 71 44 3 35 -10 39
-50 14z"/>
<path d="M10775 3740 c-5 -16 0 -29 16 -46 29 -31 61 -28 73 9 12 34 2 46 -46
54 -31 4 -37 2 -43 -17z"/>
<path d="M2166 3658 c-23 -33 -20 -38 24 -38 32 0 40 4 40 18 0 21 -18 42 -36
42 -7 0 -20 -10 -28 -22z"/>
<path d="M8137 3674 c-27 -27 12 -62 54 -49 33 11 21 49 -16 53 -17 2 -34 0
-38 -4z"/>
<path d="M1120 3640 c-16 -29 -4 -40 41 -40 31 0 39 4 39 18 0 42 -61 58 -80
22z"/>
<path d="M1410 3624 c-8 -19 -7 -30 1 -41 28 -32 85 9 63 45 -18 29 -52 27
-64 -4z"/>
<path d="M1624 3510 c-21 -21 -106 -29 -152 -13 -61 20 -122 3 -122 -35 0 -23
31 -41 73 -42 31 0 40 4 44 20 7 26 17 25 36 -4 10 -15 29 -25 56 -30 22 -3
46 -13 55 -21 9 -9 34 -15 65 -15 48 0 51 2 51 26 0 19 -8 28 -35 40 -33 13
-48 34 -24 34 6 0 9 10 7 23 -3 24 -36 35 -54 17z"/>
<path d="M11660 3491 c-7 -13 -7 -28 0 -46 8 -21 16 -26 37 -23 30 3 39 23 29
64 -8 30 -50 33 -66 5z"/>
<path d="M1110 3456 c0 -44 18 -74 55 -91 29 -14 38 -15 51 -5 25 22 13 53
-28 73 -21 9 -38 24 -38 32 0 8 -9 15 -20 15 -15 0 -20 -7 -20 -24z"/>
<path d="M7496 3465 c-14 -38 -5 -55 49 -90 66 -42 77 -43 95 -10 11 20 10 28
-3 48 -16 24 -61 50 -108 62 -19 5 -28 2 -33 -10z"/>
<path d="M551 3423 c-6 -21 -7 -41 -2 -46 15 -15 63 -3 78 20 14 21 13 23 -19
43 -18 11 -36 20 -40 20 -4 0 -12 -17 -17 -37z"/>
<path d="M1856 3374 c-12 -30 -7 -134 7 -135 41 -3 123 4 144 12 19 7 22 12
14 28 -8 13 -27 20 -72 25 l-61 7 4 40 c3 31 0 39 -13 39 -9 0 -20 -7 -23 -16z"/>
<path d="M1712 3304 c-20 -24 -27 -26 -65 -19 -98 16 -148 21 -153 14 -6 -10
27 -79 38 -79 4 0 8 -17 8 -39 0 -42 10 -54 55 -66 l30 -8 -31 -8 c-51 -14
-110 -81 -133 -151 -28 -82 -27 -119 6 -204 l27 -72 74 -38 c72 -36 76 -37
121 -25 57 15 100 40 115 65 6 11 19 29 29 40 9 11 20 37 24 58 3 21 10 41 14
44 5 3 9 13 9 23 0 10 7 24 15 31 8 7 15 22 15 34 0 16 11 27 40 40 22 10 40
21 40 25 0 5 -11 25 -25 45 -31 45 -31 51 -3 70 32 22 16 30 -26 12 -27 -11
-36 -21 -36 -39 0 -14 -7 -27 -15 -31 -8 -3 -15 -12 -15 -21 0 -18 -9 -19 -34
0 -17 13 -17 15 -2 37 21 30 20 45 -5 76 -19 24 -20 35 -14 109 6 75 5 82 -14
92 -33 18 -65 12 -89 -15z"/>
<path d="M12016 3307 c-12 -9 -17 -23 -14 -47 3 -30 7 -35 33 -38 47 -6 73 48
39 82 -19 19 -35 20 -58 3z"/>
<path d="M1036 3272 c7 -39 25 -59 61 -69 36 -8 53 3 53 37 0 32 -16 43 -88
60 l-33 8 7 -36z"/>
<path d="M7550 3244 c-9 -22 -8 -31 5 -43 20 -20 38 -3 33 35 -4 37 -25 42
-38 8z"/>
<path d="M1215 3168 c0 -18 -4 -43 -8 -55 -7 -19 -5 -23 13 -23 13 0 24 -8 27
-20 3 -13 17 -22 39 -26 51 -9 64 0 64 44 0 41 -14 62 -41 62 -9 0 -24 11 -33
25 -9 14 -26 25 -39 25 -18 0 -22 -6 -22 -32z"/>
<path d="M0 3152 c0 -40 2 -42 40 -52 22 -6 75 -10 118 -8 74 3 77 4 80 28 4
39 -39 59 -147 67 l-91 6 0 -41z"/>
<path d="M580 3112 c0 -71 57 -92 102 -38 9 11 17 33 18 49 0 26 -1 27 -60 27
l-60 0 0 -38z"/>
<path d="M382 3098 c-20 -20 -15 -66 9 -79 41 -21 97 42 69 76 -15 18 -61 20
-78 3z"/>
<path d="M8220 2960 c0 -17 47 -60 65 -60 21 0 19 33 -3 53 -21 19 -62 23 -62
7z"/>
<path d="M3236 2884 c-3 -9 -6 -21 -6 -28 0 -19 66 -46 114 -46 24 0 58 -5 76
-12 31 -10 35 -9 61 23 16 19 27 39 25 44 -1 6 -22 14 -45 19 -34 7 -49 6 -70
-7 -31 -18 -104 -13 -122 9 -15 18 -25 18 -33 -2z"/>
<path d="M728 2857 c-31 -12 -35 -36 -18 -97 8 -28 18 -38 54 -53 37 -15 48
-16 65 -5 18 11 21 23 21 80 0 56 -3 69 -19 78 -24 12 -67 11 -103 -3z"/>
<path d="M5517 2849 c-47 -36 -25 -89 37 -89 55 0 85 50 49 82 -32 28 -58 30
-86 7z"/>
<path d="M5163 2853 c-14 -3 -23 -12 -23 -23 0 -11 -9 -24 -20 -30 -14 -7 -20
-21 -20 -43 1 -87 58 -112 116 -49 28 31 34 45 34 84 0 26 -3 49 -7 51 -16 8
-59 14 -80 10z"/>
<path d="M4024 2801 c-18 -11 -35 -24 -39 -30 -4 -7 -21 -9 -46 -5 -35 6 -39
4 -39 -15 0 -11 4 -21 10 -21 5 0 18 -10 29 -22 14 -15 39 -24 82 -30 82 -11
91 -3 87 76 -3 57 -5 61 -28 64 -14 1 -39 -6 -56 -17z"/>
<path d="M5876 2765 c-10 -15 -20 -41 -23 -59 -5 -33 -5 -33 45 -39 27 -3 65
-12 84 -20 l35 -15 -4 -79 c-4 -78 -3 -79 31 -112 32 -31 36 -32 63 -19 33 16
44 66 18 91 -12 13 -13 20 -3 42 10 22 8 29 -15 53 -22 23 -25 33 -20 72 4 32
1 49 -8 57 -18 14 -99 41 -147 49 -34 5 -40 3 -56 -21z"/>
<path d="M10011 2769 c-40 -43 -46 -139 -8 -139 7 0 31 18 55 40 46 43 54 76
24 103 -25 23 -47 21 -71 -4z"/>
<path d="M5728 2738 c-8 -7 -18 -20 -21 -29 -7 -20 28 -79 45 -79 45 1 65 72
30 103 -22 20 -33 21 -54 5z"/>
<path d="M3398 2699 c-10 -5 -18 -15 -18 -22 0 -7 -10 -31 -22 -53 -20 -38
-21 -46 -9 -83 14 -48 54 -80 79 -65 19 12 49 100 57 170 7 48 5 52 -17 57
-32 9 -50 8 -70 -4z"/>
<path d="M1144 2685 c-108 -19 -143 -34 -191 -78 l-44 -40 7 -56 c18 -155 128
-280 245 -281 13 0 31 -5 39 -10 19 -12 92 57 122 114 26 50 35 147 18 184
-32 70 -46 96 -55 106 -6 6 -21 26 -34 44 -12 17 -25 32 -29 31 -4 0 -39 -7
-78 -14z"/>
<path d="M3540 2661 c0 -6 5 -13 10 -16 6 -3 10 -26 10 -50 0 -51 16 -65 78
-65 26 0 45 6 57 20 10 11 26 20 35 20 24 0 40 21 40 53 0 23 -3 28 -17 23 -9
-4 -35 -9 -58 -12 -32 -5 -44 -2 -59 15 -20 22 -96 31 -96 12z"/>
<path d="M6872 2648 c-14 -14 -16 -44 -4 -52 5 -3 18 -2 30 1 29 7 32 63 4 63
-10 0 -23 -5 -30 -12z"/>
<path d="M1393 2578 c-13 -14 -23 -34 -23 -47 0 -19 4 -22 32 -19 26 2 33 -1
36 -17 5 -25 51 -75 70 -75 26 0 40 30 29 61 -12 30 -44 49 -81 49 -19 0 -26
7 -33 36 l-8 36 -22 -24z"/>
<path d="M5230 2570 c0 -5 -7 -10 -15 -10 -18 0 -18 0 -2 -37 20 -49 61 -103
78 -103 16 0 59 70 59 97 0 11 -7 14 -24 9 -14 -3 -36 -2 -51 4 -22 8 -24 13
-15 30 9 17 8 20 -10 20 -11 0 -20 -4 -20 -10z"/>
<path d="M1794 2535 c-26 -63 -21 -75 34 -75 56 0 82 20 82 63 0 31 -24 47
-70 47 -26 0 -33 -6 -46 -35z"/>
<path d="M3835 2551 c-3 -10 0 -30 6 -45 10 -22 19 -26 52 -26 23 0 48 7 58
16 18 16 18 17 -4 33 -27 21 -73 41 -92 41 -7 0 -16 -8 -20 -19z"/>
<path d="M2140 2500 c0 -5 -5 -10 -11 -10 -18 0 -79 -63 -79 -81 0 -9 -9 -19
-20 -22 -12 -3 -20 -14 -20 -27 0 -18 4 -20 23 -14 55 18 63 13 91 -58 12 -31
20 -38 42 -38 27 0 27 0 21 59 -3 33 -12 74 -20 90 -11 21 -13 41 -7 65 4 19
5 37 2 40 -9 9 -22 7 -22 -4z"/>
<path d="M3498 2472 c-41 -30 -45 -39 -25 -66 13 -18 19 -18 83 -6 38 8 71 15
73 17 7 5 -42 53 -55 53 -6 0 -14 7 -18 15 -7 20 -14 18 -58 -13z"/>
<path d="M540 2471 c-20 -5 -26 -14 -28 -45 l-3 -39 48 7 c27 3 53 13 61 23
34 46 -6 74 -78 54z"/>
<path d="M3770 2463 c-31 -12 -40 -24 -30 -43 13 -24 60 -38 145 -42 60 -3 70
-1 73 15 5 25 -16 47 -43 47 -12 0 -28 7 -35 15 -12 15 -78 20 -110 8z"/>
<path d="M4072 2437 c-27 -29 -28 -43 -3 -57 43 -23 79 27 44 61 -19 19 -19
19 -41 -4z"/>
<path d="M1940 2410 c-14 -35 -26 -38 -33 -10 -4 16 -14 20 -52 20 -51 0 -51
1 -18 -80 11 -27 16 -32 23 -20 8 12 10 11 10 -6 0 -38 49 -38 50 -1 0 4 13
13 30 18 49 18 62 109 15 109 -7 0 -18 -13 -25 -30z"/>
<path d="M5596 2349 c-37 -29 -32 -66 12 -89 18 -10 54 -38 81 -63 27 -26 67
-51 90 -58 23 -7 46 -18 52 -26 13 -15 115 -18 124 -3 7 11 -30 50 -48 50 -7
0 -61 47 -119 105 -116 115 -139 125 -192 84z"/>
<path d="M7198 2354 c-29 -15 -36 -42 -16 -62 13 -13 75 -16 102 -6 23 9 19
50 -6 68 -28 20 -45 20 -80 0z"/>
<path d="M6054 2345 c-4 -8 -3 -26 0 -40 9 -35 56 -36 56 -1 0 31 -47 65 -56
41z"/>
<path d="M3818 2334 c-17 -16 -6 -32 42 -58 56 -30 106 -32 124 -4 19 30 -19
55 -94 63 -36 4 -68 3 -72 -1z"/>
<path d="M3567 2293 c-16 -3 -44 -15 -63 -28 -19 -13 -52 -26 -74 -30 -47 -8
-47 -11 -24 -114 21 -94 43 -113 128 -108 l61 3 17 45 c9 24 17 67 17 95 1 38
6 56 21 69 11 10 20 24 20 31 0 13 -51 45 -67 43 -4 -1 -21 -4 -36 -6z"/>
<path d="M9166 2275 c-8 -23 4 -45 24 -45 25 0 33 16 20 40 -13 24 -35 26 -44
5z"/>
<path d="M1433 2260 c-26 -11 -31 -45 -7 -54 26 -10 40 -7 64 12 21 17 21 19
5 35 -18 19 -29 20 -62 7z"/>
<path d="M6436 2241 c-4 -6 -14 -11 -22 -11 -27 0 -73 -52 -80 -89 -4 -20 -11
-39 -16 -42 -11 -7 17 -149 38 -190 21 -42 100 -89 148 -89 107 0 217 76 231
158 12 70 -24 183 -75 233 -28 29 -36 31 -124 36 -64 4 -95 2 -100 -6z"/>
<path d="M3721 2216 c-9 -11 -7 -27 12 -73 19 -48 27 -58 48 -58 34 0 48 55
26 108 -13 31 -21 37 -45 37 -16 0 -35 -6 -41 -14z"/>
<path d="M8980 2205 c-8 -9 -22 -12 -41 -8 -24 4 -33 1 -46 -17 -12 -17 -22
-21 -43 -17 -15 3 -32 1 -39 -6 -28 -28 1 -107 39 -107 11 0 27 11 36 25 9 14
25 25 36 25 10 0 29 11 42 25 19 20 29 24 55 19 31 -6 31 -6 31 35 0 39 -2 41
-29 41 -16 0 -34 -7 -41 -15z"/>
<path d="M6080 2167 c0 -47 18 -77 46 -77 9 0 14 -11 14 -34 0 -40 21 -66 53
-66 20 0 22 4 19 46 -2 25 -10 48 -17 50 -9 4 -11 18 -8 42 6 45 -14 67 -69
77 l-38 7 0 -45z"/>
<path d="M1306 2175 c-51 -18 -63 -30 -46 -50 7 -8 18 -15 25 -15 7 0 15 -7
19 -15 6 -18 50 -20 95 -4 22 8 31 17 31 34 0 21 -14 34 -33 31 -5 0 -13 7
-18 17 -12 20 -21 21 -73 2z"/>
<path d="M1534 2166 c-12 -50 53 -207 89 -214 27 -5 44 14 62 69 20 60 15 78
-38 129 -51 49 -103 56 -113 16z"/>
<path d="M3873 2160 c-15 -23 -3 -64 22 -74 9 -3 34 -6 56 -6 35 0 39 3 39 25
0 14 -8 29 -20 35 -11 6 -20 17 -20 25 0 23 -62 19 -77 -5z"/>
<path d="M910 2133 c-30 -11 -50 -37 -50 -63 0 -27 1 -28 84 -35 102 -8 126
-2 126 31 0 13 -5 24 -11 24 -6 0 -24 11 -41 25 -28 24 -72 31 -108 18z"/>
<path d="M8517 2033 c-4 -3 -7 -19 -7 -34 0 -32 28 -46 60 -29 11 6 20 7 20 3
0 -4 5 -2 12 5 21 21 -10 62 -47 62 -17 0 -35 -3 -38 -7z"/>
<path d="M1195 2016 c-29 -8 -41 -18 -43 -33 -3 -18 5 -24 47 -37 69 -20 91
-34 91 -56 0 -10 4 -21 9 -24 17 -11 42 6 40 28 -1 11 1 28 5 37 11 25 -31 89
-59 89 -13 0 -29 2 -37 4 -7 3 -31 -1 -53 -8z"/>
<path d="M4600 2020 c-18 -11 -5 -57 21 -70 28 -16 49 -5 49 23 0 35 -43 64
-70 47z"/>
<path d="M3950 1972 c0 -38 44 -62 68 -38 25 25 1 66 -38 66 -26 0 -30 -3 -30
-28z"/>
<path d="M5806 1975 c-3 -9 -22 -23 -42 -31 -36 -15 -37 -15 -59 12 -17 22
-27 26 -48 20 -26 -6 -26 -7 -14 -39 11 -25 21 -33 47 -37 33 -5 35 -8 57 -80
13 -41 23 -87 23 -102 0 -39 32 -68 76 -68 19 0 44 -5 55 -11 25 -13 46 2 51
34 2 14 11 23 25 25 17 3 30 -9 59 -53 39 -59 76 -85 117 -85 28 0 37 16 37
66 0 32 -28 74 -49 74 -5 0 -12 9 -16 20 -3 10 -20 26 -37 35 -28 13 -33 13
-48 0 -21 -19 -30 -19 -30 -1 0 22 -45 66 -67 66 -40 0 -52 21 -55 95 l-3 70
-36 3 c-26 2 -38 -1 -43 -13z"/>
<path d="M3308 1943 c-22 -6 -22 -4 1 -56 11 -23 24 -58 29 -77 7 -28 13 -35
33 -35 25 0 42 25 54 81 5 28 3 32 -18 38 -15 3 -27 16 -31 31 -6 25 -24 30
-68 18z"/>
<path d="M8362 1938 c-13 -13 -16 -54 -4 -61 4 -3 22 -8 40 -12 42 -9 52 13
22 55 -23 32 -39 37 -58 18z"/>
<path d="M1596 1911 c-16 -17 -16 -23 -4 -47 16 -33 40 -49 62 -41 25 10 19
50 -12 80 -28 27 -29 27 -46 8z"/>
<path d="M788 1899 c-10 -5 -18 -16 -18 -24 0 -7 -12 -27 -26 -44 -14 -17 -24
-39 -22 -48 3 -16 13 -18 78 -15 84 4 136 28 146 67 8 31 -1 44 -42 61 -39 16
-92 18 -116 3z"/>
<path d="M3951 1889 c-54 -10 -64 -19 -56 -50 5 -21 12 -24 62 -26 63 -2 93 9
93 35 -1 41 -32 54 -99 41z"/>
<path d="M3820 1883 c0 -7 -10 -13 -22 -15 -34 -5 -38 -68 -7 -97 27 -25 42
-26 74 -5 35 23 31 54 -10 94 -19 19 -35 29 -35 23z"/>
<path d="M2548 1809 c-26 -15 -22 -53 7 -69 20 -11 27 -11 40 0 21 18 19 57
-4 70 -23 12 -22 12 -43 -1z"/>
<path d="M7930 1797 c-31 -16 -38 -46 -14 -64 43 -31 87 5 65 53 -12 27 -17
28 -51 11z"/>
<path d="M6203 1793 c-41 -8 -47 -29 -20 -65 13 -18 31 -51 38 -73 7 -22 16
-44 20 -49 4 -4 36 -8 72 -8 106 0 131 29 111 130 -5 25 -15 33 -61 51 -51 19
-107 24 -160 14z"/>
<path d="M1627 1743 c-13 -12 -7 -79 9 -101 19 -28 40 -28 54 -1 14 26 6 63
-20 89 -21 21 -32 24 -43 13z"/>
<path d="M1249 1710 c-9 -5 -24 -21 -33 -35 -14 -21 -15 -29 -5 -41 14 -17 56
-18 86 -1 28 16 66 64 55 71 -19 11 -88 16 -103 6z"/>
<path d="M4735 1671 l-50 -26 -2 -55 c-3 -54 -2 -56 34 -78 28 -18 46 -23 73
-18 43 7 56 23 71 92 11 48 10 56 -6 74 -10 11 -26 20 -35 20 -9 0 -20 4 -25
9 -6 5 -31 -3 -60 -18z"/>
<path d="M7444 1686 c-48 -21 -47 -38 3 -105 47 -64 53 -67 126 -55 37 7 37 7
37 55 0 45 -3 50 -46 84 -51 38 -72 42 -120 21z"/>
<path d="M3320 1643 c0 -58 22 -152 38 -158 22 -8 81 46 92 85 15 52 13 90 -5
90 -8 0 -15 5 -15 10 0 15 -26 12 -40 -5 -12 -14 -15 -14 -30 0 -27 25 -40 18
-40 -22z"/>
<path d="M5717 1573 c-4 -3 -7 -19 -7 -34 0 -24 6 -30 37 -38 50 -14 62 -1 40
44 -12 25 -23 35 -40 35 -13 0 -27 -3 -30 -7z"/>
<path d="M6181 1505 c-39 -33 -43 -90 -9 -135 19 -26 28 -30 69 -30 76 0 96
14 110 77 11 49 11 56 -8 81 -17 23 -29 27 -75 30 -48 3 -60 -1 -87 -23z"/>
<path d="M7202 1518 c-7 -7 -12 -27 -12 -45 0 -31 2 -33 33 -33 38 0 57 15 57
45 0 27 -18 45 -45 45 -12 0 -26 -5 -33 -12z"/>
<path d="M3458 1489 c-38 -22 -6 -59 33 -39 22 12 24 21 7 38 -14 14 -18 14
-40 1z"/>
<path d="M4796 1444 c-29 -29 -15 -64 25 -64 22 0 30 6 35 25 11 45 -29 71
-60 39z"/>
<path d="M5208 1437 c-9 -6 -23 -24 -32 -38 -15 -23 -15 -28 -1 -39 12 -10 21
-9 50 4 39 19 45 44 17 69 -15 14 -21 14 -34 4z"/>
<path d="M2498 1322 c-9 -2 -20 -12 -23 -22 -3 -10 -15 -22 -26 -25 -25 -8
-24 -17 5 -52 13 -16 26 -42 28 -58 4 -34 28 -47 28 -15 0 13 11 27 30 38 16
9 30 22 29 27 0 6 -12 33 -27 60 -19 36 -32 50 -44 47z"/>
<path d="M4290 1299 c0 -21 12 -37 52 -69 28 -22 59 -39 67 -38 9 2 21 19 27
38 6 19 16 36 23 38 6 2 11 16 11 30 l0 27 -90 1 -90 1 0 -28z"/>
<path d="M6146 1268 c-33 -46 -12 -101 38 -104 11 0 31 -4 43 -8 26 -8 43 16
43 63 0 64 -89 99 -124 49z"/>
<path d="M5055 1240 c-12 -19 24 -62 48 -58 25 3 34 32 17 53 -14 17 -56 20
-65 5z"/>
<path d="M4196 1214 c-17 -45 3 -77 33 -50 10 9 28 16 40 16 17 0 22 4 17 16
-3 9 -6 20 -6 25 0 16 -77 10 -84 -7z"/>
<path d="M5684 1135 c-7 -42 0 -54 24 -39 22 14 28 54 10 65 -22 14 -27 10
-34 -26z"/>
<path d="M6823 1153 c-3 -7 -2 -24 1 -38 5 -20 13 -25 37 -25 29 0 30 2 27 37
-3 32 -6 36 -32 38 -15 0 -30 -5 -33 -12z"/>
<path d="M1138 1083 c-24 -28 -27 -47 -13 -81 17 -38 57 -52 94 -31 32 18 42
87 16 113 -22 21 -78 21 -97 -1z"/>
<path d="M2768 1009 c-43 -22 -48 -44 -21 -90 15 -25 23 -29 63 -29 51 0 90
22 90 50 0 32 -21 67 -47 79 -32 15 -41 14 -85 -10z"/>
<path d="M5710 989 c-16 -28 3 -49 44 -49 31 0 32 1 28 35 -4 31 -8 35 -33 35
-18 0 -32 -7 -39 -21z"/>
<path d="M4133 974 c-16 -25 -16 -27 1 -40 21 -15 56 -3 56 21 0 48 -30 59
-57 19z"/>
<path d="M4840 925 c0 -20 5 -25 24 -25 30 0 42 16 26 35 -21 25 -50 19 -50
-10z"/>
<path d="M1978 804 c-12 -11 5 -44 22 -44 19 0 34 25 24 41 -6 10 -37 12 -46
3z"/>
<path d="M3733 647 c-18 -12 -37 -35 -43 -52 -10 -27 -8 -32 20 -57 23 -22 40
-28 74 -28 42 0 50 4 84 44 19 24 -14 84 -56 102 -42 17 -39 17 -79 -9z"/>
<path d="M2107 654 c-13 -13 -7 -52 9 -58 22 -9 46 13 42 38 -3 21 -37 34 -51
20z"/>
<path d="M8572 603 c3 -29 32 -43 50 -25 15 15 -9 52 -33 52 -16 0 -20 -5 -17
-27z"/>
<path d="M2068 559 c-10 -5 -18 -18 -18 -28 0 -24 34 -54 57 -49 20 4 39 48
30 72 -7 18 -43 21 -69 5z"/>
<path d="M2580 340 c0 -94 1 -100 20 -100 23 0 35 24 45 94 9 61 -8 106 -41
106 -24 0 -24 -1 -24 -100z"/>
<path d="M1762 418 c-21 -21 -14 -57 13 -70 34 -15 57 5 53 46 -2 25 -8 32
-28 34 -14 2 -31 -3 -38 -10z"/>
<path d="M2590 90 c-18 -16 -21 -25 -16 -55 7 -35 8 -36 49 -33 l42 3 3 53 c3
51 3 52 -26 52 -16 0 -39 -9 -52 -20z"/>
<path d="M2287 83 c-14 -14 -6 -53 13 -63 26 -14 40 -5 40 24 0 34 -34 59 -53
39z"/>
</g>
</svg>
    )
    }
export default SplatterNine;