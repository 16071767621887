import React from 'react';

function SplatterOne(props) {
    return(
        <svg style={props.stylez} version="1.0" xmlns="http://www.w3.org/2000/svg"
    width="128.000000pt" height="128.000000pt" viewBox="0 0 1280.000000 1280.000000"
    preserveAspectRatio="xMidYMid meet">
    <metadata>
    Created by potrace 1.15, written by Peter Selinger 2001-2017
    </metadata>
    <g  transform="translate(0.000000,1280.000000) scale(0.100000,-0.10000)">
    <path d="M8420 11580 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5
    0 -10 -4 -10 -10z"/>
    <path d="M3648 11312 c-9 -2 -19 -10 -22 -18 -6 -16 10 -19 19 -3 4 6 13 8 19
    6 7 -3 18 -2 25 3 15 9 -14 18 -41 12z"/>
    <path d="M2592 11234 c-27 -18 -29 -34 -4 -34 21 0 42 18 42 37 0 17 -11 16
    -38 -3z"/>
    <path d="M4798 10941 c-10 -15 -23 -19 -45 -16 -31 4 -54 -17 -33 -30 6 -4 7
    -17 4 -31 -3 -13 -2 -24 4 -24 5 0 13 11 16 25 5 18 15 27 36 32 21 4 32 13
    36 30 8 32 -1 39 -18 14z"/>
    <path d="M4380 10569 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0
    -10 -5 -10 -11z"/>
    <path d="M10120 10555 c0 -8 7 -15 15 -15 8 0 15 7 15 15 0 8 -7 15 -15 15 -8
    0 -15 -7 -15 -15z"/>
    <path d="M8400 10540 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5
    0 -10 -4 -10 -10z"/>
    <path d="M9325 10170 c-3 -5 -2 -10 4 -10 5 0 13 5 16 10 3 6 2 10 -4 10 -5 0
    -13 -4 -16 -10z"/>
    <path d="M5530 9990 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
    -10 -4 -10 -10z"/>
    <path d="M8140 9300 c-11 -11 -20 -26 -20 -33 0 -8 -30 -42 -67 -76 -38 -34
    -87 -79 -111 -101 -23 -22 -60 -49 -82 -60 -22 -11 -40 -25 -40 -30 0 -14 8
    -13 77 10 68 24 95 45 122 98 31 61 90 111 129 110 57 -2 92 51 56 86 -21 21
    -40 20 -64 -4z"/>
    <path d="M6345 9300 c-3 -5 1 -10 10 -10 9 0 13 5 10 10 -3 6 -8 10 -10 10 -2
    0 -7 -4 -10 -10z"/>
    <path d="M7830 9270 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
    -10 -4 -10 -10z"/>
    <path d="M10305 8990 c4 -6 11 -8 16 -5 14 9 11 15 -7 15 -8 0 -12 -5 -9 -10z"/>
    <path d="M7677 8913 c-10 -9 -9 -23 2 -23 5 0 11 7 15 15 5 15 -5 20 -17 8z"/>
    <path d="M6533 8898 c-28 -14 -30 -28 -3 -21 26 7 27 -18 1 -41 -23 -21 -40
    -22 -137 -11 -50 5 -73 4 -78 -4 -4 -6 -14 -11 -23 -11 -8 0 -29 -6 -46 -14
    -18 -8 -43 -17 -57 -20 -14 -4 -50 -14 -80 -22 -38 -11 -114 -17 -244 -20
    -178 -4 -191 -3 -208 16 -10 10 -18 28 -18 39 0 24 -18 31 -50 19 -38 -14 -69
    -47 -101 -108 -36 -70 -44 -79 -103 -124 -55 -42 -80 -70 -91 -104 -4 -13 -29
    -49 -57 -79 -42 -46 -49 -60 -43 -82 3 -14 8 -42 9 -61 2 -19 4 -37 5 -40 0
    -4 16 -11 34 -18 18 -7 43 -25 55 -42 21 -28 21 -32 7 -53 -8 -13 -26 -29 -40
    -35 -14 -6 -25 -16 -25 -22 0 -6 -6 -10 -12 -8 -25 4 -56 -13 -62 -35 l-6 -22
    -14 21 c-13 18 -29 22 -96 27 -54 3 -87 11 -99 21 -10 9 -23 16 -29 16 -20 0
    -55 23 -85 55 -27 29 -28 29 -34 7 -7 -28 -26 -15 -49 34 -9 19 -20 34 -25 34
    -5 0 -9 10 -9 21 0 12 -17 39 -39 60 -39 39 -56 47 -63 29 -1 -5 -20 -11 -41
    -13 -45 -4 -92 -45 -107 -93 -28 -84 -16 -154 37 -219 13 -16 42 -54 64 -83
    41 -54 174 -167 216 -183 13 -5 23 -13 23 -19 0 -5 7 -10 15 -10 12 0 78 -26
    94 -37 3 -2 -13 -21 -34 -43 -37 -37 -44 -58 -48 -134 -2 -28 0 -30 36 -31 22
    -1 42 -6 45 -12 4 -5 15 -13 26 -16 15 -6 16 -10 5 -23 -27 -32 -46 -35 -70
    -10 -30 31 -39 25 -39 -24 0 -39 -1 -40 -80 -86 -44 -25 -82 -49 -85 -54 -10
    -16 54 8 87 33 79 59 112 63 153 17 27 -30 61 -46 115 -54 24 -4 53 -15 64
    -24 18 -16 19 -16 13 0 -11 31 64 46 103 21 25 -16 25 -16 8 4 -10 11 -18 28
    -18 39 0 14 14 4 53 -38 29 -32 58 -58 63 -58 6 0 29 -23 52 -52 41 -49 42
    -52 25 -71 -16 -18 -16 -20 -1 -14 15 5 15 2 4 -36 -16 -55 -47 -70 -140 -68
    -83 2 -82 -17 1 -22 49 -3 83 -19 83 -38 0 -5 -12 -9 -27 -10 -27 0 -28 0 -5
    -10 13 -5 21 -12 18 -17 -2 -4 17 -7 42 -6 26 0 55 -3 65 -8 16 -8 16 -11 -3
    -48 -23 -45 -24 -50 -10 -50 6 0 10 8 10 18 0 9 9 28 20 42 23 30 26 23 33
    -76 7 -87 27 -137 75 -187 35 -36 39 -38 82 -32 55 8 70 20 70 54 0 42 24 66
    91 91 82 31 141 88 159 155 16 61 28 76 41 53 16 -29 29 -20 23 15 -6 28 -5
    30 7 16 17 -20 94 -69 109 -69 24 0 115 -114 104 -131 -4 -8 -3 -9 4 -5 14 8
    69 -50 78 -81 3 -13 11 -23 18 -23 13 0 56 -86 56 -111 0 -19 27 -45 36 -36 7
    7 3 17 -24 62 -21 35 -33 65 -26 65 2 0 20 -15 38 -32 73 -72 109 -101 59 -48
    -90 95 -59 90 59 -8 64 -54 131 -132 113 -132 -8 0 -50 44 -50 52 0 3 -4 10
    -9 15 -5 5 -6 3 -2 -4 11 -20 -5 -15 -31 9 -36 34 -27 6 22 -65 60 -88 61
    -115 2 -59 l-30 27 18 -27 c9 -16 21 -28 24 -28 4 0 14 -11 21 -25 12 -22 14
    -23 25 -8 7 9 17 13 24 8 10 -6 10 -2 0 16 -7 13 -12 25 -11 26 1 2 -1 10 -5
    18 -12 33 6 25 33 -14 16 -22 33 -41 39 -41 5 0 4 5 -3 12 -17 17 -15 33 2 19
    8 -6 18 -11 22 -9 11 3 57 -22 66 -36 16 -24 6 -26 -24 -5 l-33 22 34 -36 c43
    -45 26 -50 -18 -5 -49 50 -36 19 14 -35 40 -42 44 -50 33 -66 -27 -37 -176
    -160 -202 -167 -39 -9 -62 -48 -48 -79 23 -50 92 -18 92 42 0 12 15 37 34 55
    18 18 65 63 103 100 l69 68 75 6 c80 7 107 17 127 46 19 26 44 22 77 -12 33
    -34 38 -36 59 -15 18 19 39 11 57 -21 7 -14 26 -37 42 -50 30 -26 72 -99 82
    -145 4 -16 25 -44 48 -65 23 -20 71 -65 107 -99 36 -34 72 -65 80 -70 17 -9
    -64 78 -122 133 -21 19 -38 38 -38 44 0 5 -5 14 -10 21 -6 7 -31 43 -55 82
    -36 55 -43 72 -33 82 16 15 68 18 125 8 35 -6 44 -4 49 9 5 13 14 6 40 -31 18
    -26 60 -64 91 -85 77 -51 207 -186 267 -278 27 -41 72 -104 99 -139 63 -82
    107 -174 113 -231 4 -47 38 -90 71 -90 9 0 25 -7 35 -17 11 -10 18 -12 18 -4
    0 6 -6 14 -14 17 -11 4 -10 11 3 40 8 18 21 34 28 34 7 0 24 -18 38 -40 32
    -51 44 -53 14 -2 -33 54 -100 142 -110 142 -4 0 -11 -4 -14 -10 -3 -5 -16 -10
    -28 -9 -16 0 -35 20 -70 72 -126 191 -209 303 -284 383 -51 56 -77 91 -70 95
    7 4 34 10 60 13 43 7 52 4 85 -21 20 -15 53 -39 73 -53 21 -14 61 -43 91 -66
    29 -22 73 -50 98 -62 67 -32 160 -109 160 -132 0 -10 18 -34 40 -52 23 -17 50
    -47 62 -66 24 -39 101 -98 187 -143 42 -22 80 -54 123 -103 34 -39 79 -87 100
    -106 20 -19 52 -62 69 -95 41 -78 83 -123 138 -146 24 -11 58 -31 75 -46 43
    -36 94 -56 141 -54 36 1 46 -4 85 -45 25 -25 69 -56 98 -69 32 -15 52 -30 52
    -40 0 -9 6 -15 13 -12 22 8 66 -22 91 -62 14 -21 41 -46 63 -56 21 -10 61 -41
    90 -70 40 -41 62 -55 92 -60 50 -8 121 3 121 19 0 6 -22 34 -49 60 -49 49 -50
    49 -119 50 -64 1 -72 4 -91 28 -22 28 -73 58 -98 58 -8 0 -21 6 -28 13 -8 6
    -32 26 -55 42 -23 17 -50 47 -61 67 -10 20 -29 40 -41 43 -51 15 -107 49 -119
    72 -7 13 -27 31 -44 39 -47 22 -141 109 -166 153 -12 21 -42 51 -67 67 -76 48
    -92 67 -99 120 -3 26 -18 68 -33 93 -15 25 -31 57 -35 70 -18 59 -62 105 -170
    178 -105 70 -151 86 -241 83 -32 0 -52 7 -94 36 -29 20 -73 45 -96 56 -24 10
    -46 22 -48 27 -3 5 -18 12 -33 16 -29 7 -124 66 -158 98 -11 10 -31 25 -45 32
    -14 7 -38 27 -54 44 -27 28 -29 34 -19 66 6 19 9 45 5 57 -14 55 -106 158
    -158 178 -13 5 -40 10 -59 12 -20 1 -38 5 -41 9 -2 4 -23 1 -46 -6 -37 -10
    -43 -16 -46 -41 -5 -60 -12 -74 -34 -74 -12 0 -29 6 -37 14 -17 15 -75 49
    -132 77 -19 10 -41 25 -49 33 -7 9 -19 16 -27 16 -7 0 -58 43 -111 95 -124
    121 -136 132 -150 127 -7 -2 -15 3 -18 12 -3 9 -12 16 -19 16 -7 0 -19 7 -26
    17 -8 9 -36 33 -64 53 -27 21 -62 49 -76 62 -15 14 -34 23 -43 22 -9 -1 -16 2
    -16 7 0 5 -8 13 -17 16 -9 4 -34 23 -55 44 -34 33 -36 38 -21 50 13 11 17 11
    20 2 3 -7 12 -13 20 -13 10 0 13 6 9 16 -7 18 19 43 46 44 9 0 20 5 24 11 5 8
    -2 10 -22 6 -19 -3 -32 0 -42 11 -11 15 -12 14 -3 -4 14 -28 -9 -49 -66 -62
    -36 -8 -44 -7 -60 10 -10 11 -31 27 -47 35 -50 26 -116 94 -116 119 0 31 14
    30 28 -2 8 -17 18 -24 30 -20 18 6 60 -20 49 -30 -4 -4 -13 -1 -22 6 -9 7 -18
    11 -20 8 -3 -2 9 -15 27 -28 17 -12 27 -17 23 -10 -4 7 -2 16 6 21 11 6 8 13
    -15 31 -16 13 -41 34 -55 47 -25 23 -25 24 -3 18 14 -3 22 -1 22 7 0 7 -13 13
    -29 13 -58 0 -76 47 -42 110 15 28 14 33 -4 33 -9 0 -15 9 -15 25 0 29 6 31
    27 10 8 -8 19 -13 24 -9 6 3 16 0 24 -6 12 -10 16 -9 21 5 4 9 10 13 13 8 3
    -5 25 -15 49 -21 23 -7 40 -17 37 -22 -4 -6 3 -10 14 -10 11 0 36 -9 56 -20
    20 -11 46 -20 60 -20 15 0 47 -22 92 -65 37 -36 70 -65 71 -65 2 0 -10 18 -27
    39 -17 21 -31 44 -31 50 0 6 -6 11 -14 11 -7 0 -19 7 -27 16 -11 14 -9 15 24
    10 20 -3 53 -8 72 -11 19 -3 35 -9 35 -13 0 -4 21 -8 48 -8 26 -1 70 -12 97
    -24 28 -12 59 -24 70 -26 20 -3 146 -36 195 -50 14 -4 41 -17 60 -28 52 -31
    12 -33 -52 -3 -29 13 -78 27 -108 31 -122 16 -157 23 -202 44 -27 13 -60 27
    -75 33 -27 10 -27 10 -9 -10 10 -11 28 -23 40 -27 11 -4 54 -29 94 -55 40 -27
    75 -49 79 -49 3 0 14 -6 22 -14 15 -13 67 -40 226 -117 88 -43 228 -119 235
    -128 3 -4 25 -19 50 -33 25 -15 58 -41 74 -59 17 -17 48 -40 70 -50 23 -10 91
    -50 151 -88 118 -74 245 -144 207 -113 -13 10 -40 27 -60 37 -95 49 -249 160
    -350 252 -13 12 -46 29 -73 38 -27 9 -51 21 -55 26 -3 5 -22 17 -42 27 -20 10
    -73 38 -117 62 -44 24 -129 69 -189 99 -60 31 -112 63 -114 70 -3 9 2 11 17 6
    12 -4 57 -8 99 -10 94 -3 137 -18 127 -43 -6 -16 -5 -16 11 -1 20 20 89 15
    117 -9 21 -19 35 -9 17 13 -7 8 -25 15 -40 15 -43 0 -90 12 -90 22 0 6 22 7
    56 3 64 -7 84 -13 84 -26 0 -5 8 -9 18 -9 18 0 150 -31 237 -55 28 -8 66 -17
    85 -21 19 -3 40 -10 45 -14 6 -4 26 -11 45 -15 19 -4 53 -20 75 -35 32 -22 55
    -29 115 -33 41 -3 86 -8 100 -11 46 -9 164 -27 212 -33 26 -3 53 -12 59 -19
    15 -18 43 -18 105 1 50 15 104 60 97 82 -2 6 8 25 22 42 15 17 25 36 23 43 -3
    7 -19 -9 -37 -35 -17 -26 -36 -47 -40 -47 -13 0 -70 30 -76 41 -4 5 -11 9 -17
    9 -20 0 16 -32 54 -47 44 -18 47 -25 15 -42 -19 -10 -47 -9 -148 4 -68 9 -142
    19 -164 21 -122 12 -168 23 -187 47 -14 18 -28 22 -83 24 -79 2 -146 12 -161
    24 -7 6 -25 11 -40 13 -60 8 -186 46 -212 64 -15 10 -61 24 -102 31 -48 8 -81
    19 -92 31 -10 10 -40 30 -67 44 -39 19 -49 21 -44 9 5 -13 2 -15 -18 -10 -13
    4 -30 7 -38 7 -8 0 -11 6 -7 15 6 17 -19 21 -30 4 -8 -14 -52 -6 -179 33 -126
    38 -274 74 -344 83 -36 5 -61 15 -75 30 -12 12 -28 25 -36 28 -11 4 -10 6 5 7
    18 1 18 2 -5 15 -14 8 -32 15 -40 15 -8 0 -26 10 -39 20 -19 16 -21 23 -11 35
    10 12 5 17 -27 32 -21 10 -40 18 -42 20 -2 1 -1 9 3 18 3 10 11 13 18 9 14 -9
    21 -10 148 -23 52 -5 110 -13 128 -16 48 -11 39 0 -30 33 -64 31 -86 37 -210
    57 -38 6 -48 11 -48 26 0 11 -7 19 -16 19 -10 0 -14 -6 -10 -15 4 -12 -3 -15
    -32 -15 -20 0 -44 -4 -52 -10 -9 -6 -17 -5 -22 3 -16 24 -8 37 25 38 20 1 25
    3 13 6 -18 5 -18 6 5 24 13 10 19 19 12 19 -6 0 -15 -4 -18 -10 -10 -15 -23
    -12 -28 8 -4 16 -5 16 -6 0 0 -10 -4 -18 -7 -18 -13 0 -104 54 -104 61 0 7 53
    20 85 21 62 2 95 -5 125 -27 l33 -24 -26 -10 c-33 -13 -35 -21 -4 -13 12 3 27
    12 33 20 10 13 17 12 58 -8 53 -25 75 -18 51 17 -14 20 -61 41 -49 22 3 -5 -1
    -9 -8 -9 -15 1 -50 25 -44 31 5 5 -54 11 -132 13 -41 1 -62 5 -62 13 0 9 -14
    6 -46 -7 -25 -11 -47 -20 -49 -20 -2 0 -3 14 -3 30 1 29 2 30 57 32 31 1 67 1
    81 0 13 -1 27 3 31 9 5 8 12 8 26 0 20 -10 33 -6 33 11 0 5 -4 7 -9 4 -5 -4
    -7 2 -4 13 5 20 40 31 97 31 64 0 332 -48 479 -87 9 -2 17 -1 17 4 0 9 -70 27
    -147 39 -51 8 -53 15 -13 32 17 8 30 20 30 28 0 8 10 15 23 15 74 5 102 10
    142 28 25 11 66 23 92 27 44 7 46 8 20 15 -20 5 -48 0 -97 -17 -38 -13 -92
    -24 -119 -24 -27 0 -61 -4 -76 -10 -65 -25 -139 -15 -150 19 -3 10 -15 21 -26
    24 -13 4 -18 10 -14 17 6 9 33 10 100 5 75 -5 99 -4 123 9 17 9 50 17 74 17
    l43 1 -39 8 c-21 4 -47 5 -58 3 -35 -10 -17 6 36 30 46 21 60 23 97 16 24 -6
    61 -11 81 -13 21 -2 45 -9 53 -16 13 -11 16 -11 13 1 -3 15 64 26 175 28 l57
    1 0 -33 c0 -18 -7 -42 -15 -53 -20 -26 -20 -26 25 -54 45 -28 49 -46 20 -82
    -38 -49 -104 -71 -220 -72 -58 0 -132 2 -165 6 -47 6 -54 6 -30 -3 17 -5 84
    -13 151 -16 140 -7 198 7 257 60 54 49 56 69 7 108 -28 23 -39 38 -34 48 4 9
    10 34 13 56 l6 40 81 -1 c76 -1 85 -3 144 -40 57 -35 70 -39 131 -39 61 0 71
    3 98 29 37 35 38 55 9 101 -46 71 -136 86 -206 35 -49 -37 -95 -44 -177 -27
    -49 10 -61 17 -63 34 -4 27 -63 73 -93 73 -12 0 -31 -8 -41 -17 -16 -15 -24
    -15 -61 -5 -58 17 -169 34 -184 28 -28 -11 -10 -26 31 -26 54 0 147 -20 141
    -31 -3 -4 4 -5 16 -2 17 5 20 2 17 -18 -1 -13 -15 -30 -30 -38 -22 -13 -30
    -13 -51 -2 -14 7 -67 14 -123 16 -156 5 -288 16 -298 26 -6 5 7 9 34 9 63 0
    45 8 -25 11 -36 2 -67 9 -79 18 -20 18 -105 18 -182 1 -47 -11 -124 -4 -124
    11 0 9 22 13 49 10 7 -2 10 3 6 9 -4 6 -15 8 -26 5 -37 -12 -18 10 24 27 32
    14 50 16 75 9 25 -8 32 -7 29 2 -2 7 -9 13 -15 13 -7 1 -13 3 -14 4 -10 10
    -55 6 -64 -5 -17 -22 -60 -29 -67 -11 -5 13 1 16 26 16 22 0 28 3 18 9 -24 15
    64 24 102 10 20 -7 49 -9 70 -5 59 11 51 23 -13 19 -33 -2 -60 -1 -60 3 0 10
    43 43 83 64 20 11 37 26 37 33 0 7 27 35 61 62 124 101 130 108 119 146 -7 24
    -6 35 3 42 8 6 3 7 -13 3 -14 -3 -28 -6 -32 -6 -4 0 -5 -13 -2 -28 5 -24 -2
    -37 -46 -85 -53 -58 -70 -68 -70 -43 0 11 -7 10 -36 -5 -20 -10 -44 -25 -53
    -33 -15 -14 -102 -37 -135 -36 -6 0 5 14 24 30 19 16 30 30 23 30 -6 0 -25
    -11 -41 -25 -38 -32 -44 -31 -31 1 8 24 8 25 -6 14 -8 -7 -15 -23 -15 -35 0
    -18 -7 -23 -37 -28 -21 -2 -44 -6 -52 -8 -16 -5 -49 47 -50 77 -1 24 22 46
    102 98 32 21 61 45 64 54 16 52 27 72 59 105 20 21 56 43 85 52 27 9 54 26 60
    36 17 33 7 62 -25 70 -32 9 -52 1 -106 -41 -19 -15 -49 -31 -67 -34 -35 -8
    -81 -46 -76 -62 5 -15 -36 -64 -53 -64 -12 0 -13 4 -3 23 7 13 -1 8 -20 -13
    -16 -19 -28 -41 -26 -48 3 -7 1 -11 -4 -7 -5 3 -28 -16 -50 -42 -32 -35 -44
    -44 -53 -35 -9 9 -8 20 5 47 9 20 17 42 17 50 0 17 86 75 112 75 9 0 20 5 23
    10 9 14 -42 2 -72 -16 -41 -25 -26 6 25 52 26 24 61 46 77 50 17 3 56 20 87
    36 88 47 124 132 83 198 -8 13 -43 12 -72 -2z m-266 -272 c3 -3 -5 -19 -17
    -36 -17 -23 -20 -34 -11 -39 9 -6 8 -11 -4 -21 -13 -11 -14 -10 -9 5 6 15 5
    16 -15 4 -23 -15 -46 -7 -65 24 -8 13 -4 23 19 47 25 27 35 31 64 26 18 -3 36
    -7 38 -10z m273 -51 c0 -24 -59 -78 -77 -71 -10 4 -15 4 -11 1 9 -9 -46 -94
    -72 -112 -35 -24 -36 -6 -2 36 18 22 32 43 32 47 0 4 26 33 57 65 54 55 73 64
    73 34z m-320 -110 c-9 -11 -16 -13 -23 -6 -7 7 -6 15 3 26 9 11 16 13 23 6 7
    -7 6 -15 -3 -26z m-153 -59 c-3 -8 -25 -25 -48 -37 -33 -16 -49 -19 -72 -12
    -44 12 -45 31 -3 39 20 3 53 15 73 25 39 20 61 13 50 -15z m234 1 c-13 -12
    -20 -15 -16 -7 14 26 28 41 33 35 3 -3 -5 -16 -17 -28z m619 -2 c0 -8 -9 -19
    -20 -25 -23 -12 -25 -7 -10 21 13 22 30 25 30 4z m-590 -30 c-7 -14 -20 -25
    -27 -25 -15 0 -10 13 15 43 21 24 28 13 12 -18z m-375 -74 c3 -5 0 -21 -6 -35
    -13 -27 -27 -30 -84 -17 l-30 7 30 15 c17 8 29 21 28 27 -3 14 53 17 62 3z
    m368 2 c-7 -2 -19 -2 -25 0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z m439 -18 c0 -6
    -14 -15 -31 -20 -16 -6 -41 -21 -54 -33 -24 -22 -57 -30 -57 -13 0 16 41 40
    58 34 10 -4 13 -2 8 5 -4 7 6 17 26 26 36 16 49 16 50 1z m-210 -68 c-17 -17
    -24 -19 -34 -9 -15 15 -2 27 33 29 l22 2 -21 -22z m-89 -4 c-7 -3 -13 -9 -13
    -15 0 -6 8 -4 18 5 23 21 49 3 31 -20 -9 -10 -5 -12 20 -7 39 8 39 -5 0 -32
    -31 -22 -69 -22 -69 1 0 7 -9 24 -21 39 -21 27 -21 26 21 39 18 5 30 -4 13
    -10z m-1060 -171 c-19 -50 -62 -104 -63 -80 0 14 -7 17 -40 14 -24 -3 -43 1
    -50 9 -6 7 -21 11 -33 8 -12 -2 7 8 43 23 36 15 73 34 83 42 9 8 17 12 17 9 0
    -4 10 -2 23 3 35 14 36 12 20 -28z m-93 -112 c0 -5 -5 -10 -11 -10 -5 0 -7 5
    -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z m1516 1 c5 -3 10 -12 12 -20 2 -10 -4
    -12 -25 -7 -39 10 -93 7 -112 -5 -12 -8 -8 -9 17 -6 57 7 279 -41 256 -55 -21
    -13 -121 -19 -147 -9 -15 6 -76 13 -135 16 -94 4 -109 8 -125 26 -39 48 4 68
    144 67 58 0 110 -3 115 -7z m64 -40 c0 -5 -9 -7 -20 -4 -11 3 -20 7 -20 9 0 2
    9 4 20 4 11 0 20 -4 20 -9z m-230 -71 c0 -13 -11 -13 -30 0 -12 8 -11 10 8 10
    12 0 22 -4 22 -10z m308 -17 c-10 -2 -28 -2 -40 0 -13 2 -5 4 17 4 22 1 32 -1
    23 -4z m-323 -24 c10 -15 5 -16 -42 -11 -24 2 -40 8 -37 13 7 13 71 11 79 -2z
    m-48 -37 c16 -10 15 -12 -12 -22 -16 -6 -33 -16 -36 -22 -4 -6 -24 -11 -44
    -12 -33 -1 -39 3 -48 27 -11 33 -8 37 35 46 34 8 72 1 105 -17z m117 -36 c18
    -7 56 -17 85 -21 29 -4 50 -10 46 -13 -9 -9 -133 -3 -195 10 l-55 11 35 13
    c46 16 45 16 84 0z m-560 -78 c9 -12 16 -25 16 -29 0 -12 -50 -6 -61 7 -14 17
    2 19 19 2 9 -9 12 -9 12 -1 0 7 -4 15 -10 18 -5 3 -10 10 -10 16 0 16 18 10
    34 -13z m99 -44 c17 -6 24 -34 9 -34 -5 0 -19 12 -33 26 -13 15 -17 24 -9 20
    8 -3 23 -9 33 -12z m-42 -21 l24 -27 -24 15 c-14 9 -27 21 -29 27 -6 19 3 14
    29 -15z m-913 -65 c-6 -18 -28 -21 -28 -4 0 9 7 16 16 16 9 0 14 -5 12 -12z
    m-358 -7 c0 -12 -20 -25 -27 -18 -7 7 6 27 18 27 5 0 9 -4 9 -9z m380 -26
    c-28 -30 -13 -34 18 -5 18 17 26 19 42 10 27 -14 25 -28 -6 -61 -16 -17 -22
    -29 -15 -33 6 -4 11 -1 11 7 0 8 7 20 15 27 21 17 20 -3 -1 -35 -23 -35 -57
    -32 -84 5 -16 23 -19 34 -11 44 6 7 8 16 4 19 -3 4 5 15 18 26 33 28 37 26 9
    -4z m1075 -8 c25 -19 7 -26 -34 -15 -26 7 -31 12 -20 19 20 12 32 11 54 -4z
    m-899 -92 c17 -41 18 -75 3 -75 -6 0 -26 12 -45 26 -29 23 -32 29 -22 42 11
    13 10 15 -4 9 -22 -8 -22 -3 -2 28 13 20 19 23 36 14 11 -6 26 -26 34 -44z
    m-477 11 c8 -10 23 -13 47 -9 51 8 41 -8 -26 -44 -68 -37 -95 -35 -85 6 7 26
    32 61 45 61 4 0 13 -6 19 -14z m1667 -20 c39 -7 79 -18 90 -24 34 -19 -108 -1
    -146 19 -43 22 -35 23 56 5z m-1746 -16 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10
    3 6 8 10 11 10 2 0 4 -4 4 -10z m217 -6 c5 -16 -51 -55 -92 -64 -23 -5 -40 -3
    -52 5 -15 11 -11 16 42 44 60 31 95 36 102 15z m1383 -10 c0 -8 -4 -12 -10 -9
    -5 3 -10 10 -10 16 0 5 5 9 10 9 6 0 10 -7 10 -16z m-1221 -68 c-3 -3 -11 5
    -18 17 -13 21 -12 21 5 5 10 -10 16 -20 13 -22z m1192 -23 c16 -10 29 -21 29
    -25 0 -8 -52 11 -87 31 -16 9 -15 10 5 11 13 0 37 -8 53 -17z m-51 -28 c12
    -14 10 -15 -15 -11 -40 7 -56 26 -23 26 14 0 31 -7 38 -15z m146 -15 c8 0 14
    -5 14 -11 0 -12 -40 -2 -55 14 -7 7 -4 8 8 3 10 -3 25 -6 33 -6z m-335 -421
    c19 -23 36 -45 37 -50 2 -5 12 -9 21 -9 19 0 28 -9 151 -145 73 -80 114 -114
    71 -59 -36 47 -121 143 -190 216 -35 38 -61 71 -57 75 3 4 12 1 18 -5 7 -7 18
    -12 25 -12 8 0 26 -8 41 -19 44 -30 145 -95 174 -111 27 -15 108 -108 108
    -123 0 -5 14 -25 31 -45 17 -20 38 -50 45 -68 14 -34 19 -96 8 -107 -16 -15
    -117 53 -204 137 -86 83 -200 166 -229 166 -6 0 -11 6 -12 13 -2 47 -11 73
    -30 91 l-21 21 11 -21 c9 -20 6 -34 -8 -34 -3 0 -12 13 -19 29 -7 16 -24 41
    -37 55 -32 35 -31 46 4 46 21 0 37 -10 62 -41z m122 -214 c31 -16 49 -35 32
    -35 -13 0 -85 43 -85 52 0 5 3 8 8 6 4 -2 24 -12 45 -23z m339 -261 c27 -11
    51 -28 57 -42 15 -31 15 -31 -24 -18 -39 14 -68 38 -98 81 -19 28 -19 29 -1
    14 10 -9 40 -24 66 -35z m554 -105 c33 -17 84 -45 116 -61 31 -16 64 -38 73
    -48 19 -21 26 -18 -79 -40 l-49 -11 -61 53 c-33 29 -69 61 -79 71 -11 11 -26
    16 -37 12 -26 -8 -35 13 -14 36 25 27 63 24 130 -12z m1587 -935 c23 -27 39
    -69 29 -78 -10 -11 -92 51 -92 69 0 13 20 35 32 35 5 0 18 -12 31 -26z"/>
    <path d="M6235 6760 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
    -7 -4 -4 -10z"/>
    <path d="M6400 6721 c0 -13 24 -37 60 -61 34 -22 34 -22 13 -1 -52 51 -73 70
    -73 62z"/>
    <path d="M6615 6480 c21 -22 41 -40 44 -40 3 0 -13 18 -34 40 -21 22 -41 40
    -44 40 -3 0 13 -18 34 -40z"/>
    <path d="M4847 8893 c-12 -12 -7 -22 8 -17 8 4 15 10 15 15 0 11 -14 12 -23 2z"/>
    <path d="M6885 8740 c-8 -13 4 -32 16 -25 12 8 12 35 0 35 -6 0 -13 -4 -16
    -10z"/>
    <path d="M7443 8693 c-15 -5 -17 -23 -4 -23 5 0 11 7 15 15 3 8 4 15 3 14 -1
    0 -8 -3 -14 -6z"/>
    <path d="M6973 8510 c-30 -12 -29 -18 1 -16 13 0 22 4 20 8 -2 3 2 9 8 11 7 3
    9 5 3 6 -5 0 -20 -4 -32 -9z"/>
    <path d="M9045 8381 c-70 -26 -125 -56 -125 -67 0 -8 12 -17 28 -20 15 -4 45
    -15 67 -25 52 -24 87 -24 135 1 48 24 51 46 12 84 -30 28 -82 40 -117 27z"/>
    <path d="M6567 8351 c-4 -17 -3 -21 5 -13 5 5 8 16 6 23 -3 8 -7 3 -11 -10z"/>
    <path d="M7878 8234 c-4 -3 -39 -7 -80 -10 -158 -9 -156 -8 -236 -91 -68 -69
    -67 -80 3 -28 17 12 45 25 64 29 19 3 58 17 86 31 47 22 55 23 90 11 22 -8 54
    -30 72 -49 43 -47 63 -57 117 -57 33 0 52 6 72 24 l28 23 -37 43 c-20 24 -49
    52 -65 62 -25 17 -102 25 -114 12z"/>
    <path d="M6815 8160 c-19 -8 -19 -9 3 -9 12 -1 22 4 22 9 0 6 -1 10 -2 9 -2 0
    -12 -4 -23 -9z"/>
    <path d="M6840 8100 c-13 -9 -13 -10 0 -10 8 0 26 -3 40 -6 24 -5 24 -5 6 10
    -23 19 -26 19 -46 6z"/>
    <path d="M6660 8050 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
    -4 -4 -4 -10z"/>
    <path d="M6590 8039 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
    -5 -10 -11z"/>
    <path d="M8500 8030 c-14 -9 -58 -23 -160 -50 -25 -7 -71 -12 -104 -11 -32 1
    -63 -2 -70 -7 -6 -5 -28 -12 -48 -16 -51 -9 -49 -32 2 -40 22 -3 58 -9 79 -12
    34 -5 43 -2 78 29 28 26 60 41 114 56 73 20 167 61 139 61 -8 0 -22 -5 -30
    -10z"/>
    <path d="M7100 7970 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
    -4 -4 -4 -10z"/>
    <path d="M9462 7831 c2 -7 7 -12 11 -12 12 1 9 15 -3 20 -7 2 -11 -2 -8 -8z"/>
    <path d="M7145 7810 c28 -4 61 -12 74 -17 33 -13 71 -4 71 16 0 13 -2 13 -9 2
    -6 -10 -19 -12 -42 -7 -19 4 -59 9 -89 11 l-55 4 50 -9z"/>
    <path d="M4735 7654 c-22 -8 -44 -19 -49 -24 -4 -4 9 -2 30 5 22 8 47 14 56
    15 9 0 20 5 23 10 8 14 -12 12 -60 -6z"/>
    <path d="M10078 7563 c-27 -7 -21 -23 7 -23 14 0 25 4 25 9 0 13 -12 18 -32
    14z"/>
    <path d="M6935 7550 c3 -5 10 -10 16 -10 5 0 9 5 9 10 0 6 -7 10 -16 10 -8 0
    -12 -4 -9 -10z"/>
    <path d="M7308 7533 c-27 -7 -22 -23 7 -23 16 0 25 6 25 15 0 15 -2 16 -32 8z"/>
    <path d="M6405 7520 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
    -7 -4 -4 -10z"/>
    <path d="M4615 7492 c-16 -8 -32 -18 -35 -22 -3 -4 -20 -15 -37 -24 -44 -22
    -42 -27 5 -10 20 7 45 13 54 14 16 0 56 47 47 54 -2 1 -17 -4 -34 -12z"/>
    <path d="M7481 7495 c8 -7 78 -9 85 -2 4 4 -14 7 -41 7 -27 0 -46 -2 -44 -5z"/>
    <path d="M6580 7460 l-23 -20 27 0 c16 0 25 4 21 10 -3 6 -1 10 4 10 6 0 11
    -4 11 -8 0 -9 71 -34 78 -28 1 2 -12 9 -29 15 -18 6 -40 18 -49 26 -15 14 -19
    13 -40 -5z"/>
    <path d="M7200 7439 c0 -12 90 -53 138 -64 18 -3 30 -10 28 -14 -3 -4 4 -7 15
    -7 29 0 22 9 -24 31 -69 33 -42 46 61 29 20 -3 32 -1 28 4 -12 20 -246 39
    -246 21z"/>
    <path d="M4740 7324 c0 -8 5 -12 10 -9 6 4 8 11 5 16 -9 14 -15 11 -15 -7z"/>
    <path d="M3870 7316 c0 -2 9 -6 20 -9 11 -3 18 -1 14 4 -5 9 -34 13 -34 5z"/>
    <path d="M7100 7295 c-5 -14 -21 -30 -36 -35 -17 -7 -24 -15 -20 -25 3 -8 9
    -22 13 -31 7 -18 -9 -17 -112 6 -27 6 -81 15 -118 20 -41 5 -76 15 -88 26 -13
    11 -19 13 -19 4 0 -9 -11 -11 -37 -7 l-38 6 36 -12 c20 -7 44 -18 53 -26 11
    -9 16 -10 16 -2 0 7 3 10 8 8 4 -3 36 -8 72 -12 36 -4 101 -15 145 -26 44 -10
    98 -19 120 -19 46 0 38 -12 -20 -30 -22 -7 -34 -15 -27 -18 16 -5 68 15 102
    40 27 19 50 21 143 12 38 -4 57 -2 57 5 0 6 -9 11 -19 11 -30 0 -92 38 -131
    80 -19 21 -47 41 -63 44 -23 5 -29 2 -37 -19z"/>
    <path d="M7595 7290 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
    -8 -4 -11 -10z"/>
    <path d="M7676 7269 c3 -5 12 -9 20 -9 8 0 32 -18 54 -40 22 -22 47 -40 56
    -40 18 0 105 -38 114 -50 6 -8 170 -114 201 -130 38 -20 16 -2 -55 45 -67 44
    -159 114 -226 172 -27 24 -56 37 -103 47 -37 7 -64 10 -61 5z"/>
    <path d="M4635 7190 c-3 -5 -2 -10 4 -10 5 0 13 5 16 10 3 6 2 10 -4 10 -5 0
    -13 -4 -16 -10z"/>
    <path d="M6748 7169 c-9 -6 -7 -9 8 -9 11 0 25 -5 31 -11 6 -6 17 -9 26 -7 8
    2 1 9 -19 19 -19 9 -38 13 -46 8z"/>
    <path d="M4620 7149 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
    -5 -10 -11z"/>
    <path d="M6550 7128 c0 -18 18 -34 28 -24 3 4 -2 14 -11 23 -16 16 -17 16 -17
    1z"/>
    <path d="M7579 7119 c13 -11 33 -19 45 -19 11 0 33 -7 49 -14 28 -15 36 -9 15
    12 -9 9 1 12 48 13 59 2 59 2 -21 10 -44 4 -98 10 -120 12 l-39 5 23 -19z"/>
    <path d="M6470 7100 c-9 -6 -10 -10 -3 -10 6 0 15 5 18 10 8 12 4 12 -15 0z"/>
    <path d="M7898 7103 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
    <path d="M4685 7060 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
    -8 -4 -11 -10z"/>
    <path d="M6474 7062 c6 -10 110 -29 112 -21 0 2 8 -2 17 -9 9 -8 31 -12 49
    -10 21 2 33 -2 34 -9 3 -26 5 -28 15 -11 7 12 6 23 -2 36 -10 17 -23 19 -83
    18 -39 0 -88 3 -109 7 -25 5 -37 5 -33 -1z"/>
    <path d="M4275 6965 c-14 -13 -25 -30 -25 -37 0 -7 7 -3 15 9 8 11 26 24 40
    27 14 4 23 11 20 16 -10 16 -24 12 -50 -15z"/>
    <path d="M8151 6986 c8 -8 158 -86 166 -86 13 0 -34 30 -96 60 -54 27 -81 37
    -70 26z"/>
    <path d="M6514 6962 c9 -15 48 -23 43 -9 -2 7 -14 14 -26 15 -13 2 -21 -1 -17
    -6z"/>
    <path d="M8450 6940 c27 -21 40 -25 40 -12 0 4 -15 13 -32 20 l-33 12 25 -20z"/>
    <path d="M6630 6936 c0 -2 14 -14 30 -26 17 -12 30 -19 30 -16 0 2 -13 14 -30
    26 -16 12 -30 19 -30 16z"/>
    <path d="M3373 6923 c-20 -8 -15 -23 7 -23 11 0 20 7 20 15 0 15 -6 17 -27 8z"/>
    <path d="M3220 6889 c-42 -25 -82 -68 -77 -83 6 -17 70 -7 87 12 8 10 32 28
    53 41 45 27 46 32 7 24 -25 -5 -29 -3 -24 11 8 21 -4 20 -46 -5z"/>
    <path d="M6588 6892 c-10 -2 -18 -11 -18 -20 0 -12 3 -13 8 -5 5 8 20 10 37 7
    16 -3 27 -4 25 -2 -28 20 -37 24 -52 20z"/>
    <path d="M7009 6855 c16 -30 59 -80 65 -75 2 3 -14 25 -36 50 -22 25 -35 36
    -29 25z"/>
    <path d="M6687 6819 c7 -7 15 -10 18 -7 3 3 -2 9 -12 12 -14 6 -15 5 -6 -5z"/>
    <path d="M7090 6766 c0 -2 8 -10 18 -17 15 -13 16 -12 3 4 -13 16 -21 21 -21
    13z"/>
    <path d="M7975 6730 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
    -8 -4 -11 -10z"/>
    <path d="M6722 6718 c5 -14 48 -31 48 -18 0 6 -7 10 -15 10 -8 0 -15 5 -15 10
    0 6 -5 10 -11 10 -6 0 -9 -5 -7 -12z"/>
    <path d="M8010 6722 c0 -12 19 -26 26 -19 2 2 -2 10 -11 17 -9 8 -15 8 -15 2z"/>
    <path d="M7141 6718 c2 -2 18 -14 34 -28 l30 -25 -24 28 c-14 15 -29 27 -34
    27 -6 0 -8 -1 -6 -2z"/>
    <path d="M5315 6678 c-11 -18 -28 -48 -39 -67 -10 -20 -25 -44 -34 -54 -8 -9
    -13 -19 -10 -22 9 -9 88 84 88 103 0 10 7 27 15 38 16 21 19 34 8 34 -5 0 -17
    -14 -28 -32z"/>
    <path d="M6785 6700 c3 -5 10 -10 16 -10 5 0 9 5 9 10 0 6 -7 10 -16 10 -8 0
    -12 -4 -9 -10z"/>
    <path d="M6849 6646 c14 -14 29 -26 34 -26 6 0 19 -7 29 -17 10 -9 20 -14 23
    -11 4 4 -79 69 -100 77 -5 2 1 -8 14 -23z"/>
    <path d="M6206 6624 c-11 -7 -17 -17 -13 -20 3 -4 14 1 23 10 21 22 15 28 -10
    10z"/>
    <path d="M8780 6570 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
    -4 -4 -4 -10z"/>
    <path d="M6971 6567 c2 -1 13 -9 24 -17 19 -14 19 -14 6 3 -7 9 -18 17 -24 17
    -6 0 -8 -1 -6 -3z"/>
    <path d="M6317 6503 c-12 -13 -21 -13 -46 -5 -17 6 -33 7 -36 3 -8 -14 7 -21
    51 -21 56 0 65 -16 25 -47 -31 -25 -39 -47 -21 -58 5 -3 7 0 4 9 -3 8 9 27 30
    47 39 35 44 52 22 74 -11 11 -16 11 -29 -2z"/>
    <path d="M4027 6485 c-27 -13 -64 -36 -83 -53 -18 -16 -39 -32 -46 -36 -7 -3
    -31 -20 -53 -36 -22 -17 -51 -38 -65 -48 -14 -9 -65 -48 -115 -87 -87 -68
    -175 -129 -223 -154 -41 -21 -46 -6 -8 23 21 15 64 54 97 87 55 55 71 79 50
    79 -11 0 -122 -62 -162 -91 -16 -11 -60 -36 -96 -55 -37 -19 -87 -52 -112 -73
    -76 -63 -143 -95 -223 -105 -74 -10 -177 -78 -224 -150 -56 -83 -104 -124
    -185 -156 -54 -22 -86 -42 -102 -63 -14 -18 -36 -43 -49 -57 -34 -34 -91 -137
    -83 -150 11 -18 65 -11 114 15 25 13 71 36 101 50 68 32 77 38 171 102 42 29
    128 77 190 107 87 43 124 67 157 103 23 26 42 51 42 55 0 12 76 79 124 109 22
    15 55 43 73 63 17 20 41 36 52 36 34 0 139 60 191 110 9 8 32 26 53 40 20 14
    37 28 37 32 0 3 21 18 48 34 26 15 54 33 62 40 8 8 44 34 80 58 61 42 102 68
    210 135 43 26 66 62 38 60 -7 0 -35 -11 -61 -24z"/>
    <path d="M8881 6454 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
    <path d="M6353 6438 c-15 -20 -25 -38 -20 -38 3 0 12 11 21 25 17 26 16 35 -1
    13z"/>
    <path d="M7150 6440 c0 -13 49 -60 62 -60 21 0 20 34 -1 52 -25 20 -61 24 -61
    8z"/>
    <path d="M7400 6410 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
    -4 -4 -4 -10z"/>
    <path d="M9080 6392 c0 -23 175 -112 219 -112 27 0 54 -12 224 -102 37 -19 67
    -32 67 -29 0 4 -21 18 -47 31 -27 14 -66 36 -88 50 -22 14 -65 39 -95 54 -30
    16 -62 35 -70 41 -19 16 -83 43 -130 55 -19 5 -45 12 -57 15 -14 5 -23 3 -23
    -3z"/>
    <path d="M3162 6358 c6 -18 28 -21 28 -4 0 9 -7 16 -16 16 -9 0 -14 -5 -12
    -12z"/>
    <path d="M7669 6277 c19 -20 37 -37 39 -37 11 0 -27 45 -49 59 -18 11 -16 6
    10 -22z"/>
    <path d="M7882 6259 c2 -6 14 -16 27 -20 27 -11 19 6 -11 22 -16 8 -19 8 -16
    -2z"/>
    <path d="M7380 6210 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
    -4 -4 -4 -10z"/>
    <path d="M8325 6200 c17 -11 35 -20 40 -20 6 0 -5 9 -23 20 -42 26 -56 26 -17
    0z"/>
    <path d="M4397 6183 c-13 -12 -7 -33 8 -33 8 0 15 9 15 20 0 20 -11 26 -23 13z"/>
    <path d="M5790 6170 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
    -10 -4 -10 -10z"/>
    <path d="M8380 6174 c0 -8 63 -35 76 -32 8 2 -5 10 -30 19 -25 9 -46 15 -46
    13z"/>
    <path d="M9765 6050 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
    -7 -4 -4 -10z"/>
    <path d="M9260 6010 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
    -4 -4 -4 -10z"/>
    <path d="M8370 5960 c27 -21 40 -25 40 -12 0 9 -44 32 -58 32 -4 0 4 -9 18
    -20z"/>
    <path d="M6870 5900 c0 -19 11 -26 23 -14 8 8 -3 34 -14 34 -5 0 -9 -9 -9 -20z"/>
    <path d="M6980 5864 c29 -33 78 -80 111 -109 16 -14 14 -9 -6 15 -66 77 -91
    102 -110 112 -17 9 -16 6 5 -18z"/>
    <path d="M7148 5878 c6 -6 22 -13 34 -15 22 -4 22 -3 3 11 -25 19 -55 22 -37
    4z"/>
    <path d="M9335 5800 c10 -11 20 -20 22 -20 2 0 -1 9 -7 20 -6 11 -16 20 -22
    20 -6 0 -3 -9 7 -20z"/>
    <path d="M7270 5783 c0 -5 10 -19 21 -33 l20 -25 -16 33 c-15 30 -25 41 -25
    25z"/>
    <path d="M4705 5740 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
    -8 -4 -11 -10z"/>
    <path d="M7135 5710 c10 -11 20 -20 23 -20 3 0 -3 9 -13 20 -10 11 -20 20 -23
    20 -3 0 3 -9 13 -20z"/>
    <path d="M8760 5720 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
    -4 -4 -4 -10z"/>
    <path d="M7325 5680 c9 -16 18 -30 21 -30 2 0 -2 14 -11 30 -9 17 -18 30 -21
    30 -2 0 2 -13 11 -30z"/>
    <path d="M7530 5640 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
    -10 -4 -10 -10z"/>
    <path d="M7350 5637 c0 -3 12 -28 26 -54 20 -36 25 -41 20 -19 -6 26 -46 89
    -46 73z"/>
    <path d="M7650 5621 c5 -11 15 -23 21 -27 6 -3 3 5 -6 20 -20 30 -30 35 -15 7z"/>
    <path d="M7690 5576 c0 -2 8 -10 18 -17 15 -13 16 -12 3 4 -13 16 -21 21 -21
    13z"/>
    <path d="M10080 5510 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
    -4 -4 -4 -10z"/>
    <path d="M7781 5475 c20 -22 49 -56 65 -75 56 -66 153 -164 158 -159 3 3 -27
    39 -67 80 -40 42 -83 89 -98 105 -14 16 -41 42 -60 59 l-34 30 36 -40z"/>
    <path d="M7450 5471 c0 -11 4 -22 9 -25 4 -3 6 6 3 19 -5 30 -12 33 -12 6z"/>
    <path d="M4580 5465 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
    -10 -7 -10 -15z"/>
    <path d="M8610 4865 c0 -8 7 -15 15 -15 8 0 15 7 15 15 0 8 -7 15 -15 15 -8 0
    -15 -7 -15 -15z"/>
    <path d="M10036 4792 c-3 -6 -1 -14 5 -17 15 -10 25 3 12 16 -7 7 -13 7 -17 1z"/>
    <path d="M5870 4719 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
    -5 -10 -11z"/>
    <path d="M8295 4450 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
    -7 -4 -4 -10z"/>
    <path d="M8530 4160 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
    -10 -4 -10 -10z"/>
    <path d="M9480 3977 c0 -18 47 -73 55 -65 7 7 -36 78 -47 78 -5 0 -8 -6 -8
    -13z"/>
    <path d="M9634 3938 c3 -7 9 -21 12 -30 4 -10 13 -18 20 -18 23 0 16 27 -12
    45 -18 12 -24 13 -20 3z"/>
    </g>
</svg>
    )
}

export default SplatterOne;