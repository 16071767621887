import React from 'react';

function SplatterThree(props) {
    return(
<svg style={props.stylez} version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="128.000000pt" height="128.000000pt" viewBox="0 0 1280.000000 1280.000000"
 preserveAspectRatio="xMidYMid meet">
<metadata>
Created by potrace 1.15, written by Peter Selinger 2001-2017
</metadata>
<g transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)"
  stroke="none">
<path d="M5181 10764 c-25 -32 -28 -93 -6 -154 8 -23 13 -45 11 -49 -3 -4 -1
-13 5 -21 7 -13 9 -13 9 -1 0 10 9 6 28 -11 27 -26 81 -53 89 -44 2 2 -8 24
-24 48 -24 38 -28 53 -28 123 0 60 -5 87 -19 108 -23 34 -39 34 -65 1z"/>
<path d="M5630 10255 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5
0 -10 -7 -10 -15z"/>
<path d="M6340 10241 c0 -5 5 -13 10 -16 6 -3 10 -2 10 4 0 5 -4 13 -10 16 -5
3 -10 2 -10 -4z"/>
<path d="M6322 9958 c-5 -5 -6 -26 -3 -46 4 -20 4 -53 0 -72 -4 -19 -5 -38 -3
-42 8 -13 -7 -9 -22 6 -8 8 -14 20 -14 26 0 6 -7 13 -15 16 -8 4 -15 13 -15
20 0 8 -6 17 -14 20 -8 3 -17 15 -21 27 -8 21 -8 21 -12 -3 -2 -14 14 -104 36
-201 40 -178 43 -226 12 -214 -9 4 -33 9 -53 12 -27 4 -45 15 -63 39 -21 28
-89 74 -108 74 -4 0 -16 -44 -21 -72 -1 -3 -4 -10 -8 -14 -5 -4 -8 -20 -8 -37
0 -16 -9 -40 -19 -53 l-20 -25 -11 22 c-7 12 -10 38 -6 58 7 45 -15 81 -48 81
-29 0 -28 -28 1 -68 11 -15 24 -39 28 -52 4 -14 13 -35 21 -47 11 -18 11 -23
-2 -34 -12 -10 -22 -9 -50 5 -20 9 -33 12 -29 6 3 -5 1 -10 -6 -10 -7 0 -5
-10 7 -27 13 -20 17 -45 16 -90 -2 -67 -16 -100 -44 -109 -10 -3 -18 -13 -18
-23 0 -10 -6 -24 -14 -30 -11 -9 -19 -3 -40 35 -14 26 -26 50 -26 55 0 4 -4
11 -10 14 -5 3 3 -29 19 -72 29 -81 30 -103 4 -103 -10 0 -14 -6 -10 -20 3
-11 1 -20 -4 -20 -13 0 -11 -28 2 -32 12 -4 1 -38 -12 -38 -5 0 -20 24 -33 53
-16 34 -25 45 -25 31 -1 -12 10 -38 24 -58 13 -20 22 -42 19 -49 -3 -8 2 -35
11 -61 9 -26 15 -49 13 -51 -2 -2 -37 14 -78 35 -41 22 -81 43 -88 46 -18 9
-62 -4 -69 -22 -9 -23 26 -54 62 -54 17 0 58 -13 90 -30 59 -30 84 -47 52 -34
-11 4 -19 0 -23 -11 -5 -13 -14 -15 -41 -10 -24 4 -33 3 -28 -4 3 -6 17 -11
29 -11 13 0 34 -8 47 -19 20 -16 21 -21 10 -34 -7 -9 -26 -19 -42 -22 -17 -4
-29 -13 -29 -22 0 -11 6 -14 23 -8 54 16 67 14 67 -11 0 -14 -7 -27 -15 -30
-8 -4 -15 -14 -15 -23 1 -14 2 -14 11 2 12 20 29 14 29 -12 0 -24 29 -81 41
-81 5 0 9 -9 9 -19 0 -17 -6 -19 -59 -14 -41 4 -61 2 -66 -7 -6 -10 -1 -12 20
-8 16 4 45 -2 69 -13 23 -10 58 -19 79 -19 20 0 37 -5 37 -11 0 -14 -69 -42
-87 -34 -9 3 -21 1 -27 -3 -13 -11 -192 -34 -259 -34 -32 0 -50 -4 -54 -14 -4
-11 -12 -8 -35 11 -39 33 -54 27 -50 -18 2 -32 1 -35 -20 -31 -14 3 -10 -2 12
-12 l34 -16 -24 -19 c-14 -10 -31 -19 -38 -19 -7 0 -23 -5 -35 -12 -21 -11
-91 -37 -282 -104 -44 -15 -87 -33 -95 -39 -8 -7 -35 -20 -59 -29 -48 -19 -49
-19 -113 46 -21 21 -47 38 -59 38 -11 0 -28 4 -37 9 -10 5 -45 5 -87 0 -38 -5
-80 -10 -92 -12 -35 -3 -39 -17 -5 -17 41 0 41 -13 0 -34 l-33 -17 35 6 c59
12 82 6 160 -39 60 -35 78 -50 89 -80 13 -34 13 -38 -9 -61 -32 -34 -88 -59
-104 -46 -7 6 -29 8 -49 4 -33 -5 -37 -3 -43 21 -3 14 -9 26 -13 26 -4 0 -14
11 -21 25 -13 24 -15 24 -32 8 -24 -21 -23 -29 4 -37 22 -5 22 -6 3 -19 -18
-14 -16 -15 23 -22 23 -4 42 -11 42 -16 0 -5 13 -9 30 -9 16 0 40 -5 53 -11
21 -9 28 -8 47 11 14 14 29 20 43 16 25 -6 57 10 57 29 0 7 19 42 43 77 38 56
53 68 125 104 150 75 198 87 377 92 137 3 158 2 145 -10 -17 -16 -100 -49
-173 -69 -28 -8 -53 -22 -59 -33 -9 -16 -8 -18 8 -11 11 4 53 14 94 21 41 8
99 18 129 23 32 6 60 7 70 2 10 -6 39 -4 76 5 88 20 85 22 85 -49 0 -47 -6
-76 -24 -111 l-24 -48 24 6 c30 8 30 -1 2 -31 -18 -19 -20 -26 -9 -32 10 -7
10 -13 2 -29 -8 -16 -19 -20 -41 -17 -16 2 -38 1 -49 -2 -24 -6 -37 -39 -22
-56 7 -9 -6 -26 -50 -62 -32 -27 -59 -54 -59 -59 0 -6 -3 -22 -7 -36 -6 -22
-1 -19 33 18 36 37 64 53 64 36 0 -23 -44 -72 -69 -77 -21 -4 -34 -16 -46 -40
-9 -19 -28 -40 -42 -46 -22 -10 -29 -9 -49 10 -17 16 -27 19 -36 12 -7 -6 -26
-15 -43 -21 -27 -10 -26 -10 15 -5 36 4 50 1 73 -17 30 -24 35 -40 12 -40 -9
0 -20 11 -25 25 -9 24 -30 35 -30 15 0 -5 5 -10 10 -10 14 0 13 -27 -1 -32 -7
-2 7 -10 30 -16 58 -18 52 -43 -21 -81 -15 -8 -25 -17 -23 -21 3 -4 -4 -15
-15 -25 -10 -9 -22 -37 -26 -61 -4 -29 -13 -48 -29 -58 -13 -8 -40 -31 -61
-51 -23 -21 -46 -34 -56 -31 -10 2 -18 -2 -18 -9 0 -7 -7 -16 -16 -19 -9 -4
-14 -9 -11 -12 3 -3 17 2 31 12 38 24 42 11 10 -33 -16 -21 11 1 60 50 80 79
116 103 116 78 0 -5 -7 -14 -15 -21 -32 -26 -6 -78 33 -66 10 4 24 17 30 31 6
13 28 31 48 40 44 18 47 18 38 3 -3 -7 4 -1 17 14 31 36 179 110 179 90 0 -9
7 -6 19 9 24 30 39 18 21 -16 -10 -20 -18 -23 -39 -19 -25 6 -26 6 -6 -10 14
-12 16 -19 8 -22 -7 -3 -13 -13 -13 -22 -1 -25 -38 -65 -54 -59 -9 4 -12 0 -9
-13 3 -13 -11 -29 -54 -60 -35 -25 -48 -39 -33 -35 14 4 35 17 47 29 12 11 31
21 44 21 15 0 32 15 53 47 17 26 37 49 44 52 7 3 10 1 7 -4 -3 -6 1 -10 10
-10 13 0 14 -4 5 -21 -19 -34 0 -37 26 -4 13 17 24 25 24 19 0 -6 -3 -14 -7
-18 -10 -10 -33 -78 -33 -99 0 -10 5 -15 10 -12 6 3 10 16 10 28 1 28 31 89
47 94 9 3 10 -6 6 -33 -5 -29 -3 -35 7 -29 10 6 11 4 6 -11 -5 -10 -8 -26 -8
-34 1 -25 -20 -70 -32 -70 -6 0 -24 -13 -41 -28 -16 -15 -46 -37 -65 -49 -19
-12 -48 -36 -65 -54 l-30 -33 38 32 c21 18 42 32 47 32 6 0 28 16 50 36 22 20
43 34 46 31 9 -8 -12 -62 -22 -60 -5 1 -9 -14 -10 -33 0 -19 0 -34 1 -34 1 0
28 53 61 118 34 68 57 127 55 141 -1 13 1 21 7 17 6 -3 20 6 31 21 l20 28 1
-70 c1 -155 8 -255 24 -350 21 -124 26 -135 24 -65 0 48 4 85 17 127 3 9 11
10 29 3 23 -8 25 -6 31 31 7 43 11 45 39 24 10 -8 23 -12 28 -9 11 7 38 -14
38 -29 0 -5 -9 -2 -20 8 -29 27 -26 -2 4 -34 26 -28 44 -105 27 -115 -5 -4
-16 6 -23 21 -9 18 -21 28 -35 28 -12 0 -29 9 -37 20 -9 11 -16 17 -16 13 0
-14 62 -78 77 -78 8 0 20 -15 28 -33 15 -36 21 -148 10 -183 -4 -12 -2 -25 4
-28 7 -5 11 -61 11 -160 0 -84 3 -156 7 -159 3 -4 7 -19 8 -34 1 -16 6 -68 10
-118 l9 -90 8 65 c4 36 5 82 2 102 -6 42 2 63 20 51 7 -4 2 1 -10 12 -24 22
-30 54 -39 227 -5 104 2 123 35 93 19 -18 50 -15 50 5 0 9 -8 12 -26 9 -9 -1
-24 23 -24 38 0 7 4 12 9 10 5 -1 10 1 11 6 3 13 -22 122 -30 127 -10 8 -31
72 -25 79 4 3 11 -1 16 -9 9 -12 10 -11 6 5 -3 11 -11 34 -18 51 -7 17 -9 40
-5 50 7 18 8 17 21 -6 8 -14 14 -36 15 -48 0 -32 37 -82 59 -82 10 0 26 -10
35 -22 l17 -23 -12 24 c-10 22 -9 23 10 18 12 -3 19 -1 15 4 -3 5 -13 9 -23 9
-12 0 -22 13 -31 43 -7 23 -19 62 -26 86 -14 46 -11 71 4 31 13 -35 23 1 12
42 l-9 33 32 -37 c18 -20 41 -58 51 -85 21 -53 158 -267 179 -280 7 -4 24 -23
37 -40 13 -18 27 -33 32 -33 9 0 -24 62 -58 107 -13 17 -24 36 -24 40 0 5 -9
19 -21 32 -27 29 -18 37 26 24 21 -7 37 -7 41 -2 9 14 -48 31 -83 24 -23 -4
-33 -2 -33 7 -1 7 -14 31 -30 53 -45 61 -80 132 -80 160 0 15 4 24 10 20 6 -4
8 -10 6 -14 -3 -4 1 -8 9 -8 8 0 12 4 8 9 -3 5 4 16 15 24 11 8 22 24 24 36 5
34 21 31 48 -10 51 -77 294 -342 313 -342 6 0 39 -28 73 -62 35 -35 73 -72 84
-83 51 -49 140 -166 174 -230 21 -38 47 -80 58 -92 15 -16 18 -29 13 -51 -5
-25 -1 -33 22 -52 15 -12 43 -28 61 -36 23 -9 37 -25 48 -51 8 -21 21 -51 29
-68 46 -103 50 -152 18 -203 -26 -42 6 -53 92 -30 66 18 58 52 -27 109 -24 17
-78 142 -96 226 -13 58 -42 104 -82 131 -19 13 -40 35 -46 50 -7 15 -13 21
-13 14 -1 -21 -17 -5 -45 45 -27 46 -26 74 0 39 8 -11 14 -15 14 -9 0 11 -43
67 -66 88 -7 6 -24 30 -39 55 -15 25 -42 61 -59 82 -17 20 -38 47 -47 60 -9
13 -44 42 -77 65 -110 73 -292 260 -292 298 0 4 -9 18 -20 32 -26 33 -25 41 3
46 19 3 18 5 -10 12 -34 8 -68 48 -60 70 5 12 91 13 172 1 60 -9 250 -36 258
-36 4 0 7 5 7 11 0 5 -3 8 -7 6 -5 -3 -33 -1 -63 4 -30 5 -104 16 -165 25 -84
13 -113 21 -123 35 -9 13 -25 19 -52 19 -21 0 -42 5 -46 12 -12 19 -13 62 -2
75 6 8 7 13 1 13 -6 0 -9 18 -7 41 2 22 0 68 -5 102 -5 34 -5 71 0 82 4 11 8
14 8 8 1 -7 6 -13 12 -13 6 0 8 8 5 18 -5 13 1 22 21 31 16 8 35 17 43 21 22
11 20 64 -3 68 -26 5 -47 -16 -47 -49 0 -29 -33 -58 -44 -40 -3 5 -8 47 -11
92 -12 176 -36 233 -103 245 -20 3 -51 12 -69 20 -17 8 -39 14 -50 14 -25 0
-63 28 -63 46 0 8 4 13 9 9 12 -7 25 14 26 40 0 14 8 21 29 23 28 4 28 4 7 18
-13 9 -30 13 -42 9 -28 -9 -39 9 -39 66 0 25 -5 50 -12 57 -6 6 -13 21 -15 33
-3 17 1 19 19 13 13 -4 27 -12 33 -19 5 -6 23 -19 38 -29 28 -17 29 -17 42 1
44 60 40 58 61 37 11 -10 42 -30 69 -43 28 -14 65 -40 82 -60 18 -19 36 -31
40 -28 9 10 -55 75 -81 83 -12 4 -23 9 -26 13 -3 3 -18 13 -34 21 -65 33 -30
53 46 27 36 -13 39 -27 8 -41 -8 -3 -4 -4 9 -2 13 3 35 -3 49 -12 15 -9 23
-12 19 -6 -6 11 19 41 27 33 12 -12 15 -83 5 -96 -9 -11 -9 -12 4 -8 10 5 17
-1 22 -17 11 -41 28 -68 42 -68 10 0 11 -5 3 -19 -7 -13 -7 -22 0 -26 5 -3 10
1 10 9 0 11 8 15 25 14 31 -3 31 -7 2 -49 -32 -47 -50 -96 -42 -109 4 -7 -2
-18 -14 -27 -12 -8 -20 -20 -18 -26 3 -7 -4 -27 -15 -45 -11 -17 -18 -38 -15
-45 4 -13 66 80 67 101 0 6 5 12 10 12 6 0 10 -12 10 -28 0 -15 7 -38 16 -50
9 -13 13 -29 10 -37 -3 -8 0 -17 5 -21 6 -3 8 -17 5 -30 -4 -13 -3 -42 0 -64
6 -37 9 -40 37 -40 20 0 41 -10 59 -27 16 -15 37 -28 46 -28 12 0 20 -15 29
-50 l12 -50 -2 50 c-2 47 -1 50 24 53 20 2 32 -4 49 -28 20 -28 23 -29 34 -14
15 21 61 27 75 11 5 -7 13 -25 17 -41 6 -25 4 -27 -12 -23 -24 8 -72 -29 -57
-44 7 -7 14 -5 23 6 16 19 30 19 30 1 0 -8 5 -16 11 -18 9 -3 9 -9 1 -24 -6
-11 -7 -26 -2 -33 6 -10 10 -8 15 8 7 25 36 25 42 1 4 -13 3 -13 -6 0 -9 13
-11 13 -11 -2 0 -9 5 -20 10 -23 6 -3 10 -17 10 -30 0 -13 5 -27 10 -30 6 -4
10 14 10 48 0 30 5 68 11 86 10 29 12 30 25 14 8 -12 11 -31 7 -56 -4 -27 -1
-43 11 -56 10 -11 14 -25 10 -37 -5 -13 -4 -16 4 -9 6 6 12 26 14 45 l3 35 18
-37 c13 -26 27 -39 49 -43 59 -13 89 20 104 115 3 25 13 62 22 84 15 37 14 40
-18 102 -34 66 -34 94 -1 94 11 0 26 6 33 13 18 19 38 10 38 -16 1 -19 2 -20
10 -5 9 15 15 15 75 -5 64 -22 65 -22 102 -95 22 -43 39 -68 44 -61 4 7 -1 21
-11 32 -11 11 -22 34 -26 51 -3 17 -12 35 -18 39 -7 6 -6 7 4 4 8 -2 32 7 54
21 52 32 59 23 60 -87 1 -48 4 -56 37 -85 20 -17 40 -38 43 -46 3 -8 4 -3 1
11 -7 37 21 46 51 18 18 -17 26 -19 43 -10 12 7 21 16 21 21 0 15 21 12 44 -7
18 -15 18 -14 -1 8 -27 30 -28 35 -7 43 8 3 12 2 9 -4 -3 -6 0 -10 8 -10 8 0
27 -7 43 -15 36 -18 40 -14 18 20 -13 20 -14 29 -5 34 9 6 8 11 -4 21 -22 18
-19 40 5 37 11 -2 23 -6 27 -10 3 -4 21 -5 39 -1 26 5 34 2 43 -15 12 -22 31
-28 31 -10 0 9 -22 60 -63 149 -7 14 -63 76 -125 139 -82 83 -111 119 -107
132 4 11 -2 23 -15 32 -14 9 -17 16 -9 19 12 4 6 48 -21 158 -6 25 -13 85 -14
133 -2 73 0 92 16 112 19 25 24 55 9 55 -5 0 -23 -8 -41 -17 -29 -17 -31 -17
-41 0 -24 42 -60 69 -96 74 -22 3 -50 16 -68 32 -62 57 -101 74 -165 69 l-59
-4 14 27 c19 35 74 69 114 69 31 0 32 0 17 29 -9 16 -13 31 -9 34 8 8 65 -22
80 -42 6 -9 14 -13 18 -9 4 3 -16 23 -44 44 -28 21 -51 42 -51 46 0 4 -9 8
-19 8 -11 0 -23 5 -26 11 -5 7 -15 4 -32 -6 -21 -14 -29 -15 -49 -4 -21 11
-22 14 -8 23 16 12 11 66 -7 66 -5 0 -9 6 -9 14 0 10 -11 13 -40 12 -29 -2
-46 4 -65 21 -14 13 -25 27 -25 31 0 28 24 82 45 102 20 19 27 21 40 10 19
-16 19 -47 0 -58 -9 -5 -13 -15 -10 -24 7 -17 45 -32 45 -17 0 5 -4 9 -8 9 -4
0 -8 25 -8 55 l0 55 43 0 c53 0 55 -15 16 -85 -15 -27 -21 -45 -14 -40 13 7
11 -6 -6 -52 -3 -8 4 -13 17 -13 13 0 28 -7 34 -16 22 -30 66 14 66 67 0 11 5
30 11 41 7 13 8 28 2 42 -34 76 -60 120 -87 144 -37 33 -68 95 -59 118 4 12 3
15 -6 10 -16 -11 -46 17 -88 82 -19 29 -41 56 -49 59 -19 7 -44 -15 -44 -39 0
-15 -5 -18 -20 -13 -22 7 -26 -5 -8 -23 6 -6 9 -14 6 -17 -9 -9 -32 5 -56 35
-21 27 -22 33 -11 69 9 32 9 46 -2 73 -7 17 -12 47 -10 65 2 30 -1 35 -34 48
-20 9 -33 20 -30 24 2 5 -1 20 -8 34 -10 19 -17 23 -28 16 -21 -13 -25 -12
-64 23 -29 24 -35 36 -30 55 3 13 0 33 -6 46 -14 26 -59 66 -76 67 -20 0 -46
48 -41 75 4 19 0 25 -16 27 -14 2 -24 14 -31 36 -11 39 -25 42 -25 5 l0 -28
-25 29 c-19 22 -28 27 -37 18 -16 -16 -45 3 -53 35 -9 42 -68 136 -87 139 -9
1 -20 -2 -26 -8z m211 -262 c15 -4 27 -11 27 -17 0 -6 9 -22 20 -36 14 -18 20
-41 20 -74 0 -51 -13 -70 -28 -43 -14 24 -30 9 -24 -22 3 -19 1 -25 -7 -20 -6
4 -11 13 -11 20 0 20 -24 36 -38 27 -10 -6 -10 -15 -2 -36 12 -32 7 -43 -14
-26 -11 9 -19 9 -34 0 -17 -10 -25 -10 -46 5 -63 41 -83 121 -42 167 35 40
102 71 136 64 8 -2 27 -6 43 -9z m-171 -318 c10 -4 10 -10 0 -32 -7 -17 -9
-33 -4 -43 7 -12 10 -9 15 13 11 52 22 59 47 34 12 -12 18 -25 14 -29 -13 -9
-16 -41 -6 -54 6 -7 12 -21 14 -32 2 -17 -2 -19 -29 -17 -19 2 -33 -1 -33 -7
0 -6 10 -11 23 -11 33 0 54 -20 61 -58 4 -24 2 -34 -9 -38 -23 -9 -18 -24 7
-21 38 4 32 -29 -15 -77 -40 -42 -48 -46 -90 -46 -28 0 -47 5 -47 11 0 6 -12
25 -26 42 -23 27 -25 36 -20 91 3 34 6 69 6 79 2 22 49 47 90 47 21 0 30 4 27
13 -2 8 -24 12 -68 11 -54 -2 -64 -4 -61 -19 2 -10 -1 -14 -7 -10 -16 9 -14
20 13 52 12 15 21 34 19 40 -3 7 3 33 13 58 17 45 17 45 35 26 10 -10 24 -21
31 -23z m302 -4 c9 -3 16 -12 16 -19 0 -7 8 -15 19 -18 26 -6 35 -112 12 -144
-16 -23 -41 -31 -41 -14 0 5 -7 17 -16 26 -14 13 -14 23 -6 57 12 47 5 70 -14
44 -12 -17 -13 -16 -23 3 -15 27 -14 36 3 22 16 -13 32 2 21 19 -3 6 -3 15 0
20 7 12 8 12 29 4z m136 -45 c0 -4 -7 -6 -15 -3 -8 4 -15 10 -15 15 0 4 7 6
15 3 8 -4 15 -10 15 -15z m-251 -40 c17 -49 13 -55 -12 -18 -13 19 -22 37 -20
42 9 15 21 6 32 -24z m45 2 c13 -25 14 -31 1 -41 -12 -10 -17 -6 -28 22 -10
23 -11 35 -3 40 15 10 14 11 30 -21z m240 23 c20 -8 21 -33 1 -40 -23 -9 -48
15 -34 32 13 16 12 16 33 8z m-34 -39 c7 -8 22 -15 34 -15 18 0 22 -6 23 -40
2 -43 -14 -63 -29 -36 -5 9 -13 14 -17 12 -10 -7 -33 42 -26 54 3 5 1 11 -5
15 -12 8 -13 25 -1 25 5 0 14 -7 21 -15z m182 -218 c28 -23 36 -36 36 -62 0
-19 -7 -40 -17 -49 -16 -15 -19 -14 -37 7 -10 12 -27 35 -37 50 -10 16 -21 26
-24 23 -9 -9 31 -86 43 -82 6 3 17 -1 23 -7 13 -13 -14 -47 -38 -47 -24 1 -51
25 -51 46 0 10 -9 30 -20 44 -27 35 -25 50 10 56 16 4 28 10 25 14 -2 4 3 17
11 29 17 23 20 22 76 -22z m97 -79 c23 -63 23 -66 -14 -102 -30 -30 -39 -34
-55 -26 -26 14 -25 5 0 -20 25 -25 25 -42 1 -57 -17 -11 -24 -7 -57 29 l-37
42 23 15 c43 30 101 93 113 124 9 23 16 22 26 -5z m-279 -8 c0 -5 -4 -10 -10
-10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m25 -46 c9 -14 14
-29 10 -32 -7 -7 -45 34 -45 49 0 17 18 9 35 -17z m-552 2 c12 -8 40 -17 62
-20 22 -3 63 -9 91 -14 44 -7 53 -6 63 10 12 19 46 24 56 8 4 -6 10 -8 15 -5
4 3 14 -2 21 -11 11 -13 10 -21 -6 -50 -18 -34 -35 -45 -35 -22 0 6 -3 9 -6 5
-3 -3 -2 -11 2 -18 17 -28 14 -68 -6 -82 -15 -11 -18 -22 -15 -63 5 -71 -8
-87 -40 -50 -13 16 -22 33 -19 37 12 21 -29 7 -51 -16 -15 -18 -26 -22 -30
-15 -5 8 -28 10 -66 7 -66 -6 -85 6 -51 34 28 22 27 26 -3 94 -21 47 -23 65
-18 120 3 36 8 65 10 65 2 0 14 -6 26 -14z m367 -17 c0 -6 -4 -7 -10 -4 -5 3
-10 11 -10 16 0 6 5 7 10 4 6 -3 10 -11 10 -16z m236 -72 c21 -18 58 -45 82
-61 23 -16 42 -30 42 -32 0 -2 -18 -4 -40 -4 -36 0 -41 3 -54 34 -17 40 -42
61 -31 26 5 -16 3 -21 -6 -18 -14 5 -53 88 -40 88 4 0 25 -15 47 -33z m-169
-27 c9 -16 21 -30 25 -30 4 0 8 -5 8 -11 0 -8 -5 -7 -15 1 -25 21 -57 70 -46
70 5 0 18 -13 28 -30z m-1064 -7 c24 -13 28 -18 15 -21 -20 -4 -58 14 -58 28
0 14 6 13 43 -7z m1137 -4 c0 -5 -4 -9 -10 -9 -5 0 -10 7 -10 16 0 8 5 12 10
9 6 -3 10 -10 10 -16z m40 -41 c8 -18 21 -34 29 -36 8 -3 11 0 7 7 -4 6 -2 11
3 11 6 0 11 8 12 18 0 14 2 13 9 -4 9 -22 -8 -104 -22 -104 -12 0 -58 78 -65
110 -7 39 8 38 27 -2z m-200 -18 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8
10 11 10 2 0 4 -4 4 -10z m70 -62 c0 -6 -9 1 -20 15 -11 14 -20 29 -20 34 1
11 40 -37 40 -49z m312 36 c11 -7 10 -30 -1 -52 -5 -9 -17 -36 -26 -59 -19
-46 -24 -50 -43 -31 -9 9 -13 7 -18 -5 -3 -9 -12 -17 -20 -17 -7 0 -11 6 -8
14 3 8 0 24 -6 36 -16 30 -5 57 15 40 14 -11 14 -9 5 16 -9 23 -8 31 4 40 9 6
16 17 16 24 0 9 10 10 37 5 21 -4 41 -9 45 -11z m-1257 -43 c9 -16 -17 -61
-37 -61 -16 0 -17 3 -8 20 6 11 15 20 20 20 5 0 7 7 4 15 -7 16 11 21 21 6z
m818 -26 c-3 -9 1 -15 11 -15 9 0 16 -7 16 -16 0 -8 -4 -12 -10 -9 -5 3 -10 1
-10 -4 0 -6 -8 -11 -19 -11 -14 0 -17 6 -14 28 7 43 12 54 22 47 6 -3 8 -12 4
-20z m257 -16 c0 -11 -4 -17 -10 -14 -5 3 -10 13 -10 21 0 8 5 14 10 14 6 0
10 -9 10 -21z m-1015 -40 c4 -11 4 -23 0 -27 -7 -7 -25 17 -25 36 0 20 18 14
25 -9z m820 11 c3 -5 2 -10 -4 -10 -5 0 -13 5 -16 10 -3 6 -2 10 4 10 5 0 13
-4 16 -10z m244 -42 c-8 -7 -23 -14 -34 -15 -11 -1 -14 0 -7 3 6 3 12 14 12
23 0 26 12 33 28 17 13 -12 13 -16 1 -28z m-461 0 c7 -7 12 -18 12 -26 0 -16
-24 -42 -38 -42 -6 0 -13 -14 -17 -30 -4 -17 -15 -35 -26 -40 -10 -6 -18 -16
-16 -23 1 -7 -4 -12 -10 -11 -7 1 -25 2 -39 3 -18 1 -24 5 -21 15 3 8 2 16 -3
18 -4 2 -10 22 -11 45 -3 44 16 77 56 94 31 12 100 10 113 -3z m806 -52 c-3
-11 -8 -30 -11 -41 -3 -11 -14 -28 -24 -38 -11 -10 -19 -26 -19 -37 0 -20 -27
-40 -54 -40 -9 0 -16 7 -16 15 0 8 -6 12 -14 9 -24 -9 -26 11 -7 62 23 59 46
73 39 23 -3 -19 -8 -43 -12 -54 -3 -11 9 11 28 49 37 74 48 86 77 79 14 -4 18
-11 13 -27z m-764 -17 c0 -6 -4 -7 -10 -4 -5 3 -10 11 -10 16 0 6 5 7 10 4 6
-3 10 -11 10 -16z m380 -49 c0 -6 -14 -23 -31 -38 -26 -23 -30 -32 -22 -48 9
-16 10 -30 3 -79 -3 -23 -37 63 -38 97 -1 31 4 40 31 56 36 23 57 27 57 12z
m-260 -20 c0 -5 18 -10 40 -10 41 0 63 -21 44 -40 -13 -13 -81 7 -92 27 -6 10
-16 14 -26 10 -16 -6 -22 4 -9 16 10 11 43 8 43 -3z m321 -72 c28 -32 20 -55
-8 -27 -15 15 -44 4 -37 -15 9 -23 -14 -28 -27 -5 -10 19 -7 25 17 45 36 29
33 29 55 2z m-231 -8 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2
0 4 -4 4 -10z m390 -21 c0 -6 -4 -7 -10 -4 -5 3 -10 11 -10 16 0 6 5 7 10 4 6
-3 10 -11 10 -16z m-63 -11 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1
-19z m201 -15 c2 -14 -4 -26 -18 -33 -24 -13 -40 -6 -40 19 0 41 52 54 58 14z
m-769 -45 c5 -14 5 -28 -1 -35 -8 -10 -3 -13 21 -13 17 0 31 -4 31 -10 0 -19
-48 -22 -80 -5 -20 10 -42 14 -61 10 -22 -5 -29 -3 -29 8 0 21 28 47 50 47 10
0 22 5 25 10 11 18 35 11 44 -12z m650 -2 c16 -30 6 -53 -15 -35 -15 12 -20
59 -6 59 5 0 14 -11 21 -24z m-122 6 c16 -5 20 -11 14 -20 -6 -10 -21 -12 -55
-7 -45 7 -58 18 -33 28 16 7 47 6 74 -1z m-232 -21 c6 -10 -37 -61 -51 -61 -5
0 -20 7 -33 17 l-24 16 34 18 c38 20 65 24 74 10z m319 -26 c22 -33 20 -48 -9
-75 l-24 -23 -17 36 c-31 64 13 118 50 62z m77 -2 c-8 -15 -10 -15 -11 -2 0
17 10 32 18 25 2 -3 -1 -13 -7 -23z m-1671 -59 c7 -8 23 -14 34 -13 26 3 66
-9 66 -21 0 -19 -71 -30 -193 -30 -72 0 -127 3 -124 6 16 17 155 74 178 74 15
0 32 -7 39 -16z m1230 6 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10
2 0 4 -4 4 -10z m414 -17 c-11 -47 -44 -74 -44 -36 0 14 36 63 47 63 2 0 0
-12 -3 -27z m-1477 -16 c-4 -12 -36 -19 -50 -10 -5 3 1 12 14 19 25 13 42 9
36 -9z m1017 -69 c8 -14 5 -23 -19 -48 -15 -16 -26 -33 -22 -36 3 -4 0 -22 -8
-41 -18 -44 -19 -48 -1 -34 11 9 15 8 21 -6 6 -17 7 -17 16 0 16 27 34 20 36
-16 2 -24 13 -42 47 -72 35 -31 43 -43 36 -55 -5 -8 -10 -10 -10 -5 0 6 -4 4
-9 -3 -8 -13 -59 -24 -74 -15 -4 2 -2 13 4 25 8 15 8 19 0 14 -6 -4 -11 0 -11
11 0 15 -2 16 -10 3 -6 -10 -6 -16 0 -18 13 -5 3 -22 -12 -22 -6 0 -8 3 -5 6
3 4 2 15 -4 26 -10 18 -11 18 -35 -1 -15 -12 -32 -17 -42 -13 -16 6 -16 7 1
10 9 2 17 8 17 13 0 9 -9 18 -67 71 -28 25 -30 31 -28 94 3 78 17 94 66 73 23
-9 33 -9 42 0 9 9 6 13 -11 17 -28 8 -19 24 14 24 13 0 24 5 24 11 0 16 31 7
44 -13z m-536 -74 c22 -15 22 -34 -1 -34 -20 1 -49 28 -42 39 8 15 17 14 43
-5z m252 -11 c0 -20 -3 -24 -11 -16 -7 7 -9 19 -6 27 10 24 17 19 17 -11z
m510 -115 c14 -13 27 -22 30 -20 3 2 6 -4 8 -12 2 -11 -4 -16 -17 -16 -32 0
-35 -1 -48 -12 -9 -7 -13 -7 -13 1 0 6 -9 11 -20 11 -11 0 -20 6 -20 14 0 8 9
18 20 21 11 3 20 13 20 21 0 20 13 17 40 -8z m50 -139 c0 -45 -5 -47 -37 -17
-29 27 -29 42 0 55 31 14 37 8 37 -38z m805 -49 c10 -11 16 -20 13 -20 -3 0
-13 9 -23 20 -10 11 -16 20 -13 20 3 0 13 -9 23 -20z m-1446 -163 c11 -28 29
-196 22 -202 -2 -3 -12 -5 -22 -5 -18 0 -26 16 -35 67 -3 15 -9 32 -14 38 -4
5 -10 28 -13 50 -5 33 -2 43 16 57 29 23 36 22 46 -5z m-770 -166 c11 -7 10
-11 -7 -20 -15 -8 -23 -7 -31 3 -19 22 9 35 38 17z m1642 -62 c4 -19 7 -26 8
-16 1 18 31 25 31 7 0 -5 0 -12 0 -15 0 -3 -3 -5 -6 -4 -4 0 -11 -5 -15 -13
-7 -10 -16 -6 -39 19 -33 35 -37 49 -17 56 22 9 32 1 38 -34z m-1669 5 c-12
-8 -22 -20 -22 -25 0 -6 -8 -18 -18 -27 -10 -10 -12 -13 -5 -8 25 16 27 -4 3
-29 -39 -42 -17 -37 28 5 23 22 48 40 57 40 18 0 20 -41 3 -59 -11 -10 -8 -11
12 -7 23 6 61 -8 50 -17 -33 -26 -89 -49 -127 -52 -27 -2 -65 -11 -85 -20 -21
-8 -49 -15 -64 -15 l-26 0 36 50 c20 28 36 52 36 55 0 8 59 92 72 102 12 10
38 19 63 22 5 0 -1 -6 -13 -15z m2203 6 c3 -5 -1 -10 -9 -10 -9 0 -16 5 -16
10 0 6 4 10 9 10 6 0 13 -4 16 -10z m-1381 -62 c12 -123 14 -168 7 -173 -4 -2
-21 9 -36 25 -16 17 -24 30 -18 30 7 0 11 15 11 33 0 26 8 126 11 135 1 1 5 2
10 2 5 0 12 -24 15 -52z m926 -24 c0 -14 -12 -19 -23 -8 -9 9 4 35 14 28 5 -3
9 -12 9 -20z m-1927 -62 c-26 -48 -39 -62 -60 -62 -25 0 -12 37 26 79 50 55
67 46 34 -17z m927 -41 c16 -30 12 -41 -14 -41 -17 0 -25 7 -28 25 -8 39 23
51 42 16z m-65 -11 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8
-4 11 -10z m134 -65 c3 -19 2 -19 -16 -3 -20 18 -51 9 -39 -11 4 -6 3 -11 -3
-11 -6 0 -8 -8 -4 -17 3 -10 3 -15 -1 -12 -4 4 -9 19 -12 35 -3 15 -7 30 -9
33 -1 3 16 6 38 6 34 0 42 -4 46 -20z m-351 -3 c7 -5 10 -16 6 -25 -8 -21 -20
-22 -28 -1 -13 33 -4 42 22 26z m67 -58 c16 -38 13 -54 -10 -54 -18 0 -29 35
-21 66 8 32 13 30 31 -12z m205 6 c11 -21 -1 -27 -34 -14 -13 5 -14 9 -5 20
16 19 26 18 39 -6z m-349 -109 c6 -22 8 -48 4 -58 -8 -18 -10 -15 -32 75 -10
39 16 24 28 -17z m79 -5 c0 -12 -30 -46 -42 -46 -15 0 -8 29 10 40 16 11 16
11 0 6 -21 -6 -24 14 -6 32 10 10 15 9 25 -5 7 -9 13 -21 13 -27z m294 9 c15
-14 25 -29 21 -34 -3 -5 -1 -12 5 -16 26 -16 0 -75 -33 -75 -25 0 -60 47 -62
85 -2 38 8 46 24 18 5 -10 12 -15 15 -12 3 3 -3 15 -14 26 -14 15 -16 23 -7
26 20 9 23 8 51 -18z m66 -37 c0 -6 -7 -5 -15 2 -8 7 -15 17 -15 22 0 6 7 5
15 -2 8 -7 15 -17 15 -22z m-182 -113 c16 -34 15 -40 -2 -26 -16 13 -28 51
-17 51 4 0 13 -11 19 -25z m-238 -63 c0 -7 -4 -10 -10 -7 -5 3 -10 16 -10 28
0 18 2 19 10 7 5 -8 10 -21 10 -28z m260 -65 c0 -11 -5 -17 -12 -15 -8 3 -14
24 -15 49 l-2 44 14 -30 c8 -16 14 -38 15 -48z m19 -54 c11 -25 7 -43 -9 -43
-10 0 -40 52 -40 69 0 18 38 -1 49 -26z m-79 -204 c0 -24 -4 -38 -10 -34 -17
10 -11 75 7 75 2 0 3 -18 3 -41z"/>
<path d="M6800 9236 c0 -2 8 -10 18 -17 15 -13 16 -12 3 4 -13 16 -21 21 -21
13z"/>
<path d="M6357 8756 c-13 -33 -18 -104 -8 -110 17 -11 27 27 21 79 -4 37 -7
45 -13 31z"/>
<path d="M5640 9680 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M3305 9610 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M3818 9565 c-14 -14 19 -56 52 -65 58 -16 63 -12 29 30 -29 36 -64
51 -81 35z"/>
<path d="M5652 9309 c1 -12 9 -24 16 -27 9 -3 13 3 10 19 -1 12 -9 24 -16 27
-9 3 -13 -3 -10 -19z"/>
<path d="M7235 8730 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M7276 8575 c7 -17 20 -20 28 -6 3 5 -1 12 -10 15 -22 9 -24 8 -18 -9z"/>
<path d="M7495 8363 c0 -14 6 -28 13 -31 10 -3 13 4 10 24 -4 36 -24 42 -23 7z"/>
<path d="M6466 7683 c-6 -14 -5 -15 5 -6 7 7 10 15 7 18 -3 3 -9 -2 -12 -12z"/>
<path d="M7756 7669 c3 -5 11 -9 19 -9 8 0 16 4 19 9 3 5 -5 9 -19 9 -14 0
-22 -4 -19 -9z"/>
<path d="M7779 7610 c19 -16 51 -37 70 -46 20 -9 50 -26 66 -38 47 -35 63 -41
30 -12 -16 15 -51 37 -76 51 -25 13 -60 35 -77 49 -16 15 -34 26 -38 26 -5 -1
6 -14 25 -30z"/>
<path d="M6415 7599 c-4 -6 -5 -12 -2 -15 2 -3 7 2 10 11 7 17 1 20 -8 4z"/>
<path d="M7310 7284 c0 -8 5 -12 10 -9 6 4 8 11 5 16 -9 14 -15 11 -15 -7z"/>
<path d="M7350 7273 c0 -12 7 -23 15 -27 13 -5 14 -2 4 17 -17 32 -19 33 -19
10z"/>
<path d="M7346 7231 c-4 -7 -5 -15 -2 -18 9 -9 19 4 14 18 -4 11 -6 11 -12 0z"/>
<path d="M7371 7205 c0 -11 4 -27 9 -35 9 -13 10 -13 9 0 0 8 -4 24 -9 35 l-9
20 0 -20z"/>
<path d="M7525 7210 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M4555 7200 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M7680 7067 c0 -5 7 -30 16 -58 9 -27 14 -59 11 -71 -4 -15 1 -30 14
-45 18 -18 21 -34 20 -89 -1 -85 -13 -100 -49 -58 -15 17 -35 45 -45 63 -18
35 -40 37 -45 4 -2 -11 10 -56 27 -101 16 -44 27 -83 24 -86 -12 -12 -52 19
-55 42 -4 33 -32 51 -55 36 -40 -25 -22 -74 27 -74 57 0 89 -23 119 -87 16
-33 32 -74 36 -92 8 -36 65 -81 104 -81 21 0 23 3 17 32 -9 47 -38 97 -80 142
-20 21 -35 43 -33 49 2 6 -2 22 -9 35 -14 27 -10 47 15 76 13 14 16 39 14 115
-1 87 -5 104 -37 176 -19 44 -36 76 -36 72z"/>
<path d="M3395 6890 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M5536 6888 c-3 -7 -7 -30 -10 -51 l-5 -38 30 37 c16 20 27 39 24 42
-3 3 -12 -3 -21 -14 -15 -18 -15 -18 -11 9 4 27 0 37 -7 15z"/>
<path d="M5440 6865 c-7 -9 -8 -15 -2 -15 5 0 12 7 16 15 3 8 4 15 2 15 -2 0
-9 -7 -16 -15z"/>
<path d="M6843 6823 c51 -4 54 -7 32 -30 -26 -25 -5 -36 22 -11 19 18 36 23
73 22 36 0 47 3 43 13 -4 10 -31 13 -112 11 -58 -1 -85 -3 -58 -5z"/>
<path d="M7118 6824 c-3 -3 -3 -14 -1 -25 5 -16 8 -18 14 -7 16 25 6 51 -13
32z"/>
<path d="M5082 6780 c7 -11 16 -20 20 -20 5 0 3 9 -4 20 -7 11 -16 20 -20 20
-5 0 -3 -9 4 -20z"/>
<path d="M7050 6775 c-7 -8 -9 -15 -4 -15 4 0 1 -6 -8 -12 -11 -9 -13 -20 -8
-38 7 -21 8 -22 9 -5 1 11 10 31 21 45 11 14 20 28 20 33 0 12 -17 8 -30 -8z"/>
<path d="M5142 6754 c10 -10 20 -15 24 -12 3 4 -5 12 -18 18 -22 12 -22 12 -6
-6z"/>
<path d="M8260 6739 c7 -11 14 -18 17 -15 8 8 -5 36 -17 36 -7 0 -7 -6 0 -21z"/>
<path d="M5217 6704 c-21 -14 -41 -33 -44 -42 -3 -9 -16 -30 -28 -47 -32 -44
-37 -55 -28 -55 5 0 22 23 38 51 29 49 71 89 94 89 17 0 13 -34 -9 -90 -11
-28 -20 -54 -20 -58 1 -14 32 51 46 96 26 88 17 99 -49 56z"/>
<path d="M7090 6700 c-1 -3 -3 -25 -5 -50 l-4 -45 10 39 c5 22 7 44 5 50 -2 6
-5 9 -6 6z"/>
<path d="M8310 6672 c0 -12 19 -26 26 -19 2 2 -2 10 -11 17 -9 8 -15 8 -15 2z"/>
<path d="M5556 6635 c-9 -26 -7 -32 5 -12 6 10 9 21 6 23 -2 3 -7 -2 -11 -11z"/>
<path d="M6330 6620 c0 -19 -7 -44 -15 -54 -8 -10 -12 -23 -9 -27 8 -14 44
-11 45 4 0 6 5 2 11 -10 13 -27 46 -30 55 -7 7 20 -22 45 -42 38 -7 -3 -18 3
-24 14 -9 17 -8 21 5 24 14 3 14 6 -5 28 l-20 25 -1 -35z"/>
<path d="M5705 6610 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M5575 6594 c-4 -10 -9 -28 -11 -39 -3 -14 1 -12 12 8 8 16 14 33 11
40 -2 6 -7 2 -12 -9z"/>
<path d="M5002 6579 c-12 -19 -11 -19 7 -10 26 14 36 31 19 31 -7 0 -19 -9
-26 -21z"/>
<path d="M5520 6580 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M4410 6573 c-36 -5 -80 -37 -80 -59 0 -25 63 -3 97 32 18 19 29 33
25 32 -4 0 -23 -3 -42 -5z"/>
<path d="M7065 6569 c-4 -6 -5 -12 -2 -15 2 -3 7 2 10 11 7 17 1 20 -8 4z"/>
<path d="M5297 6535 c-15 -14 -27 -29 -27 -32 0 -4 14 6 30 22 28 26 34 35 27
35 -1 0 -15 -11 -30 -25z"/>
<path d="M4954 6528 l-19 -23 23 19 c21 18 27 26 19 26 -2 0 -12 -10 -23 -22z"/>
<path d="M5202 6511 c-6 -17 -9 -31 -6 -31 7 0 25 52 20 57 -2 2 -8 -10 -14
-26z"/>
<path d="M5053 6493 c-10 -16 -22 -28 -28 -29 -22 -2 -55 -17 -55 -24 0 -4
-27 -24 -59 -44 -32 -20 -62 -44 -66 -53 -6 -17 -7 -17 -16 0 -8 13 -7 22 2
33 10 12 9 13 -7 8 -16 -6 -15 -3 5 19 36 39 11 33 -28 -7 -25 -26 -31 -39
-26 -56 6 -17 12 -21 33 -16 l27 6 -30 -25 c-16 -14 -33 -25 -37 -25 -5 0 -8
-7 -8 -15 0 -8 1 -15 3 -15 1 0 34 30 72 68 105 100 230 158 166 77 -14 -18
-14 -19 1 -5 16 14 81 130 73 130 -1 0 -11 -12 -22 -27z"/>
<path d="M4300 6490 c-9 -6 -10 -10 -3 -10 6 0 15 5 18 10 8 12 4 12 -15 0z"/>
<path d="M5155 6429 c-3 -4 1 -9 9 -12 14 -6 26 0 26 13 0 9 -30 9 -35 -1z"/>
<path d="M8943 6403 c-18 -7 -16 -23 2 -23 8 0 15 7 15 15 0 8 -1 15 -2 14 -2
0 -9 -3 -15 -6z"/>
<path d="M8047 6387 c-3 -8 -1 -20 4 -28 7 -12 11 -11 25 3 17 17 12 38 -11
38 -7 0 -15 -6 -18 -13z"/>
<path d="M4727 6304 c-16 -9 -27 -17 -25 -20 5 -5 68 25 68 32 0 7 -15 3 -43
-12z"/>
<path d="M4520 6210 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"/>
<path d="M5099 6203 c-13 -16 -12 -17 4 -4 16 13 21 21 13 21 -2 0 -10 -8 -17
-17z"/>
<path d="M3990 6189 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M6740 6190 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M4943 6138 c-25 -32 -26 -40 -4 -26 12 7 21 18 21 25 0 16 -5 16 -17
1z"/>
<path d="M7496 6141 c-3 -5 1 -11 9 -15 8 -3 15 -1 15 4 0 13 -18 22 -24 11z"/>
<path d="M4016 6043 c-6 -14 -5 -15 5 -6 7 7 10 15 7 18 -3 3 -9 -2 -12 -12z"/>
<path d="M6145 6040 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M6416 6030 c12 -45 124 -122 124 -85 0 7 -15 18 -32 24 -18 6 -48 28
-66 49 -29 33 -32 34 -26 12z"/>
<path d="M7108 5999 c-10 -6 -18 -18 -18 -29 0 -26 38 -36 58 -16 15 15 15 18
-3 36 -15 14 -24 16 -37 9z"/>
<path d="M4525 5950 c-46 -47 -156 -109 -257 -146 -33 -11 -39 -11 -47 3 -7
13 -10 7 -16 -25 -4 -26 -14 -46 -26 -53 -11 -5 -17 -14 -14 -20 4 -5 26 10
50 33 31 32 80 60 185 108 117 53 147 71 166 100 46 68 26 68 -41 0z"/>
<path d="M6200 5952 c0 -10 -3 -22 -7 -26 -4 -4 -8 -58 -10 -120 -1 -62 -7
-133 -12 -157 -6 -24 -10 -64 -10 -89 1 -44 2 -43 20 30 14 55 18 96 15 153
-8 119 10 101 55 -53 11 -41 26 -81 31 -87 6 -7 23 -59 38 -115 16 -57 29 -98
30 -93 1 6 10 -12 21 -40 16 -42 18 -44 13 -15 -7 46 -33 137 -49 176 -28 66
-105 306 -105 327 0 12 -6 34 -14 48 -7 15 -11 39 -9 53 3 14 3 26 -1 26 -3 0
-6 -8 -6 -18z"/>
<path d="M6285 5960 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M4130 5870 c-108 -55 -190 -65 -190 -22 0 10 -9 23 -19 29 -15 10
-22 9 -33 -5 -31 -36 -33 -45 -5 -38 21 5 29 1 46 -22 l20 -29 -22 -21 c-32
-33 -7 -26 33 8 19 17 46 33 60 37 14 3 59 23 102 45 90 45 112 48 103 16 -5
-20 -5 -21 5 -5 13 21 6 47 -12 47 -7 -1 -47 -18 -88 -40z"/>
<path d="M6611 5654 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M3864 5635 c-18 -13 -18 -14 4 -8 12 3 25 9 28 14 8 14 -10 11 -32
-6z"/>
<path d="M3750 5610 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M6078 5590 c-7 -24 -3 -38 8 -28 3 4 4 17 2 30 l-3 23 -7 -25z"/>
<path d="M3780 5574 c-35 -25 -49 -44 -33 -44 5 0 24 16 43 35 40 41 38 42
-10 9z"/>
<path d="M6035 5551 c-6 -11 9 -23 19 -14 9 9 7 23 -3 23 -6 0 -12 -4 -16 -9z"/>
<path d="M6150 5460 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M3750 5450 c0 -5 4 -10 9 -10 6 0 13 5 16 10 3 6 -1 10 -9 10 -9 0
-16 -4 -16 -10z"/>
<path d="M5921 5381 c-1 -74 1 -82 14 -71 9 7 12 16 8 19 -5 3 -8 22 -8 41 0
19 -2 49 -6 65 -5 20 -7 1 -8 -54z"/>
<path d="M6550 5384 c0 -6 7 -19 15 -30 8 -10 14 -14 14 -9 0 6 -6 19 -14 29
-8 11 -15 15 -15 10z"/>
<path d="M7140 5300 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M5957 5240 c3 -36 12 -94 20 -130 9 -35 19 -110 23 -165 l8 -100 -1
92 c-1 50 1 89 6 86 4 -2 7 -12 7 -21 0 -9 9 -28 20 -42 20 -24 21 -25 35 -6
8 11 15 35 15 53 0 21 5 33 14 33 10 0 17 -28 26 -97 21 -166 79 -370 141
-494 50 -101 83 -139 119 -139 17 0 33 -7 39 -17 14 -24 14 2 1 28 -8 14 -17
18 -36 13 -36 -9 -59 16 -115 129 -44 87 -71 162 -86 237 -3 14 -11 45 -18 70
-18 58 -48 267 -40 279 7 12 -5 121 -14 121 -10 0 -41 -78 -42 -108 -1 -39
-13 -75 -25 -79 -13 -4 -51 104 -63 182 -5 33 -16 78 -25 100 l-15 40 6 -65z"/>
<path d="M5931 5274 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M6402 5253 c11 -40 35 -93 43 -93 2 0 -5 19 -15 43 -11 23 -20 50
-20 59 0 9 -4 19 -9 23 -5 3 -5 -11 1 -32z"/>
<path d="M3600 5255 c-11 -13 -10 -14 4 -9 9 3 16 10 16 15 0 13 -6 11 -20 -6z"/>
<path d="M6652 5216 c12 -25 24 -44 26 -42 5 6 -28 75 -39 82 -5 3 1 -15 13
-40z"/>
<path d="M6475 5100 c9 -16 18 -30 21 -30 2 0 -2 14 -11 30 -9 17 -18 30 -21
30 -2 0 2 -13 11 -30z"/>
<path d="M3028 5047 c-52 -46 -63 -68 -22 -40 27 17 84 71 84 79 0 12 -20 0
-62 -39z"/>
<path d="M6713 5066 c3 -7 9 -34 12 -60 4 -25 11 -46 15 -46 5 0 6 10 3 23 -5
21 -4 21 16 -3 19 -24 40 -68 66 -140 80 -224 103 -296 116 -355 8 -33 15 -52
17 -43 5 25 -43 229 -62 266 -9 17 -16 40 -16 52 0 11 -4 28 -10 38 -20 36
-60 129 -60 140 0 6 -12 24 -26 39 -14 15 -35 45 -45 66 -18 35 -37 52 -26 23z"/>
<path d="M6500 5053 c0 -17 43 -59 47 -47 2 6 -1 22 -6 35 -8 20 -10 21 -10 5
-1 -20 -1 -20 -16 0 -8 11 -15 14 -15 7z"/>
<path d="M3505 4910 c-27 -28 -45 -50 -39 -50 13 0 99 88 93 95 -3 2 -27 -18
-54 -45z"/>
<path d="M6561 4934 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M2906 4899 c3 -5 11 -9 19 -9 8 0 16 4 19 9 3 5 -5 9 -19 9 -14 0
-22 -4 -19 -9z"/>
<path d="M6571 4874 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M6580 4836 c0 -8 5 -18 10 -21 6 -3 10 1 10 9 0 8 -4 18 -10 21 -5 3
-10 -1 -10 -9z"/>
<path d="M6011 4794 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M3101 4667 c-12 -15 -5 -27 16 -27 7 0 13 9 13 20 0 23 -13 26 -29 7z"/>
<path d="M6031 4629 c0 -24 9 -75 19 -114 11 -38 17 -56 15 -40 -3 17 -9 65
-15 109 -11 89 -20 111 -19 45z"/>
<path d="M5098 4477 c-3 -19 -2 -25 4 -19 6 6 8 18 6 28 -3 14 -5 12 -10 -9z"/>
<path d="M6774 4411 c3 -24 11 -51 20 -59 13 -14 14 -10 0 34 -20 70 -25 75
-20 25z"/>
<path d="M6071 4414 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M5972 4350 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M8190 4252 c0 -10 11 -29 25 -42 l25 -23 0 25 c0 14 -8 32 -18 41
-24 22 -32 21 -32 -1z"/>
<path d="M7040 4250 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M6850 4234 c0 -15 27 -50 34 -43 4 3 -2 17 -13 30 -11 13 -20 19 -21
13z"/>
<path d="M6451 4225 c-1 -37 48 -215 60 -215 4 0 -2 27 -12 61 -11 34 -21 74
-24 89 -6 32 -24 80 -24 65z"/>
<path d="M7880 4138 c17 -35 30 -68 30 -74 0 -5 23 -31 50 -56 53 -49 61 -61
33 -51 -16 6 -16 4 1 -15 21 -24 46 -29 46 -9 0 6 -9 27 -20 45 -11 18 -20 38
-20 46 0 7 -17 33 -38 57 -20 24 -50 61 -65 82 -42 56 -50 45 -17 -25z"/>
<path d="M6102 4075 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z"/>
<path d="M9194 3955 c-8 -20 13 -45 38 -45 24 0 34 26 18 45 -16 19 -49 19
-56 0z"/>
<path d="M6567 3810 c-15 -56 -8 -120 18 -154 14 -18 25 -39 25 -45 0 -7 7
-27 15 -44 8 -18 21 -50 29 -72 8 -22 15 -33 15 -24 1 9 -10 47 -24 83 -14 37
-25 82 -25 102 0 19 -7 49 -16 65 -8 17 -19 49 -23 72 -6 38 -8 39 -14 17z"/>
<path d="M7094 3800 c2 -19 9 -51 15 -70 9 -31 10 -29 4 20 -3 30 -9 62 -15
70 -6 10 -8 4 -4 -20z"/>
<path d="M7121 3664 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M5530 3420 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M8115 3180 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M8350 3175 c-16 -20 -7 -75 22 -134 19 -37 27 -46 47 -43 13 1 39 -7
57 -19 31 -19 35 -19 41 -4 3 10 -1 29 -11 44 -9 14 -26 46 -38 71 -38 84 -89
120 -118 85z"/>
<path d="M7437 2985 c9 -19 18 -33 20 -31 6 6 -19 66 -28 66 -5 0 -1 -16 8
-35z"/>
</g>
</svg>
    )
    }
export default SplatterThree;